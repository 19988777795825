import { serializable, identifier, object, primitive, alias, list } from 'serializr'
import SmsPhoneNumber from './SmsPhoneNumber'
import CareUser from '@/models/CareUser'

// Generated by https://quicktype.io

export enum CaregiverStatus {
  Requested = 'requested',
  Invited = 'invitation_sent',
  Locked = 'invitation_locked',
  Verified = 'verified',
}

export default class CareRelationship {
  @serializable(identifier())
  id!: string

  @serializable(alias('carereceiver', object(CareUser)))
  careReceiver!: CareUser

  @serializable(object(CareUser))
  caregiver?: CareUser

  @serializable(primitive())
  status?: CaregiverStatus

  @serializable(alias('caregiver_email', primitive()))
  caregiverEmail!: string

  @serializable(alias('requested_caregiver_email_address', primitive()))
  requestedCaregiverEmailAddress?: string

  @serializable(alias('carereceiver_to_caregiver_relationship_type', primitive()))
  carereceiverToCaregiverRelationshipType?: string

  @serializable(alias('caregiver_to_carereceiver_relationship_type', primitive()))
  caregiverToCarereceiverRelationshipType?: string

  @serializable(alias('phone_numbers', list(object(SmsPhoneNumber))))
  phoneNumbers?: SmsPhoneNumber[]
}
