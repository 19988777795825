import { render, staticRenderFns } from "./StepProgressBarEdge.vue?vue&type=template&id=2bf531a3&scoped=true&"
import script from "./StepProgressBarEdge.vue?vue&type=script&lang=ts&"
export * from "./StepProgressBarEdge.vue?vue&type=script&lang=ts&"
import style0 from "./StepProgressBarEdge.vue?vue&type=style&index=0&id=2bf531a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf531a3",
  null
  
)

export default component.exports