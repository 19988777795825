import { serializable, primitive, identifier, alias, list, object } from 'serializr'
import SupplementStrength from '@/models/SupplementStrength'

export default class Supplement {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('primary_name', primitive()))
  primaryName!: string

  @serializable(alias('strengths', list(object(SupplementStrength))))
  strengths!: SupplementStrength[]

  get displayName(): string {
    if (this.name !== this.primaryName) {
      return `${this.name} (${this.primaryName})`
    }
    return this.name
  }
}
