import padStart from 'lodash/padStart'
import { serializable, primitive, identifier, alias } from 'serializr'
import { ppCoreDate, deserializeOnly } from '@/models/transforms'

export class BasicCardInfo {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('last_four', primitive()))
  lastFour!: string

  @serializable(alias('brand', primitive()))
  brand!: string
}

export default class StripeCard {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('address_city', primitive()))
  addressCity!: string

  @serializable(alias('address_line1', primitive()))
  addressLine1!: string

  @serializable(alias('address_line2', primitive()))
  addressLine2!: string

  @serializable(alias('address_state', primitive()))
  addressState!: string

  @serializable(alias('address_zip', primitive()))
  addressZip!: string

  @serializable(alias('brand', primitive()))
  brand!: string

  @serializable(alias('cardholder_name', primitive()))
  cardholderName!: string

  @serializable(alias('class', primitive()))
  cardClass!: string

  @serializable(alias('country', primitive()))
  country!: string

  @serializable(alias('default_fsa_hsa_payment_method', primitive()))
  defaultFsaHsaPaymentMethod!: boolean

  @serializable(alias('default_payment_method', primitive()))
  defaultPaymentMethod!: boolean

  @serializable(alias('exp_month', primitive()))
  expirationMonth!: string

  @serializable(alias('exp_year', primitive()))
  expirationYear!: string

  @serializable(alias('funding', primitive()))
  funding!: string

  @serializable(alias('is_fsa_hsa', primitive()))
  isFsaHsa!: boolean

  @serializable(alias('last_four', primitive()))
  lastFour!: string

  @serializable(alias('stripe_id', primitive()))
  stripeId!: string

  @serializable(alias('created_at', deserializeOnly(ppCoreDate())))
  createdAt!: Date

  get expirationDateString(): string {
    const month = padStart(this.expirationMonth, 2, '0')

    return `${month}/${this.expirationYear}`
  }

  get isDefaultMethod() {
    return this.defaultPaymentMethod || this.defaultFsaHsaPaymentMethod
  }
}
