import { render, staticRenderFns } from "./HelpView.vue?vue&type=template&id=8f8fdf02&scoped=true&"
import script from "./HelpView.vue?vue&type=script&lang=ts&"
export * from "./HelpView.vue?vue&type=script&lang=ts&"
import style0 from "./HelpView.vue?vue&type=style&index=0&id=8f8fdf02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f8fdf02",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardBody } from 'bootstrap-vue'
import { BCardHeader } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import IconChat from '@/assets/icons/icon-chat.svg'
import IconExternalLink from '@/assets/icons/icon-external-link.svg'
import IconMail from '@/assets/icons/icon-mail.svg'
import IconPhone from '@/assets/icons/icon-phone.svg'
installComponents(component, {BCardBody,BCardHeader,BCardTitle,BListGroup,BListGroupItem,IconChat,IconExternalLink,IconMail,IconPhone})
