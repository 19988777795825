

























































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import Card from '@/components/Card.vue'
import Icon from '@/components/Icon.vue'
import SkeletonLine from '@/components/SkeletonLine.vue'
import SkeletonCircle from '@/components/SkeletonCircle.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import TodaysMedListItem from '@/components/dashboard/TodaysMedListItem.vue'

import Today from '@/models/Today'
import { PPError } from '@/ppapi/PPError'

@Component({
  computed: {
    ...mapState('medications', ['todaysMeds']),
    ...mapGetters('asyncStatus', ['isLoading', 'getError']),
  },
  components: {
    Card,
    Icon,
    LinkArrowIcon,
    SkeletonLine,
    SkeletonCircle,
    TodaysMedListItem,
  },
})
export default class TodaysMedsCard extends Vue {
  todaysMeds!: Today
  isLoading!: (key: string) => boolean
  getError!: (key: string) => PPError | null

  get hasError() {
    return !!this.getError('medications/loadToday')
  }

  get loading() {
    return !!this.isLoading('medications/loadToday')
  }
}
