




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class DaytimeGreeting extends Vue {
  @Prop(String) name?: string
  @Prop({ type: Boolean, default: false }) short?: boolean

  get greeting() {
    const today = new Date()
    const curHr = today.getHours()
    const name = this.name

    if (!name || this.short) {
      if (curHr >= 5 && curHr < 12) {
        return this.$t('Good Morning')
      } else if (curHr >= 12 && curHr < 17) {
        return this.$t('Good Afternoon')
      } else {
        return this.$t('Good Evening')
      }
    }

    if (curHr >= 5 && curHr < 12) {
      return this.$t('Good Morning, {name}', { name })
    } else if (curHr >= 12 && curHr < 17) {
      return this.$t('Good Afternoon, {name}', { name })
    } else {
      return this.$t('Good Evening, {name}', { name })
    }
  }
}
