
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { mapGetters } from 'vuex'
import Order from '@/models/Order'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import DeliveryProblemAlert from '@/components/DeliveryProblemAlert.vue'
import { arrivalMessage, shipmentDatesMessage } from '@/i18n'
import { displayCostWithPendingItems } from '@/util/order'
import featureFlags from '@/util/featureFlags'
import { AsyncStatusKey } from '@/vuex/asyncStatus'

@Component({
  components: {
    LinkArrowIcon,
    DeliveryProblemAlert,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress']),
    ...mapGetters('orders', ['arrivalDateMessageFor']),
  },
})
export default class OrderListItem extends Vue {
  showTracking: boolean = false

  @Prop({ type: Object, required: true })
  order!: Order

  isInProgress!: (key: AsyncStatusKey) => boolean
  arrivalDateMessageFor!: (order: Order) => any

  get formattedDeliveredAt(): TranslateResult {
    const { deliveredAt, deliveryProblem, hasMultipleShipments, shipments } = this.order
    if (!deliveredAt) return ''

    if (hasMultipleShipments) {
      const [last] = shipments.slice(-1)
      return arrivalMessage(last.deliveredAt, shipmentDatesMessage(shipments), last.deliveryProblem)
    }
    return arrivalMessage(deliveredAt, undefined, deliveryProblem)
  }

  get subtitle() {
    const cost = displayCostWithPendingItems(this.order)

    return [this.$tc('order.item.medicationCount', this.order.lineItemsInShipment.length), cost]
      .filter(Boolean)
      .join(', ')
  }

  get arrivalMessageData() {
    return this.arrivalDateMessageFor(this.order)
  }

  get arrivalMessage(): string {
    return this.arrivalMessageData?.arrivalMessage
  }

  async created(): Promise<void> {
    if (await featureFlags.enabled('enable-tracking')) {
      this.showTracking = true
    }
  }

  get showFallbackTracking() {
    return this.order.showFallbackTracking
  }
}
