import { serializable, alias, list, object } from 'serializr'
import { deserializeOnly } from '@/models/transforms'
import StripeCard from '@/models/StripeCard'
import StripeBankAccount from '@/models/StripeBankAccount'

export default class GetPastOrdersResponse {
  @serializable(alias('stripe_cards', deserializeOnly(list(object(StripeCard)))))
  stripeCards: StripeCard[] = []

  @serializable(alias('stripe_bank_accounts', deserializeOnly(list(object(StripeBankAccount)))))
  stripeBankAccounts: StripeBankAccount[] = []
}
