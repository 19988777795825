import { PPError } from './PPError'
import Address from '@/models/Address'

export enum PPAddressErrorType {
  stateRequiresId = 'state_requires_identification',
  addressSuggested = 'modified',
  needsStreet2 = 'street2',
  poBox = 'po_box',
  notFound = 'not_found',
  invalid = 'invalid',
  other = 'other',
}

export default class PPAddressError extends PPError {
  readonly type: PPAddressErrorType

  constructor(data: any) {
    super('Address Error', data)
    const type = PPAddressError.type(data)
    if (!type) {
      throw new Error('data does not contain a recognized error type')
    }
    this.type = type
  }

  get modifiedFields(): string[] {
    return this.data.errors.modified || []
  }

  get suggestedAddress(): Address | null {
    if (this.type !== PPAddressErrorType.addressSuggested) {
      return null
    }

    const address = new Address()
    address.name = this.data.name
    address.street = this.data.street
    address.street2 = this.data.street2
    address.city = this.data.city
    address.state = this.data.state
    address.zipcode = this.data.zipcode
    return address
  }

  static addressError(data: any): PPAddressError | undefined {
    if (PPAddressError.isAddressError(data)) {
      return new PPAddressError(data)
    } else {
      return undefined
    }
  }

  static isAddressError(data: any): boolean {
    return !!PPAddressError.type(data)
  }

  static type(data: any): PPAddressErrorType | undefined {
    return Object.values(PPAddressErrorType).find(v => data && data.errors && data.errors[v])
  }
}
