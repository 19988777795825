import Vue from 'vue'
import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store'
import UserEvent from '@/models/UserEvent'
import trackAsyncStatus from '@/util/trackAsyncStatus'

export interface State {
  userEvents: UserEvent[] | null
}

const state: State = {
  userEvents: null,
}

const mutations: MutationTree<State> = {
  setUserEvents(state, events: UserEvent[]): void {
    state.userEvents = events
  },
  removeEvent(state, { id }: { id: string }): void {
    if (!state.userEvents) return

    state.userEvents = state.userEvents.filter(event => event.id !== id)
  },
}

const actions: ActionTree<State, RootState> = {
  load: trackAsyncStatus('userEvents/load', async function ({ commit }) {
    const events = await Vue.$pillpack.events.get()

    commit('setUserEvents', events)
  }),
  acknowledge: trackAsyncStatus('userEvents/acknowledge', async function ({ commit }, { id }: any) {
    await Vue.$pillpack.events.acknowledge(id)

    commit('removeEvent', { id })
  }),
}

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default module
