import { serializable, primitive, list, object, identifier, alias } from 'serializr'
import Dose from '@/models/PrescriptionDose'
import { deserializeOnly } from '@/models/transforms'

export default class SigLine {
  @serializable(identifier())
  id!: string

  @serializable(deserializeOnly(primitive()))
  text!: string

  @serializable(deserializeOnly(alias('schedule_type', primitive())))
  scheduleType!: string

  @serializable(deserializeOnly(alias('is_every_day', primitive())))
  isEveryDay!: string

  @serializable(deserializeOnly(alias('dow', primitive())))
  daysOfWeek!: string

  @serializable(list(object(Dose)))
  doses?: Dose[]
}
