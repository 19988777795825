import PPSession from './PPSession'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize } from '@/models/transforms'
import ProjectedShipment from '@/models/ProjectedShipment'

export default class PPShipmentsApi {
  static async projectedNextShipment({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<ProjectedShipment>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/consumer_api/users/${userId}/projected_next_shipment`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const order = deserialize(ProjectedShipment, response.data.projected_next_shipment)
    return new PPSuccessResponse(order)
  }
}
