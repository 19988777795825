import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=06b53837&scoped=true&"
import script from "./Banner.vue?vue&type=script&lang=ts&"
export * from "./Banner.vue?vue&type=script&lang=ts&"
import style0 from "./Banner.vue?vue&type=style&index=0&id=06b53837&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b53837",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BAlert } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import { BButtonClose } from 'bootstrap-vue'
import IconClose from '@/assets/icons/icon-close.svg'
installComponents(component, {BAlert,BButton,BButtonClose,IconClose})
