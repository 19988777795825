import { serializable, primitive, alias, list, object } from 'serializr'
import OTCMedicationStrength from '@/models/OTCMedicationStrength'
import OTCMedicationFallback from '@/models/OTCMedicationFallback'

export default class OTCMedication {
  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('strengths', list(object(OTCMedicationStrength))))
  strengths!: OTCMedicationStrength[]

  @serializable(alias('fallback', object(OTCMedicationFallback)))
  fallback!: OTCMedicationFallback

  get displayName(): string {
    return this.name
  }
}
