import { serializable, alias, primitive } from 'serializr'

export default class PacketSet {
  @serializable(alias('id', primitive()))
  id!: string

  @serializable(alias('class', primitive()))
  class!: string

  @serializable(alias('start_date', primitive()))
  startDate!: string

  @serializable(alias('end_date', primitive()))
  endDate!: string
}
