










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Modal from '@/components/Modal.vue'
import Card from '@/components/Card.vue'
import TourProgressBar from '@/components/tour-progress/TourProgressBar.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'

@Component({
  components: {
    Modal,
    Card,
    TourProgressBar,
    LinkArrowIcon,
  },
  computed: {
    ...mapState('user', ['me']),
  },
})
export default class Tour extends Vue {
  @Prop(Array)
  data!: any[]

  step: number = 0
  length?: number = this.data.length

  next() {
    this.step += 1
  }

  back() {
    this.step -= 1
  }

  close() {
    this.$bvModal.hide('tour-modal')
  }

  getImgUrl(image: string) {
    const images = require.context('@/assets/onboarding-tour', false, /\.png$/)
    return images(`./${image}.png`)
  }
}
