import PPClientModule from './PPClientModule'
import PPPharmaciesApi from './PPPharmaciesApi'

export default class PPPharmaciesClient extends PPClientModule {
  search = this.wrapAnonApi(PPPharmaciesApi.search)

  getDetails = this.wrapAnonApi(PPPharmaciesApi.getDetails)

  all = this.wrapApi(PPPharmaciesApi.all)

  add = this.wrapApi(PPPharmaciesApi.add)
}
