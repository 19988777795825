import { serializable, list, object } from 'serializr'
import { deserializeOnly } from '@/models/transforms'
import ServiceAlert from '@/models/ServiceAlert'

export default class ServiceAlertsResponse {
  @serializable(deserializeOnly(list(object(ServiceAlert))))
  resolved?: ServiceAlert[]

  @serializable(deserializeOnly(list(object(ServiceAlert))))
  unresolved?: ServiceAlert[]

  @serializable(deserializeOnly(list(object(ServiceAlert))))
  messages?: ServiceAlert[]
}
