/* eslint-disable import/export */
import {
  serialize as ser,
  deserialize as des,
  SKIP,
  getDefaultModelSchema,
  Clazz,
  PropSchema,
  custom,
} from 'serializr'
import { parse, format } from 'date-fns'
import { stripNonPhoneDigits } from '@/util/phoneNumber'
import TrackingEventState from '@/models/Tracking/TrackingEvent'

export class SerializeError extends Error {}
export class DeserializeError extends Error {}

/**
 * deserializes json or object into instances of a model Class
 *
 * @public
 * @param {Class} Clazz<T> model to use for serializinmodel to use for serializing
 * @param {object|array} object or array to deserialize
 * @returns {object|array}
 */
export function deserialize<T>(Model: Clazz<T>, data: any): T {
  try {
    return des(Model, data)
  } catch (e) {
    throw new DeserializeError(e.message)
  }
}

export function deserializeArray<T, V>(Model: Clazz<T>, data: V[]): T[] {
  try {
    return des(Model, data)
  } catch (e) {
    throw new DeserializeError(e.message)
  }
}

/**
 * serializes instance or array of instances of a model into json
 *
 * @public
 * @param {object} instance
 * @returns {object}
 */
export function serializeInstance<T>(instance: T): any {
  try {
    return ser(instance)
  } catch (e) {
    throw new SerializeError(e.message)
  }
}

/**
 * serializes instance or array of instances of a model into json
 *
 * @public
 * @param {array} instance
 * @returns {array}
 */
export function serializeArray<T>(instance: T[]): any[] {
  try {
    return ser(instance)
  } catch (e) {
    throw new SerializeError(e.message)
  }
}

/**
 * serializes an object or array into json from a given class
 *
 * @public
 * @param {Class} Clazz<T> model to use for serializing
 * @param {object|array} data
 * @returns {object|array}
 */
export function serialize<T>(model: Clazz<T>, data: T): any {
  try {
    return ser(getDefaultModelSchema(model), data)
  } catch (e) {
    throw new SerializeError(e.message)
  }
}

/**
 * clones an object by serializing and deserializing it locally
 *
 * @public
 * @param {Class} Clazz<T> model to use for serializing
 * @param {object} data
 * @returns {object}
 */
export function clone<T>(model: Clazz<T>, data: T): T {
  const serializedObj = serialize(model, data)
  const newCopy = deserialize(model, serializedObj)
  return newCopy
}

/**
 * used to prevent the app from serializing params we don't want to update in the back end
 *
 * @public
 * @param {PropSchema}
 * @returns {PropSchema}
 */
export function deserializeOnly(prop: PropSchema): PropSchema {
  return { ...prop, serializer: () => SKIP }
}

/**
 * Marks a property as "optional" so that serializr does not throw an error when deserializing.
 * Sometimes the json response will not contain all the deserializable properties.
 *
 * @public
 * @param {PropSchema}
 * @returns {PropSchema}
 */
export function optional(prop: PropSchema): PropSchema {
  return {
    deserializer: (json, callback, context, currentPropertyValue) => {
      if (!json) {
        return SKIP
      }

      return prop.deserializer(json, callback, context, currentPropertyValue)
    },
    serializer: prop.serializer,
  }
}

/**
 * Simply parses a date string from PPCore API, and serializes it back.
 * PPCore sends dates back as 'DD-MM-YYYY'.
 *
 * @public
 * @returns {PropSchema}
 */
export function ppCoreDate(): PropSchema {
  return custom(
    (date: Date) => {
      if (!date) return SKIP
      return format(date, 'DD-MM-YYYY')
    },
    (str: string) => {
      if (!str) return SKIP
      return parse(str)
    },
  )
}

/**
 * Deserializes iso8601 strings into Date objects
 * and serializes them back into iso8601 strings
 *
 * @public
 * @returns {PropSchema}
 */
export function iso8601Date(): PropSchema {
  return custom(
    (date: Date) => {
      if (!date) return SKIP
      return format(date, 'YYYY-MM-DDTHH:mm:ss[Z]')
    },
    (str: string) => {
      if (!str) return SKIP
      return parse(str)
    },
  )
}

/**
 * Deserializes trackingId/containerId Ship Track Proxy
 * These apparently can be a whitespace character
 *
 * @public
 * @returns {PropSchema}
 */
export function trackingIdentifier(): PropSchema {
  return custom(
    (id: string) => id,
    (id: string): string | typeof SKIP => {
      if (!id) return SKIP
      if (typeof id === 'string') return id.trim() || SKIP
      return id
    },
  )
}

/**
 * Deserializes date objects from the Ship Track Proxy
 * These come in the form: { time: 1594049692000, timezone: 'GMT-5:00' }
 * DANGER! WARNING! The timezone can be 'America/Pacific' or 'PST' or 'GMT-5:00'
 *
 * @public
 * @returns {PropSchema}
 */
export function trackingEventDate(): PropSchema {
  return custom(
    dateTime => ({ time: dateTime?.getTime() }),
    ({ time }: { time: number }): Date | typeof SKIP => {
      if (!time) return SKIP
      return parse(time)
    },
  )
}

/**
 * Deserializes epoch dates from the Ship Track Proxy
 * These come in the form: 1594049692000
 * All are UTC
 *
 * @public
 * @returns {PropSchema}
 */
export function trackingEventEpochDate(): PropSchema {
  return custom(
    dateTime => dateTime?.getTime(),
    (time: number): Date | typeof SKIP => {
      if (!time) return SKIP
      return parse(time)
    },
  )
}

/**
 * Deserializes the transitState from the transitInfo object on a TrackingHistory
 * These come in the form: { transitInfo: { transitState: 'DELIVERED' } }
 *
 * @public
 * @returns {PropSchema}
 */
export function transitState(): PropSchema {
  return custom(
    () => SKIP,
    (trackingInfo: { transitState: TrackingEventState }): TrackingEventState | typeof SKIP => {
      if (!trackingInfo?.transitState) return SKIP
      return trackingInfo.transitState
    },
  )
}

/**
 * Deserializes a stringified JSON list into an array of strings and then back into stringified JSON.
 * Can also cop with malformed data that we might get from the server
 *
 * ex.
 * "["one", "two", "three", "bar"]" <-> ["one", "two", "three", "bar"]
 *
 * @public
 * @returns {PropSchema}
 */

export function stringifiedJsonList(): PropSchema {
  return custom(
    // array to stringified array
    (strArray: string[]) => {
      if (!strArray || !strArray.length) return null
      return JSON.stringify(
        strArray
          .map(s => s.replace(/[[\]"]/g, '')) // drop JSON characters
          .map(s => s.trim()) // drop leading/trailing whitespace
          .filter(s => !!s), // drop empty strings
      )
    },
    // string to array
    (str: string) => {
      if (!str) return []

      // Sometimes the data for other_allergies and other_conditions can come in messy.
      // We might get stringified JSON array, we might get comma-separated values, or we might get a mix.
      // The first deserialization strategy is to see if the string can be parsed as JSON. If so, use that.
      // The fallback strategy attempts to split on commas and drop outer whitespace, JSON characters, and empties.
      // This can handle most cases that we get from the server.
      try {
        const list = JSON.parse(str)
        return list
      } catch {
        return str
          .split(',')
          .map(s => s.replace(/[[\]"]/g, '')) // drop JSON characters
          .map(s => s.trim()) // drop leading/trailing whitespace
          .filter(s => !!s) // drop empty strings
      }
    },
  )
}

export function phoneNumber(): PropSchema {
  return custom(
    (str: string) => (str ? stripNonPhoneDigits(str) : SKIP),
    (str: string) => (str ? stripNonPhoneDigits(str) : SKIP),
  )
}

export function decimalNumber(): PropSchema {
  return custom(
    (num: number) => (num && typeof num === 'number' ? String(num) : SKIP),
    (str: string) => (str ? Number(str) : SKIP),
  )
}
