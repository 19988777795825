import { render, staticRenderFns } from "./SessionErrorElement.vue?vue&type=template&id=3a5f86fe&"
import script from "./SessionErrorElement.vue?vue&type=script&lang=ts&"
export * from "./SessionErrorElement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BAlert } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
installComponents(component, {BAlert,BButton})
