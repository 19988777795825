import { serializable, primitive, identifier, alias, object } from 'serializr'
import { OTCMedicationRequestType } from './OTCMedicationRequest'

export class Drug {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('description', primitive()))
  description!: string
}

export class Medication {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('description', primitive()))
  description!: string
}

export default class OTCMedicationResponse {
  @serializable(alias('drug', object(Drug)))
  drug!: Drug

  @serializable(alias('medication', object(Medication)))
  medication!: Medication

  @serializable(alias('prescription_id', primitive()))
  prescriptionId!: string

  @serializable(alias('estimated_price', primitive()))
  estimatedPrice!: number

  @serializable(alias('type', primitive()))
  type!: OTCMedicationRequestType
}
