import { render, staticRenderFns } from "./OnboardingTour.vue?vue&type=template&id=25a4e4c5&scoped=true&"
import script from "./OnboardingTour.vue?vue&type=script&lang=ts&"
export * from "./OnboardingTour.vue?vue&type=script&lang=ts&"
import style0 from "./OnboardingTour.vue?vue&type=style&index=0&id=25a4e4c5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a4e4c5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BCardBody } from 'bootstrap-vue'
import { BCardHeader } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import { BImg } from 'bootstrap-vue'
installComponents(component, {BButton,BCardBody,BCardHeader,BCardTitle,BImg})
