import PPCoreError from '@/models/PPCoreError'

export class PPError extends Error {
  readonly data?: any
  readonly errors?: PPCoreError[]
  readonly status?: number

  constructor(message?: string, data?: any) {
    super(message)
    this.data = data
  }
}

export class PPLogInFailedError extends PPError {
  readonly error: PPError
  constructor(error: PPError) {
    const message =
      (error && error.data && error.data.error) || 'Invalid email address or password.'
    super(message)
    this.error = error
  }
}

export class PPSessionResetError extends PPError {
  readonly error: PPError
  constructor(error: PPError) {
    super('Session has been reset.')
    this.error = error
  }
}
