



















































import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import SiteLogo from '@/components/navbar/SiteLogo.vue'

@Component({
  components: {
    PageHeader,
    SiteLogo,
  },
})
export default class OfflineElement extends Vue {}
