import PPSession from './PPSession'
import { PPHttp, PPHttpMethod, PPHttpSuccessResponse, PPHttpFailureResponse } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize } from '@/models/transforms'
import { PPError } from '@/ppapi/PPError'
import TrackingHistory from '@/models/Tracking/TrackingHistory'
import intercept from '@/util/intercept'

interface TrackingArgs {
  trackingId?: string
  containerId?: string
  amazonCarrier: string | false
}

interface ShipTrackProxyResponse extends TrackingHistory {
  message?: string
}

class PPTrackingResponse extends PPHttpSuccessResponse {
  readonly data!: ShipTrackProxyResponse
}

export default class PPTrackingApi {
  static async track({
    baseUrl,
    session,
    trackingId,
    containerId,
    amazonCarrier,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    locale: string
  } & TrackingArgs): Promise<PPResponse<TrackingHistory>> {
    const path = PPTrackingApi.getPath({ trackingId, containerId, amazonCarrier })

    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path,
      urlParams: {
        locale,
      },
    })
    return PPTrackingApi.parse(response)
  }

  static async mockTrack({
    trackingId,
    containerId,
    amazonCarrier,
    mock,
  }: {
    baseUrl: string
    session: PPSession
    locale: string
    mock: string
  } & TrackingArgs): Promise<PPResponse<TrackingHistory>> {
    const path = PPTrackingApi.getPath({ trackingId, containerId, amazonCarrier })
    const response = (await intercept(mock, path)) as PPTrackingResponse

    return PPTrackingApi.parse(response)
  }

  static getPath({ containerId }: TrackingArgs) {
    if (!containerId) {
      throw new Error('Must include containerId')
    }
    return `/ship_track_proxy/tracking_info?container_id=${containerId}`
  }

  static parse(
    response: PPTrackingResponse | PPHttpFailureResponse,
  ): PPFailureResponse | PPSuccessResponse<TrackingHistory> {
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    // this endpoint can fail with a 200 status and a valid json response, but no tracking info in Output.
    // this can happen when we are not authorized to perform a request because of IAM permissions.
    if (!response.data.trackingEvents) {
      return new PPFailureResponse(new PPError(response.data.message))
    }

    const trackingHistory = deserialize(TrackingHistory, response.data)
    return new PPSuccessResponse(trackingHistory)
  }
}
