import { serializable, alias, primitive, list, object } from 'serializr'
import { parse } from 'date-fns'
import PacketMedication from '@/models/Today/PacketMedication'

export default class Packet {
  @serializable(alias('admin_date', primitive()))
  adminDate!: string

  @serializable(alias('admin_time', primitive()))
  adminTime!: string

  @serializable(alias('time_range', primitive()))
  timeRange!: string

  @serializable(alias('packet_medications', list(object(PacketMedication))))
  packetMedications!: PacketMedication[]

  get time() {
    return parse(`${this.adminDate} ${this.adminTime}`)
  }
}
