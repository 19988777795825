import { serializable, primitive, identifier, alias, raw } from 'serializr'
import { deserializeOnly, ppCoreDate } from '@/models/transforms'

export default class UserEvent<L = any> {
  @serializable(identifier())
  id!: string

  @serializable(primitive())
  title?: string

  @serializable(primitive())
  class!: string

  @serializable(primitive())
  acknowledged?: boolean

  @serializable(deserializeOnly(alias('occurred_at', deserializeOnly(ppCoreDate()))))
  occurredAt?: Date

  @serializable(primitive())
  description?: string

  @serializable(primitive())
  viewed?: boolean

  @serializable(deserializeOnly(raw()))
  locals?: L
}
