import { Validation } from 'vuelidate'
import { $t } from '@/i18n'

export function validationState(validation: Validation): boolean | null {
  if (validation.$dirty && validation.$invalid) {
    return false
  }
  return null
}

export const validationStateMixin = {
  methods: {
    isValid(validation: Validation): boolean | null {
      return validationState(validation)
    },

    /**
     * Looks for invalid validations and returns a string of all error messages concatenated by '\n'
     *
     * Use this to automagically get the translations for any invalid field,
     * as long as the name of the validations for the field on the component
     * is the same as the names of the fields in the translation file.
     *
     * See the EditInsuranceModal for several examples.
     *
     * @public
     * @param {Validation} $validation the validation field on the component
     * @param {Object} opts fields/values to pass to the translation
     * @returns {String|null}
     *
     * @example
     * <b-form-group :invalid-feedback="invalidFeedbackMessage($v.firstName, { field: 'first name'})" />
     */
    invalidFeedbackMessage($validation: Validation, opts: object): String | null {
      if (!$validation) {
        return null
      }

      // finds the names of invalid validations
      if ($validation.$invalid) {
        const invalid = $validation
          .$flattenParams()
          .filter(param => !$validation[param.name as keyof Validation])

        if (!invalid.length) {
          return null
        }

        // take those validation names and look them up in the translations
        return invalid
          .map(param => {
            const translation = `_validations.${param.name}`
            return $t(translation, opts).toString()
          })
          .join('\n')
      }

      return null
    },
  },
}
