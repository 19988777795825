import PPClientModule from './PPClientModule'
import PPShipmentsApi from './PPShipmentsApi'

export default class PPShipmentsClient extends PPClientModule {
  projectedNextShipment() {
    return this.performRequest(args => {
      return PPShipmentsApi.projectedNextShipment(args)
    })
  }
}
