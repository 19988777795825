import { PPArgs } from './PPClient'
import PPSession from './PPSession'
import { PPHttp, PPHttpMethod, PPHttpResponse } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import GetPaymentMethodsResponse from '@/models/GetPaymentMethodsResponse'
import { deserialize } from '@/models/transforms'
import Account from '@/models/Account'
import StripeCard from '@/models/StripeCard'
import StripeBankAccount from '@/models/StripeBankAccount'

interface StripePayment {
  paymentMethodId: string
  paymentMethodClass: string
  amount: string
}

export default class PPPaymentMethodsApi {
  static async get({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<GetPaymentMethodsResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/payment_methods`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserialize(GetPaymentMethodsResponse, response.data)
    return new PPSuccessResponse(results)
  }

  static async createCard({
    baseUrl,
    session,
    userId,
    locale,
    token,
    isFsaHsa,
    makeDefault,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    token: stripe.Token
    isFsaHsa: boolean
    makeDefault: boolean
  }): Promise<PPResponse<StripeCard>> {
    const response = await this.createRequest({
      baseUrl,
      session,
      userId,
      locale,
      token,
      isFsaHsa,
      makeDefault,
      type: 'StripeCard',
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserialize(StripeCard, response.data)

    return new PPSuccessResponse(results)
  }

  static async createBankAccount({
    baseUrl,
    session,
    userId,
    locale,
    token,
    makeDefault,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    token: stripe.Token
    makeDefault: boolean
  }): Promise<PPResponse<StripeBankAccount>> {
    const response = await this.createRequest({
      baseUrl,
      session,
      userId,
      locale,
      token,
      isFsaHsa: false,
      makeDefault,
      type: 'StripeBankAccount',
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserialize(StripeBankAccount, response.data)

    return new PPSuccessResponse(results)
  }

  private static async createRequest({
    baseUrl,
    session,
    userId,
    locale,
    token,
    isFsaHsa,
    makeDefault,
    type,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    token: stripe.Token
    isFsaHsa: boolean
    makeDefault: boolean
    type: 'StripeCard' | 'StripeBankAccount'
  }): Promise<PPHttpResponse> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/payment_methods/${type}`,
      urlParams: {
        locale,
        make_new_default: makeDefault,
      },
      content: {
        is_fsa_hsa: isFsaHsa,
        token: token.id,
      },
    })

    return response
  }

  static async makeDefault({
    baseUrl,
    session,
    userId,
    locale,
    paymentMethod,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    paymentMethod: StripeCard | StripeBankAccount
  }): Promise<PPResponse<StripeCard | StripeBankAccount>> {
    const type =
      paymentMethod instanceof StripeCard ? paymentMethod.cardClass : paymentMethod.accountClass

    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/payment_methods/${type}/${paymentMethod.id}/set_as_default`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    if (paymentMethod instanceof StripeCard) {
      const results = deserialize(StripeCard, response.data)
      return new PPSuccessResponse(results)
    }

    const results = deserialize(StripeBankAccount, response.data)
    return new PPSuccessResponse(results)
  }

  static async delete({
    baseUrl,
    session,
    userId,
    locale,
    paymentMethod,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    paymentMethod: StripeCard | StripeBankAccount
  }): Promise<PPResponse<void>> {
    const type =
      paymentMethod instanceof StripeCard ? paymentMethod.cardClass : paymentMethod.accountClass

    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.DELETE,
      baseUrl,
      path: `/api/users/${userId}/payment_methods/${type}/${paymentMethod.id}`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return new PPSuccessResponse(undefined)
  }

  static async verify({
    baseUrl,
    session,
    userId,
    locale,
    id,
    deposit1,
    deposit2,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    id: string
    deposit1: string
    deposit2: string
  }): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/payment_methods/StripeBankAccount/${id}/verify`,
      urlParams: {
        locale,
      },
      content: {
        amounts: [deposit1, deposit2],
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return new PPSuccessResponse(undefined)
  }

  static async makePayment(
    { baseUrl, locale, session, userId }: PPArgs,
    { paymentMethodId, paymentMethodClass, amount }: StripePayment,
  ) {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/payment_methods/${paymentMethodClass}/${paymentMethodId}/make_payment`,
      urlParams: {
        locale,
      },
      content: {
        id: paymentMethodId,
        class: paymentMethodClass,
        payment_amount: amount,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const account = deserialize(Account, response.data.account)

    return new PPSuccessResponse(account)
  }
}
