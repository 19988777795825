/*
Basic documentation in /docs/directives.md#v-loading
*/

import Vue, { DirectiveOptions } from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import Loading from '@/components/Loading.vue'

const Loader = Vue.extend(Loading)
type LoaderType = Vue & {
  isVisible?: Boolean
  className?: String
}

interface CustomElement extends HTMLElement {
  loader?: Node
  instance?: LoaderType
  domInserted?: Boolean
}

const toggleLoading = (el: CustomElement, binding: DirectiveBinding) => {
  Vue.nextTick(() => {
    if (!el.instance) return
    el.instance.isVisible = binding.value as Boolean
  })
}

const insertDOM = (el: CustomElement, binding: DirectiveBinding) => {
  if (!el.loader || !el.instance) return
  el.appendChild(el.loader)
  el.instance.isVisible = binding.value as Boolean
}

const removeDOM = (el: CustomElement) => {
  if (!el.loader) return
  if (el.loader.parentNode === el) {
    el.removeChild(el.loader)
  }
}

const directiveOptions: DirectiveOptions = {
  bind: (el: CustomElement, binding) => {
    const loader = new Loader({
      el: document.createElement('div'),
      propsData: {
        fullscreen: !!binding.modifiers.fullscreen,
        opaque: !!binding.modifiers.opaque,
      },
    })
    el.instance = loader
    el.loader = loader.$el

    insertDOM(el, binding)
    toggleLoading(el, binding)
  },

  update: (el: CustomElement, binding) => {
    if (binding.oldValue !== binding.value) {
      toggleLoading(el, binding)
    }
  },

  unbind: (el: CustomElement) => {
    removeDOM(el)
    if (el.instance) {
      el.instance.$destroy()
    }
  },
}

export default directiveOptions
