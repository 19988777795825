import { serializable, primitive, identifier, alias, object } from 'serializr'

export class PhysicianAddress {
  @serializable(alias('street', primitive()))
  street!: string

  @serializable(alias('street2', primitive()))
  street2!: string

  @serializable(alias('city', primitive()))
  city!: string

  @serializable(alias('state', primitive()))
  state!: string

  @serializable(alias('zipcode', primitive()))
  zipcode!: string
}

export default class Physician {
  @serializable(identifier())
  id!: string

  @serializable(primitive())
  npi?: string

  @serializable(primitive())
  spi?: string

  @serializable(alias('prescriber_id', primitive()))
  prescriberId?: string

  @serializable(alias('first_name', primitive()))
  firstName?: string

  @serializable(alias('last_name', primitive()))
  lastName?: string

  @serializable(alias('full_name', primitive()))
  rawFullName?: string

  @serializable(alias('phone_number', primitive()))
  phoneNumber?: string

  @serializable(alias('address_line', primitive()))
  addressLine?: string

  @serializable(alias('dea_number', primitive()))
  deaNumber?: string

  @serializable(alias('surescripts_prescriber_id', primitive()))
  sureScriptsPrescriberId?: string

  @serializable(alias('address', object(PhysicianAddress)))
  address?: PhysicianAddress

  get clinic() {
    return this.formattedAddress
  }

  get formattedAddress(): string {
    if (!this.address) return ''

    const { street, city, state, zipcode } = this.address
    return `${street}, ${city}, ${state} ${zipcode?.substring(0, 5)}`
  }

  get categoryId(): string {
    return this.spi || this.sureScriptsPrescriberId || this.id
  }

  get fullName(): string {
    return this.rawFullName || `${this.firstName} ${this.lastName}`
  }
}
