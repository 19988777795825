import PPClientModule from './PPClientModule'
import PPTrackingApi from './PPTrackingApi'

interface TrackingArgs {
  trackingId?: string
  containerId?: string
  amazonCarrier?: string | false
}

export default class PPTrackingClient extends PPClientModule {
  track({ amazonCarrier, trackingId, containerId }: TrackingArgs) {
    const carrierId = amazonCarrier ?? false

    return this.performRequest(args => {
      return PPTrackingApi.track({ ...args, amazonCarrier: carrierId, trackingId, containerId })
    })
  }

  mockTrack({ amazonCarrier, trackingId, containerId }: TrackingArgs, mock: string) {
    const carrierId = amazonCarrier ?? false

    return this.performRequest(args => {
      return PPTrackingApi.mockTrack({
        ...args,
        mock,
        amazonCarrier: carrierId,
        trackingId,
        containerId,
      })
    })
  }
}
