





































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import ServiceAlertDashboardCard from '@/components/service-alerts/ServiceAlertDashboardCard.vue'
import OnboardingTour from '@/components/dashboard/OnboardingTour.vue'
import OnboardingCard from '@/components/dashboard/OnboardingCard.vue'
import PremiumDispenserCard from '@/components/dashboard/PremiumDispenserCard.vue'
import TodaysMedsCard from '@/components/dashboard/TodaysMedsCard.vue'
import OrdersCardCurrent from '@/components/OrdersCardCurrent.vue'
import ActiveMedicationsCard from '@/components/medications/ActiveMedicationsCard.vue'
import PendingMedicationsCard from '@/components/medications/PendingMedicationsCard.vue'
import ConfirmEmail from '@/components/ConfirmEmail.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import DaytimeGreeting from '@/components/DaytimeGreeting.vue'
import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'

import DashboardMedication from '@/models/DashboardMedication'
import Insurance from '@/models/Insurance'
import ServiceAlert from '@/models/ServiceAlert'
import User from '@/models/User'
import Today from '@/models/Today'

import { BannerInfo } from '@/vuex/banners'

@Component({
  computed: {
    ...mapState('user', ['me']),
    ...mapState('dispensers', ['sellingPremium']),
    ...mapState('insurances', ['insurances']),
    ...mapState('alerts', { serviceAlerts: 'unresolved' }),
    ...mapState('medications', ['todaysMeds']),
    ...mapState('welcome', ['onboardingTour']),
    ...mapGetters('medications', ['pendingMedications']),
    ...mapGetters('asyncStatus', ['hasSucceeded', 'isLoading']),
  },
  components: {
    Card,
    PageHeader,
    ConfirmEmail,
    DaytimeGreeting,
    TodaysMedsCard,
    ServiceAlertDashboardCard,
    OnboardingTour,
    OnboardingCard,
    PremiumDispenserCard,
    ActiveMedicationsCard,
    PendingMedicationsCard,
    OrdersCardCurrent,
    LinkArrowIcon,
  },
})
export default class DashboardView extends Vue {
  me!: User
  insurances!: Insurance[]
  sellingPremium!: boolean
  serviceAlerts!: ServiceAlert[]
  pendingMedications!: DashboardMedication[]
  todaysMeds!: Today
  onboardingTour!: boolean
  hasSucceeded!: (key: string, id?: string) => boolean
  isLoading!: (key: string, id?: string) => boolean

  get isOnboarding() {
    const notShipped = this.me.onboarded && this.me.sentFirstShipment === false
    return this.me.onboarding || notShipped
  }

  get showOnboardingCard() {
    return !this.me.isHubCheckout && this.isOnboarding
  }

  get showServiceAlerts() {
    if (this.isOnboarding) {
      return this.serviceAlerts.length
    }
    return this.hasSucceeded('alerts/load')
  }

  get showTodaysMeds() {
    return this.isOnboarded && (this.isLoading('medications/loadToday') || this.todaysMeds)
  }

  get showDispenserUpgrade() {
    return (
      this.sellingPremium &&
      (!this.me.hasPremiumDispenser || this.hasSucceeded('dispensers/updatePremiumDispenser'))
    )
  }

  get isOnboarded() {
    return this.me.onboarded
  }

  get hasPendingMedications() {
    return !!this.pendingMedications.length
  }

  showCalibrateBanner() {
    // Only show for Calibrate users without insurance
    if (!this.me.cohorts?.hasOwnProperty('calibrate_full') || this.insurances.length > 0) {
      return
    }

    const banner: BannerInfo = {
      id: 'banner-calibrate-enter-insurance',
      title: this.$t('Calibrate.banner.Important'),
      message: this.$t('Calibrate.banner.Please enter your insurance information'),
      bgVariant: 'danger',
      actions: [
        {
          label: this.$t('Calibrate.banner.Update'),
          to: 'settings/payment',
          handler: ({ dismiss }) => {
            dismiss()
          },
        },
      ],
    }

    this.$store.dispatch('showBanner', banner)
  }

  async created() {
    this.$store.dispatch('alerts/ensureLoaded')
    await this.$store.dispatch('user/ensureMe')
    await this.$store.dispatch('insurances/ensureLoaded')

    if (this.isOnboarding) {
      this.$store.dispatch('user/loadOnboardState')
    }
    this.$store.dispatch('medications/loadPending')
    this.$store.dispatch('dispensers/load')
    if (this.isOnboarded) {
      this.$store.dispatch('medications/loadToday')
      this.$store.dispatch('orders/loadCurrent')
      this.$store.dispatch('orders/loadPast.initial')
    }
  }

  async updated() {
    this.showCalibrateBanner()
  }
}
