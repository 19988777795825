import { Module } from 'vuex'
import Vue from 'vue'
import { RootState } from '@/store'
import Prescription from '@/models/Prescription'
import DashboardMedication from '@/models/DashboardMedication'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import Physician from '@/models/Physician'
import { PhysicianUpdateParams } from '@/ppapi/PPMedicationsApi'

export interface PhysicianUpdateState {
  physician: Physician | null
  mainMedication: DashboardMedication | null
  otherMedications: DashboardMedication[]
}
const module: Module<PhysicianUpdateState, RootState> = {
  namespaced: true,
  state: {
    physician: null,
    mainMedication: null,
    otherMedications: [],
  },
  mutations: {
    setPhysician(state, physician: Physician) {
      state.physician = physician
    },
    setMainMedication(state, med: DashboardMedication) {
      state.mainMedication = med
    },
    setOtherMedications(state, meds: DashboardMedication[]) {
      state.otherMedications = meds
    },
    reset(state) {
      state.physician = null
      state.mainMedication = null
      state.otherMedications = []
    },
  },
  actions: {
    sendRequest: trackAsyncStatus(
      'physicianUpdate/sendRequest',
      async function (
        { state, dispatch },
        payload: {
          prescription: Prescription
          physician: Physician
          otherPrescriptions?: Prescription[]
          location?: string
        },
      ) {
        if (!payload || !payload.prescription || !payload.physician) return

        const medication = state.mainMedication

        const params: PhysicianUpdateParams = {
          prescriptionId: payload.prescription.id,
          physicianId: payload.physician.id,
          otherPrescriptionIds: payload.otherPrescriptions?.map(p => p.id),
          location: payload.location,
        }

        await Vue.$pillpack.medications.physicianUpdateRequest(params)

        await dispatch(
          'medications/loadOne',
          { type: medication?.type, id: medication?.id },
          { root: true },
        )
      },
    ),
    selectPhysician: trackAsyncStatus(
      'physicianUpdate/selectPhysician',
      async function ({ commit }, physician: Physician) {
        if (!physician) return
        const { spi } = physician
        if (spi) {
          const fullPhysician = await Vue.$pillpack.physicians.getPhysician({
            prescriberId: spi,
          })
          fullPhysician.id = physician.id
          commit('setPhysician', fullPhysician)
        } else {
          commit('setPhysician', physician)
        }
      },
      {
        mergeRequests: false,
      },
    ),
  },
}

export default module
