import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize } from '@/models/transforms'
import { PPArgs } from './PPClient'
import ShipmentLiteratureResponse from '@/models/ShipmentLiteratureResponse'

export default class PPShipmentLiteratureApi {
  static async getShipmentLiterature(
    args: PPArgs,
    { shipmentId }: { shipmentId: string },
  ): Promise<PPResponse<ShipmentLiteratureResponse>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/shipments/${shipmentId}/shipment_literature`,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const shipmentLiteratureResponse = deserialize(ShipmentLiteratureResponse, response.data)
    return new PPSuccessResponse(shipmentLiteratureResponse)
  }
}
