import { serializable, alias, list, object } from 'serializr'
import { ppCoreDate, deserializeOnly } from '@/models/transforms'
import Address from '@/models/Address'
import Prescription from '@/models/Prescription'

export default class ProjectedShipment {
  @serializable(alias('ship_date', deserializeOnly(ppCoreDate())))
  shipDate?: Date

  @serializable(alias('arrival_date', deserializeOnly(ppCoreDate())))
  arrivalDate?: Date

  @serializable(alias('editable_until', deserializeOnly(ppCoreDate())))
  editableUntil?: Date

  @serializable(alias('intended_address', object(Address)))
  intendedAddress?: Address

  @serializable(alias('medications', deserializeOnly(list(object(Prescription)))))
  medications?: Prescription[]

  @serializable(alias('available_medications', deserializeOnly(list(object(Prescription)))))
  availableMedications?: Prescription[]
}
