import { alias, serializable, primitive } from 'serializr'

export default class UserPreferences {
  @serializable(primitive())
  dispenser?: string

  @serializable(alias('sms_enabled', primitive()))
  smsEnabled?: boolean

  @serializable(alias('phi_in_communications_enabled', primitive()))
  phiInCommunicationsEnabled?: boolean

  @serializable(alias('otc_catalog_v2', primitive()))
  otcCatalogV2Enabled?: boolean

  @serializable(alias('rx_catalog_v2', primitive()))
  rxCatalogV2Enabled?: boolean

  @serializable(alias('use_child_safe_packaging', primitive()))
  useChildSafePackaging?: boolean
}
