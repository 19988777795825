/* eslint-disable no-console */

import { register } from 'register-service-worker'
import EventBus from './util/EventBus'

const serviceWorkerEnabledEnvs = ['production']

if (serviceWorkerEnabledEnvs.includes(process.env.NODE_ENV || '')) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      )
      EventBus.dispatchEvent('ServiceWorker.ready')
    },
    registered() {
      console.log('Service worker has been registered.')
      EventBus.dispatchEvent('ServiceWorker.registered')
    },
    cached() {
      console.log('Content has been cached for offline use.')
      EventBus.dispatchEvent('ServiceWorker.cached')
    },
    updatefound() {
      console.log('New content is downloading.')
      EventBus.dispatchEvent('ServiceWorker.updatefound')
    },
    updated() {
      console.log('New content is available; please refresh.')
      EventBus.dispatchEvent('ServiceWorker.updated')
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
      EventBus.dispatchEvent('ServiceWorker.offline')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
