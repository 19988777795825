












import { Component, Vue } from 'vue-property-decorator'
import NotFound from '@/components/NotFound.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'

@Component({
  components: { NotFound, LinkArrowIcon },
})
export default class NotFoundView extends Vue {}
