






import { Component, Vue, Prop } from 'vue-property-decorator'
import LoadingSpinner from './LoadingSpinner.vue'

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class Loading extends Vue {
  isVisible: Boolean = true

  @Prop({ type: Boolean, default: false })
  readonly fullscreen?: Boolean

  @Prop({ type: Boolean, default: false })
  readonly opaque?: Boolean

  get className() {
    return { fullscreen: this.fullscreen, opaque: this.opaque }
  }
}
