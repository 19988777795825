









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class StepProgressBar extends Vue {
  @Prop()
  state!: string

  @Prop()
  stepNumber!: number

  get content() {
    const i = this.stepNumber
    switch (this.state) {
      case 'past':
        return '&#10003' // checkmark
      case 'error':
        return '!'
      default:
        return i + 1
    }
  }
}
