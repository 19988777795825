






























import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import IconPrescriber from '@/assets/icons/icon-prescription.svg'
import IconInsurance from '@/assets/icons/icon-insurance-shield-large.svg'
import IconShipping from '@/assets/icons/icon-shipment.svg'
import Modal from '@/components/Modal.vue'
import Card from '@/components/Card.vue'

@Component({
  components: { Modal, Card },
  computed: {
    ...mapState('user', ['me']),
  },
})
export default class deliveryInfoModal extends Vue {
  get copy() {
    return {
      header: this.$t('shipping.header'),
      items: [
        {
          header: this.$t('shipping.item1.header'),
          body: this.$t('shipping.item1.body'),
          icon: IconInsurance,
        },
        {
          header: this.$t('shipping.item2.header'),
          body: this.$t('shipping.item2.body'),
          icon: IconPrescriber,
        },
        {
          header: this.$t('shipping.item3.header'),
          body: this.$t('shipping.item3.body'),
          icon: IconShipping,
        },
      ],
    }
  }

  close() {
    this.$bvModal.hide('delivery-info-modal')
  }
}
