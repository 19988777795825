interface Window {
  CustomEvent: CustomEvent
}

;(function () {
  if (typeof window.CustomEvent === 'function') return
  function CustomEvent(event: string, params: any) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    const evt: CustomEvent = <any>document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = Event.prototype

  window.CustomEvent = <any>CustomEvent
})()
