import { serializable, primitive, identifier, alias, list, object } from 'serializr'

export class Dosage {
  @serializable(alias('unit_count', primitive()))
  unitCount!: number

  @serializable(alias('time', primitive()))
  time!: string
}

export enum OTCMedicationRequestTiming {
  Interval = 'interval',
  SpecificDay = 'specific_day',
  Recommended = 'recommended_dose',
}

export enum OTCMedicationRequestInterval {
  EveryDay = '1',
  EveryOtherDay = '2',
}

export enum OTCMedicationRequestType {
  Packet = 'packet',
  Bulk = 'bulk',
}

export const RECOMMENDED_STRENGTH_ID = 'recommended'
export const RECOMMENDED_FORMULATION_ID = 'recommended'

export default class OTCMedicationRequest {
  @serializable(alias('drug_id', identifier()))
  drugId!: string

  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('dosages', list(object(Dosage))))
  dosages!: Dosage[]

  @serializable(alias('medication_id', primitive()))
  medicationId!: string

  @serializable(alias('timing', primitive()))
  timing!: OTCMedicationRequestTiming

  @serializable(alias('type', primitive()))
  type!: OTCMedicationRequestType

  @serializable(alias('interval_choice', primitive()))
  intervalChoice?: OTCMedicationRequestInterval

  @serializable(alias('days_of_week', list(primitive())))
  daysOfWeek!: string[]

  @serializable(alias('unit_count', primitive()))
  unitCount!: number

  @serializable(alias('auto_refill', primitive()))
  autoRefill!: boolean
}

export class OTCMedicationRequestRequest {
  @serializable(alias('otcmedication_request', object(OTCMedicationRequest)))
  otcMedicationRequest!: OTCMedicationRequest
}
