import { serializable, primitive, alias, object } from 'serializr'
import { ppCoreDate, deserializeOnly } from '@/models/transforms'
import OnboardStatePayload from './OnboardStatePayload'

export enum OnboardingQueue {
  InsuranceCheck = 'insurance_check', // Not used
  Prequalify = 'prequalify',
  DataEntry = 'data_entry', // RX OUTREACH
  RphCheck = 'rph_check', // RESOLUTION
  Failure = 'failure',
  Onboarded = 'onboarded', // Same as user.onboarded
}

export default class OnboardState {
  @serializable(primitive())
  id!: string

  @serializable(alias('queue_name', primitive()))
  queueName!: OnboardingQueue

  @serializable(object(OnboardStatePayload))
  payload!: OnboardStatePayload

  @serializable(alias('created_at', primitive()))
  createdAt!: string

  @serializable(alias('updated_at', primitive()))
  updatedAt!: string

  @serializable(alias('time_entered_queue', deserializeOnly(ppCoreDate())))
  timeEnteredQueue!: Date

  @serializable(alias('onboarding_pod_id', primitive()))
  onboardingPodId!: string

  @serializable(alias('rph_required', primitive()))
  rphRequired!: boolean

  @serializable(alias('reentered_onboarding_at', deserializeOnly(ppCoreDate())))
  reenteredOnboardingAt?: Date

  @serializable(alias('entered_onboarded_at', deserializeOnly(ppCoreDate())))
  enteredOnboardedAt?: Date

  @serializable(alias('entered_prequalify_at', deserializeOnly(ppCoreDate())))
  enteredPrequalifyAt?: Date

  @serializable(alias('entered_data_entry_at', deserializeOnly(ppCoreDate())))
  enteredDataEntryAt?: Date

  @serializable(alias('entered_rph_check_at', deserializeOnly(ppCoreDate())))
  enteredRphCheckAt?: Date

  @serializable(alias('entered_insurance_check_at', deserializeOnly(ppCoreDate())))
  enteredInsuranceCheckAt?: Date

  @serializable(alias('entered_transfer_at', deserializeOnly(ppCoreDate())))
  enteredTransferAt?: Date

  @serializable(alias('entered_pharmacy_onboard_failure_at', deserializeOnly(ppCoreDate())))
  enteredPharmacyOnboardFailureAt?: Date

  @serializable(alias('pended_from_queue', primitive()))
  pendedFromQueue?: boolean

  @serializable(alias('onboarded_by_id', primitive()))
  onboardedById?: string

  get isVerifying() {
    return (
      this.queueName === OnboardingQueue.Prequalify ||
      this.queueName === OnboardingQueue.InsuranceCheck
    )
  }

  get isInFinalCheck() {
    return this.queueName === OnboardingQueue.RphCheck
  }
}
