import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import OTCMedication from '@/models/OTCMedication'

interface State {
  otcMedications: OTCMedication[]
}

const state: State = {
  otcMedications: [],
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,

  mutations: {
    setOtcMedications(state, otcMedications: OTCMedication[]): void {
      state.otcMedications = otcMedications
    },
  },

  actions: {
    load: trackAsyncStatus('otcMedications/load', async function ({ commit }) {
      if (state.otcMedications && state.otcMedications.length) {
        // return otc meds if they've already been fetched
        return
      }

      const otcMedications = await Vue.$pillpack.otcMedications.getAll()

      commit('setOtcMedications', otcMedications)
    }),
  },
}

export default module
