/* eslint-disable no-use-before-define */
import { PPHttp, PPHttpMethod, PPHttpContentType, PPHttpUrlParams } from './PPHttp'
import Address from '@/models/Address'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize, deserializeArray, serialize } from '@/models/transforms'
import PPAddressError from './PPAddressError'
import ZipcodeLookup from '@/models/ZipcodeLookup'
import { PPArgs, PPBaseArgs } from './PPClient'

export default class PPAddressesApi {
  static async get({ baseUrl, session, userId, locale }: PPArgs): Promise<PPResponse<Address[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/addresses`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const addressList = deserializeArray(Address, response.data)
    return new PPSuccessResponse(addressList)
  }

  static async delete(
    { baseUrl, session, userId, locale }: PPArgs,
    address: Address,
  ): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.DELETE,
      baseUrl,
      path: `/api/users/${userId}/addresses/${address.id}`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    return PPSuccessResponse.voidResponse()
  }

  static async addOrUpdate(
    { baseUrl, session, userId, locale }: PPArgs,
    {
      address,
      options,
    }: {
      address: Address
      options: { force: boolean }
    },
  ): Promise<PPResponse<Address>> {
    // default params for when adding a new address
    let method = PPHttpMethod.POST
    let path = `/api/users/${userId}/addresses`

    // modify params when editing an existing address
    if (address.id) {
      method = PPHttpMethod.PUT
      path += `/${address.id}`
    }

    const urlParams: PPHttpUrlParams = { locale }
    if (options.force) {
      urlParams.force = true
    }

    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method,
      baseUrl,
      path,
      urlParams,
      content: JSON.stringify(serialize(Address, address)),
      contentType: PPHttpContentType.JSON,
    })

    if (response.failure) {
      const addressError = PPAddressError.addressError(response.error.data)
      if (addressError) {
        return new PPFailureResponse(addressError)
      } else {
        return new PPFailureResponse(response.error)
      }
    }

    const updatedAddress = deserialize(Address, response.data)
    return new PPSuccessResponse(updatedAddress)
  }

  static async setCurrentShippingAddress(
    { baseUrl, session, userId, locale }: PPArgs,
    addressId: string,
  ): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/addresses/${addressId}/set_as_current_shipping`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    // TODO: this API currently returns 204 but we may want to update it to return
    // the full address list since it modifies two address objects
    return PPSuccessResponse.voidResponse()
  }

  static async zipcodeLookup(
    { baseUrl, session, locale }: PPBaseArgs,
    zipcode: string,
  ): Promise<PPResponse<ZipcodeLookup>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/addresses/lookup_zip`,
      urlParams: { locale, zipcode },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const zipcodeLookup = deserialize(ZipcodeLookup, response.data)
    return new PPSuccessResponse(zipcodeLookup)
  }
}
