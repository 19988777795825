import { alias, serializable, primitive } from 'serializr'

export default class PaymentMethod {
  @serializable
  type?: string

  @serializable
  brand?: string

  @serializable(alias('last_four', primitive()))
  lastFour?: string

  @serializable(alias('icon_asset_url', primitive()))
  iconAssetUrl?: string
}
