import { render, staticRenderFns } from "./ServiceAlertNavBell.vue?vue&type=template&id=d530b140&scoped=true&"
import script from "./ServiceAlertNavBell.vue?vue&type=script&lang=ts&"
export * from "./ServiceAlertNavBell.vue?vue&type=script&lang=ts&"
import style0 from "./ServiceAlertNavBell.vue?vue&type=style&index=0&id=d530b140&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d530b140",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BBadge } from 'bootstrap-vue'
import { BNavItem } from 'bootstrap-vue'
import IconBell from '@/assets/icons/icon-bell.svg'
installComponents(component, {BBadge,BNavItem,IconBell})
