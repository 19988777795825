




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SkeletonCircle extends Vue {
  @Prop()
  diameter!: number

  get style() {
    const diameterInPixels = `${this.diameter}px`

    return { width: diameterInPixels, height: diameterInPixels }
  }
}
