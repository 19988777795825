import { TranslateResult } from 'vue-i18n'

import { arrivalMessage } from '@/i18n'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import DashboardMedication, { Group, MedicationType } from '@/models/DashboardMedication'
import PrescriptionRequest, { ChaseStates, ChaseTypes } from '@/models/PrescriptionRequest'
import User from '@/models/User'

export type StatusClass =
  | 'med-status--shipping'
  | 'med-status--paused'
  | 'med-status--default'
  | 'med-status--failed'
  | 'med-status--in-progress'
  | 'med-status--denied'

export type MedicationStatus =
  | 'expired'
  | 'discontinued'
  | 'pending'
  | 'new_chase'
  | 'in_progress'
  | 'denied'
  | 'has_error'
  | 'unable_to_acquire'
  | 'received_signup_med'
  | 'received'
  | 'unknown'
  | 'pre_rph_check'
  | 'active'
  | 'pending_otc'
  | 'ortf'

export type ShipmentStatus =
  | 'paused'
  | 'with_arrival_date'
  | 'waiting'
  | 'ready'
  | 'contact_us'
  | 'by_request'
  | 'unknown_offcycle'
  | 'unknown'
  | 'past'
  | 'loading'

export function chaseStatusFinder(med: DashboardMedication): MedicationStatus | undefined {
  const chase = med.prescriptionRequest
  if (!chase) {
    return undefined
  }

  let status: MedicationStatus = chase.state

  if (status === ChaseStates.Received && chase.type === ChaseTypes.SignupMed) {
    status = 'received_signup_med'
  }

  return status
}

export function shipmentStatusFinder(med: DashboardMedication, user?: User): ShipmentStatus {
  // we have an arrival date! let's use it.
  if (med.nextArrivalDate) {
    return 'with_arrival_date'
  }

  if (!user || !user.id || !user.id.length) {
    return 'loading'
  }

  // if the user is ONBOARDING, we are still waiting for a first shipment
  // if the user or user.id is falsy, we probably haven't finished loading them yet
  if (!user.onboarded) {
    if (med.isByRequest) {
      return 'contact_us'
    }
    if (med.rphChecked) {
      return 'ready'
    }
    return 'waiting'
  }

  if (med.isPaused) {
    return 'paused'
  }

  if (med.isByRequest) {
    // edge case where rx has no arrival date, but the customer requested a refill
    if (med.refillRequested) {
      return 'unknown'
    }

    return 'by_request'
  }

  if (med.isPast) {
    return 'past'
  }

  // off-cycle bulks have no estimated arrival dates until shipment is generated
  if (med.autoRefill && !med.inPackets) {
    return 'unknown_offcycle'
  }

  // this would happen if we were not able to get an arrival date
  return 'unknown'
}

export function medStatusFinder(med: DashboardMedication): MedicationStatus {
  const chase = med.prescriptionRequest

  if (chase) {
    return chaseStatusFinder(med) || 'unknown'
  }

  if (med.group === Group.Pending && med.selfPrescribed) {
    return 'pending_otc'
  }

  if (med.group === Group.Pending && med.type === MedicationType.SignupMedication) {
    return med.isOrtf ? 'ortf' : 'pending'
  }

  if (med.expired) {
    return 'expired'
  }

  if (med.discontinued) {
    return 'discontinued'
  }

  if (!med.rphChecked && !med.selfPrescribed) {
    return 'pre_rph_check'
  }

  if (med.isOrtf) {
    return 'ortf'
  }

  if (med.group === Group.Active) {
    return 'active'
  }

  return 'unknown'
}

export function arrivalDateCopy(med?: DashboardMedication): TranslateResult {
  const arrival = med && med.nextArrivalDate
  return arrivalMessage(arrival)
}

export function chaseClass(chase: PrescriptionRequest): StatusClass | undefined {
  let str: StatusClass | undefined

  switch (chase.state) {
    case ChaseStates.Denied:
      str = 'med-status--denied'
      break
    case ChaseStates.HasError:
      str = 'med-status--failed'
      break
    case ChaseStates.InProgress:
      str = 'med-status--in-progress'
      break
    case ChaseStates.UnableToAcquire:
      str = 'med-status--failed'
      break
    case ChaseStates.NewChase:
    case ChaseStates.Received:
    case ChaseStates.Pending:
    case undefined:
      str = 'med-status--default'
      break
    default:
      throw new UnreachableCaseError(chase.state)
  }
  return str
}
