import PPClientModule from './PPClientModule'
import OTCMedication from '@/models/OTCMedication'
import OTCMedicationRequest from '@/models/OTCMedicationRequest'
import PPOTCMedicationsApi from '@/ppapi/PPOTCMedicationsApi'
import OTCMedicationResponse from '@/models/OTCMedicationResponse'

export default class PPOTCMedicationsClient extends PPClientModule {
  getAll(): Promise<OTCMedication[]> {
    return this.performRequest(PPOTCMedicationsApi.getAll)
  }

  getCost({ medicationId, quantity }: { medicationId: string; quantity: number }): Promise<number> {
    return this.performRequest(args => {
      return PPOTCMedicationsApi.getCost({
        ...args,
        medicationId,
        quantity,
      })
    })
  }

  request(otcMedicationRequest: OTCMedicationRequest): Promise<OTCMedicationResponse> {
    return this.performRequest(args => {
      return PPOTCMedicationsApi.request({
        ...args,
        payload: otcMedicationRequest,
      })
    })
  }
}
