import { serializable, primitive, alias } from 'serializr'

export default class OTCMedicationFormulation {
  @serializable(alias('id', primitive()))
  id!: string

  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('short_name', primitive()))
  shortName!: string

  @serializable(alias('type', primitive()))
  type!: string
}
