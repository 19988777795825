import { serializable, primitive, identifier, alias, object } from 'serializr'
import { iso8601Date } from '@/models/transforms'
import { BasicCardInfo } from '@/models/StripeCard'

export default class StripeCharge {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('stripe_id', primitive()))
  stripeId!: string

  @serializable(alias('charged_at', iso8601Date()))
  chargedAt!: Date

  @serializable(alias('amount', primitive()))
  amount!: string

  @serializable(alias('amount_refunded', primitive()))
  amountRefunded!: string

  @serializable(alias('stripe_card', object(BasicCardInfo)))
  cardInfo!: BasicCardInfo

  get displayAmount() {
    // amounts are always negative
    const amount = (-1 * Number(this.amount)).toFixed(2)

    return `$${amount}`
  }
}
