




































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import User from '@/models/User'

import Card from '@/components/Card.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import AlertIcon from '@/components/AlertIcon.vue'

@Component({
  components: {
    Card,
    LinkArrowIcon,
    AlertIcon,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
})
export default class ConfirmEmail extends Vue {
  me!: User
  getError!: (key: string, id?: string) => Error | null
  isInProgress!: (key: string, id?: string) => boolean
  hasSucceeded!: (key: string, id?: string) => boolean

  isHidden = false

  get show() {
    return this.me.id && !this.me.confirmed && !this.isHidden
  }

  get borderVariant() {
    if (this.sendingEmail) return ''
    else if (this.confirmationSent) return 'success'
    else return ''
  }

  get confirmationSent() {
    return this.hasSucceeded('user/resendEmailConfirmation')
  }

  get sendingEmail() {
    return this.isInProgress('user/resendEmailConfirmation')
  }

  get needsConfirmation() {
    return !this.me.confirmed
  }

  get errorOccurred() {
    return !!this.getError('user/resendEmailConfirmation')
  }

  resendEmail() {
    this.$store.dispatch('user/resendEmailConfirmation')
  }

  dismiss() {
    this.isHidden = true
  }
}
