import { DirectiveOptions, PluginObject, VNode } from 'vue'
import { DirectiveBinding } from 'vue/types/options'

interface GoogleAnalyticsOptions {
  eventCategory?: string
  eventAction?: string
  eventValue?: number
  eventLabel?: string
  hitCallback?: Function
  hitCallbackFail?: Function
}

interface GoogleAnalyticsEventOptions extends GoogleAnalyticsOptions {
  eventCategory: string
  eventAction: string
}

class GoogleAnalytics {
  enabled: boolean = !!process.env.ENABLE_GA

  get ga() {
    return this.enabled ? (window as any).ga : () => {}
  }

  private asyncWrapper(method: string, type: string, options?: GoogleAnalyticsOptions) {
    return this.enabled
      ? new Promise((resolve, reject) => {
          this.ga(method, type, {
            ...options,
            hitCallback: resolve,
            hitCallbackFail: reject,
          })
        })
      : Promise.resolve
  }

  trackPageView = (path: string = window.location.pathname, replaceIds = true) => {
    // Following guide for SPA support for GA
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
    path = replaceIds ? path.replace(/[0-9a-f]{24}/g, 'ID') : path
    this.ga('set', 'page', path)
    return this.asyncWrapper('send', 'pageview')
  }

  trackEvent = (options: GoogleAnalyticsEventOptions) => {
    return this.asyncWrapper('send', 'event', options)
  }
}

const googleAnalytics = new GoogleAnalytics()
const eventMap = new WeakMap()

function bindEvent($el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
  const { arg: eventName, value, modifiers } = binding
  const eventAction = eventName as string
  const isShortHand = typeof value === 'string'
  const eventCategory = isShortHand ? value : value.category
  const eventLabel = isShortHand ? null : value.value
  const componentInstance = vnode?.componentInstance

  const eventHandler = () => {
    googleAnalytics.trackEvent({
      eventCategory,
      eventAction,
      eventLabel,
    })
  }

  if (modifiers.native || !componentInstance) {
    $el.addEventListener(eventAction, eventHandler)
  } else if (componentInstance) {
    componentInstance.$on(eventAction, eventHandler)
  }

  eventMap.set($el, eventHandler)
}

function unBindEvent($el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
  const { arg: eventName, modifiers } = binding
  const eventAction = eventName as string
  const componentInstance = vnode?.componentInstance
  const oldEventHandler = eventMap.get($el)

  if (modifiers.native || !componentInstance) {
    $el.removeEventListener(eventAction, oldEventHandler)
  } else if (componentInstance) {
    componentInstance.$off(eventAction, oldEventHandler)
  }
  eventMap.delete($el)
}

const GoogleAnalyticsDirective: DirectiveOptions = {
  bind($el, binding, vnode) {
    bindEvent($el, binding, vnode)
  },
  update($el, binding, vnode) {
    unBindEvent($el, binding, vnode)
    bindEvent($el, binding, vnode)
  },
  unbind($el, binding, vnode) {
    unBindEvent($el, binding, vnode)
  },
}

const GoogleAnalyticsPlugin: PluginObject<void> = {
  install(Vue: any) {
    Vue.prototype.$ga = googleAnalytics
    Vue.$ga = googleAnalytics
    Vue.directive('ga', GoogleAnalyticsDirective)
  },
}

export default GoogleAnalyticsPlugin

export const trackPageView = googleAnalytics.trackPageView
export const trackEvent = googleAnalytics.trackEvent
