




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class LinkArrowIcon extends Vue {
  @Prop({ type: Boolean, default: false })
  large!: boolean

  @Prop({ type: Boolean, default: false })
  blue?: boolean

  @Prop({ type: Boolean, default: false })
  grey?: boolean

  @Prop({ type: Boolean, default: false })
  down?: boolean

  @Prop({ type: Boolean, default: false })
  up?: boolean

  @Prop({ type: Boolean, default: false })
  left?: boolean

  @Prop({ type: Boolean, default: false })
  expander?: boolean
}
