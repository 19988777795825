

































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { parse } from 'date-fns'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { formatDateMedium, arrivalMessage } from '@/i18n'
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import RadioGroup from '@/components/inputs/RadioGroup.vue'
import DashboardMedication from '@/models/DashboardMedication'
import { validationStateMixin } from '@/util/validationState'
import Order from '@/models/Order'

@Component({
  components: {
    Modal,
    Loading,
    RadioGroup,
  },
  computed: {
    ...mapGetters('orders', ['currentOrders', 'nextShipmentDate']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    orderType: {
      required,
    },
    selectedDate: {
      required: requiredIf(function (this: OrderRefillModal) {
        return this.orderType === 'rush'
      }),
    },
  },
})
export default class OrderRefillModal extends Vue {
  @Prop({ type: String, required: true })
  modalId!: string

  @Prop({ type: Object, required: true })
  med!: DashboardMedication

  orderType: 'next_scheduled' | 'rush' = 'next_scheduled'
  selectedDate: string | null = null
  dateOptions: { text: string; value: string }[] | null = null

  currentOrders!: Order[]
  nextShipmentDate!: Date | undefined

  get nextShipmentDeliveryMessage() {
    return arrivalMessage(this.nextShipmentDate)
  }

  get orderTypeOptions() {
    return [
      {
        text: this.$t('Next scheduled shipment'),
        subtitle: this.nextShipmentDeliveryMessage,
        value: 'next_scheduled',
        disabled: !this.med.canOrderRefill,
      },
      {
        text: this.$t('Rush shipment'),
        value: 'rush',
        disabled: !this.med.canOrderRush,
      },
    ]
  }

  async mounted() {
    await this.$store.dispatch('orders/loadCurrent')
  }

  async onSubmit(ok: () => void) {
    if (this.orderType === 'rush') {
      return this.submitRushOrder(ok)
    }
    return this.submitRefillRequest(ok)
  }

  async submitRushOrder(ok: () => void) {
    this.$v.$touch()

    if (this.$v.$invalid) return
    if (!this.selectedDate) return

    await this.$store.dispatch('medications/orderRushShipment', {
      id: this.med.id,
      dateNeeded: parse(this.selectedDate),
    })

    ok()
  }

  async submitRefillRequest(ok: () => void) {
    this.$v.$touch()

    if (this.$v.$invalid) return

    await this.$store.dispatch('medications/requestRefill', {
      id: this.med.id,
    })

    ok()
  }

  async onShown() {
    if (!this.med.primaryPrescriptionId) return

    const options = await Vue.$pillpack.medications.getRushShipmentOptions(
      this.med.primaryPrescriptionId,
    )

    this.dateOptions = options.map(option => ({
      text: formatDateMedium(option.arrivalDate),
      value: option.arrivalDate.toDateString(),
    }))
  }

  onHidden() {
    this.orderType = 'next_scheduled'
    this.selectedDate = null

    this.$v.$reset()
  }
}
