import { serializable, object, primitive, alias } from 'serializr'

class Drug {
  @serializable(alias('id', primitive()))
  id!: string

  @serializable(alias('description', primitive()))
  description!: string

  @serializable(alias('rxcui', primitive()))
  rxcui!: string
}

class Formulation {
  @serializable(alias('name', primitive()))
  name!: string
}

export default class RxCatalogConcept {
  @serializable(alias('drug', object(Drug)))
  drug!: Drug

  @serializable(alias('formulation', object(Formulation)))
  formulation?: Formulation

  @serializable(alias('strength', primitive()))
  strength?: string

  get displayString() {
    if (!this.strength) return null
    return `${this.strength} (${this.formulation?.name})`
  }
}
