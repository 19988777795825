

















import { Component, Vue, Prop } from 'vue-property-decorator'

import AlertIcon from '@/components/AlertIcon.vue'

@Component({
  components: {
    AlertIcon,
  },
})
export default class GenericError extends Vue {
  @Prop({ type: String, default: 'horizontal' })
  layout!: 'horizontal' | 'vertical'
}
