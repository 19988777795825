











import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PrintLayout extends Vue {
  print() {
    window.print()
  }

  mounted() {
    document.body.classList.add('print-layout')
  }

  beforeDestroy() {
    document.body.classList.remove('print-layout')
  }
}
