import { Location } from 'vue-router'

import ServiceAlert, { AlertType } from '@/models/ServiceAlert'
import UnreachableCaseError from './UnreachableCaseError'

export default class AlertProperties {
  imageUrl: string = ''
  linkText: string = ''
  linkAttrs: { href: string; target?: string } | { to: Location } | null = null
  variant: BootstrapVue.Variant = 'danger'
  iconName: string = 'exclamation'
  canResolve: boolean = false
  canAcknowledge: boolean = false

  constructor(alert: ServiceAlert) {
    switch (alert.class) {
      case AlertType.AccountHold:
      case AlertType.AccountHoldImminent:
      case AlertType.AccountOverdue:
        this.linkAttrs = { to: { name: 'payment' } }
        break
      case AlertType.Announcement:
        if (alert.locals.link_url) {
          this.linkAttrs = { href: alert.locals.link_url, target: '_blank' }
          this.linkText = alert.locals.link_text
        }
        this.iconName = 'speakerphone'
        this.canResolve = true
        break
      case AlertType.RenewalRequested:
        this.imageUrl = alert.locals.medication_image_url
        this.linkAttrs = {
          to: {
            name: 'medication-details',
            params: {
              type: 'dashboard_medication',
              id: alert.locals.user_medication_id,
            },
          },
        }
        break
      case AlertType.OrtfTransfer:
        this.iconName = 'pills'
        this.variant = 'secondary'
        this.canResolve = true
        this.linkAttrs = {
          to: {
            name: 'medications',
          },
        }
        break
      case AlertType.DeletedSignupMed:
      case AlertType.Default:
        break
      default:
        throw new UnreachableCaseError(alert.class)
    }

    if (alert.resolved) {
      // resolved alerts are removable but their link is removed
      this.canResolve = false
      this.canAcknowledge = true
      this.linkAttrs = null
    }
  }

  get fullSizeLink(): boolean {
    return Boolean(this.linkAttrs && !this.linkText)
  }

  get hasTextLink(): boolean {
    return Boolean(this.linkAttrs && this.linkText)
  }
}
