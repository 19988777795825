import PPSession from './PPSession'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { serialize, deserialize, deserializeArray } from '@/models/transforms'
import OTCMedication from '@/models/OTCMedication'
import OTCMedicationRequest, { OTCMedicationRequestRequest } from '@/models/OTCMedicationRequest'
import OTCMedicationResponse from '@/models/OTCMedicationResponse'

export default class PPOTCMedicationsApi {
  static async getAll({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<OTCMedication[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: '/api/v2/otc_medications',
      urlParams: {
        locale,
        user_id: userId,
        with_medication_costs: true,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserializeArray(OTCMedication, response.data)

    return new PPSuccessResponse(results)
  }

  static async getCost({
    baseUrl,
    session,
    userId,
    locale,
    medicationId,
    quantity,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    medicationId: string
    quantity: number
  }): Promise<PPResponse<number>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/medications/otc_cost`,
      urlParams: {
        locale,
        medication_id: medicationId,
        quantity,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const cost = Number(response.data.otc_cost)

    return new PPSuccessResponse(cost)
  }

  static async request({
    baseUrl,
    session,
    userId,
    locale,
    payload,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    payload: OTCMedicationRequest
  }): Promise<PPResponse<OTCMedicationResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/otc_medication_requests`,
      urlParams: {
        locale,
      },
      content: serialize(OTCMedicationRequestRequest, { otcMedicationRequest: payload }),
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserialize(OTCMedicationResponse, response.data)

    return new PPSuccessResponse(results)
  }
}
