import capitalize from 'lodash/capitalize'

export const lowercase = (value: string) => value.toLocaleLowerCase()

export const currency = (value: string | number) => `$${Number(value).toFixed(2)}`

export function capitalizeEachWord(str?: string): string {
  if (!str) return ''
  return str.split(' ').map(capitalize).join(' ')
}
