import { PPHttp, PPHttpMethod, PPHttpContentType } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserializeArray, serialize } from '@/models/transforms'
import Insurance from '@/models/Insurance'
import { PPArgs } from './PPClient'

export default class PPInsuranceApi {
  static async all(args: PPArgs): Promise<PPResponse<Insurance[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/insurances`,
      urlParams: {
        locale: args.locale,
        hide_sensitive_data: true,
        exclude_good_rx: true,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const insurances = deserializeArray(Insurance, response.data)

    return new PPSuccessResponse(insurances)
  }

  static async create(args: PPArgs, insurance: Insurance): Promise<PPResponse<Insurance[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/api/users/${args.userId}/insurances`,
      urlParams: { locale: args.locale },
      content: JSON.stringify({
        insurance: serialize(Insurance, insurance),
        hide_sensitive_data: true,
        return_insurances: true,
      }),
      contentType: PPHttpContentType.JSON,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const insurances = deserializeArray(Insurance, response.data)

    return new PPSuccessResponse(insurances)
  }
}
