/* eslint-disable no-console */
import { PPHttpSuccessResponse, PPHttpResponse } from '@/ppapi/PPHttp'

// when you lazy-load this module you need to reference the default property:
// https://webpack.js.org/guides/lazy-loading/
export default async function intercept(mock: string, path: string): Promise<PPHttpResponse> {
  const data = await import(
    /* webpackChunkName: "mocks" */
    `./mocks/${mock}`
  )
  const response = new PPHttpSuccessResponse(data)
  console.info(`Mocked Request for ${path}`, response)
  return response
}
