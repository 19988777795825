import PPClientModule from './PPClientModule'
import PPBillingApi from './PPBillingApi'

export default class PPBillingClient extends PPClientModule {
  turnOnAutopay = this.wrapApi(PPBillingApi.turnOnAutopay)

  makeManualOrderPayment = this.wrapApi(PPBillingApi.makeManualOrderPayment)

  makeManualPartialPayment = this.wrapApi(PPBillingApi.makeManualPartialPayment)

  loadPayments = this.wrapApi(PPBillingApi.loadPayments)
}
