


























































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import Card from '@/components/Card.vue'
import Modal from '@/components/Modal.vue'
import LoadingButton from '@/components/LoadingButton.vue'
import DispenserSwatch from '@/components/dashboard/DispenserSwatch.vue'
import { BannerInfo } from '@/vuex/banners'

import Dispenser from '@/models/Dispenser'

@Component({
  components: {
    Card,
    Modal,
    LoadingButton,
    DispenserSwatch,
  },
  computed: {
    ...mapState('dispensers', ['availableColors', 'purchasedDispenser']),
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded']),
  },
})
export default class PremiumDispenserCard extends Vue {
  isInProgress!: (key: string) => boolean
  hasSucceeded!: (key: string) => boolean
  availableColors!: Dispenser[]
  purchasedDispenser?: Dispenser

  selectedColor: Dispenser | null = null
  dispenserPrice = '$15.00'

  created() {
    this.selectedColor = this.availableColors[0]
  }

  get cardDispenserColor() {
    if (!this.availableColors || !this.availableColors.length) return null
    return this.availableColors[0]
  }

  getImgUrl(color: string) {
    const images = require.context('@/assets/dispensers', false, /\.jpg$/)
    return images(`./${color}.jpg`)
  }

  async submit(ok: Function) {
    const dispenser = this.selectedColor
    const banner: BannerInfo = {
      id: 'premium-dispenser-ordered',
      bgVariant: 'success',
      message: this.$t('Order successful!'),
      durationMs: 2000,
    }
    const success = await this.$store.dispatch('dispensers/updatePremiumDispenser', dispenser)

    if (this.hasSucceeded('dispensers/updatePremiumDispenser')) {
      this.$store.dispatch('showBanner', banner)
      ok(success)
    }
  }

  get colorText() {
    if (!this.selectedColor) return ''
    return this.$t('Color: {color}', { color: this.selectedColor.name })
  }
}
