import { alias, serializable, primitive, identifier, object } from 'serializr'

export class LastFailedPaymentInfo {
  @serializable(primitive())
  description!: string

  @serializable(primitive())
  details!: string

  @serializable(alias('last_four', primitive()))
  lastFour!: string

  @serializable(primitive())
  amount!: string

  @serializable(alias('amount_string', primitive()))
  amountString!: string

  @serializable(alias('total_amount', primitive()))
  totalAmount?: string

  @serializable(alias('total_amount_string', primitive()))
  totalAmountString?: string

  get displayAmount() {
    return this.totalAmountString || this.amountString
  }
}

export default class Account {
  @serializable(identifier())
  id!: string

  @serializable(primitive())
  autopay!: boolean

  @serializable(primitive())
  overdue!: boolean

  @serializable(alias('show_dashboard_overdue_notice', primitive()))
  showDashboardOverdueNotice!: boolean

  @serializable(alias('last_failed_payment_info', object(LastFailedPaymentInfo)))
  lastFailedPaymentInfo?: LastFailedPaymentInfo

  @serializable(alias('current_balance', primitive()))
  currentBalance?: string

  @serializable(alias('current_owed_balance', primitive()))
  currentOwedBalance?: string

  @serializable(alias('current_orders_overdue_balance', primitive()))
  currentOrdersOverdueBalance?: string

  @serializable(alias('fsa_eligible_balance', primitive()))
  fsaEligibleBalance?: string

  @serializable(alias('non_fsa_elig_balance', primitive()))
  nonFsaEligBalance?: string

  @serializable(alias('credit_balance', primitive()))
  creditBalance?: string

  @serializable(alias('promotional_credit', primitive()))
  promotionalCredit?: string

  @serializable(alias('balance_due_date', primitive()))
  balanceDueDate?: string

  @serializable(alias('has_pending_collection_payment', primitive()))
  hasPendingCollectionPayment?: boolean

  @serializable(alias('billing_day_of_month', primitive()))
  billingDayOfMonth?: number

  @serializable(alias('monthly_billing_day', primitive()))
  monthlyBillingDay?: string
}
