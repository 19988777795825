import honeybadger from 'honeybadger-js'
import FeatureFlags from '@/util/featureFlags'

declare global {
  interface Window {
    honeybadger: typeof honeybadger
  }
}

export default class PPSession {
  readonly csrfToken: string
  readonly userId?: string
  readonly adminId?: string
  readonly timeoutInMs?: number

  constructor(csrfToken: string, userId?: string, adminId?: string, timeoutInMs?: number) {
    this.csrfToken = csrfToken
    this.userId = userId
    this.adminId = adminId
    this.timeoutInMs = timeoutInMs
  }

  static fromObject(obj: any): PPSession | undefined {
    const csrfToken = obj.csrf_token
    const userId = obj.user_id
    const adminId = obj.admin_id
    const timeoutInMs = obj.timeout_in_ms
    const featureFlags = obj.feature_flags

    // must be a string
    if (typeof csrfToken !== 'string') {
      if (window.honeybadger) {
        window.honeybadger.notify(`PPSession: csrfToken has a illegal type ${typeof csrfToken}`)
      }
      return undefined
    }

    // must be undefined or a string
    if (userId && typeof userId !== 'string') {
      if (window.honeybadger) {
        window.honeybadger.notify(`PPSession: userId has a illegal type ${typeof userId}`)
      }
      return undefined
    }

    // must be undefined or a string
    if (adminId && typeof adminId !== 'string') {
      if (window.honeybadger) {
        window.honeybadger.notify(`PPSession: adminId has a illegal type ${typeof adminId}`)
      }
      return undefined
    }

    FeatureFlags.set(featureFlags || [])

    return new PPSession(csrfToken, userId, adminId, timeoutInMs)
  }
}
