import { serializable, alias, object } from 'serializr'
import { ppCoreDate, deserializeOnly } from '@/models/transforms'
import Address from '@/models/Address'

export default class ShipmentInfo {
  @serializable(alias('ship_date', deserializeOnly(ppCoreDate())))
  shipDate!: Date

  @serializable(alias('start_date', deserializeOnly(ppCoreDate())))
  startDate!: Date

  @serializable(alias('end_date', deserializeOnly(ppCoreDate())))
  endDate!: Date

  @serializable(alias('arrival_date', deserializeOnly(ppCoreDate())))
  arrivalDate!: Date

  @serializable(alias('editable_until', deserializeOnly(ppCoreDate())))
  editableUntil!: Date

  @serializable(alias('address', object(Address)))
  address!: Address
}
