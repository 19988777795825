import { serializable, primitive, identifier, alias } from 'serializr'
import { ppCoreDate, deserializeOnly } from '@/models/transforms'

export default class OrderPaymentItem {
  @serializable(alias('record_id', identifier()))
  id?: string

  @serializable(alias('amount_string', primitive()))
  amount?: string

  @serializable(alias('raw_description_string', primitive()))
  rawDescription?: string

  @serializable(alias('raw_detail_string', primitive()))
  rawDetails?: string

  @serializable(alias('icon_asset_string', primitive()))
  brand?: string

  @serializable(alias('icon_asset_url', primitive()))
  iconAssetUrl?: string

  @serializable(alias('is_failed_payment', primitive()))
  isFailedPayment?: boolean

  @serializable(alias('has_refund', primitive()))
  hasRefund?: boolean

  @serializable(alias('refund_amount_string', primitive()))
  refundAmount?: string

  @serializable(alias('created_at', deserializeOnly(ppCoreDate())))
  createdAt!: Date
}
