import { alias, serializable, primitive, identifier, object, list } from 'serializr'
import { TranslateResult } from 'vue-i18n'
import TrackingEvent, { TrackingEventState } from '@/models/Tracking/TrackingEvent'
import TrackingDate from '@/models/Tracking/TrackingDate'
import { trackingEventDate, trackingEventEpochDate, transitState } from '@/models/transforms'
import TrackingAddress from './TrackingAddress'

// Shipment Tracking Docs: https://quip-amazon.com/a4o9A2kQwAa6/Shipment-Tracking-Page
export default class TrackingHistory {
  @serializable(identifier())
  containerId!: string

  @serializable(primitive())
  carrierId!: string

  @serializable(primitive())
  trackingNumber!: string

  @serializable(primitive())
  carrierDisplayName!: string

  @serializable(object(TrackingEvent))
  currentStatusEvent!: TrackingEvent

  @serializable(object(TrackingDate))
  carrierEstimatedDeliveryDate!: TrackingDate

  @serializable(trackingEventEpochDate())
  carrierEstimatedDeliveryDateAsEpoch?: Date

  @serializable(object(TrackingDate))
  currentEstimatedDeliveryDate!: TrackingDate

  @serializable(trackingEventEpochDate())
  currentEstimatedDeliveryDateAsEpoch?: Date

  @serializable(object(TrackingDate))
  originalEstimatedDeliveryDate!: TrackingDate

  @serializable(trackingEventEpochDate())
  originalEstimatedDeliveryDateAsEpoch?: Date

  @serializable(trackingEventDate())
  shipDate?: Date

  @serializable(trackingEventDate())
  promisedArrivalDate?: Date

  @serializable(object(TrackingAddress))
  shippingAddress?: TrackingAddress

  @serializable(object(TrackingAddress))
  signedForByLocation!: TrackingAddress

  @serializable(alias('transitInfo', transitState()))
  transitState?: TrackingEventState

  @serializable(list(object(TrackingEvent)))
  trackingEvents!: TrackingEvent[]

  get bestArrivalEstimateDate() {
    return (
      this.currentEstimatedDeliveryDateAsEpoch ||
      this.carrierEstimatedDeliveryDateAsEpoch ||
      this.originalEstimatedDeliveryDateAsEpoch
    )
  }

  get bestArrivalEstimateTrackingDate() {
    return [
      this.currentEstimatedDeliveryDate,
      this.carrierEstimatedDeliveryDate,
      this.originalEstimatedDeliveryDate,
    ].find(obj => Object.keys(obj).length)
  }

  dateTimeMessage?: TranslateResult
  dateMessage?: TranslateResult
  arrivalMessage?: TranslateResult
}
