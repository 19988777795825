import { render, staticRenderFns } from "./OfflineElement.vue?vue&type=template&id=983ad35e&scoped=true&"
import script from "./OfflineElement.vue?vue&type=script&lang=ts&"
export * from "./OfflineElement.vue?vue&type=script&lang=ts&"
import style0 from "./OfflineElement.vue?vue&type=style&index=0&id=983ad35e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "983ad35e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import IconNoInternet from '@/assets/icons/icon-no-internet.svg'
installComponents(component, {IconNoInternet})
