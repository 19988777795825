import PPClientModule from './PPClientModule'
import PPAuthApi from './PPAuthApi'

interface LoginParams {
  email: string
  password: string
  captchaToken: string
}

interface ClaimAccountParams {
  cohort?: string
  cardholderId?: string
  firstName?: string
  lastName?: string
  birthdate?: string
}

export default class PPAuthClient extends PPClientModule {
  login(params: LoginParams & ClaimAccountParams) {
    return this.performAnonRequest(args => {
      return PPAuthApi.login({ ...args, ...params })
    })
  }

  logout() {
    return this.performAnonRequest(args => {
      return PPAuthApi.logout(args)
    })
  }

  resetPassword(email: string) {
    return this.performAnonRequest(args => {
      return PPAuthApi.resetPassword({ ...args, email })
    })
  }

  editPassword({ token, newPassword }: { token: string; newPassword: string }) {
    return this.performAnonRequest(args => {
      return PPAuthApi.editPassword({ ...args, token, newPassword })
    })
  }
}
