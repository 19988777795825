export default class Deferred {
  promise!: Promise<void>
  resolve!: Function
  reject!: Function

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
    Object.freeze(this)
  }
}
