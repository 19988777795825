





















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class SearchInput extends Vue {
  @Prop(String)
  id!: string

  clear() {
    this.$emit('clear')
  }
}
