import { serializable, primitive, identifier, list, object } from 'serializr'

class LiteratureSection {
  @serializable(identifier())
  id!: string

  @serializable(primitive())
  question!: string

  @serializable(primitive())
  answer!: string

  @serializable(primitive())
  footer!: string
}

export default class MedicationLiterature {
  @serializable(identifier())
  id!: string

  @serializable(primitive())
  name!: string

  @serializable(list(object(LiteratureSection)))
  sections!: LiteratureSection[]
}
