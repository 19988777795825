import { serializable, primitive, alias, list, object } from 'serializr'
import { deserializeOnly } from '@/models/transforms'
import Order from '@/models/Order'

export default class GetPastOrdersResponse {
  @serializable(alias('orders', deserializeOnly(list(object(Order)))))
  orders?: Order[]

  @serializable(alias('next_page_token', deserializeOnly(primitive())))
  nextPageToken?: string

  @serializable(alias('has_next', deserializeOnly(primitive())))
  hasNext?: boolean
}
