



























import { Component, Mixins } from 'vue-property-decorator'
import ProgressMixin from '@/mixins/ProgressMixin'
import StepProgressBarNode from '@/components/step-progress/StepProgressBarNode.vue'
import StepProgressBarEdge from '@/components/step-progress/StepProgressBarEdge.vue'

@Component({
  components: {
    StepProgressBarNode,
    StepProgressBarEdge,
  },
})
export default class StepProgressBar extends Mixins(ProgressMixin) {}
