






















import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import DashboardMedication, { AutoRefillActions } from '@/models/DashboardMedication'
import Modal from '@/components/Modal.vue'

@Component({
  components: { Modal },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class PauseMedModal extends Vue {
  isInProgress!: (key: string, id: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  @Prop({ type: Object, required: true })
  med!: DashboardMedication

  get message() {
    let title: TranslateResult

    switch (this.med.autoRefillAction) {
      case AutoRefillActions.Start:
        title = this.$t('autoRefill.start.message')
        break
      case AutoRefillActions.Resume:
        title = this.$t('autoRefill.resume.message')
        break
      case AutoRefillActions.Pause:
        title = this.$t('autoRefill.pause.message')
        break
      case AutoRefillActions.None:
        title = this.$t('autoRefill.unknown.message')
        break
      default:
        throw new UnreachableCaseError(this.med.autoRefillAction)
    }
    return title
  }

  get titleText() {
    let title: TranslateResult

    switch (this.med.autoRefillAction) {
      case AutoRefillActions.Start:
        title = this.$t('autoRefill.start.header')
        break
      case AutoRefillActions.Resume:
        title = this.$t('autoRefill.resume.header')
        break
      case AutoRefillActions.Pause:
        title = this.$t('autoRefill.pause.header')
        break
      case AutoRefillActions.None:
        title = this.$t('autoRefill.unknown.message')
        break
      default:
        throw new UnreachableCaseError(this.med.autoRefillAction)
    }
    return title
  }

  get inProgress() {
    const id = this.med.id
    return (
      this.isInProgress('medications/pauseAutoRefill', id) ||
      this.isInProgress('medications/resumeAutoRefill', id)
    )
  }

  async confirm() {
    switch (this.med.autoRefillAction) {
      case AutoRefillActions.Start:
      case AutoRefillActions.Resume:
        await this.resumeMed()
        break
      case AutoRefillActions.Pause:
        await this.pauseMed()
        break
      case AutoRefillActions.None:
        await Promise.reject()
        break
      default:
        throw new UnreachableCaseError(this.med.autoRefillAction)
    }

    this.close()
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  async pauseMed() {
    await this.$store.dispatch('medications/pauseAutoRefill', this.med)
  }

  async resumeMed() {
    await this.$store.dispatch('medications/resumeAutoRefill', this.med)
  }
}
