







































import { Component, Vue, Prop } from 'vue-property-decorator'
import DashboardMedication, { Group } from '@/models/DashboardMedication'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import MedStatusLine from '@/components/medications/MedStatusLine.vue'
import ShipmentStatusLine from '@/components/medications/ShipmentStatusLine.vue'
import MedImage from '@/components/medications/MedImage.vue'
import OrderRefillButton from '@/components/medications/OrderRefillButton.vue'
import AutoRefillModal from '@/components/medications/AutoRefillModal.vue'

@Component({
  components: {
    LinkArrowIcon,
    MedStatusLine,
    ShipmentStatusLine,
    MedImage,
    OrderRefillButton,
    AutoRefillModal,
  },
})
export default class MedicationListItem extends Vue {
  @Prop({ type: DashboardMedication, required: true })
  med!: DashboardMedication

  @Prop({ type: Boolean, default: false })
  past!: DashboardMedication

  @Prop(Boolean)
  hideShipmentStatus?: boolean

  get showResumeButton() {
    const rx = this.med.primaryPrescription

    return rx && this.med.group === Group.Active && this.med.isPaused
  }

  get autoRefillModalId() {
    return `resume-autorefill-modal-${this.med.id}`
  }

  openAutoRefillModal() {
    this.$bvModal.show(this.autoRefillModalId)
  }
}
