import { serializable, alias, object, list, primitive, identifier } from 'serializr'
import { ppCoreDate, trackingIdentifier, deserializeOnly } from '@/models/transforms'
import OrderLineItem from '@/models/OrderLineItem'

export default class Shipment {
  @serializable(identifier())
  id!: string

  @serializable(alias('created_at', deserializeOnly(ppCoreDate())))
  createdAt?: Date

  @serializable(alias('delivered_at', deserializeOnly(ppCoreDate())))
  deliveredAt!: Date

  @serializable(alias('delivery_problem', deserializeOnly(primitive())))
  deliveryProblem?: boolean

  @serializable(alias('line_items', deserializeOnly(list(object(OrderLineItem)))))
  lineItems!: OrderLineItem[]

  @serializable(alias('tracking_url', primitive()))
  trackingUrl?: string

  @serializable(alias('tracking_id', trackingIdentifier()))
  trackingId?: string

  @serializable(alias('container_id', trackingIdentifier()))
  containerId?: string

  @serializable(alias('amazon_carrier', primitive()))
  amazonCarrier?: string

  get lineItemsCount() {
    return this.lineItems.length
  }

  get lineItemsPendingInsurance() {
    return this.lineItems.filter(item => item.costIsPending)
  }

  get lineItemsWithCost() {
    return this.lineItems.filter(item => !item.costIsPending)
  }

  get hasPackets(): boolean {
    return !!this.lineItems.find(
      item => item.dashboardMedication && item.dashboardMedication.inPackets,
    )
  }

  get isProjected(): boolean {
    return !this.trackingId && !this.containerId
  }
}
