/* eslint-disable no-underscore-dangle */
// get non PHI/PII/PCI context

export type VMErrorInfo = {
  isRoot: boolean
  parentVnodeTag: string | undefined
  name: string
  file: string
}

export default function errorInfoFromVm(vm: any): VMErrorInfo {
  let options: any

  if (typeof vm === 'function' && vm.cid != null) {
    options = vm.options
  } else if (vm._isVue) {
    options = vm.$options || vm.constructor.options
  } else {
    options = vm || {}
  }

  const name = options.name || options._componentTag
  const file = options.__file

  // don't even think about returning any sensitive data here
  return {
    isRoot: vm.$root === vm,
    parentVnodeTag: options._parentVnode ? options._parentVnode.tag : undefined,
    name,
    file,
  }
}
