import Vue from 'vue'
import once from 'lodash/once'

export type StripeLoaded = { ok: true; stripe: stripe.Stripe } | { ok: false; err: Error }

export const injectStripeScript = () => {
  if (!window) return
  // The existence of the stripe script tag unfortunately breaks
  // our cypress tests.  So don't include it if we're running cypress tests
  // See https://github.com/cypress-io/cypress/issues/5270
  if (window.Cypress) return

  const scriptTag = document.createElement('script')
  scriptTag.src = 'https://js.stripe.com/v3'
  scriptTag.async = true

  document.head.appendChild(scriptTag)
}

const getStripe: () => Promise<StripeLoaded> = once(
  () =>
    new Promise(resolve => {
      Vue.$pillpack.stripe
        .getKey()
        .then(key => {
          resolve({ ok: true, stripe: Stripe(key) })
        })
        .catch(err => {
          resolve({ ok: false, err })
        })
    }),
)

export default getStripe
