import { alias, serializable, primitive, list, object } from 'serializr'

export type MissingPropCode = 'MOP' | 'EMAIL' | 'SMS_CONSENT' | 'SSN'

export class MissingHubCheckoutProps {
  @serializable(primitive())
  code!: MissingPropCode

  @serializable(primitive())
  description!: String
}

export default class HubCheckout {
  @serializable(alias('is_hub_checkout', primitive()))
  isHubCheckout!: boolean

  @serializable(alias('is_complete', primitive()))
  isComplete?: boolean

  @serializable(alias('missing_hub_checkout_props', list(object(MissingHubCheckoutProps))))
  missingProps?: MissingHubCheckoutProps[]
}
