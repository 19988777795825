import once from 'lodash/once'

const apiKey = process.env.GOOGLE_PLACES_API_KEY
const googlePlacesSrc = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`

const injectGooglePlacesScript = once(
  () =>
    new Promise((resolve, reject) => {
      const googlePlaces = document.createElement('script')
      googlePlaces.setAttribute('id', 'google-places-script')
      googlePlaces.setAttribute('src', googlePlacesSrc)
      googlePlaces.setAttribute('async', 'true')
      googlePlaces.onload = () => resolve()
      googlePlaces.onerror = e => reject(e)

      document.head.appendChild(googlePlaces)
    }),
)

export const textSearch = async ({
  name,
  location,
}: {
  name: string
  location: google.maps.LatLng
}): Promise<google.maps.places.PlaceResult[]> => {
  await injectGooglePlacesScript()

  const placeholderDiv = document.createElement('div')
  const service = new window.google.maps.places.PlacesService(placeholderDiv)

  return new Promise(resolve => {
    const params = {
      query: name,
      rankBy: 'distance',
      location,
    }
    service.textSearch(params, resolve)
  })
}

export const geocode = async (zipcode: string): Promise<google.maps.GeocoderResult[]> => {
  await injectGooglePlacesScript()

  const geocoder = new window.google.maps.Geocoder()
  return new Promise(resolve => {
    geocoder.geocode({ address: zipcode }, resolve)
  })
}

export const details = async (placeId: string): Promise<google.maps.places.PlaceResult> => {
  await injectGooglePlacesScript()

  const placeholderDiv = document.createElement('div')
  const service = new window.google.maps.places.PlacesService(placeholderDiv)
  return new Promise(resolve => service.getDetails({ placeId }, resolve))
}
