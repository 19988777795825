
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import Card from '@/components/Card.vue'
import DashboardMedication from '@/models/DashboardMedication'
import MedicationListItem from '@/components/medications/MedicationListItem.vue'
import CardToggleHeader from '@/components/CardToggleHeader.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'

@Component({
  components: {
    Card,
    CardToggleHeader,
    MedicationListItem,
    SkeletonListGroup,
  },
  computed: {
    ...mapState('medications', ['showPendingMedications']),
    ...mapGetters('medications', ['sortedPendingMedications']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class PendingMedicationsCard extends Vue {
  showPendingMedications!: boolean
  sortedPendingMedications!: DashboardMedication[]
  isInProgress!: (status: string) => boolean
  getError!: (status: string) => Error

  @Prop(String)
  title?: string

  @Prop(Boolean)
  visible!: boolean

  get hasError(): boolean {
    return !!this.getError('medications/loadPending')
  }

  setShowPendingMedications(value: boolean) {
    if (this.visible) return

    this.$store.commit('medications/setShowPendingMedications', value)
  }
}
