import { serialize, deserialize } from '@/models/transforms'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPSuccessResponse, PPFailureResponse } from './PPResponse'
import { PPArgs } from './PPClient'
import Account from '@/models/Account'
import ManualPayment from '@/models/ManualPayment'
import StripeCharge from '@/models/StripeCharge'
import StripePayment from '@/models/StripePayment'
import AccountAdjustment from '@/models/AccountAdjustment'

export type Payment =
  | { type: 'StripePayment'; data: StripePayment }
  | { type: 'StripeCharge'; data: StripeCharge }
  | { type: 'AccountAdjustment'; data: AccountAdjustment }

const parsePayment = (payment: any) => {
  switch (payment.class) {
    case 'Collection::StripePayment':
      return { type: 'StripePayment', data: deserialize(StripePayment, payment) }
    case 'Collection::StripeCharge':
      return { type: 'StripeCharge', data: deserialize(StripeCharge, payment) }
    case 'AccountAdjustment':
      return { type: 'AccountAdjustment', data: deserialize(AccountAdjustment, payment) }
    default:
      return null
  }
}

export default class PPBillingApi {
  static async loadPayments({
    baseUrl,
    session,
    userId,
    locale,
  }: PPArgs): Promise<PPResponse<Payment[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/consumer_api/users/${userId}/orders/bill_payments`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      throw new PPFailureResponse(response.error)
    }

    const payments = response.data.payments.map(parsePayment).filter(Boolean)

    return new PPSuccessResponse(payments)
  }

  static async turnOnAutopay({
    baseUrl,
    session,
    userId,
    locale,
  }: PPArgs): Promise<PPResponse<boolean>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/accounts/autopay_on`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      throw new PPFailureResponse(response.error)
    }

    const autopayOn = response.data.autopay

    return new PPSuccessResponse(autopayOn)
  }

  static async makeManualOrderPayment(
    { baseUrl, session, userId, locale }: PPArgs,
    { accountId, overdueOnly }: { accountId: string; overdueOnly?: boolean },
  ): Promise<PPResponse<Account>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/accounts/${accountId}/make_manual_order_payment`,
      urlParams: {
        locale,
      },
      content: {
        overdue_only: !!overdueOnly,
      },
    })

    if (response.failure) {
      throw new PPFailureResponse(response.error)
    }

    const account = deserialize(Account, response.data.account)

    return new PPSuccessResponse(account)
  }

  static async makeManualPartialPayment(
    { baseUrl, session, userId, locale }: PPArgs,
    { accountId, payments }: { accountId: string; payments: ManualPayment[] },
  ): Promise<PPResponse<Account>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/accounts/${accountId}/make_manual_partial_payment`,
      urlParams: {
        locale,
      },
      content: {
        payments: payments.map(payment => serialize(ManualPayment, payment)),
      },
    })

    if (response.failure) {
      throw new PPFailureResponse(response.error)
    }

    const account = deserialize(Account, response.data)

    return new PPSuccessResponse(account)
  }
}
