import { Module } from 'vuex'
import Vue from 'vue'
import throttle from 'lodash/throttle'
import { RootState } from '@/store'
import { Allergy, GetAllergies } from '@/models/Allergies'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import { BannerInfo } from './banners'
import { $t } from '@/i18n'

export interface AllergiesState {
  allergies: Allergy[]
  commonAllergies: Allergy[]
  otherAllergies: string[]
  searchResults: Allergy[] | Error
  currentQuery: string
}

const module: Module<AllergiesState, RootState> = {
  namespaced: true,
  state: {
    allergies: [],
    commonAllergies: [],
    otherAllergies: [],
    searchResults: [],
    currentQuery: '',
  },
  mutations: {
    addAllergy(state, allergy: Allergy) {
      state.allergies.push(allergy)
    },
    updateAllergies(state, allergies: Allergy[]) {
      state.allergies = allergies
    },
    updateCommonAllergies(state, allergies: Allergy[]) {
      state.commonAllergies = allergies
    },
    updateOtherAllergies(state, list: string[]) {
      state.otherAllergies = list
    },
    updateCurrentQuery(state, query: string) {
      state.currentQuery = query
    },
    updateSearchResults(state, allergies: Allergy[]) {
      state.searchResults = allergies
    },
    resetSearch(state) {
      state.searchResults = []
      state.currentQuery = ''
    },
  },
  actions: {
    load: trackAsyncStatus('allergies/load', async function ({ dispatch }) {
      const allergies = await Vue.$pillpack.allergiesConditions.allergies()
      dispatch('handleDataFromServer', allergies)
    }),

    loadCommon: trackAsyncStatus('allergies/loadCommon', async function ({ commit }) {
      const allergies = await Vue.$pillpack.allergiesConditions.commonAllergies()
      commit('updateCommonAllergies', allergies)
    }),

    search: throttle(
      async ({ commit, state, dispatch }, query: string) => {
        if (!query || query.length < 3) {
          commit('resetSearch')
          return
        }
        commit('updateCurrentQuery', query)

        const conditions = await dispatch('_fetchSearchResults', query)
        if (query !== state.currentQuery) return
        commit('updateSearchResults', conditions || [])
        commit('updateCurrentQuery', '')
      },
      500,
      { trailing: true },
    ),

    // Use a second action for search to track async requests separately
    _fetchSearchResults: trackAsyncStatus(
      'allergies/search',
      async function (_, query) {
        const conditions = await Vue.$pillpack.allergiesConditions.searchAllergies(query)
        return conditions
      },
      { mergeRequests: false },
    ),

    addAllergy: trackAsyncStatus(
      'allergies/addAllergy',
      async function ({ state, commit, dispatch }, allergy: Allergy | string) {
        if (typeof allergy === 'string') {
          const newList = [...state.otherAllergies, allergy]
          commit('updateOtherAllergies', newList)
          const allergies = await Vue.$pillpack.allergiesConditions.updateOtherAllergies(newList)
          dispatch('handleDataFromServer', allergies)
        } else {
          commit('addAllergy', allergy)
          const allergies = await Vue.$pillpack.allergiesConditions.addAllergy(allergy.id)
          dispatch('handleDataFromServer', allergies)
        }
      },
    ),

    addAllergies: trackAsyncStatus(
      'allergies/addAllergy',
      async function (
        { state, dispatch },
        { allergyIds, otherAllergies }: { allergyIds: string[]; otherAllergies: string[] },
      ) {
        if (otherAllergies.length) {
          const newList = [...state.otherAllergies, ...otherAllergies]
          const allergies = await Vue.$pillpack.allergiesConditions.updateOtherAllergies(newList)
          dispatch('handleDataFromServer', allergies)
        }
        if (allergyIds.length) {
          const allergies = await Vue.$pillpack.allergiesConditions.addAllergies(allergyIds)
          dispatch('handleDataFromServer', allergies)
        }

        const banner: BannerInfo = {
          id: 'allergy-save-complete',
          bgVariant: 'success',
          title: $t('Updated!'),
          message: $t('Allergies have been added to your health profile'),
          durationMs: 3000,
        }

        dispatch('showBanner', banner, { root: true })
      },
    ),

    handleDataFromServer({ commit }, allergies: GetAllergies) {
      commit('updateAllergies', allergies.allergies)
      if (allergies.otherAllergies) {
        commit('updateOtherAllergies', allergies.otherAllergies)
      }
    },
  },
  getters: {
    allFormatted(state) {
      return state.allergies
        .map(a => a.displayName)
        .concat(state.otherAllergies)
        .join(', ')
    },
  },
}

export default module
