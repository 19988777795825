import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store'
import Dispenser from '@/models/Dispenser'
import trackAsyncStatus from '@/util/trackAsyncStatus'

export interface DispensersState {
  sellingPremium: boolean
  availableColors: Dispenser[]
  purchasedDispenser: Dispenser | null
}
const module: Module<DispensersState, RootState> = {
  namespaced: true,
  state: {
    sellingPremium: false,
    availableColors: [],
    purchasedDispenser: null,
  },
  mutations: {
    updateSellingPremium(state, val: boolean) {
      state.sellingPremium = val
    },
    updateAvailableColors(state, dispensers: Dispenser[]) {
      state.availableColors = dispensers
    },
    updatePurchasedDispenser(state, dispenser: Dispenser) {
      state.purchasedDispenser = dispenser
    },
  },
  actions: {
    load: trackAsyncStatus('dispensers/load', async function ({ commit }) {
      const dispensers = await Vue.$pillpack.dispensers.load()
      commit('updateSellingPremium', dispensers.sellingPremium)
      commit('updateAvailableColors', dispensers.availableColors)
    }),
    updatePremiumDispenser: trackAsyncStatus(
      'dispensers/updatePremiumDispenser',
      async function ({ commit }, payload) {
        const user = await Vue.$pillpack.dispensers.updatePremiumDispenser(payload)
        commit('updatePurchasedDispenser', payload)
        commit('user/updateUser', user, { root: true })
      },
    ),
  },
  getters: {},
}

export default module
