


















import { Component, Vue, Prop } from 'vue-property-decorator'

import GenericError from '@/components/GenericError.vue'

@Component({
  components: {
    GenericError,
  },
})
export default class Card extends Vue {
  @Prop(Boolean)
  hasError!: boolean

  get noBody() {
    return 'no-body' in this.$attrs
  }
}
