























































import { Component, Vue } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { TranslateResult } from 'vue-i18n'
import { validationStateMixin } from '@/util/validationState'
import LoadingButton from '@/components/LoadingButton.vue'
import Recaptcha from '@/components/Recaptcha.vue'

@Component({
  components: { LoadingButton, Recaptcha },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    email: { required, email },
    password: { required },
  },
})
export default class Login extends Vue {
  email: string = ''
  password: string = ''
  loading: boolean = false
  error?: TranslateResult | null = null
  $refs!: {
    recaptcha: Recaptcha
  }

  get showHubCheckoutMessage() {
    return !!this.$route.query.hub_checkout_transfer
  }

  get hubCheckoutName() {
    // This is a query param provided by Hub Checkout
    return this.$route.query.hub_checkout_name
  }

  validateCaptcha() {
    this.$refs?.recaptcha?.execute?.()
  }

  onSubmit() {
    this.validateCaptcha()
  }

  async logIn(captchaToken?: string): Promise<void> {
    this.loading = true
    this.error = null
    this.$v.$touch()
    if (this.$v.$invalid) {
      if (this.$v.email.$invalid) {
        this.error = this.$t('_validations.email')
      }
      this.loading = false
      return
    }
    const { email, password } = this
    try {
      const error = await this.$store.dispatch('logIn', { email, password, captchaToken })
      if (error) {
        this.error = this.$t('login.alerts.invalid')
      } else {
        this.password = ''
      }
    } catch (err) {
      Vue.$newrelic.noticeError(err)
    } finally {
      this.loading = false
      this.$refs?.recaptcha?.reset?.()
    }
  }
}
