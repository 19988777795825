import PPSession from './PPSession'
import { PPResponse, PPFailureResponse } from './PPResponse'
import { PPError, PPSessionResetError } from './PPError'
import { PPHttpError } from './PPHttp'
import eventBus from '@/util/EventBus'

import PPAddressesClient from './PPAddressesClient'
import PPBillingClient from './PPBillingClient'
import PPEventsClient from './PPEventsClient'
import PPInsuranceClient from './PPInsuranceClient'
import PPMedicationsClient from './PPMedicationsClient'
import PPOTCMedicationsClient from './PPOTCMedicationsClient'
import PPServiceAlertsClient from './PPServiceAlertsClient'
import PPShipmentsClient from './PPShipmentsClient'
import PPSupplementsClient from './PPSupplementsClient'
import PPUsersClient from './PPUsersClient'
import PPOrdersClient from './PPOrdersClient'
import PPAuthApi from './PPAuthApi'
import PPAuthClient from './PPAuthClient'
import PPAllergiesConditionsClient from './PPAllergiesConditionsClient'
import PPPaymentMethodsClient from './PPPaymentMethodsClient'
import PPPhysiciansClient from './PPPhysiciansClient'
import PPStripeClient from './PPStripeClient'
import PPClientModule from './PPClientModule'
import { Locale } from '@/i18n'
import PPPharmaciesClient from './PPPharmaciesClient'
import PPDispenserClient from './PPDispenserClient'
import PPTrackingClient from './PPTrackingClient'
import PPCloudWatchClient from './PPCloudWatchClient'
import PPTrustedDeviceClient from './PPTrustedDevicesClient'
import PPShipmentLiteratureClient from './PPShipmentLiteratureClient'

export interface PPBaseArgs {
  baseUrl: string
  session: PPSession
  locale: string
}

export interface PPArgs extends PPBaseArgs {
  userId: string
}

export class PPClientState {
  hasSession = false

  isLoggedIn = false
  isAssumedUser = false
  isAdminMasquerading = false

  constructor(client?: PPClient) {
    if (client) {
      this.hasSession = client.hasSession
      this.isLoggedIn = client.isLoggedIn
      this.isAssumedUser = client.isAssumedUser
      this.isAdminMasquerading = client.isAdminMasquerading
    }
  }
}

export default class PPClient {
  private readonly baseUrl: string
  private _session?: PPSession
  private _assumedUserId?: string
  private _locale: Locale = Locale.ENGLISH

  addresses: PPAddressesClient
  allergiesConditions: PPAllergiesConditionsClient
  billing: PPBillingClient
  dispensers: PPDispenserClient
  events: PPEventsClient
  insurances: PPInsuranceClient
  paymentMethods: PPPaymentMethodsClient
  medications: PPMedicationsClient
  orders: PPOrdersClient
  otcMedications: PPOTCMedicationsClient
  serviceAlerts: PPServiceAlertsClient
  shipments: PPShipmentsClient
  tracking: PPTrackingClient
  cloudWatch: PPCloudWatchClient
  supplements: PPSupplementsClient
  users: PPUsersClient
  auth: PPAuthClient
  stripe: PPStripeClient
  pharmacies: PPPharmaciesClient
  physicians: PPPhysiciansClient
  trustedDevice: PPTrustedDeviceClient
  shipmentLiteratureResponse: PPShipmentLiteratureClient

  private boundPerformRequest = this.performRequest.bind(this)
  private boundPerformAnonRequest = this.performAnonRequest.bind(this)
  private boundWrapApi = this.wrapApi.bind(this)
  private boundWrapAnonApi = this.wrapAnonApi.bind(this)

  constructor(apiEndpoint: string) {
    this.baseUrl = apiEndpoint

    this.addresses = this.createModule(PPAddressesClient)
    this.allergiesConditions = this.createModule(PPAllergiesConditionsClient)
    this.billing = this.createModule(PPBillingClient)
    this.dispensers = this.createModule(PPDispenserClient)
    this.events = this.createModule(PPEventsClient)
    this.insurances = this.createModule(PPInsuranceClient)
    this.paymentMethods = this.createModule(PPPaymentMethodsClient)
    this.medications = this.createModule(PPMedicationsClient)
    this.orders = this.createModule(PPOrdersClient)
    this.otcMedications = this.createModule(PPOTCMedicationsClient)
    this.serviceAlerts = this.createModule(PPServiceAlertsClient)
    this.shipments = this.createModule(PPShipmentsClient)
    this.tracking = this.createModule(PPTrackingClient)
    this.cloudWatch = this.createModule(PPCloudWatchClient)
    this.supplements = this.createModule(PPSupplementsClient)
    this.users = this.createModule(PPUsersClient)
    this.auth = this.createModule(PPAuthClient)
    this.stripe = this.createModule(PPStripeClient)
    this.pharmacies = this.createModule(PPPharmaciesClient)
    this.physicians = this.createModule(PPPhysiciansClient)
    this.trustedDevice = this.createModule(PPTrustedDeviceClient)
    this.shipmentLiteratureResponse = this.createModule(PPShipmentLiteratureClient)
  }

  private createModule<T extends PPClientModule>(PPModule: new (...args: any[]) => T) {
    return new PPModule(
      this.boundPerformRequest,
      this.boundPerformAnonRequest,
      this.boundWrapApi,
      this.boundWrapAnonApi,
    )
  }

  public get locale(): Locale {
    return this._locale
  }

  public set locale(locale: Locale) {
    this._locale = locale
    this.notifyLocaleUpdated(locale)
  }

  private get session(): PPSession | undefined {
    return this._session
  }

  private set session(nextSession: PPSession | undefined) {
    this._session = nextSession
    this.notifyStateUpdated()
  }

  private get assumedUserId(): string | undefined {
    return this._assumedUserId
  }

  private set assumedUserId(nextAssumedUsedId: string | undefined) {
    this._assumedUserId = nextAssumedUsedId
    this.notifyStateUpdated()
  }

  get hasSession(): boolean {
    return !!this.session
  }

  get isLoggedIn(): boolean {
    return !!(this.session && this.session.userId)
  }

  get authenticatedUserId(): string | undefined {
    return this.session && this.session.userId
  }

  get isAssumedUser(): boolean {
    return !!this.assumedUserId
  }

  assumeUser(userId: string) {
    this.assumedUserId = userId
  }

  clearAssumedUser() {
    this.assumedUserId = undefined
  }

  get userId(): string | undefined {
    return this.assumedUserId || this.authenticatedUserId
  }

  get isAdminMasquerading(): boolean {
    return !!(this.session && this.isLoggedIn && this.session.adminId)
  }

  private notifyLocaleUpdated(locale: Locale) {
    this.notify('PPClient.LocaleUpdated', locale)
  }

  private notifyStateUpdated() {
    this.notify('PPClient.StateUpdated', new PPClientState(this))
  }

  private notifyResponse(payload: { session: PPSession | undefined }) {
    this.notify('PPClient.ResponseReceived', payload)
  }

  private notifySessionReset() {
    this.notify('PPClient.SessionReset')
  }

  private notifyServerError(e: Error) {
    this.notify('PPClient.ServerError', e)
  }

  private notify(eventName: string, obj?: any) {
    eventBus.dispatchEvent(eventName, obj)
  }

  async startSession(): Promise<void> {
    const response = await PPAuthApi.getSession({ baseUrl: this.baseUrl })
    this.handleResponse(response)
    if (!this.session) {
      throw new PPError('Failed to get session')
    }
  }

  private wrapApi<Args, Result>(
    apiMethod: (baseArgs: PPArgs, otherArgs: Args) => Promise<PPResponse<Result>>,
  ): (args: Args) => Promise<Result> {
    return async (args: Args) => {
      return this.performRequest(async (baseArgs: PPArgs) => {
        const result = await apiMethod(baseArgs, args)
        return result
      })
    }
  }

  private wrapAnonApi<Args, Result>(
    apiMethod: (baseArgs: PPBaseArgs, otherArgs: Args) => Promise<PPResponse<Result>>,
  ): (args: Args) => Promise<Result> {
    return async (args: Args) => {
      return this.performAnonRequest(async (baseArgs: PPBaseArgs) => {
        const result = await apiMethod(baseArgs, args)
        return result
      })
    }
  }

  private async performAnonRequest<T>(
    request: (args: PPBaseArgs) => Promise<PPResponse<T>>,
  ): Promise<T> {
    try {
      const session = this.session
      if (!session) {
        throw new PPError('Can not perform request: no session')
      }

      const response = await request({
        baseUrl: this.baseUrl,
        session,
        locale: this.locale,
      })
      return this.handleResponse(response, session)
    } catch (e) {
      console.error(e) // eslint-disable-line
      return this.handleResponse(new PPFailureResponse(e))
    }
  }

  private async performRequest<T>(request: (args: PPArgs) => Promise<PPResponse<T>>): Promise<T> {
    try {
      const session = this.session
      if (!session) {
        throw new PPError('Can not perform request: no session')
      }

      const userId = this.userId
      if (!userId) {
        throw new PPError('Can not perform request: no userId')
      }

      const response = await request({
        baseUrl: this.baseUrl,
        session,
        userId,
        locale: this.locale,
      })
      return this.handleResponse(response, session)
    } catch (e) {
      // Sometimes handleResponse throws an error, like when deserializers fail.
      // We should log these, instead of silently failing, and handle them as failed responses.
      console.error(e) // eslint-disable-line
      return this.handleResponse(new PPFailureResponse(e))
    }
  }

  private handleResponse<T>(response: PPResponse<T>, originalSession?: PPSession): T {
    if (response.updatedSession) {
      this.session = response.updatedSession
    }
    this.notifyResponse({ session: this.session })

    if (response.failure) {
      const error = response.error

      if (PPHttpError.isServerError(error)) {
        this.notifyServerError(error)
      }

      if (
        PPHttpError.isUnauthorizedError(error) &&
        this.session &&
        // If the current session wasn't used to make the request, we already have
        // a new session so no point in clearing it out
        originalSession === this.session
      ) {
        this.session = undefined
        this.assumedUserId = undefined
        this.notifySessionReset()
        throw new PPSessionResetError(<PPError>response.error)
      }

      throw response.error
    }

    return response.data
  }
}
