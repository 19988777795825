import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import TrackingHistory from '@/models/Tracking/TrackingHistory'
import Shipment from '@/models/Shipment'
import processTrackingHistory from '@/util/processTrackingHistory'
import FeatureFlags from '@/util/featureFlags'

export interface TrackingState {
  trackingHistories: {
    [key: string]: TrackingHistory
  }
}

const state: TrackingState = {
  trackingHistories: {},
}

const module: Module<TrackingState, RootState> = {
  namespaced: true,
  state,

  mutations: {
    setTrackingHistory(
      state: TrackingState,
      { trackingHistory, shipment }: { trackingHistory: TrackingHistory; shipment: Shipment },
    ) {
      if (shipment && trackingHistory) {
        Vue.set(state.trackingHistories, shipment.id, trackingHistory)
      }
    },
  },

  actions: {
    load: trackAsyncStatus(
      'tracking/load',
      async function ({ commit }, shipment: Shipment) {
        const ff = await FeatureFlags.get('mock-tracking')
        const startLoadTime = performance.now()
        let trackingHistory

        if (ff?.enabled) {
          trackingHistory = await Vue.$pillpack.tracking.mockTrack(
            {
              trackingId: shipment.trackingId,
              containerId: shipment.containerId,
              amazonCarrier: shipment.trackingId,
            },
            ff.options.mock,
          )
        } else {
          trackingHistory = await Vue.$pillpack.tracking.track({
            trackingId: shipment.trackingId,
            containerId: shipment.containerId,
            amazonCarrier: shipment.amazonCarrier,
          })
        }

        const processedTrackingHistory = processTrackingHistory(trackingHistory, shipment)

        commit('setTrackingHistory', {
          trackingHistory: processedTrackingHistory,
          shipment,
        })

        const endLoadTime = performance.now()
        const timeToLoad = endLoadTime - startLoadTime
        Vue.$pillpack.cloudWatch.noteLatency('PPTrackingAPI_track', timeToLoad)

        try {
          Vue.$pillpack.cloudWatch.noteEventDescriptions(processedTrackingHistory)
        } catch (e) {
          console.warn(e) // eslint-disable-line no-console
        }
      },
      { mergeRequests: false, throwError: true },
    ),
  },

  getters: {
    trackingHistoryFor: ({ trackingHistories }) => (
      shipment: Shipment,
    ): TrackingHistory | undefined => {
      return trackingHistories[shipment.id]
    },
  },
}

export default module
