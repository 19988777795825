





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import flatten from 'lodash/flatten'
import Order from '@/models/Order'
import Card from '@/components/Card.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import OrderListItem from '@/components/OrderListItem.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'

const MINIMUM_ORDERS = 3

@Component({
  components: {
    Card,
    LinkArrowIcon,
    OrderListItem,
    SkeletonListGroup,
  },
  computed: {
    ...mapGetters('orders', ['currentOrders', 'pastOrders']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class OrdersCardCurrent extends Vue {
  @Prop({ default: false, type: Boolean })
  includePastOrders!: boolean

  @Prop({ required: true, type: String })
  heading!: string

  currentOrders!: Order[]
  pastOrders!: Order[]
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => Error

  get ordersToDisplay(): Order[] {
    if (!this.includePastOrders || this.currentOrders.length >= MINIMUM_ORDERS) {
      return this.currentOrders
    }

    return flatten([this.currentOrders, this.pastOrders]).slice(0, 3)
  }

  get hasError(): boolean {
    return !!this.getError('orders/loadCurrent')
  }

  get isLoadingOrders() {
    const loadingCurrent = !this.currentOrders.length && this.isInProgress('orders/loadCurrent')

    if (this.includePastOrders) {
      const loadingPast = !this.pastOrders.length && this.isInProgress('orders/loadPast.initial')
      return loadingCurrent && loadingPast
    }

    return loadingCurrent
  }

  get isEmpty() {
    return !this.ordersToDisplay.length
  }
}
