import type { PutMetricDataInput } from 'aws-sdk/clients/cloudwatch'
import { PPArgs } from './PPClient'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse } from './PPResponse'

export default class PPCloudWatchApi {
  static putMetricData(
    { baseUrl, session }: PPArgs,
    cloudWatchEvent: PutMetricDataInput,
  ): Promise<PPResponse<any>> {
    const path = '/consumer_api/cloud_watch'

    return PPHttp.request({
      baseUrl,
      path,
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      content: cloudWatchEvent,
      suppressErrorModal: true,
    })
  }
}
