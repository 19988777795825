

















import { Component, Mixins } from 'vue-property-decorator'
import ProgressMixin from '@/mixins/ProgressMixin'
import TourProgressBarNode from '@/components/tour-progress/TourProgressBarNode.vue'

type StepState = 'default' | 'past' | 'current' | 'future'

@Component({
  components: {
    TourProgressBarNode,
  },
})
export default class TourProgressBar extends Mixins(ProgressMixin) {}
