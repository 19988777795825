// interface for handling external redirects that we can stub

export interface LocationUtil {
  redirect: (url: string) => void
  replace: (url: string) => void
}

const locationUtil: LocationUtil = {
  redirect: (url: string) => window.location.assign(url),
  replace: (url: string) => window.location.replace(url),
}

export default locationUtil
