import Vue from 'vue'
import { MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import ProjectedShipment from '@/models/ProjectedShipment'

interface State {
  next: ProjectedShipment | null
}

const state: State = {
  next: null,
}

const mutations: MutationTree<State> = {
  handleLoadNextResponse(state, response: ProjectedShipment): void {
    state.next = response
  },
}

const actions: ActionTree<State, RootState> = {
  loadNext: trackAsyncStatus('projectedShipments/loadNext', async function ({ commit }) {
    const response = await Vue.$pillpack.shipments.projectedNextShipment()
    commit('handleLoadNextResponse', response)
  }),
}

const getters = {
  next: (state: State) => state.next,
}

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default module
