import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import Supplement from '@/models/Supplement'

interface State {
  supplements: Supplement[]
}

const state: State = {
  supplements: [],
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,

  mutations: {
    setSupplements(state, supplements: Supplement[]): void {
      state.supplements = supplements
    },
  },

  actions: {
    // supplements haven't changed in years.
    // we can safely used the last loaded list.
    load: trackAsyncStatus('supplements/load', async function ({ commit, state }) {
      if (state.supplements && state.supplements.length) {
        return
      }

      const supplements = await Vue.$pillpack.supplements.getAll()

      commit('setSupplements', supplements)
    }),
  },
}

export default module
