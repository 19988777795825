import { serializable, primitive, identifier, object, alias, list } from 'serializr'
import { deserializeOnly, optional, ppCoreDate } from '@/models/transforms'
import PrescriptionRequest from '@/models/PrescriptionRequest'
import Physician from '@/models/Physician'
import Dose from '@/models/PrescriptionDose'
import SigLine from '@/models/PrescriptionSigLine'
import MedChangeRequest from './MedChangeRequest'
import CurrentUpdatePhysicianRequest from './CurrentUpdatePhysicianRequest'

export enum RxStatus {
  Chasing = 'chasing',
  Past = 'past',
  Pending = 'pending',
  Active = 'active',
}

export type DaysSupply = 0 | 30 | 90

export default class Prescription {
  @serializable(identifier())
  id!: string

  @serializable(primitive())
  status?: RxStatus

  @serializable(alias('med_description', primitive()))
  medDescription?: string

  @serializable(alias('med_image_url', primitive()))
  medImageUrl?: string

  @serializable(alias('start_date', deserializeOnly(ppCoreDate())))
  startDate?: Date

  @serializable(alias('end_date', deserializeOnly(ppCoreDate())))
  endDate?: Date

  @serializable(alias('next_ship_date', deserializeOnly(ppCoreDate())))
  nextShipDate?: Date

  @serializable(alias('estimated_arrival_date', deserializeOnly(ppCoreDate())))
  estimatedArrivalDate?: Date

  @serializable(alias('delivery_problem', deserializeOnly(primitive())))
  deliveryProblem?: boolean

  @serializable(alias('expiration_date', deserializeOnly(ppCoreDate())))
  expirationDate?: Date

  @serializable(primitive())
  directions?: string

  @serializable(alias('twelve_hour_doses', list(object(Dose))))
  doses?: Dose[]

  @serializable(alias('sig_lines', list(object(SigLine))))
  sigLines?: SigLine[]

  @serializable(alias('remaining_refills', primitive()))
  remainingRefills?: number

  @serializable(deserializeOnly(object(Physician)))
  physician?: Physician

  @serializable(primitive())
  discontinued!: boolean

  @serializable(primitive())
  expired!: boolean

  @serializable(alias('in_packets', primitive()))
  inPackets?: boolean

  @serializable(alias('packetable', primitive()))
  packetable?: boolean

  @serializable(alias('controlled', primitive()))
  controlled?: boolean

  @serializable(alias('is_pill', primitive()))
  isPill?: boolean

  @serializable(alias('icon_name', primitive()))
  iconName?: string

  @serializable(alias('form_units', primitive()))
  form?: string

  @serializable(alias('form_units_plural', primitive()))
  formPlural?: string

  @serializable(alias('dispense_days_supply', primitive()))
  rawDispenseDaysSupply?: string

  @serializable(alias('dispense_quantity', primitive()))
  rawDispenseQuantity?: string

  @serializable(alias('rx_number', primitive()))
  rxNumber?: string

  @serializable(alias('auto_refill', primitive()))
  autoRefill?: boolean

  @serializable(alias('self_prescribed', primitive()))
  selfPrescribed?: boolean

  @serializable(primitive())
  scheduled?: boolean

  @serializable(alias('is_dispensable', primitive()))
  isDispensable?: boolean

  @serializable(alias('prescription_request', deserializeOnly(object(PrescriptionRequest))))
  prescriptionRequest!: PrescriptionRequest

  @serializable(alias('med_change_requests', deserializeOnly(list(object(MedChangeRequest)))))
  medChangeRequests!: MedChangeRequest[]

  @serializable(alias('refill_requested', deserializeOnly(primitive())))
  refillRequested!: boolean

  @serializable(alias('rush_ship_date', deserializeOnly(ppCoreDate())))
  rushShipDate?: Date

  @serializable(alias('rph_checked', primitive()))
  rphChecked!: boolean

  @serializable(
    alias(
      'update_physician_request',
      optional(deserializeOnly(object(CurrentUpdatePhysicianRequest))),
    ),
  )
  updatePhysicianRequest?: CurrentUpdatePhysicianRequest

  get dispenseDaysSupply(): DaysSupply {
    if (this.rawDispenseDaysSupply === undefined) {
      return 0
    } else if (this.inPackets) {
      return 30
    } else {
      return (Number(this.rawDispenseDaysSupply) as DaysSupply) || 0
    }
  }

  get dispenseQuantity(): string {
    if (!this.rawDispenseQuantity || !this.form) return ''
    const quantity = Number(this.rawDispenseQuantity)
    const units = quantity === 1 ? this.form : this.formPlural
    return `${quantity} ${units}`
  }

  get medicationType(): string {
    if (this.inPackets) {
      return 'packet'
    } else if (this.iconName) {
      const icon = this.iconName.replace(/\.svg$/, '')
      return icon === 'other' ? 'box' : icon
    } else {
      return 'box'
    }
  }

  // a medication that must be requested by the customer for us to ship
  get isByRequest(): boolean {
    return !this.autoRefill && !this.scheduled
  }

  get isPaused(): boolean {
    return !!this.scheduled && !this.autoRefill
  }

  get isChasing(): boolean {
    return this.status === RxStatus.Chasing
  }

  get isActive(): boolean {
    return this.status === RxStatus.Active
  }

  get isPast(): boolean {
    return this.status === RxStatus.Past
  }

  get isPending(): boolean {
    return this.status === RxStatus.Pending
  }

  get hasOpenChange(): boolean {
    return this.medChangeRequests.some(req => req.open)
  }
}
