





































































import { mapState, mapGetters } from 'vuex'
import throttle from 'lodash/throttle'
import { Component, Vue } from 'vue-property-decorator'
import HelpCenterArticle from '@/models/HelpCenterArticle'
import Card from '@/components/Card.vue'
import SearchInput from '@/components/inputs/SearchInput.vue'

const MIN_QUERY_LENGTH = 2

@Component({
  components: {
    Card,
    SearchInput,
  },
  computed: {
    ...mapState('helpCenter', ['query', 'searchResults', 'resultsCount', 'resultsQuery']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class HelpSearchCard extends Vue {
  query!: string
  resultsCount!: number
  resultsQuery!: string
  searchResults!: HelpCenterArticle[]
  isInProgress!: (key: string) => boolean

  // Dont send a request more than once every 500ms
  throttledSearch = throttle(this.search, 500, { leading: true, trailing: true })

  get isSearching() {
    return this.isInProgress('helpCenter/search')
  }

  get searchLink() {
    return `https://help.pillpack.com/hc/en-us/search?utf8=%E2%9C%93&query=${this.query}`
  }

  onInput(text: string) {
    // Set the current query value
    this.$store.commit('helpCenter/setQuery', text.trim())

    // Either clear the list or queue up a new search
    if (!text || text.length < MIN_QUERY_LENGTH) {
      this.$store.dispatch('helpCenter/clear')
    } else {
      this.throttledSearch()
    }
  }

  clear() {
    this.$store.commit('helpCenter/setQuery', '')
    this.$store.dispatch('helpCenter/clear')
  }

  async search() {
    if (this.query) {
      this.$store.dispatch('helpCenter/runSearch')
    }
  }

  beforeDestroy() {
    this.clear()
  }
}
