










import { Component, Vue } from 'vue-property-decorator'
import SkeletonLine from '@/components/SkeletonLine.vue'
import SkeletonCircle from '@/components/SkeletonCircle.vue'

@Component({
  components: {
    SkeletonLine,
    SkeletonCircle,
  },
})
export default class SkeletonListGroupItem extends Vue {}
