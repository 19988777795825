import PPClientModule from './PPClientModule'
import PPOrdersApi from './PPOrdersApi'

export default class PPOrdersClient extends PPClientModule {
  current = this.wrapApi(PPOrdersApi.current)

  past = this.wrapApi(PPOrdersApi.past)

  getOrder = this.wrapApi(PPOrdersApi.getOrder)

  preferences = this.wrapApi(PPOrdersApi.preferences)
}
