import { serializable, primitive, alias } from 'serializr'

enum UserIdentificationType {
  SocialSecurityNumber = '07',
  DriversLicense = '06',
}

export default class UserIdentification {
  @serializable
  id?: string

  @serializable
  jurisdiction?: string

  @serializable
  type?: UserIdentificationType

  @serializable(alias('no_ssn', primitive()))
  noSsn?: boolean = false

  @serializable(alias('no_license', primitive()))
  noLicense?: boolean = false

  static socialSecurityNumber(ssn: string) {
    const userId = new UserIdentification()
    userId.id = ssn
    userId.jurisdiction = 'US'
    userId.type = UserIdentificationType.SocialSecurityNumber
    return userId
  }

  static driversLicense(licenseNumber: string, state: string) {
    const userId = new UserIdentification()
    userId.id = licenseNumber
    userId.jurisdiction = state
    userId.type = UserIdentificationType.DriversLicense
    // implies the user has indicated they have no SSN
    userId.noSsn = true
    return userId
  }

  static noSsnOrDriversLicense() {
    const userId = new UserIdentification()
    // implies the user has indicated they have no SSN and no driver license
    userId.noSsn = true
    userId.noLicense = true
    return userId
  }
}
