import { Module } from 'vuex'
import { RootState } from '@/store'

export type ScrollDirection = 'up' | 'down'
export interface LayoutState {
  menuHeight: number
  isMenuFixed: boolean
  scrollDir: ScrollDirection | null
}

const module: Module<LayoutState, RootState> = {
  namespaced: true,
  state: {
    menuHeight: 0,
    isMenuFixed: false,
    scrollDir: null,
  },
  mutations: {
    setMenuHeight: (state, height) => {
      state.menuHeight = height
    },
    setMenuFixed: (state, atTop: boolean) => {
      state.isMenuFixed = atTop
    },
    setScrollDir: (state, dir: ScrollDirection) => {
      state.scrollDir = dir
    },
  },
}

export default module
