




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { BannerAction, BannerInfo } from '@/vuex/banners'
import { trackEvent } from '@/plugins/GoogleAnalyticsPlugin'

@Component
export default class Banner extends Vue {
  @Prop({ type: Object, required: true }) banner!: BannerInfo
  timerStyle: any = null
  timerId: number | null = null

  get bgVariant() {
    return this.banner.bgVariant || 'primary'
  }

  get textVariant() {
    return this.banner.textVariant || 'white'
  }

  get alertClasses() {
    return [`text-${this.textVariant}`, `bg-${this.bgVariant}`]
  }

  dismiss() {
    this.clearTimer()
    if (this.banner.onDismissed && typeof this.banner.onDismissed === 'function') {
      this.banner.onDismissed()
    }
    this.$store.dispatch('dismissBanner', { id: this.banner.id })
  }

  clearTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId)
      this.timerId = null
    }
  }

  actionAttributes(action: BannerAction) {
    const attrs: any = {
      size: 'sm',
      variant: action.variant || this.textVariant,
    }

    if (action.to) {
      attrs.to = action.to
    } else if (action.href) {
      attrs.href = action.href
      attrs.target = '_blank'
      attrs.rel = 'noreferrer'
    }
    return attrs
  }

  onAction(event: Event, action: BannerAction) {
    if (action.handler) {
      trackEvent({
        eventCategory: 'cwa_banner_action',
        eventAction: action.label as string,
        eventLabel: this.banner.id || 'empty',
      })
      action.handler(this, event)
    }
  }

  @Watch('banner', { immediate: true })
  startBannerTimer(banner: BannerInfo) {
    if (!banner) {
      return
    }

    // Create a timer for auto-dismissed banners
    const { durationMs } = banner
    if (durationMs) {
      this.timerStyle = {}
      setTimeout(() => {
        // Initiate an animation
        this.timerStyle = {
          transform: 'scaleX(1)',
          transition: `transform ${durationMs}ms ease-in-out`,
        }
        // Dismiss after duration expires
        this.timerId = window.setTimeout(() => this.dismiss(), durationMs)
      }, 250)
    } else {
      // Make the timer full width for permanent banners
      this.timerStyle = {
        transform: 'scaleX(0)',
      }
    }
  }

  beforeDestroy() {
    this.clearTimer()
  }
}
