import { PluginObject } from 'vue'
import honeybadger from 'honeybadger-js'
import errorInfoFromVm from '@/util/errorInfoFromVm'

declare global {
  interface Window {
    honeybadger: typeof honeybadger
  }
}

const plugin: PluginObject<void> = {
  install(Vue: any) {
    window.honeybadger = honeybadger

    window.honeybadger.beforeNotify(notice => {
      // clobber the context object to prevent sending any sensitive data
      // https://docs.honeybadger.io/lib/javascript/guides/filtering-sensitive-data.html
      if (notice && notice.context) {
        notice.context = { vm: notice.context.vm }
      }
    })

    window.honeybadger.configure({
      apiKey: process.env.HONEYBADGER_API_KEY as string,
      environment: 'production',
      revision: process.env.GIT_COMMIT,
      disabled: !process.env.ENABLE_HB,
    })

    Vue.prototype.$honeybadger = window.honeybadger
    Vue.$honeybadger = window.honeybadger

    // hook into Vue's error handling
    const chainedErrorHandler = Vue.config.errorHandler

    Vue.config.errorHandler = (error: Error, vm: any, info: any) => {
      window.honeybadger.notify(error, { context: { vm: errorInfoFromVm(vm) } })

      if (typeof chainedErrorHandler === 'function') {
        chainedErrorHandler.call(Vue, error, vm, info)
      } else {
        throw error
      }
    }
  },
}

export default plugin
