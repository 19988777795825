import PPClientModule from './PPClientModule'
import PPServiceAlertsApi from './PPServiceAlertsApi'

export default class PPServiceAlertsClient extends PPClientModule {
  get() {
    return this.performRequest(args => {
      return PPServiceAlertsApi.get(args)
    })
  }

  acknowledge(alertId: string) {
    return this.performRequest(args => {
      return PPServiceAlertsApi.acknowledge({ ...args, alertId })
    })
  }

  resolve(alertId: string) {
    return this.performRequest(args => {
      return PPServiceAlertsApi.resolve({ ...args, alertId })
    })
  }

  viewMany(alertIds: string[]) {
    return this.performRequest(args => {
      return PPServiceAlertsApi.viewMany({ ...args, alertIds })
    })
  }
}
