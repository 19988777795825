import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import Address from '@/models/Address'
import PPAddressError, { PPAddressErrorType } from '@/ppapi/PPAddressError'
import ZipcodeLookup from '@/models/ZipcodeLookup'

interface AddressesState {
  addressList: Address[]
  editor: {
    show: boolean // is address editor visible
    address: Address | null // the address to show in the editor (the editor will make and edit it's own copy)
    confirmedAddress: Address | null // the address the user selected on the confirmation screen
  }
  zipcodeLookup: { [s: string]: ZipcodeLookup }
}

const state: AddressesState = {
  addressList: [],
  editor: {
    show: false,
    address: null,
    confirmedAddress: null,
  },
  zipcodeLookup: {},
}

const module: Module<AddressesState, RootState> = {
  namespaced: true,
  state,

  mutations: {
    resetState(state) {
      state.addressList = []
      state.editor.show = false
      state.editor.address = null
      state.editor.confirmedAddress = null
      state.zipcodeLookup = {}
    },

    setAddressList(state, addressList: Address[]) {
      state.addressList = addressList
    },

    addOrUpdateAddress(state, address: Address) {
      const index = state.addressList.findIndex(element => element.id === address.id)
      if (index >= 0) {
        state.addressList.splice(index, 1, address)
      } else {
        state.addressList.push(address)
      }
    },

    deleteAddress(state, address: Address) {
      const index = state.addressList.findIndex(element => element.id === address.id)
      if (index >= 0) {
        state.addressList.splice(index, 1)
      }
    },

    showEditor(state, show: boolean) {
      state.editor.show = show
    },

    setEditorAddress(state, address: Address) {
      // make a defensive copy for ourself
      state.editor.address = address ? address.clone() : null
    },

    setEditorConfirmedAddress(state, address: Address) {
      state.editor.confirmedAddress = address
    },

    addZipcodeLookup(state, { zipcode, info }: { zipcode: string; info: ZipcodeLookup }) {
      Vue.set(state.zipcodeLookup, zipcode, info)
    },
  },

  actions: {
    get: trackAsyncStatus('addresses/get', async function ({ commit }) {
      const addressList = await Vue.$pillpack.addresses.get()
      commit('setAddressList', addressList)
    }),

    addOrUpdate: trackAsyncStatus(
      'addresses/addOrUpdate',
      async function ({ commit }, { address, force }) {
        const updatedAddress = await Vue.$pillpack.addresses.addOrUpdate({
          address,
          options: { force },
        })
        commit('addOrUpdateAddress', updatedAddress)
      },
    ),

    delete: trackAsyncStatus('addresses/delete', async function ({ commit }, { address }) {
      await Vue.$pillpack.addresses.delete(address)
      commit('deleteAddress', address)
    }),

    setCurrentShippingAddress: trackAsyncStatus(
      'addresses/setCurrentShippingAddress',
      async function ({ dispatch }, { address }) {
        await Vue.$pillpack.addresses.setCurrentShippingAddress(address.id)
        // we now refetch all addresses since two of them have changed
        // but setCurrentShippingAddress currently returns void
        await dispatch('get')
      },
    ),

    async fetchZipcodeLookup({ commit }, zipcode: string) {
      const info = await Vue.$pillpack.addresses.zipcodeLookup(zipcode)
      commit('addZipcodeLookup', { zipcode, info })
    },

    beginEditNewAddress({ dispatch }) {
      dispatch('beginEditAddress', new Address())
    },

    beginEditAddress({ commit }, address: Address) {
      commit('setEditorAddress', address)
      commit('setEditorConfirmedAddress', null)
      commit('asyncStatus/reset', { key: 'addresses/addOrUpdate' }, { root: true })
      commit('showEditor', true)
    },

    confirmAddress({ commit, state }, address: Address) {
      if (state && state.editor && state.editor.address) {
        // the suggested address from usps won't include the id
        // we will copy the id from original address to ensure that when we submit we are
        // updating that address rather than creating a new address
        address.id = state.editor.address.id
      }
      commit('setEditorAddress', address)
      commit('setEditorConfirmedAddress', address)
    },
  },

  getters: {
    isEditing: state => !!(state.editor.address && state.editor.address.id),
    suggestedAddress: (state, getters, rootState, rootGetters) => {
      const error = rootGetters['asyncStatus/getError']('addresses/addOrUpdate')
      if (error && error instanceof PPAddressError) {
        return error.suggestedAddress
      } else {
        return null
      }
    },
    isAddressConfirmable: (state, getters) => {
      switch (getters.addressErrorType) {
        case PPAddressErrorType.stateRequiresId:
          return false
        case PPAddressErrorType.addressSuggested:
        case PPAddressErrorType.needsStreet2:
        case PPAddressErrorType.poBox:
          return true
        case PPAddressErrorType.notFound:
        case PPAddressErrorType.invalid:
        case PPAddressErrorType.other:
        default:
          // in these cases only allow the user to force save if we the zip code they entered
          // is in the state they entered.
          return !!(
            state.editor &&
            state.editor.address &&
            state.editor.address.state &&
            state.editor.address.zipcode &&
            state.zipcodeLookup[state.editor.address.zipcode] &&
            state.zipcodeLookup[state.editor.address.zipcode].state &&
            state.editor.address.state === state.zipcodeLookup[state.editor.address.zipcode].state
          )
      }
    },
    addressErrorType: (state, getters, rootState, rootGetters) => {
      const error = rootGetters['asyncStatus/getError']('addresses/addOrUpdate')
      if (error && error instanceof PPAddressError) {
        return error.type
      } else {
        return undefined
      }
    },
    currentShippingAddress: state =>
      state.addressList.find(address => address.isCurrentShipping) || null,
  },
}

export default module
