












import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SessionErrorElement extends Vue {
  reload() {
    window.location.reload()
  }
}
