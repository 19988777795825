import PPClientModule from './PPClientModule'
import PPAllergiesConditionsApi from './PPAllergiesConditionsApi'

export default class PPUsersClient extends PPClientModule {
  allergies() {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.getUserAllergies(args)
    })
  }

  conditions() {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.getUserConditions(args)
    })
  }

  commonAllergies() {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.getCommonAllergies(args)
    })
  }

  commonConditions() {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.getCommonConditions(args)
    })
  }

  searchAllergies(query: string) {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.searchAllergies({ ...args, query })
    })
  }

  searchConditions(query: string) {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.searchConditions({ ...args, query })
    })
  }

  addAllergy(allergyId: string) {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.addAllergy({ ...args, allergyId })
    })
  }

  addAllergies(allergyIds: string[]) {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.addAllergies({ ...args, allergyIds })
    })
  }

  addCondition(conditionId: string) {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.addCondition({ ...args, conditionId })
    })
  }

  addConditions(conditionIds: string[]) {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.addConditions({ ...args, conditionIds })
    })
  }

  updateOtherAllergies(data: string[]) {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.updateOtherAllergies({ ...args, data })
    })
  }

  updateOtherConditions(data: string[]) {
    return this.performRequest(args => {
      return PPAllergiesConditionsApi.updateOtherConditions({ ...args, data })
    })
  }
}
