





























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { distanceInWordsToNow } from 'date-fns'
import { mapGetters } from 'vuex'

import AlertProperties from '@/util/AlertProperties'
import ServiceAlert from '@/models/ServiceAlert'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import AlertIcon from '@/components/AlertIcon.vue'

@Component({
  components: {
    LinkArrowIcon,
    AlertIcon,
  },
  filters: {
    ago: (date: Date) => distanceInWordsToNow(date, { addSuffix: true }),
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class ServiceAlertListItem extends Vue {
  isInProgress!: (key: string, id?: string) => boolean

  @Prop({ type: ServiceAlert, required: true })
  alert!: ServiceAlert

  get testId() {
    return `service-alert-${this.alert.id}`
  }

  get alertProps() {
    return new AlertProperties(this.alert)
  }

  get fullSizeLinkAttrs(): object {
    const { fullSizeLink, linkAttrs } = this.alertProps
    return fullSizeLink && linkAttrs ? linkAttrs : {}
  }

  get unresolved() {
    return !this.alert.resolved
  }

  get isUpdating() {
    return (
      this.isInProgress('alerts/acknowledge', this.alert.id) ||
      this.isInProgress('alerts/resolve', this.alert.id)
    )
  }

  async acknowledge() {
    if (!this.alertProps.canAcknowledge) return
    await this.$store.dispatch('alerts/acknowledge', this.alert)
  }

  async resolve() {
    if (!this.alertProps.canResolve) return
    await this.$store.dispatch('alerts/resolve', this.alert)
  }
}
