/**
 * EventBus
 *
 * A simple global event bus for PubSub outside the context of the Vuex store
 * We want to do things on events from stateless modules like PPAPI and PPHttp
 */
class EventBus {
  private bus: Element

  constructor() {
    this.bus = document.createElement('pp-custom-event-el')
  }

  /**
   * Publish a custom event
   *
   * @example - when fetch requests fail from PPHttp
   * eventBus.dispatchEvent('fetchFailed', { detail: error })
   */
  dispatchEvent(event: string, detail?: Object): void {
    this.bus.dispatchEvent(new CustomEvent(event, { detail }))
  }

  /**
   * Subscribe to events
   *
   * @example - when fetch fails, update the store
   * eventBus.addEventListener('PPClient.NetworkError', () => {
   *   store.commit('setOffline')
   * })
   */
  addEventListener(event: string, callback: EventListener): void {
    this.bus.addEventListener(event, callback)
  }

  removeEventListener(event: string, callback: EventListener): void {
    this.bus.removeEventListener(event, callback)
  }
}

export default new EventBus()
