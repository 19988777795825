import { getLocalStorage, setLocalStorage } from '@/util/localStorage'
import eventBus from '@/util/EventBus.ts'

export enum PlatformType {
  ios = 'ios',
  android = 'android',
}

class Platform {
  private readonly modalDelay = 5000

  private get pageViews(): number {
    return Number(getLocalStorage('app-visits', 0))
  }

  private get appSessions(): number {
    return Number(getLocalStorage('app-sessions', 0))
  }

  private get hasPrompted(): boolean {
    return !!getLocalStorage('pwa-prompt-viewed')
  }

  private checkLocalStorage(): boolean {
    return !this.hasPrompted && this.pageViews > 1
  }

  private async sleep(duration: number) {
    return new Promise(resolve => setTimeout(resolve, duration))
  }

  get isFirstVisit(): boolean {
    return this.appSessions === 1
  }

  // user is already running PWA
  get isPWA(): boolean {
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as Navigator).standalone ||
      document.referrer.includes('android-app://')
    )
  }

  // mobile OS
  get platform(): PlatformType | null {
    if (/iP(hone|ad|od)/gi.test(navigator.userAgent)) {
      return PlatformType.ios
    } else if (/Android/gi.test(navigator.userAgent)) {
      return PlatformType.android
    } else {
      return null
    }
  }

  incrementPageViews() {
    const count = this.pageViews
    setLocalStorage('app-visits', count + 1)
  }

  incrementAppSessions() {
    const count = this.appSessions
    setLocalStorage('app-sessions', count + 1)
  }

  // only load the async PWA components if the user is on mobile and is not already in the PWA
  shouldLoadInstallPrompts(): boolean {
    return !!this.platform && !this.isPWA
  }

  // should we auto prompt the user?
  shouldPromptInstallModal(): boolean {
    if (!this.shouldLoadInstallPrompts()) return false
    if (!this.checkLocalStorage()) return false
    // for now we are only auto-prompting ios
    return this.platform === PlatformType.ios
  }

  afterPrompt(): void {
    setLocalStorage('pwa-prompt-viewed', true)
  }

  canUseWebAuth(): Promise<boolean> {
    try {
      return PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().catch(() => false)
    } catch {
      return Promise.resolve(false)
    }
  }

  async maybePrompt(): Promise<void> {
    if (!this.shouldPromptInstallModal()) return
    await this.sleep(this.modalDelay)
    eventBus.dispatchEvent('PWA.showModal')
  }
}

const platform = new Platform()

export default platform
