import { render, staticRenderFns } from "./ConfirmEmail.vue?vue&type=template&id=2287afa6&scoped=true&"
import script from "./ConfirmEmail.vue?vue&type=script&lang=ts&"
export * from "./ConfirmEmail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2287afa6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButtonClose } from 'bootstrap-vue'
import { BCardHeader } from 'bootstrap-vue'
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
import { BSpinner } from 'bootstrap-vue'
import IconCheckGreen from '@/assets/icons/icon-check-green.svg'
import IconClose from '@/assets/icons/icon-close.svg'
import IconExclamation from '@/assets/icons/icon-exclamation.svg'
installComponents(component, {BButtonClose,BCardHeader,BListGroup,BListGroupItem,BMedia,BSpinner,IconCheckGreen,IconClose,IconExclamation})
