import { serializable, primitive, alias } from 'serializr'
import RxCatalogConcept from './RxCatalogConcept'

export default class PrescriptionStrength {
  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('form', primitive()))
  form?: string

  @serializable(alias('strength', primitive()))
  strength?: string

  @serializable(alias('rxcui', primitive()))
  rxcui?: string

  get displayString() {
    if (!this.strength) return null
    return `${this.strength} (${this.form})`
  }

  static fromRxConcept(concept: RxCatalogConcept): PrescriptionStrength {
    const ps = new PrescriptionStrength()
    ps.name = concept.drug.description
    ps.rxcui = concept.drug.rxcui
    ps.strength = concept.strength
    ps.form = concept.formulation?.name
    return ps
  }
}
