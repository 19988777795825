
















import { Component, Vue, Prop } from 'vue-property-decorator'

import PageTitle from '@/components/PageTitle.vue'
import Login from '@/components/Login.vue'

@Component({
  components: { Login, PageTitle },
})
export default class LoginView extends Vue {
  @Prop()
  redirectReason!: string

  get sessionTimedOut() {
    return this.redirectReason === 'sessionTimeout'
  }

  get apiHost() {
    return process.env.API_HOST
  }
}
