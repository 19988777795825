import PPSession from './PPSession'
import { PPHttp, PPHttpMethod, PPHttpContentType } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize, deserializeArray, serializeInstance } from '@/models/transforms'
import { GetAllergies, Allergy, UpdateOtherAllergies } from '@/models/Allergies'
import { GetConditions, Condition, UpdateOtherConditions } from '@/models/Conditions'

export default class PPAllergiesConditionsApi {
  static async getUserAllergies({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<GetAllergies>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/allergies`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const allergies = deserialize(GetAllergies, response.data)
    return new PPSuccessResponse(allergies)
  }

  static async getUserConditions({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<GetConditions>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/conditions`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const conditions = deserialize(GetConditions, response.data)
    return new PPSuccessResponse(conditions)
  }

  static async getCommonAllergies({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<Allergy[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/allergies/display_on_signup`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const allergies = deserializeArray(Allergy, response.data)
    return new PPSuccessResponse(allergies)
  }

  static async getCommonConditions({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<Condition[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/conditions/display_on_signup`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const conditions = deserializeArray(Condition, response.data)
    return new PPSuccessResponse(conditions)
  }

  static async searchAllergies({
    baseUrl,
    session,
    userId,
    locale,
    query,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    query: string
  }): Promise<PPResponse<Allergy[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/allergies/search`,
      urlParams: { q: query, locale, limit: 10 },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const allergies = deserializeArray(Allergy, response.data.allergies)
    return new PPSuccessResponse(allergies)
  }

  static async searchConditions({
    baseUrl,
    session,
    userId,
    locale,
    query,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    query: string
  }): Promise<PPResponse<Condition[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/conditions/search`,
      urlParams: { q: query, locale, limit: 10 },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const conditions = deserializeArray(Condition, response.data.conditions)
    return new PPSuccessResponse(conditions)
  }

  static async addAllergy({
    baseUrl,
    session,
    userId,
    locale,
    allergyId,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    allergyId: string
  }): Promise<PPResponse<GetAllergies>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/allergies`,
      urlParams: { locale, return_index: true },
      content: JSON.stringify({ id: allergyId }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const allergies = deserialize(GetAllergies, response.data)
    return new PPSuccessResponse(allergies)
  }

  static async addAllergies({
    baseUrl,
    session,
    userId,
    locale,
    allergyIds,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    allergyIds: string[]
  }): Promise<PPResponse<GetAllergies>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/allergies`,
      urlParams: { locale, return_index: true },
      content: JSON.stringify({ ids: allergyIds }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const allergies = deserialize(GetAllergies, response.data)
    return new PPSuccessResponse(allergies)
  }

  static async updateOtherAllergies({
    baseUrl,
    session,
    userId,
    locale,
    data,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    data: string[]
  }): Promise<PPResponse<GetAllergies>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.PUT,
      baseUrl,
      path: `/api/users/${userId}/allergies/update_other_allergies`,
      urlParams: { locale, return_index: true },
      content: JSON.stringify(serializeInstance(new UpdateOtherAllergies(data))),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const allergies = deserialize(GetAllergies, response.data)
    return new PPSuccessResponse(allergies)
  }

  static async addCondition({
    baseUrl,
    session,
    userId,
    locale,
    conditionId,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    conditionId: string
  }): Promise<PPResponse<GetConditions>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/conditions`,
      urlParams: { locale, return_index: true },
      content: JSON.stringify({ id: conditionId }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const conditions = deserialize(GetConditions, response.data)
    return new PPSuccessResponse(conditions)
  }

  static async addConditions({
    baseUrl,
    session,
    userId,
    locale,
    conditionIds,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    conditionIds: string[]
  }): Promise<PPResponse<GetConditions>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/conditions`,
      urlParams: { locale, return_index: true },
      content: JSON.stringify({ ids: conditionIds }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const conditions = deserialize(GetConditions, response.data)
    return new PPSuccessResponse(conditions)
  }

  static async updateOtherConditions({
    baseUrl,
    session,
    userId,
    locale,
    data,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    data: string[]
  }): Promise<PPResponse<GetConditions>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.PUT,
      baseUrl,
      path: `/api/users/${userId}/conditions/update_other_conditions`,
      urlParams: { locale, return_index: true },
      content: JSON.stringify(serializeInstance(new UpdateOtherConditions(data))),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const conditions = deserialize(GetConditions, response.data)
    return new PPSuccessResponse(conditions)
  }
}
