import { Component, Vue, Prop } from 'vue-property-decorator'

type StepState = 'default' | 'past' | 'error' | 'current' | 'future'

@Component
export default class ProgressMixin extends Vue {
  /**
   * The index of the current step (0-indexed)
   */
  @Prop({ type: Number, required: true })
  currentStep!: number

  @Prop(Boolean)
  hasError!: boolean

  /**
   * This is an array of any arbitrary data.  The length of the array will determine the
   * number of steps.  At each step, the element at that position in the array will be
   * passed into all slots so that the consumer of this component can render any custom content
   * they want.
   */
  @Prop()
  steps!: unknown[]

  getState(i: number): StepState {
    if (this.isPastStep(i)) return 'past'
    if (this.isErrorStep(i)) return 'error'
    if (this.isCurrentStep(i)) return 'current'
    if (this.isFutureStep(i)) return 'future'
    return 'default'
  }

  isCurrentStep(i: number) {
    return i === this.currentStep
  }

  isErrorStep(i: number) {
    return this.isCurrentStep(i) && this.hasError
  }

  isPastStep(i: number) {
    return i < this.currentStep
  }

  isFutureStep(i: number) {
    return i > this.currentStep
  }

  isLastStep(i: number) {
    return i === this.steps.length - 1
  }

  screenReaderLabelPrefix(i: number) {
    const total = this.steps.length
    const stepNumber = i + 1
    return this.$t('Step {i} of {total}:', { i: stepNumber, total })
  }

  screenReaderLabelSuffix(i: number) {
    if (this.getState(i) === 'current') {
      return this.$t('This is the current step')
    }
    return ''
  }
}
