
































import { Component, Vue, Prop } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})
export default class RadioGroup extends Vue {
  @Prop({ type: Array, required: true })
  options!: object[]

  @Prop({ type: [String, Number, Boolean] })
  value!: string | number | null
}
