import Vue from 'vue'
import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { closestTo, isAfter } from 'date-fns'
import { TranslateResult } from 'vue-i18n'
import Order from '@/models/Order'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import i18n from '@/i18n'
import featureFlags from '@/util/featureFlags'
import GetPastOrdersResponse from '@/models/GetPastOrdersResponse'
import GetCurrentOrdersResponse from '@/models/GetCurrentOrdersResponse'
import OrderPreferences from '@/models/OrderPreferences'
import Shipment from '@/models/Shipment'
import { UserState } from '@/vuex/user'
import { Payment } from '@/ppapi/PPBillingApi'
import TrackingHistory from '@/models/Tracking/TrackingHistory'
import ShipmentLiteratureResponse from '@/models/ShipmentLiteratureResponse'

export interface State {
  preferences: OrderPreferences | null
  projectedNextArrivalDate?: Date
  ordersById: { [id: string]: Order }
  pastOrders: {
    ids: string[]
    nextPageToken: string | null
    hasNext: boolean
  }
  currentOrders: {
    ids: string[]
  }
  billPayments: {
    data: Payment[]
    numberToShow: number
  }
  shipmentLiteratures: { [id: string]: ShipmentLiteratureResponse }
}

const state: State = {
  preferences: null,
  projectedNextArrivalDate: undefined,
  ordersById: {},
  pastOrders: {
    ids: [],
    nextPageToken: null,
    hasNext: true,
  },
  currentOrders: {
    ids: [],
  },
  billPayments: {
    data: [],
    numberToShow: 3,
  },
  shipmentLiteratures: {},
}

const mutations: MutationTree<State> = {
  handleLoadPastResponse(state, response: GetPastOrdersResponse): void {
    const { orders = [], nextPageToken } = response

    orders.forEach(order => {
      Vue.set(state.ordersById, order.id, order)
    })

    const orderIds = orders.map(order => order.id)
    state.pastOrders.ids.push(...orderIds)

    state.pastOrders.hasNext = !!nextPageToken
    state.pastOrders.nextPageToken = nextPageToken || null
  },
  handleLoadCurrentResponse(state, response: GetCurrentOrdersResponse): void {
    const { orders = [], projectedNextArrivalDate } = response

    orders.forEach(order => {
      Vue.set(state.ordersById, order.id, order)
    })

    const orderIds = orders.map(order => order.id)
    state.currentOrders.ids = orderIds

    state.projectedNextArrivalDate = projectedNextArrivalDate
  },
  handleLoadOrderResponse(state, order: Order): void {
    Vue.set(state.ordersById, order.id, order)
  },
  handleLoadShipmentLiteratureResponse(state, { id, literatureResponse }): void {
    Vue.set(state.shipmentLiteratures, id, literatureResponse)
  },
  setPreferences(state, preferences: OrderPreferences) {
    state.preferences = preferences
  },
  showMoreBillPayments(state) {
    state.billPayments.numberToShow += 5
  },
  setBillPayments(state, payments: Payment[]) {
    state.billPayments.data = payments
  },
}

const actions: ActionTree<State, RootState> = {
  'loadPast.initial': trackAsyncStatus(
    'orders/loadPast.initial',
    async function ({ state, commit, dispatch }) {
      const { ids, hasNext } = state.pastOrders
      if (!hasNext || ids.length) return
      const response = await Vue.$pillpack.orders.past(undefined)
      await dispatch('safeLoadTracking', response.orders)
      commit('handleLoadPastResponse', response)
    },
  ),
  'loadPast.more': trackAsyncStatus(
    'orders/loadPast.more',
    async function ({ state, commit, dispatch }) {
      const { nextPageToken } = state.pastOrders
      if (!nextPageToken) return

      const response = await Vue.$pillpack.orders.past(nextPageToken)
      await dispatch('safeLoadTracking', response.orders)
      commit('handleLoadPastResponse', response)
    },
  ),
  loadCurrent: trackAsyncStatus('orders/loadCurrent', async function ({ commit, dispatch }) {
    const response = await Vue.$pillpack.orders.current()
    await dispatch('safeLoadTracking', response.orders)
    commit('handleLoadCurrentResponse', response)
  }),
  loadOne: trackAsyncStatus('orders/loadOne', async function ({ commit, dispatch }, { id }) {
    const order = await Vue.$pillpack.orders.getOrder(id)
    await dispatch('safeLoadTracking', [order])
    commit('handleLoadOrderResponse', order)
  }),
  async safeLoadTracking({ dispatch }, orders: Order[]) {
    orders?.forEach(async order => {
      try {
        await dispatch('loadTracking', order.shipments)
        order.showFallbackTracking = false
      } catch (error) {
        order.showFallbackTracking = true
      }
      dispatch('noteShipmentProperties', order.shipments)
    })
  },
  async loadTracking({ dispatch, rootState }, shipments: Shipment[]) {
    if (await featureFlags.enabled('enable-tracking')) {
      await Promise.all(
        shipments.map(async shipment => {
          if (shipment.isProjected) {
            return Promise.reject(Error('Projected shipment'))
          }
          if (!shipment.amazonCarrier) {
            return Promise.reject(Error('Not a CoreTrans shipment'))
          }
          if (!(rootState as any).tracking.trackingHistories[shipment.id]) {
            return dispatch('tracking/load', shipment, { root: true })
          }
          return Promise.resolve()
        }),
      )
    }
  },
  loadPreferences: trackAsyncStatus('orders/loadPreferences', async function ({ commit }) {
    const preferences = await Vue.$pillpack.orders.preferences()

    commit('setPreferences', preferences)
  }),
  loadShipmentLiterature: trackAsyncStatus(
    'orders/loadShipmentLiterature',
    async function ({ commit }, { id }: { id: string }) {
      const literatureResponse = await Vue.$pillpack.shipmentLiteratureResponse.getShipmentLiterature(
        {
          shipmentId: id,
        },
      )
      commit('handleLoadShipmentLiteratureResponse', { id, literatureResponse })
    },
  ),
  loadBillPayments: trackAsyncStatus(
    'orders/loadBillPayments',
    async function ({ commit, dispatch, rootState }) {
      await dispatch('user/ensureMe', {}, { root: true })
      const userState: UserState = (rootState as any).user
      if (userState.me.isOnOrdersBilling) return

      const payments = await Vue.$pillpack.billing.loadPayments()

      commit('setBillPayments', payments)
    },
  ),
  noteShipmentProperties(_, shipments: Shipment[]) {
    return Vue.$pillpack.cloudWatch.noteShipmentProperties(shipments)
  },
}

const getters: GetterTree<State, RootState> = {
  getShipmentLiteratureFlag: (state: State) => (shipmentId: string) => {
    return state.shipmentLiteratures[shipmentId].showDigitalShipmentLiterature
  },
  getShipmentLiteratureURL: (state: State) => (shipmentId: string) => {
    return state.shipmentLiteratures[shipmentId].shipmentLiteratureURL
  },
  pastOrders: (state: State) => {
    return state.pastOrders.ids
      .map(id => {
        return state.ordersById && state.ordersById[id]
      })
      .filter(order => !!order)
      .sort((order1, order2) => {
        return !!order1.deliveredAt && !!order2.deliveredAt
          ? +order2.deliveredAt - +order1.deliveredAt
          : -1
      })
  },
  currentOrders: (state: State) => {
    return state.currentOrders.ids
      .map(id => {
        return state.ordersById && state.ordersById[id]
      })
      .filter(order => !!order)
      .sort((order1, order2) => {
        return !!order1.deliveredAt && !!order2.deliveredAt
          ? +order1.deliveredAt - +order2.deliveredAt
          : 1
      })
  },

  // TODO remove this getter when the server starts sending projected_next_arrival_date
  nextShipmentDate: (state: State, getters) => {
    if (state.projectedNextArrivalDate) {
      return state.projectedNextArrivalDate
    }

    // this logic has been moved to server
    const today = new Date()
    const deliveryDates = getters.currentOrders
      .filter(
        (order: Order) =>
          !!(order.canMakeChanges && order.deliveredAt && isAfter(order.deliveredAt, today)),
      )
      .map((order: Order) => order.deliveredAt)

    return closestTo(today, deliveryDates as Date[])
  },
  visibleBillPayments: (state: State) => {
    return state.billPayments.data.slice(0, state.billPayments.numberToShow)
  },
  canShowMoreBillPayments: (state: State) => {
    return state.billPayments.numberToShow < state.billPayments.data.length
  },
  arrivalDateMessageFor: (state, getters, rootState, rootGetters) => (order: Order) => {
    if (order.hasMultipleShipments) {
      const shipmentsCount = order.shipments.length
      const dateTimeMessages: Set<TranslateResult> = new Set()
      const dateMessages: Set<TranslateResult> = new Set()

      // eslint-disable-next-line consistent-return
      order.shipments.forEach(shipment => {
        const trackingHistory = rootGetters['tracking/trackingHistoryFor'](
          shipment,
        ) as TrackingHistory
        if (!trackingHistory) return null
        const { dateTimeMessage, dateMessage } = trackingHistory
        if (dateTimeMessage && dateMessage) {
          dateTimeMessages.add(dateTimeMessage)
          dateMessages.add(dateMessage)
        }
      })
      const arrivalMessageFinal = [...dateMessages].join('; ')
      return {
        arrivalMessage: `${arrivalMessageFinal} ${i18n.tc(
          'order.conditionalShipmentsCount',
          shipmentsCount,
        )}`.trim(),
        dateMessage: arrivalMessageFinal,
        dateTimeMessage: [...dateTimeMessages].join('; '),
      }
    } else {
      const shipment = order.shipments[0]
      const trackingHistory = rootGetters['tracking/trackingHistoryFor'](
        shipment,
      ) as TrackingHistory
      if (!trackingHistory) return null
      const { dateTimeMessage, dateMessage, arrivalMessage } = trackingHistory
      return {
        dateTimeMessage,
        dateMessage,
        arrivalMessage,
      }
    }
  },
}

const module = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default module
