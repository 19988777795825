import { PPArgs, PPBaseArgs } from './PPClient'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'

import { textSearch, geocode, details } from '@/util/googlePlaces'
import Pharmacy, { PharmacySearchResult } from '@/models/Pharmacy'
import { deserializeArray } from '@/models/transforms'

export default class PPPharmaciesApi {
  static async search(
    args: PPBaseArgs,
    { name, zipcode }: { name: string; zipcode: string },
  ): Promise<PPResponse<PharmacySearchResult[]>> {
    try {
      const geocodeResults = await geocode(zipcode)

      const { location } = geocodeResults[0].geometry

      const textSearchResults = await textSearch({ name: `${name} pharmacy`, location })
      const pharmacyTypes = ['health', 'pharmacy']
      const filteredResults = textSearchResults.filter((result: google.maps.places.PlaceResult) =>
        result.types?.some(resultType => pharmacyTypes.includes(resultType)),
      )

      const pharmacies = deserializeArray(PharmacySearchResult, filteredResults)
      return new PPSuccessResponse(pharmacies)
    } catch (e) {
      return new PPFailureResponse(e)
    }
  }

  static async getDetails(
    args: PPBaseArgs,
    { pharmacy }: { pharmacy: PharmacySearchResult },
  ): Promise<PPResponse<Pharmacy>> {
    try {
      const googlePlacesDetails = await details(pharmacy.googlePlaceId)

      const pharmacyDetails = Pharmacy.fromGooglePlaceResult(googlePlacesDetails)

      return new PPSuccessResponse(pharmacyDetails)
    } catch (e) {
      return new PPFailureResponse(e)
    }
  }

  static async all(args: PPArgs): Promise<PPResponse<Pharmacy[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/pharmacies`,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = response.data.pharmacies.map(Pharmacy.fromPillPackCoreResponse)

    return new PPSuccessResponse(results)
  }

  static async add(
    args: PPArgs,
    { pharmacy }: { pharmacy: Pharmacy },
  ): Promise<PPResponse<Pharmacy>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/pharmacies`,
      content: {
        pharmacy: pharmacy.toPillPackCoreRequest(),
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const savedPharmacy = Pharmacy.fromPillPackCoreResponse(response.data.pharmacy)

    return new PPSuccessResponse(savedPharmacy)
  }
}
