import { serializable, alias, primitive } from 'serializr'

export default class PacketUserMedication {
  @serializable(alias('id', primitive()))
  id!: string

  @serializable(alias('icon_name', primitive()))
  iconName!: null | string

  @serializable(alias('image_url', primitive()))
  imageUrl!: null | string

  @serializable(alias('packaging', primitive()))
  packaging!: null | string
}
