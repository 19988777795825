import { serializable, identifier, alias, primitive, object } from 'serializr'
import { deserializeOnly, ppCoreDate } from '@/models/transforms'
import Physician from '@/models/Physician'

export enum ChaseStates {
  Pending = 'pending',
  Denied = 'denied',
  HasError = 'has_error',
  Received = 'received',
  NewChase = 'new_chase',
  InProgress = 'in_progress',
  UnableToAcquire = 'unable_to_acquire',
}

export enum ChaseTypes {
  SignupMed = 'signup_med',
  NewMed = 'new_med',
  DoseCorrect = 'dose_correct',
  Renewal = 'renewal',
}

export enum ChaseDisposition {
  UnableToConfirmPrescriber = 'unable_to_confirm_prescriber',
  RequestDenied = 'request_denied',
  RequestDeniedLocalFill = 'request_denied_local_fill',
  AppointmentRequired = 'appointment_required',
  NoPrescriberResponse = 'no_prescriber_response',
  RequestDeniedContactPillpack = 'request_denied_contact_pillpack',
}

export default class PrescriptionRequest {
  @serializable(identifier())
  id!: string

  @serializable(deserializeOnly(alias('due_date', deserializeOnly(ppCoreDate()))))
  dueDate!: Date

  @serializable(deserializeOnly(primitive()))
  state!: ChaseStates

  @serializable(deserializeOnly(primitive()))
  type!: ChaseTypes

  @serializable(alias('customer_messageable_disposition', deserializeOnly(primitive())))
  customerMessageableDisposition!: ChaseDisposition

  @serializable(alias('is_fresh_chase', primitive()))
  isFreshChase!: boolean

  @serializable(object(Physician))
  physician!: Physician
}
