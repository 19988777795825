import { render, staticRenderFns } from "./InsuranceItem.vue?vue&type=template&id=4b90e093&scoped=true&"
import script from "./InsuranceItem.vue?vue&type=script&lang=ts&"
export * from "./InsuranceItem.vue?vue&type=script&lang=ts&"
import style0 from "./InsuranceItem.vue?vue&type=style&index=0&id=4b90e093&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b90e093",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BMedia } from 'bootstrap-vue'
import IconInsuranceShield from '@/assets/icons/icon-insurance-shield.svg'
installComponents(component, {BMedia,IconInsuranceShield})
