import Vue from 'vue'
import { Module } from 'vuex'
import sortBy from 'lodash/sortBy'

import { RootState } from '@/store'
import DashboardMedication, {
  Group,
  MedicationType,
  PrintCategory,
} from '@/models/DashboardMedication'
import { MedicationResponse } from '@/ppapi/PPMedicationsApi'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import MedicationLiterature from '@/models/MedicationLiterature'
import { PPHttpFailureResponse } from '@/ppapi/PPHttp'
import { PPError } from '@/ppapi/PPError'
import { medStatusFinder, MedicationStatus } from '@/util/medStatusHelpers'
import categorizeMedications, { CategorizedMeds } from '@/util/categorizeMeds'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import AddOtcFormState from '@/models/AddOtcFormState'
import AddOtcMedFormState from '@/models/AddOtcMedFormState'
import Today from '@/models/Today'
import Prescription from '@/models/Prescription'
import SigLine from '@/models/PrescriptionSigLine'

interface MedicationsState {
  activeMedIds: Set<string>
  pastMedIds: Set<string>
  pendingMedIds: Set<string>
  todaysMeds: Today | null
  medsById: { [id: string]: DashboardMedication }
  literatureByMedId: { [id: string]: MedicationLiterature }
  showPendingMedications: boolean
  showPastMedications: boolean
  sortBy: string
  medSearchResults: string[]
  rxCatalogItems: string[]
}

const state: MedicationsState = {
  activeMedIds: new Set(),
  pastMedIds: new Set(),
  pendingMedIds: new Set(),
  todaysMeds: null,
  medsById: {},
  literatureByMedId: {},
  showPendingMedications: false,
  showPastMedications: false,
  sortBy: 'description', // reversed if first char is '-'
  medSearchResults: [],
  rxCatalogItems: [],
}

const module: Module<MedicationsState, RootState> = {
  namespaced: true,
  state,

  mutations: {
    updateMedications(state, meds: MedicationResponse): void {
      if (meds.activeMedications) {
        state.activeMedIds = new Set(meds.activeMedications.map(med => med.id))
        meds.activeMedications.forEach(med => Vue.set(state.medsById, med.id, med))
      }

      if (meds.pastMedications) {
        state.pastMedIds = new Set(meds.pastMedications.map(med => med.id))
        meds.pastMedications.forEach(med => Vue.set(state.medsById, med.id, med))
      }

      if (meds.pendingMedications) {
        state.pendingMedIds = new Set(meds.pendingMedications.map(med => med.id))
        meds.pendingMedications.forEach(med => Vue.set(state.medsById, med.id, med))
      }
    },

    updateToday(state, today: Today): void {
      state.todaysMeds = today
    },

    insertMedication(state, med: DashboardMedication): void {
      Vue.set(state.medsById, med.id, med)

      let key: keyof MedicationsState
      switch (med.group) {
        case Group.Past:
          key = 'pastMedIds'
          break
        case Group.Pending:
          key = 'pendingMedIds'
          break
        case Group.Active:
          key = 'activeMedIds'
          break
        default:
          throw new UnreachableCaseError(med.group)
      }

      const medIds = new Set(state[key])
      medIds.add(med.id)
      state[key] = medIds
    },

    insertLiterature(
      state,
      { id, literature }: { id: string; literature: MedicationLiterature },
    ): void {
      Vue.set(state.literatureByMedId, id, literature)
    },

    setShowPendingMedications(state, value: boolean): void {
      state.showPendingMedications = value
    },

    setShowPastMedications(state, value: boolean): void {
      state.showPastMedications = value
    },

    resetState(state) {
      state.pendingMedIds = new Set()
      state.activeMedIds = new Set()
      state.pastMedIds = new Set()
      state.medsById = {}
      state.showPastMedications = false
    },

    setMedicationResults(state, results: string[]) {
      state.medSearchResults = results
    },

    resetMedicationResults(state) {
      state.medSearchResults = []
    },

    setRxCatalogItems(state, results: string[]) {
      state.rxCatalogItems = results
    },
  },

  actions: {
    load: trackAsyncStatus('medications/load', async function ({ commit }) {
      const meds = await Vue.$pillpack.medications.getAll()
      commit('updateMedications', meds)
    }),

    loadToday: trackAsyncStatus('medications/loadToday', async function ({ commit }) {
      const today = await Vue.$pillpack.medications.getToday()
      commit('updateToday', today)
    }),

    loadPending: trackAsyncStatus('medications/loadPending', async function ({ commit }) {
      const meds = await Vue.$pillpack.medications.getPending()
      commit('updateMedications', meds)
    }),

    loadOne: trackAsyncStatus(
      'medications/loadOne',
      async function ({ commit }, { id, type }: { id: string; type: MedicationType }) {
        const med = await Vue.$pillpack.medications.getOne({ medId: id, type })
        commit('insertMedication', med)
      },
    ),

    loadLiterature: trackAsyncStatus(
      'medications/loadLiterature',
      async function ({ commit }, med: DashboardMedication) {
        if (!med || !med.ndc9) {
          return
        }

        const { id, ndc9 } = med
        const literature = await Vue.$pillpack.medications.literature(ndc9)

        if (!literature) {
          return
        }

        commit('insertLiterature', { id, literature })
      },
    ),

    async ensureLiterature({ state, dispatch }, med: DashboardMedication) {
      const medId = med && med.id
      if (state.literatureByMedId[medId]) {
        return
      }

      await dispatch('loadLiterature', med)
    },

    pauseAutoRefill: trackAsyncStatus(
      'medications/pauseAutoRefill',
      async function ({ commit }, med: DashboardMedication) {
        if (!med || !med.primaryPrescriptionId) {
          return
        }

        const medId = med.id
        const rxId = med.primaryPrescriptionId
        const paused = await Vue.$pillpack.medications.pauseAutoRefill(rxId)

        if (!paused) {
          throw new PPHttpFailureResponse(new PPError('Unable to pause medication'))
        }

        const updatedMed = await Vue.$pillpack.medications.getOne({
          medId,
          type: MedicationType.UserMedication,
        })
        commit('insertMedication', updatedMed)
      },
    ),

    resumeAutoRefill: trackAsyncStatus(
      'medications/resumeAutoRefill',
      async function ({ commit }, med: DashboardMedication) {
        if (!med || !med.primaryPrescriptionId) {
          return
        }

        const medId = med.id
        const rxId = med.primaryPrescriptionId
        const resumed = await Vue.$pillpack.medications.resumeAutoRefill(rxId)

        if (!resumed) {
          throw new PPHttpFailureResponse(new PPError('Unable to resume medication'))
        }

        const updatedMed = await Vue.$pillpack.medications.getOne({
          medId,
          type: MedicationType.UserMedication,
        })
        commit('insertMedication', updatedMed)
      },
    ),

    delete: trackAsyncStatus('medications/delete', async function ({ dispatch, state }, { id }) {
      const med = state.medsById[id]
      if (!med || !med.primaryPrescriptionId) {
        return
      }

      await Vue.$pillpack.medications.delete(med.primaryPrescriptionId)

      await dispatch('loadOne', { id: med.id, type: med.type })
    }),

    requestRefill: trackAsyncStatus(
      'medications/requestRefill',
      async function ({ dispatch, state }, { id }) {
        const med = state.medsById[id]
        if (!med || !med.primaryPrescriptionId) {
          return
        }

        await Vue.$pillpack.medications.requestRefill(med.primaryPrescriptionId)

        const reloadMed = dispatch('loadOne', { id: med.id, type: med.type })
        const reloadOrders = dispatch('orders/loadCurrent', {}, { root: true })

        await Promise.all([reloadMed, reloadOrders])
      },
    ),

    cancelRefillRequest: trackAsyncStatus(
      'medications/cancelRefillRequest',
      async function ({ dispatch, state }, { id }) {
        const med = state.medsById[id]
        if (!med || !med.primaryPrescriptionId) {
          return
        }

        await Vue.$pillpack.medications.cancelRefillRequest(med.primaryPrescriptionId)

        await dispatch('loadOne', { id: med.id, type: med.type })
      },
    ),

    orderRushShipment: trackAsyncStatus(
      'medications/orderRushShipment',
      async function ({ dispatch, state }, { id, dateNeeded }) {
        const med = state.medsById[id]
        if (!med || !med.primaryPrescriptionId) {
          return
        }

        await Vue.$pillpack.medications.orderRushShipment({
          rxId: med.primaryPrescriptionId,
          dateNeeded,
        })

        await dispatch('loadOne', { id: med.id, type: med.type })
      },
    ),

    cancelRushShipment: trackAsyncStatus(
      'medications/cancelRushShipment',
      async function ({ dispatch, state }, { id }) {
        const med = state.medsById[id]
        if (!med || !med.primaryPrescriptionId) {
          return
        }

        await Vue.$pillpack.medications.cancelRushShipment(med.primaryPrescriptionId)

        await dispatch('loadOne', { id: med.id, type: med.type })
      },
    ),

    search: trackAsyncStatus(
      'medications/search',
      async function ({ commit }, { query }: { query: string }) {
        commit('resetMedicationResults')
        if (query) {
          commit('setMedicationResults', await Vue.$pillpack.medications.search({ query }))
        }
      },
      { mergeRequests: false },
    ),

    getRxCatalog: trackAsyncStatus(
      'medications/getRxCatalog',
      async function ({ commit }) {
        if (state?.rxCatalogItems?.length) return
        commit('setRxCatalogItems', await Vue.$pillpack.medications.getRxCatalog())
      },
      { mergeRequests: false },
    ),

    requestOtc: trackAsyncStatus(
      'medications/requestOtc',
      async function ({ dispatch }, otcForm: AddOtcFormState) {
        const supplement = await otcForm.requestOtc()
        if (supplement && supplement.id) {
          await dispatch('loadOne', {
            id: supplement.id,
            type: MedicationType.SupplementRequest,
          })
        }
      },
    ),

    requestOtcMedication: trackAsyncStatus(
      'medications/requestOtcMedication',
      async function ({ dispatch }, otcForm: AddOtcMedFormState) {
        const otc = await otcForm.requestOtcMedication()
        if (otc && otc.medication.id) {
          await dispatch('loadOne', {
            id: otc.medication.id,
            type: MedicationType.OtcMedicationRequest,
          })
        }
      },
    ),
    medPreferenceChange: trackAsyncStatus(
      'medications/medPreferenceChange',
      async function (
        { dispatch },
        payload: {
          medication: DashboardMedication
          prescription: Prescription
          inPackets: boolean
          daysSupply: number
          hoaPreferences: SigLine[]
          outreachConsent: boolean
        },
      ) {
        if (!payload || !payload.medication || !payload.prescription) return

        // Make the change request
        await Vue.$pillpack.medications.medPreferenceChangeRequest({
          prescriptionId: payload.prescription.id,
          daysSupply: payload.daysSupply,
          inPackets: payload.inPackets,
          hoaPreferences: payload.hoaPreferences,
          outreachConsent: payload.outreachConsent,
        })

        // reload the user medication
        dispatch('loadOne', {
          id: payload.medication.id,
          type: payload.medication.type,
        })
      },
    ),
  },

  getters: {
    allMedications(state) {
      if (!state.medsById) return []
      return Object.values(state.medsById)
    },
    activeMedications(state) {
      if (!state.activeMedIds) return []
      return Array.from(state.activeMedIds).map(medId => state.medsById[medId])
    },
    pausedMedications(state) {
      if (!state.activeMedIds) return []
      return Array.from(state.activeMedIds)
        .map(medId => state.medsById[medId])
        .filter(med => med.isPaused)
    },
    pastMedications(state) {
      if (!state.pastMedIds) return []
      return Array.from(state.pastMedIds).map(medId => state.medsById[medId])
    },
    pendingMedications(state) {
      if (!state.pendingMedIds) return []
      return Array.from(state.pendingMedIds).map(medId => state.medsById[medId])
    },
    sortedActiveMedications(state, getters) {
      return sortBy(getters.activeMedications, [state.sortBy])
    },
    sortedPausedMedications(state, getters) {
      return sortBy(getters.pausedMedications, [state.sortBy])
    },
    sortedPastMedications(state, getters) {
      return sortBy(getters.pastMedications, [state.sortBy])
    },
    sortedPendingMedications(state, getters) {
      return sortBy(getters.pendingMedications, [state.sortBy])
    },
    medsByPrescriber: (state, getters) => {
      const medications: DashboardMedication[] = getters.allMedications
      const medsByPrescriber = categorizeMedications(medications, med => med.physician!)
      return sortBy(medsByPrescriber, 'fullName')
    },
    actionableMedsByPrescriber: (state, getters) => {
      const actionableStates: MedicationStatus[] = ['has_error', 'denied']
      const medications: DashboardMedication[] = getters.allMedications
      const medsByPrescriber = categorizeMedications(
        medications.filter(med => actionableStates.includes(medStatusFinder(med))),
        med => med.physician!,
      )
      return sortBy(medsByPrescriber, 'fullName')
    },
    medsInPrintCategories: (state, getters): CategorizedMeds<PrintCategory>[] => {
      const medications = getters.sortedActiveMedications
      const medsByPrintableCategories = categorizeMedications(medications, med => {
        if (med.isPaused) {
          return PrintCategory.Paused
        } else if (med.selfPrescribed) {
          return PrintCategory.SelfPrescribed
        } else {
          return PrintCategory.Prescribed
        }
      })
      return medsByPrintableCategories
    },
    getMedication: state => (medId: string) => {
      return state.medsById[medId]
    },
    getLiterature: state => (medId: string) => {
      return state.literatureByMedId[medId]
    },
  },
}

export default module
