
















import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { TranslateResult } from 'vue-i18n'

import User from '@/models/User'
import SkeletonLine from '@/components/SkeletonLine.vue'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import DashboardMedication from '@/models/DashboardMedication'
import {
  ShipmentStatus,
  shipmentStatusFinder,
  medStatusFinder,
  arrivalDateCopy,
  StatusClass,
} from '@/util/medStatusHelpers'

@Component({
  components: { SkeletonLine },
  computed: {
    ...mapState('user', ['me']),
  },
})
export default class ShipmentStatusLine extends Vue {
  me?: User

  @Prop({ type: DashboardMedication, required: true })
  med!: DashboardMedication

  get shipmentStatus(): ShipmentStatus {
    return shipmentStatusFinder(this.med, this.me)
  }

  get loading(): boolean {
    return this.shipmentStatus === 'loading'
  }

  get statusLineClass(): StatusClass {
    let status: StatusClass

    switch (this.shipmentStatus) {
      case 'with_arrival_date':
      case 'ready':
        status = 'med-status--shipping'
        break
      case 'paused':
        status = 'med-status--paused'
        break
      case 'waiting':
      case 'by_request':
      case 'contact_us':
      case 'unknown_offcycle':
      case 'unknown':
      case 'past':
      case 'loading':
        status = 'med-status--default'
        break
      default:
        throw new UnreachableCaseError(this.shipmentStatus)
    }

    return status
  }

  get shipmentStatusCopy(): TranslateResult | undefined {
    let copy: TranslateResult | undefined

    switch (this.shipmentStatus) {
      case 'with_arrival_date':
        copy = arrivalDateCopy(this.med)
        break
      case 'waiting':
        copy = this.$t('shipmentStatus.waiting.arrival')
        break
      case 'ready':
        copy = this.$t('shipmentStatus.ready.arrival')
        break
      case 'paused':
        copy = this.$t('Paused')
        break
      case 'by_request':
        copy = this.$t('shipmentStatus.byRequest.arrival')
        break
      case 'contact_us':
        copy = this.$t('shipmentStatus.contactUs.arrival')
        break
      case 'unknown_offcycle':
        copy = this.$t('shipmentStatus.unknownOffcycle.arrival')
        break
      case 'unknown':
        copy = this.$t('shipmentStatus.unknown.arrival')
        break
      case 'loading':
        copy = this.$t('shipmentStatus.loading.arrival')
        break
      case 'past':
        break
      default:
        throw new UnreachableCaseError(this.shipmentStatus)
    }

    return copy
  }

  // If we don't have a know shipment status (ex. arrival date, paused, by request med, etc)
  // and the med is past or chase has been denied
  // then we don't want to show the "Contact Us for delivery date" messages.
  get showShipmentStatus() {
    let shipmentStatusKnown = false

    switch (this.shipmentStatus) {
      case 'with_arrival_date':
      case 'waiting':
      case 'ready':
      case 'paused':
      case 'by_request':
        shipmentStatusKnown = true
        break
      case 'contact_us':
      case 'unknown_offcycle':
      case 'unknown':
      case 'loading':
      case 'past':
        shipmentStatusKnown = false
        break
      default:
        throw new UnreachableCaseError(this.shipmentStatus)
    }

    const medStatus = medStatusFinder(this.med)
    let medIsActive = true

    switch (medStatus) {
      case 'pending_otc':
      case 'pending':
      case 'new_chase':
      case 'in_progress':
      case 'has_error':
      case 'received_signup_med':
      case 'pre_rph_check':
      case 'active':
      case 'received':
      case 'ortf':
        medIsActive = true
        break
      case 'unknown':
      case 'unable_to_acquire':
      case 'denied':
      case 'discontinued':
      case 'expired':
        medIsActive = false
        break
      default:
        throw new UnreachableCaseError(medStatus)
    }

    return shipmentStatusKnown || medIsActive
  }
}
