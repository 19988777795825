import { alias, serializable, list, object, primitive } from 'serializr'

import Address from '@/models/Address'
import PaymentMethod from '@/models/PaymentMethod'
import AnnualBill from '@/models/AnnualBill'
import Insurance from '@/models/Insurance'

export default class OrderPreferences {
  @serializable(alias('user_phone_number', primitive()))
  userPhoneNumber!: string

  @serializable(alias('monthly_billing_day', primitive()))
  monthlyBillingDay!: string

  @serializable(alias('billing_day_of_month', primitive()))
  billingDayOfMonth!: number

  @serializable(alias('billing_protocol', primitive()))
  billingProtocol!: string

  @serializable(alias('shipping_address', object(Address)))
  shippingAddress!: Address

  @serializable(alias('default_payment_method', object(PaymentMethod)))
  defaultPaymentMethod!: PaymentMethod

  @serializable(alias('default_fsa_hsa_payment_method', object(PaymentMethod)))
  defaultFsaHsaPaymentMethod!: PaymentMethod

  @serializable(alias('annual_bills', list(object(AnnualBill))))
  annualBills!: AnnualBill[]

  @serializable(alias('primary_insurance', object(Insurance)))
  primaryInsurance?: Insurance
}
