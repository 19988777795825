import Vue from 'vue'
import VueI18n, { Formatter, TranslateResult } from 'vue-i18n'
import {
  format,
  isToday,
  isTomorrow,
  isThisYear,
  differenceInDays,
  isPast,
  distanceInWords,
  distanceInWordsStrict,
  isYesterday,
  isFuture,
  subDays,
} from 'date-fns'
import uniqWith from 'lodash/uniqWith'
import dateFnsEnglishLocale from 'date-fns/locale/en'
import Shipment from './models/Shipment'
import TrackingDate from './models/Tracking/TrackingDate'

export enum Locale {
  ENGLISH = 'en',
  SPANISH = 'es',
}

Vue.use(VueI18n)

const loadedLanguages = new Set([Locale.ENGLISH]) // our default language that is preloaded

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    medium: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    'medium:noYear': {
      month: 'long',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}

const messagesEn = require('@/locales/en')
const trackingStatusMessagesEn = require('@/locales/ast/en')

const i18n = new VueI18n({
  locale: Locale.ENGLISH,
  fallbackLocale: Locale.ENGLISH,
  messages: {
    en: {
      ...messagesEn,
      ...trackingStatusMessagesEn,
    },
  },
  dateTimeFormats,
})

const localeStringToDateFnsLocale: { [locale: string]: typeof dateFnsEnglishLocale } = {
  en: dateFnsEnglishLocale,
}

export async function loadSpanishAsync() {
  const spanishLocale = Locale.SPANISH
  if (!loadedLanguages.has(spanishLocale)) {
    const [messagesEs, trackingStatusMessagesEs, dateFnsSpanishLocale] = await Promise.all([
      import(/* webpackChunkName: "lang-es" */ '@/locales/es.json'),
      import(/* webpackChunkName: "lang-es-ast" */ '@/locales/ast/es.json'),
      import('date-fns/locale/es'),
    ])

    i18n.setLocaleMessage(spanishLocale, {
      ...messagesEs.default,
      ...trackingStatusMessagesEn, // fallback to english for missing translations
      ...trackingStatusMessagesEs.default,
    })
    localeStringToDateFnsLocale.es = dateFnsSpanishLocale
    loadedLanguages.add(spanishLocale)
  }
}

export async function setAppLocale(locale: Locale = Locale.ENGLISH) {
  if (locale === Locale.SPANISH) {
    await loadSpanishAsync()
  }
  Vue.$pillpack.locale = locale
  i18n.locale = locale
}

// Expose all localization functions to be imported anywhere.
// The $ prefix makes these functions compatible with `yarn gettext`
export const $t = i18n.t.bind(i18n)
export const $tc = i18n.tc.bind(i18n)
export const $te = i18n.te.bind(i18n)
export const $d = i18n.d.bind(i18n)
export const $n = i18n.n.bind(i18n)

const getLocaleForDates = (locale: string) => {
  return localeStringToDateFnsLocale[locale] || dateFnsEnglishLocale
}

export const formatDate = (date: Date, formatStr: string) => {
  return format(date, formatStr, { locale: getLocaleForDates(i18n.locale) })
}

export const formatDateSmall = (date: Date) => {
  const today = new Date('2020-04-27')
  const offset = differenceInDays(date, today)
  const formatStr = Math.abs(offset) < 365 ? 'MMM D' : 'MMM D, YYYY'

  return formatDate(date, formatStr)
}

export const formatDateMedium = (date: Date) => {
  const today = new Date().setHours(0, 0, 0, 0)
  const offset = differenceInDays(date, today)
  const formatStr = Math.abs(offset) < 365 ? 'MMMM D' : 'MMMM D, YYYY'

  return formatDate(date, formatStr)
}

export const formatDateLong = (date: Date) => {
  const formatStr = isThisYear(date) ? 'dddd, MMMM D' : 'dddd, MMMM D, YYYY'

  return formatDate(date, formatStr)
}

export const formatDateFilter = (date: Date | undefined, formatStr: string) => {
  if (!date) {
    return ''
  }
  return formatDate(date, formatStr)
}

export const formatDateDistance = (date1: Date, date2: Date, options: any = {}) => {
  return distanceInWords(date1, date2, { locale: getLocaleForDates(i18n.locale), ...options })
}

export const formatDateDistanceStrict = (date1: Date, date2: Date, options: any = {}) => {
  return distanceInWordsStrict(date1, date2, { locale: getLocaleForDates(i18n.locale), ...options })
}

export const isByEndOfDay = (date: Date) => {
  // check for dates have had time manually zeroed out
  return ![date.getHours(), date.getMinutes(), date.getSeconds()].filter(Boolean).length
}

export const arrivalMessage = (
  date: Date | undefined,
  altMessage?: string,
  deliveryProblem: boolean = false,
) => {
  if (!date) {
    return $t('shipmentStatus.unknown.arrival')
  }
  const today = new Date()
  const offset = differenceInDays(date, today)

  if (isToday(date)) {
    return $t('Estimated to arrive today')
  }
  if (isPast(date)) {
    if (deliveryProblem) {
      return $t('Expected arrival by {date}', { date: altMessage || formatDateMedium(date) })
    } else {
      return $t('Delivered on {date}', { date: altMessage || formatDateMedium(date) })
    }
  }
  if (isTomorrow(date)) {
    return $t('Estimated arrival by tomorrow')
  }
  if (offset < 6 && offset > 0) {
    const dayString = formatDate(date, 'dddd')
    return $t('Estimated arrival by {day}', { day: altMessage || dayString })
  }
  return $t('Estimated arrival by {date}', { date: altMessage || formatDateMedium(date) })
}

const getTodayMessage = (date: Date, hasTime: boolean) => {
  const short = $t('Today')
  let long = short

  if (hasTime) {
    if (isByEndOfDay(date)) {
      long = $t('Today by end of day')
    } else if (isPast(date)) {
      long = $t('Today at {time}', { time: formatDate(date, 'ha') })
    } else {
      long = $t('Today by {time}', { time: formatDate(date, 'ha') })
    }
  }

  return { short, long }
}

const getTomorrowMessage = (date: Date, hasTime: boolean) => {
  const short = $t('Tomorrow')
  let long = short
  if (hasTime) {
    if (isByEndOfDay(date)) {
      long = $t('Tomorrow by end of day')
    } else {
      long = $t('Tomorrow by {time}', { time: formatDate(date, 'ha') })
    }
  }

  return { short, long }
}

const getYesterdayMessage = (date: Date, hasTime: boolean) => {
  const short = $t('Yesterday')
  let long = short
  if (hasTime) {
    if (isByEndOfDay(date)) {
      long = $t('Yesterday by end of day')
    } else {
      long = $t('Yesterday at {time}', { time: formatDate(date, 'ha') })
    }
  }

  return { short, long }
}

const getPastMessage = (
  date: Date,
  options: { date: TranslateResult; time: Formatter },
  hasTime: boolean,
) => {
  const short = $t('{date}', { date: options.date })
  let long = short
  if (hasTime) {
    if (isByEndOfDay(date)) {
      long = $t('{date} by end of day', { date: options.date })
    } else {
      long = $t('{date} at {time}', options)
    }
  }

  return { short, long }
}

const getFutureMessage = (
  date: Date,
  options: { date: TranslateResult; time: Formatter },
  hasTime: boolean,
) => {
  const short = $t('{date}', { date: options.date })
  let long = short
  if (hasTime) {
    if (isByEndOfDay(date)) {
      long = $t('{date} by end of day', { date: options.date })
    } else {
      long = $t('{date} by {time}', options)
    }
  }

  return { short, long }
}

// Render a human readable date time expression relative to current dateTime
export const dateTimeMessage = (date: Date | TrackingDate) => {
  let dateTime: Date
  let hasTime = true
  if (date instanceof TrackingDate) {
    dateTime = date.dateTime
    hasTime = date.hasTime
  } else {
    dateTime = date
  }
  if (!date) {
    const short = $t('shipmentStatus.unknown.details')
    const long = $t('shipmentStatus.unknown.arrival')
    return { short, long }
  }
  const today = new Date()
  const offset = differenceInDays(dateTime, today)
  let options: any

  if (hasTime) {
    if (dateTime.getHours() < 7) {
      // is delivery time before 7am?
      // say end of day yesterday
      dateTime = subDays(new Date(dateTime.toDateString()), 1) // zeroes out time, subtracts 1 day
    } else if (dateTime.getHours() > 19) {
      // is delivery time after 7pm?
      // say end of day today
      dateTime = new Date(dateTime.toDateString()) // zeroes out time
    }
  }

  if (isToday(dateTime)) {
    return getTodayMessage(dateTime, hasTime)
  }
  if (isTomorrow(dateTime)) {
    return getTomorrowMessage(dateTime, hasTime)
  }
  if (isYesterday(dateTime)) {
    return getYesterdayMessage(dateTime, hasTime)
  }
  if (isFuture(dateTime) && offset < 6) {
    options = { date: formatDate(dateTime, 'dddd'), time: formatDate(dateTime, 'ha') }
  } else {
    options = { date: formatDateMedium(dateTime), time: formatDate(dateTime, 'ha') }
  }
  if (isPast(dateTime)) {
    return getPastMessage(dateTime, options, hasTime)
  }

  return getFutureMessage(dateTime, options, hasTime)
}

export const shipmentDatesMessage = (shipments: Shipment[]) => {
  if (!shipments) return ''
  const shipmentsCount = shipments.length
  const dates = shipments.map(shipment => shipment.deliveredAt)
  const uniqueDates = uniqWith(dates, (a, b) => a.toString() === b.toString())
  const message = uniqueDates.map(date => formatDateSmall(date)).join('; ')
  return `${message} ${$tc('order.conditionalShipmentsCount', shipmentsCount)}`.trim()
}

export const trackingEventDateString = (date: Date | TrackingDate) => {
  return dateTimeMessage(date)
}

export default i18n
