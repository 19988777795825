import { serializable, identifier, object, primitive, alias } from 'serializr'
import OTCMedicationFormulation from './OTCMedicationFormulation'

export default class OTCMedicationStrength {
  @serializable(alias('drug_id', identifier()))
  drugId!: string

  @serializable(alias('medication_id', identifier()))
  medicationId!: string

  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('in_packets', primitive()))
  inPackets!: boolean

  @serializable(alias('formulation', object(OTCMedicationFormulation)))
  formulation!: OTCMedicationFormulation
}
