import { serializable, primitive, identifier, alias } from 'serializr'
import { ppCoreDate, deserializeOnly } from '@/models/transforms'

export class BasicBankAccountInfo {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('bank_name', primitive()))
  bankName!: string

  @serializable(alias('last_four', primitive()))
  lastFour!: string
}

export default class StripeBankAccount {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('class', primitive()))
  accountClass!: string

  @serializable(alias('stripe_id', primitive()))
  stripeId!: string

  @serializable(alias('bank_name', primitive()))
  bankName!: string

  @serializable(alias('acct_last_four', primitive()))
  lastFour!: string

  @serializable(alias('country', primitive()))
  country!: string

  @serializable(alias('currency', primitive()))
  currency!: string

  @serializable(alias('verified', primitive()))
  verified!: boolean

  @serializable(alias('account_holder_name', primitive()))
  accountHolderName!: string

  @serializable(alias('default_payment_method', primitive()))
  defaultPaymentMethod!: boolean

  @serializable(alias('created_at', deserializeOnly(ppCoreDate())))
  createdAt!: Date

  get isDefaultMethod() {
    return this.defaultPaymentMethod
  }
}
