import Axios, { CancelTokenSource } from 'axios'
import HelpCenterArticle from '@/models/HelpCenterArticle'
import { deserializeArray } from '@/models/transforms'

const HELP_API_BASE_URL = 'https://pillpack.zendesk.com/api/v2/help_center/articles/search.json'
let cancelSource: CancelTokenSource = Axios.CancelToken.source()

/* eslint-disable camelcase */
export interface SearchResponse {
  query: string
  count: number
  next_page: string
  page: number
  page_count: number
  per_page: number
  previous_page: null
  results: HelpCenterArticle[]
}
/* eslint-enable camelcase */

export function cancelRequest() {
  cancelSource.cancel()
  cancelSource = Axios.CancelToken.source()
}

export default async function searchHelpCenter(
  query: string,
  per_page: number = 5,
): Promise<SearchResponse | null> {
  // Cancel any in-flight requests
  cancelRequest()

  // Send request to Zendesk Help Center API
  // https://developer.zendesk.com/rest_api/docs/help_center/introduction
  try {
    const { data } = await Axios.get(HELP_API_BASE_URL, {
      cancelToken: cancelSource.token,
      params: { query, per_page },
    })
    const results = deserializeArray(HelpCenterArticle, data.results)
    return { ...data, results, query }
  } catch (e) {
    if (e.message && e.message === 'cancel') {
      // request canceled, do nothing
      return null
    } else {
      throw e
    }
  }
}
