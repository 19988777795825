import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { PPBaseArgs } from './PPClient'

export default class PPStripeApi {
  static async getKey({ baseUrl, session, locale }: PPBaseArgs): Promise<PPResponse<string>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: '/stripe/key',
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return new PPSuccessResponse(response.data.key)
  }
}
