































import { Component, Vue, Prop } from 'vue-property-decorator'

import ServiceAlert from '@/models/ServiceAlert'
import ServiceAlertListItem from '@/components/service-alerts/ServiceAlertListItem.vue'
import Card from '@/components/Card.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'

@Component({
  components: {
    Card,
    LinkArrowIcon,
    ServiceAlertListItem,
  },
})
export default class ServiceAlertDashboardCard extends Vue {
  @Prop({ type: Array, required: true })
  alerts!: ServiceAlert[]

  get hasAlerts() {
    return this.alerts.length > 0
  }

  get topAlert() {
    return this.alerts[0]
  }
}
