import eventBus from '@/util/EventBus'

const oneMinuteInMs = 1000 * 60
const defaultPollingInterval = oneMinuteInMs
const defaultSessionTimeout = 30 * oneMinuteInMs

class SessionMonitor {
  private _sessionExpiry: number | null = null
  private _intervalId: number | null = null

  get expiry() {
    return this._sessionExpiry
  }

  start() {
    if (this.isRunning) return

    this.setExpiry()
    eventBus.addEventListener('PPClient.ResponseReceived', this.handleResponse)

    this._intervalId = window.setInterval(this.checkIfSessionExpired, this.pollingInterval)
  }

  timeout = defaultSessionTimeout
  pollingInterval = defaultPollingInterval
  timeToWarnBeforeExpiration = 5 * oneMinuteInMs

  stop() {
    eventBus.removeEventListener('PPClient.ResponseReceived', this.handleResponse)

    if (this._intervalId) {
      window.clearInterval(this._intervalId)
      this._intervalId = null
    }
  }

  private checkIfSessionExpired = () => {
    if (this.willExpireSoon()) {
      eventBus.dispatchEvent('SessionMonitor.SessionExpiringSoon')
    }

    if (this.isExpired()) {
      this.stop()
      eventBus.dispatchEvent('SessionMonitor.SessionExpiration')
    }
  }

  private isExpired() {
    if (!this._sessionExpiry) {
      return false
    }

    return Date.now() > this._sessionExpiry
  }

  private willExpireSoon() {
    if (!this._sessionExpiry) {
      return false
    }

    if (this.isExpired()) {
      return false
    }

    return this._sessionExpiry - Date.now() < this.timeToWarnBeforeExpiration
  }

  private handleResponse = (e: any) => {
    const session = e.detail && e.detail.session

    if (session && session.timeoutInMs) {
      this.timeout = session.timeoutInMs
    }

    this.setExpiry()
    eventBus.dispatchEvent('SessionMonitor.SessionExtended')
  }

  private setExpiry = () => {
    const now = Date.now()
    this._sessionExpiry = now + this.timeout
  }

  private get isRunning() {
    return !!this._intervalId
  }
}

export default new SessionMonitor()
