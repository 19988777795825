// Map a number from one range to another
// https://gist.github.com/xposedbones/75ebaef3c10060a3ee3b246166caab56
/**
 * https://gist.github.com/xposedbones/75ebaef3c10060a3ee3b246166caab56
 * @description Map a number from one range to another
 * @param {number} num
 * @param {number} inMin
 * @param {number} inMax
 * @param {number} outMin
 * @param {number} outMax
 */
function mapNum(num: number, inMin: number, inMax: number, outMin: number, outMax: number) {
  return ((num - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
}

/**
 * https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
 *
 * @description Get a random number based on a seed (deterministic)
 * @param {number} seed The seed to use in the PRNG
 */
function seededPRNG(seed: number) {
  const x = Math.sin(seed) * 10000
  return x - Math.floor(x)
}

/**
 * @description Get an integer within a given range based on a hashable string
 * @param {string} source A number-like string, i.e. A user ID
 * @param {number} max
 * @param {number} min
 */
function deterministicIndex(source: string, max: number, min: number = 0): number {
  const seed = parseInt(source.replace(/\D/g, ''), 10) // Hopefully extract a useful number
  if (!seed) {
    throw new Error(`Expected number-like input, given: ${source}`)
  }

  const rand = seededPRNG(seed)
  const mapped = mapNum(rand, 0, 1, min, max)
  return Math.floor(mapped)
}

// Pulled from _pillpack-variables.scss
const COLORS = ['#F8B516', '#3BC090', '#7555C2', '#F69840', '#143C5A']
const max = COLORS.length - 1

/**
 * Returns a CSS Color string, deterministically based on the users ID
 * @param {string} id Users ID
 */
export default function getUserColor(id: string) {
  if (!id) return 'transparent'
  return COLORS[deterministicIndex(id, max)]
}
