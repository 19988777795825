import { serializable, primitive, alias, list, object } from 'serializr'
import { stringifiedJsonList } from './transforms'

export class Allergy {
  @serializable(primitive())
  id!: string

  @serializable(primitive())
  code?: string

  @serializable(alias('qs1_code', primitive()))
  qs1Code?: string

  @serializable(alias('reaction_type', primitive()))
  reactionType?: string

  @serializable(primitive())
  description!: string

  @serializable(primitive())
  friendly?: string

  @serializable(alias('display_on_signup', primitive()))
  displayOnSignup?: boolean

  get displayName(): string {
    return this.friendly || this.description
  }
}

export class GetAllergies {
  @serializable(list(object(Allergy)))
  allergies!: Allergy[]

  @serializable(alias('other_allergies', stringifiedJsonList()))
  otherAllergies?: string[]
}

export class UpdateOtherAllergies {
  @serializable(stringifiedJsonList())
  data?: string[]

  constructor(data: string[]) {
    this.data = data
  }
}
