import { Module } from 'vuex'
import { RootState } from '@/store'
import { trackEvent } from '@/plugins/GoogleAnalyticsPlugin'
import ServiceAlert, { MessageIds } from '@/models/ServiceAlert'
import { getLocalStorage, setLocalStorage } from '@/util/localStorage'

export interface WelcomeState {
  payerWelcomeModal: boolean
  onboardingTour: boolean
  onboardingPopover: boolean
}
const module: Module<WelcomeState, RootState> = {
  namespaced: true,
  state: {
    payerWelcomeModal: false,
    onboardingTour: false,
    onboardingPopover: false,
  },
  mutations: {
    setOnboardingTour: (state, isVisible: boolean) => {
      state.onboardingTour = isVisible
    },
    setPayerWelcomeModal: (state, isVisible: boolean) => {
      state.payerWelcomeModal = isVisible
    },
    setOnboardingPopover: (state, isVisible: boolean) => {
      state.onboardingPopover = isVisible
    },
  },
  actions: {
    async showPayerWelcomeModal({ commit, dispatch, rootGetters }) {
      const user = rootGetters['user/currentUser']
      const message = rootGetters['alerts/allMessages'].find((m: ServiceAlert) => {
        return m.messageId === MessageIds.PayerWelcomeModal
      })

      if (!user || !message) return

      commit('setPayerWelcomeModal', true)
      dispatch('alerts/viewMany', [message.id], { root: true })
      trackEvent({
        eventCategory: 'payer_welcome_modal',
        eventAction: 'show_modal',
      })
    },
    async closePayerWelcomeModal({ commit, dispatch, rootGetters }) {
      const user = rootGetters['user/currentUser']
      const message = rootGetters['alerts/allMessages'].find((m: ServiceAlert) => {
        return m.messageId === MessageIds.PayerWelcomeModal
      })

      if (!user || !message) return

      commit('setPayerWelcomeModal', false)
      dispatch('alerts/resolve', message, { root: true })
      trackEvent({
        eventCategory: 'payer_welcome_modal',
        eventAction: 'dismiss_modal',
      })
    },
    async showOnboardingTour({ commit, rootGetters }) {
      const { showWelcomeTour } = rootGetters['user/currentUser']

      if (showWelcomeTour && !getLocalStorage('onboarding-tour-dismissed')) {
        commit('setOnboardingTour', true)
      }
    },
    hideOnboardingTour({ commit }) {
      commit('setOnboardingTour', false)
      commit('setOnboardingPopover', true)
      setLocalStorage('onboarding-tour-dismissed', true)
    },
  },
}

export default module
