import { $t } from '@/i18n'

export const OnboardingTourSteps = [
  {
    header: $t('onboardingTour.item1.header'),
    image: 'med-list-mobile',
    body: $t('onboardingTour.item1.body'),
  },
  {
    header: $t('onboardingTour.item2.header'),
    image: 'add-prescription-otc',
    body: $t('onboardingTour.item2.body'),
  },
  {
    header: $t('onboardingTour.item3.header'),
    image: 'orders-information',
    body: $t('onboardingTour.item3.body'),
  },
  {
    header: $t('onboardingTour.item4.header'),
    image: 'print-medication',
    body: $t('onboardingTour.item4.body'),
  },
  {
    header: $t('onboardingTour.item5.header'),
    image: 'help-center',
    body: $t('onboardingTour.item5.body'),
  },
]

export default OnboardingTourSteps
