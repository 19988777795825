import { render, staticRenderFns } from "./TrustDeviceModal.vue?vue&type=template&id=df7dfc64&scoped=true&"
import script from "./TrustDeviceModal.vue?vue&type=script&lang=ts&"
export * from "./TrustDeviceModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7dfc64",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import IconSecureDesktop from '@/assets/icons/icon-secure-desktop.svg'
import IconSecureMobile from '@/assets/icons/icon-secure-mobile.svg'
installComponents(component, {BButton,IconSecureDesktop,IconSecureMobile})
