import { serializable, primitive, identifier, alias, object } from 'serializr'
import { iso8601Date } from '@/models/transforms'
import { BasicBankAccountInfo } from '@/models/StripeBankAccount'

export default class StripePayment {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('stripe_id', primitive()))
  stripeId!: string

  @serializable(alias('debited_at', iso8601Date()))
  debitedAt!: Date

  @serializable(alias('amount', primitive()))
  amount!: string

  @serializable(alias('status', primitive()))
  status!: string

  @serializable(alias('stripe_bank_account', object(BasicBankAccountInfo)))
  bankAccountInfo!: BasicBankAccountInfo

  get displayAmount() {
    // amounts are always negative
    const amount = (-1 * Number(this.amount)).toFixed(2)

    return `$${amount}`
  }
}
