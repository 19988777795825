import zip from 'lodash/zip'
import flatMap from 'lodash/flatMap'
import { TranslateResult } from 'vue-i18n'
import { serializable, primitive, alias, list, object } from 'serializr'
import Prescription, { DaysSupply } from '@/models/Prescription'
import SigLine from '@/models/PrescriptionSigLine'
import { optional } from '@/models/transforms'
import { $t } from '@/i18n'

export interface FillPreference {
  daysSupply: DaysSupply
  inPackets: boolean
}

export type FillPreferenceString = 'packet' | 'bulk' // One day this could include 'packet-30' or 'bulk-90'

export default class MedChangeRequest implements FillPreference {
  @serializable(primitive())
  open!: boolean

  @serializable(alias('days_supply', primitive()))
  daysSupply!: DaysSupply

  @serializable(alias('in_packets', primitive()))
  inPackets!: boolean

  @serializable(alias('hoa_preferences', optional(list(object(SigLine)))))
  hoaPreferences?: SigLine[]

  composeChanges(prescription: Prescription): TranslateResult[] {
    if (!this.open) return []

    const changes = []

    if (this.inPackets !== prescription.inPackets) {
      const oldVal = prescription.inPackets ? 'packets' : 'bottle'
      const newVal = this.inPackets ? 'packets' : 'bottle'
      changes.push($t('Packaging from {oldVal} to {newVal}', { oldVal, newVal }))
    }

    // This is a diffing algorithm for HOA preference changes.
    // It will find the dose time changes between the current HOA and the preferred HOA for all sig lines
    if (this.hoaPreferences) {
      const currentSigLines = prescription.sigLines
      const preferredSigLines = this.hoaPreferences
      if (currentSigLines && preferredSigLines) {
        const hoaDiff = MedChangeRequest.getHoaDiff(currentSigLines, preferredSigLines)
        hoaDiff.forEach(([current, preferred]) => {
          changes.push($t('{current} dose to {preferred}', { current, preferred }))
        })
      }
    }

    return changes
  }

  static getHoaDiff(currentSigLines: SigLine[], preferredSigLines: SigLine[]): [string, string][] {
    const normalizeTime = (time: string) => time.replace(/\s/, '').toUpperCase()
    const removeIncompletePair = (pair: any[]) => !!(pair[0] && pair[1])
    const removeIdenticalTimes = (pair: any[]) => normalizeTime(pair[0]) !== normalizeTime(pair[1])

    const sigLinePairs = currentSigLines
      .map(sig => [sig, preferredSigLines.find(s => s.id === sig.id)])
      .filter(removeIncompletePair)

    const doseTimePairs = flatMap(sigLinePairs, ([current, preferred]) =>
      zip(
        current?.doses?.map(dose => dose.time),
        preferred?.doses?.map(dose => dose.time),
      ),
    )
      .filter(removeIncompletePair)
      .filter(removeIdenticalTimes)

    return doseTimePairs as [string, string][]
  }
}
