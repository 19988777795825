import DashboardMedication from '@/models/DashboardMedication'

export interface CategorizedMeds<C> {
  id: string
  category: C
  meds: DashboardMedication[]
}
interface CategoryObject {
  id: string
  categoryId?: string
}

type Category = CategoryObject | string

const getCategoryId = (category: Category): string => {
  return typeof category === 'string' ? category : category.categoryId ?? category.id
}

/**
 * Categorizes medications based on a category function
 * the category function can return multiple categories
 * @param {Array} medications
 * @param {Function} categoryFn returns one or many categories
 */
export default function categorizeMedications<T extends Category>(
  medications: DashboardMedication[],
  categoryFn: (med: DashboardMedication) => T | T[] | null | undefined,
): CategorizedMeds<T>[] {
  const categoryMap = new Map<string, CategorizedMeds<T>>()

  medications.forEach(med => {
    if (!med) return

    let categories = categoryFn(med)

    if (!categories) return
    if (!Array.isArray(categories)) {
      categories = [categories]
    }
    if (!categories.length) return

    categories.forEach(category => {
      const id = getCategoryId(category)
      if (id == null) return
      if (categoryMap.has(id)) {
        categoryMap.get(id)!.meds.push(med)
      } else {
        categoryMap.set(id, {
          id,
          category,
          meds: [med],
        })
      }
    })
  })

  return [...categoryMap.values()]
}
