






















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { ScrollDirection } from '@/vuex/layout'
import NavMenu from '@/components/navbar/NavMenu.vue'
import MobileNavLink from '@/components/navbar/MobileNavLink.vue'
import ServiceAlertNavBell from '@/components/navbar/ServiceAlertNavBell.vue'
import SiteLogo from '@/components/navbar/SiteLogo.vue'
import UserDropdown from '@/components/navbar/UserDropdown.vue'
import HamburgerIcon from '@/components/HamburgerIcon.vue'
import Banners from '@/components/Banners.vue'
import User from '@/models/User'

@Component({
  components: {
    Banners,
    HamburgerIcon,
    SiteLogo,
    NavMenu,
    MobileNavLink,
    ServiceAlertNavBell,
    UserDropdown,
  },
  computed: {
    ...mapState('layout', ['isMenuFixed', 'scrollDir']),
    ...mapState('user', ['me']),
    ...mapState('welcome', ['onboardingPopover']),
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapMutations('layout', ['setMenuHeight', 'setScrollDir', 'setMenuFixed', 'hideMenu']),
  },
})
export default class Navbar extends Vue {
  isLoggedIn!: boolean
  isMenuFixed!: boolean
  scrollDir!: ScrollDirection
  setMenuHeight!: (height: number) => void
  setMenuFixed!: (atTop: boolean) => void
  setScrollDir!: (dir: ScrollDirection) => void
  hideMenu!: () => void
  me!: User
  onboardingPopover!: boolean

  // Data
  lastScrollPosition = 0

  // Computed
  get isSignedUp() {
    return this.me.id && this.me.signedUp
  }

  get showNavForRoute() {
    return !this.$route.meta.hideNav
  }

  onScroll() {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
    if (currentScrollPosition < 0) {
      return
    }

    if (currentScrollPosition > 0 && !this.isMenuFixed) {
      this.updateMenuHeight()
      this.setMenuFixed(true)
    } else if (currentScrollPosition === 0 && this.isMenuFixed) {
      this.setMenuFixed(false)
    }

    // Stop if the difference between current scroll position
    // and last scroll position is less than the offset
    if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
      return
    }

    const scrollDir = currentScrollPosition < this.lastScrollPosition ? 'up' : 'down'
    if (scrollDir !== this.scrollDir) {
      this.setScrollDir(scrollDir)
    }
    this.lastScrollPosition = currentScrollPosition
  }

  updateMenuHeight() {
    const height = this.$el.clientHeight
    if (height > 0) this.setMenuHeight(height)
  }

  mounted() {
    window.addEventListener('scroll', this.onScroll, { passive: true })
  }

  beforeDestroy() {
    // unbind scroll
    window.removeEventListener('scroll', this.onScroll)
  }

  hidePopover() {
    this.$store.commit('welcome/setOnboardingPopover', false)
  }
}
