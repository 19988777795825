

















import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'

import User from '@/models/User'
import CareUser from '@/models/CareUser'

import UserInitials from '@/components/UserInitials.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'

@Component({
  components: {
    UserInitials,
    LinkArrowIcon,
  },
  computed: {
    ...mapState('user', ['me']),
  },
})
export default class CaregiverElement extends Vue {
  me!: User

  @Prop({ type: Object, required: true })
  user!: CareUser

  @Prop(String)
  relation!: string

  get active() {
    return this.me.id === this.user.id
  }

  get relationshipType() {
    return this.relation
  }
}
