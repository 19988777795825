import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

// Modules (keep alphabetical)
import addressesModule from '@/vuex/addresses'
import addRxModule from '@/vuex/addRx'
import allergiesModule from '@/vuex/allergies'
import asyncStatusModule from '@/vuex/asyncStatus'
import bannersModule, { BannerInfo } from '@/vuex/banners'
import conditionsModule from '@/vuex/conditions'
import dispensersModule from '@/vuex/dispensers'
import insuranceModule from '@/vuex/insurance'
import helpCenterModule from '@/vuex/helpCenter'
import layoutModule from '@/vuex/layout'
import medicationModule from '@/vuex/medications'
import ordersModule from '@/vuex/orders'
import otcMedicationsModule from '@/vuex/otcMedications'
import paymentMethodsModule from '@/vuex/paymentMethods'
import pharmaciesModule from '@/vuex/pharmacies'
import projectedShipmentModule from '@/vuex/projectedShipments'
import serviceAlertsModule from '@/vuex/serviceAlerts'
import physiciansModule from '@/vuex/physicians'
import physicianUpdateModule from '@/vuex/physicianUpdate'
import supplementsModule from '@/vuex/supplements'
import userEventsModule from '@/vuex/userEvents'
import userModule from '@/vuex/user'
import pwaModule from '@/vuex/pwa'
import welcomeModule from '@/vuex/welcome'
import trackingModule from '@/vuex/tracking'

import trackAsyncStatus from '@/util/trackAsyncStatus'
import eventBus from '@/util/EventBus'
import sessionMonitor from '@/util/sessionMonitor'
import { PPLogInFailedError, PPError } from '@/ppapi/PPError'
import { PPClientState } from '@/ppapi/PPClient'
import { $t, setAppLocale, Locale } from '@/i18n'
import { PPHttpError } from '@/ppapi/PPHttp'
import { trackEvent } from '@/plugins/GoogleAnalyticsPlugin'
import platform from '@/util/platform'
import User from '@/models/User'
import ServiceAlert, { MessageIds } from './models/ServiceAlert'

Vue.use(Vuex)

export interface ErrorModalState {
  show: boolean
  refresh: boolean
}

export interface RootState {
  locale: Locale
  clientState: PPClientState
  isOffline: boolean
  pageTitle: string
  errorModal: ErrorModalState
  showSessionExpirationModal: boolean
  showTrustedDeviceModal: boolean
}

const state: RootState = {
  locale: Locale.ENGLISH,
  clientState: new PPClientState(),
  isOffline: false,
  pageTitle: 'PillPack',
  errorModal: {
    show: false,
    refresh: true,
  },
  showSessionExpirationModal: false,
  showTrustedDeviceModal: false,
}

const decode = (str: string | (string | null)[]): string => {
  if (str == null) return ''
  const value = typeof str === 'string' ? str : str[0]
  return decodeURIComponent(value || '')
}

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  modules: {
    addresses: addressesModule,
    alerts: serviceAlertsModule,
    allergies: allergiesModule,
    asyncStatus: asyncStatusModule,
    banners: bannersModule,
    conditions: conditionsModule,
    dispensers: dispensersModule,
    insurances: insuranceModule,
    helpCenter: helpCenterModule,
    layout: layoutModule,
    medications: medicationModule,
    orders: ordersModule,
    otcMedications: otcMedicationsModule,
    paymentMethods: paymentMethodsModule,
    pharmacies: pharmaciesModule,
    projectedShipment: projectedShipmentModule,
    physicians: physiciansModule,
    physicianUpdate: physicianUpdateModule,
    supplements: supplementsModule,
    userEvents: userEventsModule,
    user: userModule,
    pwa: pwaModule,
    welcome: welcomeModule,
    tracking: trackingModule,
    addRx: addRxModule,
  },

  mutations: {
    updateClientState(state, newState) {
      state.clientState = newState
    },
    setLocale(state, locale: Locale) {
      state.locale = locale
    },
    setPageTitle(state, title: string) {
      // Append 'PillPack' unless the title is already 'PillPack'
      state.pageTitle = title && title !== 'PillPack' ? `${title} - PillPack` : 'PillPack'
    },
    setOffline(state) {
      state.isOffline = true
    },
    setOnline(state) {
      state.isOffline = false
    },
    showErrorModal(state, refresh: boolean = true) {
      state.errorModal = {
        show: true,
        refresh,
      }
    },
    hideErrorModal(state) {
      state.errorModal = {
        show: false,
        refresh: true,
      }
    },
    showSessionExpirationModal(state) {
      state.showSessionExpirationModal = true
    },
    hideSessionExpirationModal(state) {
      state.showSessionExpirationModal = false
    },
    showTrustedDeviceModal(state) {
      state.showTrustedDeviceModal = true
    },
    hideTrustedDeviceModal(state) {
      state.showTrustedDeviceModal = false
    },
  },

  actions: {
    startSession: trackAsyncStatus(
      'startSession',
      async (): Promise<void> => {
        await Vue.$pillpack.startSession()

        if (Vue.$pillpack.isLoggedIn) {
          sessionMonitor.start()
        }
      },
    ),

    async logIn(
      { dispatch, commit },
      { email, password, captchaToken }: { email: string; password: string; captchaToken: string },
    ): Promise<PPError | null> {
      try {
        if (!Vue.$pillpack.hasSession) {
          await dispatch('startSession')
        }

        const {
          cohort,
          cardholder_id: cardholderId,
          first_name: firstName,
          last_name: lastName,
          birthdate,
        } = router.currentRoute.query

        if (cohort && cardholderId) {
          // The user is coming from signup after trying to claim an account
          // Passing these two params will welcome the user into their given cohort
          await Vue.$pillpack.auth.login({
            email,
            password,
            captchaToken,
            cohort: decode(cohort),
            cardholderId: decode(cardholderId),
            firstName: decode(firstName),
            lastName: decode(lastName),
            birthdate: decode(birthdate),
          })
        } else {
          await Vue.$pillpack.auth.login({ email, password, captchaToken })
        }

        await dispatch('user/loadMe')

        sessionMonitor.start()

        const { redirect_url: redirectUrl } = router.currentRoute.query
        if (redirectUrl) {
          // we have to call toString even though it is a string to avoid this error:
          // Argument of type 'string | (string | null)[]' is not assignable to parameter of type 'RawLocation'.
          const decodedUrl = decode(redirectUrl)
          const { location, resolved } = router.resolve(decodedUrl)
          if (resolved.name === 'not-found') {
            // This avoids passing an external site like "amazon.com" to the redirect_url
            router.replace({ name: 'not-found' })
          } else {
            router.replace(location)
          }
        } else {
          router.replace('/')
        }
      } catch (err) {
        if (err instanceof PPLogInFailedError) {
          return err
        }
        commit('showErrorModal', false)
        throw err
      }
      return null
    },

    async logOut() {
      Vue.$pillpack.clearAssumedUser()
      await Vue.$pillpack.auth.logout()
      window.location.reload()
    },

    resetState({ commit }): Promise<void> {
      commit('asyncStatus/reset', { key: 'startSession' })

      // reset other modules
      commit('user/resetState')
      commit('medications/resetState')

      return Promise.resolve()
    },

    resetPassword: trackAsyncStatus('resetPassword', async (args, { email }) => {
      await Vue.$pillpack.auth.resetPassword(email)
    }),

    editPassword: trackAsyncStatus('editPassword', async ({ dispatch }, { newPassword, token }) => {
      await Vue.$pillpack.auth.editPassword({ newPassword, token })
      await dispatch('startSession')
      router.push({ name: 'home' })
    }),

    updateOnlineStatus({ commit, dispatch, state }): void {
      const condition = navigator.onLine ? 'online' : 'offline'

      const offlineBanner: BannerInfo = {
        id: 'offline-banner',
        title: $t('Offline'),
        message: $t('You are not connected to the internet'),
        bgVariant: 'danger',
      }

      const onlineBanner: BannerInfo = {
        id: 'offline-banner',
        title: $t('Online'),
        message: $t('You are now connected to the internet!'),
        bgVariant: 'success',
        durationMs: 3000,
      }

      // only update the state and show the banner if the previous state has changed
      if (condition === 'offline' && state.isOffline === false) {
        commit('setOffline')
        dispatch('showBanner', offlineBanner)
      } else if (condition === 'online' && state.isOffline === true) {
        commit('setOnline')
        dispatch('showBanner', onlineBanner)
      }
    },

    setTrustedDevice: trackAsyncStatus('setTrustedDevice', async () => {
      await Vue.$pillpack.trustedDevice.setTrustedDevice()
    }),
  },

  getters: {
    isLoggedIn(state): boolean {
      return state.clientState.isLoggedIn
    },
  },
})

eventBus.addEventListener('PWA.showModal', () => {
  store.commit('pwa/toggleModal', true)
})

eventBus.addEventListener('PPClient.SessionReset', () => {
  store.dispatch('resetState')
})

eventBus.addEventListener('PPClient.StateUpdated', e => {
  const newState: PPClientState = (e as any).detail
  store.commit('updateClientState', newState)
})

eventBus.addEventListener('PPClient.LocaleUpdated', e => {
  const locale: Locale = (e as any).detail
  store.commit('setLocale', locale)
})

eventBus.addEventListener('PPClient.ServerError', e => {
  const error: PPHttpError = (e as any).detail

  if (PPHttpError.isGet(error) || error.suppressErrorModal) {
    return
  }

  store.commit('showErrorModal')
})

eventBus.addEventListener('SessionMonitor.SessionExpiringSoon', () => {
  store.commit('showSessionExpirationModal')
})
eventBus.addEventListener('SessionMonitor.SessionExtended', () => {
  store.commit('hideSessionExpirationModal')
})
eventBus.addEventListener('SessionMonitor.SessionExpiration', () => {
  store.commit('hideSessionExpirationModal')
})

eventBus.addEventListener('PPAuth.UntrustedDevice', () => {
  store.commit('showTrustedDeviceModal')
})

eventBus.addEventListener('ServiceWorker.updated', () => {
  const banner: BannerInfo = {
    id: 'refresh-to-update',
    title: $t('New updates available!'),
    message: $t('Refresh your browser to get the best experience'),
    bgVariant: 'primary',
    textVariant: 'white',
    dismissible: true,
    oneTime: false,
    actions: [
      {
        label: $t('Refresh'),
        handler: () => {
          window.location.reload()
        },
      },
    ],
  }

  store.dispatch('showBanner', banner)
})

window.addEventListener('online', () => {
  store.dispatch('updateOnlineStatus')
})
window.addEventListener('offline', () => {
  store.dispatch('updateOnlineStatus')
})

// Side effect: Update document.title
store.watch(
  state => state.pageTitle,
  newTitle => {
    document.title = newTitle
  },
)

// User object loaded
//
// Effects:
// - display identification modal
// - display shipping hold banner
// - display unresolved care relationships banner
// - increment session count
// - set app locale to users locale
// - show welcome to pillpack modal
// - send GA event with userId
// - capture the device properties like pwa/web-auth status

function onUserLoaded(user: User) {
  if (router.currentRoute.name !== 'caregiver') {
    store.dispatch('user/checkForUnfinishedHubCheckout')
  }
  store.dispatch('user/checkIfRequiresIdentification')
  store.dispatch('user/checkIfOnShippingHold')
  store.dispatch('user/checkForUnresolvedCareRelationships')
  platform.incrementAppSessions()
  setAppLocale(user.locale)
  store.dispatch('welcome/showOnboardingTour')

  trackEvent({
    eventCategory: 'cwa_user_access',
    eventAction: 'access',
  })

  Vue.$pillpack.cloudWatch.noteDeviceProperties()
}

function onMessagesLoaded(messages: ServiceAlert[]) {
  messages.forEach(message => {
    if (message.messageId === MessageIds.PayerWelcomeModal) {
      store.dispatch('welcome/showPayerWelcomeModal')
    }
  })
}

store.watch(
  (state, getters) => getters['user/hasUser'],
  (hasUser: boolean) => {
    if (hasUser) {
      onUserLoaded(store.getters['user/currentUser'])
    }
  },
)

store.watch(
  (state, getters) => {
    return getters['alerts/hasMessages'] && getters['user/hasUser']
  },
  (hasMessages: boolean) => {
    if (hasMessages) {
      onMessagesLoaded(store.getters['alerts/allMessages'])
    }
  },
)

export default store
