export default function getApiHost(): string {
  const apiEndpoint = process.env.API_HOST || ''
  const adminEndpoint = process.env.ADMIN_API_HOST || ''

  // If the app is accessed through admin.**.pillpack.com
  // Use a different server for API calls
  // This is used to point admin.app.pillpack.com at admin.pillpack.com
  const useAdmin = window.location.host.startsWith('admin.')
  return useAdmin ? adminEndpoint : apiEndpoint
}
