/* eslint-disable no-useless-constructor */
/* eslint-disable no-empty-function */
import { PPArgs, PPBaseArgs } from './PPClient'
import { PPResponse } from './PPResponse'

type WrapApi<BaseArgs> = {
  <Result>(apiMethod: (baseArgs: BaseArgs) => Promise<PPResponse<Result>>): () => Promise<Result>
  <Result, Args>(apiMethod: (baseArgs: BaseArgs, otherArgs: Args) => Promise<PPResponse<Result>>): (
    args: Args,
  ) => Promise<Result>
}

export default class PPClientModule {
  constructor(
    protected performRequest: <T>(request: (args: PPArgs) => Promise<PPResponse<T>>) => Promise<T>,
    protected performAnonRequest: <T>(
      request: (args: PPBaseArgs) => Promise<PPResponse<T>>,
    ) => Promise<T>,
    protected wrapApi: WrapApi<PPArgs>,
    protected wrapAnonApi: WrapApi<PPBaseArgs>,
  ) {}
}
