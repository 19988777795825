











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class UserInitials extends Vue {
  @Prop(String)
  name?: string

  @Prop({ type: String, default: '1.5em' })
  size!: string

  @Prop(String)
  color?: string

  get backgroundColor() {
    return this.color
  }

  get initials() {
    if (this.name) {
      return this.name
        .split(/\s/) // split all names
        .filter((_, i, { length }) => !i || i === length - 1) // take first and last names
        .map(name => name.charAt(0).toLocaleUpperCase()) // take first letter
        .join('') // merge into string
    }
    return '??'
  }
}
