import { serializable, primitive, identifier, alias } from 'serializr'
import { iso8601Date } from '@/models/transforms'

export default class Bill {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('amount', primitive()))
  amount!: string

  @serializable(alias('adjusted_at', iso8601Date()))
  adjustedAt!: Date

  @serializable(alias('description', primitive()))
  description!: string

  @serializable(alias('fsa_eligible_amount', primitive()))
  fsaEligibleAmount!: string

  @serializable(alias('non_fsa_eligible_amount', primitive()))
  nonFsaEligibleAmount!: string

  get displayAmount() {
    // amounts are always negative
    const amount = (-1 * Number(this.amount)).toFixed(2)

    return `$${amount}`
  }
}
