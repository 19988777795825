import axios, { AxiosResponse, AxiosError } from 'axios'
import { PPError } from './PPError'

export enum PPHttpMethod {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

enum PPHttpStatusCode {
  UNAUTHORIZED = 401,
  NO_CONTENT = 204,
  SERVER_ERROR = 500,
}

export enum PPHttpContentType {
  FORM_URL_ENCODED = 'application/x-www-form-urlencoded; charset=UTF-8',
  JSON = 'application/json; charset=UTF-8',
  MULTIPART_FORM_DATA = 'multipart/form-data',
}

export type PPHttpUrlParams = { [param: string]: string | number | boolean | undefined }
export type PPHttpNameValuePairs = Array<[string, string]>

export class PPHttpError extends PPError {
  readonly status: number
  readonly method: PPHttpMethod
  readonly suppressErrorModal: boolean

  constructor(
    status: number,
    statusText: string,
    method: PPHttpMethod,
    data?: any,
    suppressErrorModal = false,
  ) {
    super(statusText, data)
    this.status = status
    this.method = method
    this.suppressErrorModal = suppressErrorModal
  }

  get isUnauthorized(): boolean {
    return this.status === PPHttpStatusCode.UNAUTHORIZED
  }

  get isServerError(): boolean {
    return this.status >= PPHttpStatusCode.SERVER_ERROR
  }

  static isUnauthorizedError(error: Error): boolean {
    return error instanceof PPHttpError && error.isUnauthorized
  }

  static isServerError(error: Error): boolean {
    return error instanceof PPHttpError && error.isServerError
  }

  static isGet(error: Error): boolean {
    return error instanceof PPHttpError && error.method === PPHttpMethod.GET
  }
}

export class PPNetworkError extends PPError {
  readonly error: Error
  constructor(error: Error) {
    super(error.message)
    this.error = error
  }

  static isNetworkError(error: Error): boolean {
    return error instanceof PPNetworkError
  }
}

export interface PPHttpRequest {
  csrfToken?: string
  method: PPHttpMethod
  baseUrl: string
  path: string
  urlParams?: PPHttpUrlParams
  content?: string | PPHttpNameValuePairs | object
  contentType?: PPHttpContentType
  suppressErrorModal?: boolean
}

export class PPHttpSuccessResponse {
  readonly success = true
  readonly failure = false
  readonly data: any
  readonly headers: any

  constructor(data: any, headers?: any) {
    this.data = data
    this.headers = headers
  }
}

export class PPHttpFailureResponse {
  readonly success = false
  readonly failure = true

  readonly error: PPError

  constructor(error: PPError) {
    this.error = error
  }
}

export type PPHttpResponse = PPHttpSuccessResponse | PPHttpFailureResponse

export class PPHttp {
  static async request(args: PPHttpRequest): Promise<PPHttpResponse> {
    const headers = this.headers(args.csrfToken, args.contentType)
    const body = this.body(args.content)

    try {
      const response = await axios({
        url: args.path,
        baseURL: args.baseUrl,
        withCredentials: true,
        method: args.method,
        headers,
        params: args.urlParams,
        data: body,
      })
      return this.responseFromHttpSuccess(response)
    } catch (error) {
      return this.responseFromHttpError(error, {
        method: args.method,
        suppressErrorModal: args.suppressErrorModal,
      })
    }
  }

  private static headers(csrfToken?: string, contentType?: string): Record<string, string> {
    // https://stackoverflow.com/a/13315210
    const headers: { [s: string]: string } = {}

    headers.Accept = 'application/json'

    if (csrfToken) {
      headers['X-CSRF-Token'] = csrfToken
    }

    if (contentType) {
      headers['Content-Type'] = contentType
    }

    return headers
  }

  private static body(
    content?: string | PPHttpNameValuePairs | object,
  ): null | URLSearchParams | string | object {
    if (!content) {
      return null
    }

    if (content instanceof Array) {
      const body = new URLSearchParams()
      const pairs = <PPHttpNameValuePairs>content
      pairs.forEach(([name, value]) => {
        body.append(name, value)
      })
      return body
    }

    return content
  }

  private static responseFromHttpSuccess(response: AxiosResponse): PPHttpSuccessResponse {
    return new PPHttpSuccessResponse(response.data, response.headers)
  }

  private static responseFromHttpError(
    axiosError: AxiosError,
    { method, suppressErrorModal }: { method: PPHttpMethod; suppressErrorModal?: boolean },
  ): PPHttpFailureResponse {
    const { response } = axiosError
    const error = response
      ? new PPHttpError(
          response.status,
          response.statusText,
          method,
          response.data,
          suppressErrorModal,
        )
      : new PPNetworkError(axiosError)
    return new PPHttpFailureResponse(error)
  }
}
