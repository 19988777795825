import Vue from 'vue'
import { Module } from 'vuex'

import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import Physician from '@/models/Physician'
import SignupPhysician from '@/models/SignupPhysician'

export interface PrescriberSearchQuery {
  lastName: string
  distance: number
  location: string
  firstName?: string
  phone?: string
}

type State = {
  searchQuery: PrescriberSearchQuery
  physicians: Physician[]
  physicianSearchResults: SignupPhysician[]
}

const state: State = {
  searchQuery: {
    lastName: '',
    location: '',
    distance: 10,
    firstName: '',
    phone: '',
  },
  physicians: [],
  physicianSearchResults: [],
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,

  mutations: {
    setSearchQuery(state: State, query: PrescriberSearchQuery) {
      state.searchQuery = query
    },
    setPhysicians(state: State, { physicians }: { physicians: Physician[] }) {
      state.physicians = physicians
    },
    setPhysicianSearchResults(state: State, { physicians }: { physicians: SignupPhysician[] }) {
      state.physicianSearchResults = physicians
    },
    resetPhysicianSearchResults(state: State) {
      state.physicianSearchResults = []
    },
  },

  actions: {
    load: trackAsyncStatus('physicians/load', async function ({ commit }) {
      const physicians = await Vue.$pillpack.physicians.allPhysicians()

      commit('setPhysicians', { physicians })
    }),

    add: trackAsyncStatus(
      'physicians/add',
      async function (
        _,
        {
          physician,
        }: {
          physician: Physician
        },
      ) {
        const savedPhysician = await Vue.$pillpack.physicians.addPhysician({ physician })

        return savedPhysician
      },
    ),

    searchByPhone: trackAsyncStatus(
      'physicians/searchByPhone',
      async function (
        { commit },
        { lastName, phoneNumber }: { lastName: string; phoneNumber: string },
      ) {
        const physicians = await Vue.$pillpack.physicians.searchPhysicians({
          lastName,
          phoneNumber,
        })

        commit('setPhysicianSearchResults', { physicians })
      },
      { mergeRequests: false },
    ),

    searchByLocation: trackAsyncStatus(
      'physicians/searchByLocation',
      async function ({ commit }, query: PrescriberSearchQuery) {
        commit('setSearchQuery', query)
        const physicians = await Vue.$pillpack.physicians.searchPhysiciansByLocation(query)

        commit('setPhysicianSearchResults', { physicians })
      },
      { mergeRequests: false },
    ),
  },
}

export default module
