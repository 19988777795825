import { serializable, primitive, alias, object } from 'serializr'
import OnboardInsuranceCheck from './OnboardInsuranceCheck'

export default class OnboardStatePayload {
  @serializable(primitive())
  priority!: number

  @serializable(primitive())
  revision!: number

  @serializable(primitive())
  editor!: string

  @serializable(alias('insurance_check', object(OnboardInsuranceCheck)))
  insuranceCheck!: OnboardInsuranceCheck
}
