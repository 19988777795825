










import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * This component will lazy load any svg icon in the /assets/icons folder
 * Pass in the name of the icon without "icon-"
 */

@Component
export default class Icon extends Vue {
  @Prop({ type: String, required: true })
  name!: string

  @Prop(Boolean)
  greyStroke!: boolean

  @Prop(Boolean)
  blueStroke!: boolean

  @Prop(String)
  fill!: 'blue' | 'grey'

  get fillClass() {
    if (!this.fill) return ''

    return `${this.fill}-fill`
  }

  icon = () => import(`../assets/icons/icon-${this.name}.svg`)
}
