import { serializable, primitive, alias } from 'serializr'
import { deserializeOnly } from '@/models/transforms'

export default class Dose {
  @serializable(alias('hoa_time', primitive()))
  time!: string

  @serializable(deserializeOnly(primitive()))
  quantity!: number
}
