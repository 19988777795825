


















import { Component, Vue, Prop } from 'vue-property-decorator'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import PacketMedication from '@/models/Today/PacketMedication'
import MedImage from '@/components/medications/MedImage.vue'
import BulkMedication from '@/models/Today/BulkMedication'

@Component({
  components: {
    LinkArrowIcon,
    MedImage,
  },
})
export default class TodaysMedListItem extends Vue {
  @Prop({ type: Object, required: true })
  med!: PacketMedication | BulkMedication

  get link() {
    return {
      name: 'medication-details',
      params: { id: this.med.userMedication.id, type: 'user_medication' },
    }
  }
}
