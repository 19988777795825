import { ActionContext } from 'vuex'
import { AsyncStatusKey } from '@/vuex/asyncStatus'

type ActionPayload = { id?: string } & any

type Action<State, RootState, ReturnValue> = (
  context: ActionContext<State, RootState>,
  payload?: ActionPayload,
) => Promise<ReturnValue>

interface TrackAsyncOptions {
  mergeRequests?: boolean
  throwError?: boolean
}

/**
 * Takes a vuex action and returns a new vuex action.  The
 * new action keeps the behavior of the original but tracks
 * its status.  (Is the action currently running?  Has it
 * completed successfully?  Has it failed with an error?)
 * The status can be found in the asyncStatus module of the vuex
 * store under the key passed into this function.
 * @param action a vuex action
 * @param options
 * @returns a new vuex action
 */

const trackAsyncStatus = <State, RootState, ReturnValue>(
  key: AsyncStatusKey,
  action: Action<State, RootState, ReturnValue>,
  { mergeRequests = true, throwError = false }: TrackAsyncOptions = {
    mergeRequests: true,
    throwError: false,
  },
) =>
  async function (
    context: ActionContext<State, RootState>,
    payload?: ActionPayload,
  ): Promise<ReturnValue | void> {
    const id = payload && payload.id

    if (
      mergeRequests &&
      context.rootGetters &&
      context.rootGetters['asyncStatus/isInProgress'] &&
      context.rootGetters['asyncStatus/isInProgress'](key, id)
    ) {
      return context.rootGetters['asyncStatus/getInFlightRequest'](key, id)
    }

    try {
      const request = action(context, payload)
      context.commit('asyncStatus/start', { key, id, request }, { root: true })
      const results = await request
      context.commit('asyncStatus/succeed', { key, id }, { root: true })
      return results
    } catch (err) {
      context.commit('asyncStatus/fail', { key, id, err }, { root: true })
      if (throwError) {
        throw err
      }
    }

    return undefined
  }

export default trackAsyncStatus
