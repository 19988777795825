import { serializable, alias, primitive, object, list } from 'serializr'
import { ppCoreDate } from '@/models/transforms'
import PrescriptionStrength from './PrescriptionStrength'

enum RequestType {
  TRANSFER = 'transfer_in_request',
  NEW = 'new_prescription_request',
}

export class PrescriptionRequestDetails {
  @serializable(primitive())
  description!: string

  @serializable(alias('med_details', object(PrescriptionStrength)))
  medDetails!: PrescriptionStrength

  @serializable(alias('physician_id', primitive()))
  physicianId!: string

  @serializable(alias('other_physician_info', primitive()))
  otherPhysicianInfo!: string

  @serializable(alias('add_next_pillpack', primitive()))
  addToNextPillpack!: boolean

  @serializable(alias('expected_arrival_date', ppCoreDate()))
  expectedArrivalDate?: Date

  @serializable(alias('other_shipment_notes', primitive()))
  otherShipmentNotes?: string

  @serializable(alias('other_ship_date_selected', primitive()))
  otherShipDateSelected?: boolean

  @serializable(alias('other_requested_ship_date_as_string', ppCoreDate()))
  otherRequestedShipDateAsString?: Date
}

export class TransferInRequestDetails extends PrescriptionRequestDetails {
  @serializable(alias('pharmacy_id', primitive()))
  pharmacyId!: string

  @serializable(alias('is_transfer_in', primitive()))
  isTransferIn!: boolean
}

class MedRequest {
  @serializable(alias('request_type', primitive()))
  requestType?: RequestType
}

export class TransferInRequest extends MedRequest {
  requestType = RequestType.TRANSFER

  @serializable(list(object(TransferInRequestDetails)))
  prescriptions!: TransferInRequestDetails[]

  constructor({ prescription }: { prescription: TransferInRequestDetails }) {
    super()
    this.prescriptions = [prescription]
  }
}

export class NewMedRequest extends MedRequest {
  requestType = RequestType.NEW

  @serializable(list(object(PrescriptionRequestDetails)))
  prescriptions!: PrescriptionRequestDetails[]

  constructor({ prescription }: { prescription: PrescriptionRequestDetails }) {
    super()
    this.prescriptions = [prescription]
  }
}
