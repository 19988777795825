















































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import User from '@/models/User'

@Component({
  components: {
    LinkArrowIcon,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters(['isLoggedIn']),
  },
})
export default class AppFooter extends Vue {
  isLoggedIn!: boolean
  me!: User

  get appLinks() {
    return [
      { to: '/dashboard', text: this.$t('Home') },
      { to: '/medications', text: this.$t('Medications') },
      { to: '/orders', text: this.$t('Orders') },
      { to: '/settings', text: this.$t('Account') },
      { to: '/help', text: this.$t('Help') },
    ]
  }

  get currentYear() {
    return new Date().getFullYear()
  }

  get showLinks() {
    return this.isLoggedIn && !this.me.inAmazonPharmacy
  }
}
