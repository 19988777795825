



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Card from '@/components/Card.vue'
import User from '@/models/User'
import Tour from '@/components/Tour.vue'
import { OnboardingTourSteps } from '@/models/onboardingTour'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import { trackEvent } from '@/plugins/GoogleAnalyticsPlugin'

@Component({
  props: ['data', 'isHelpCenter'],
  components: {
    Card,
    Tour,
    LinkArrowIcon,
  },
  computed: {
    ...mapState('user', ['me']),
  },
  data() {
    return {
      item: OnboardingTourSteps,
    }
  },
})
export default class OnboardingTour extends Vue {
  @Prop(Boolean)
  isHelpCenter!: Boolean

  me!: User

  onDismiss() {
    trackEvent({
      eventCategory: 'cwa_onboarding_tour',
      eventAction: 'cwa_onboarding_tour_dismiss',
    })
    this.$store.dispatch('welcome/hideOnboardingTour', this.me.id)
  }

  showOnboardingTour() {
    if (this.isHelpCenter) {
      trackEvent({
        eventCategory: 'cwa_onboarding_tour',
        eventAction: 'cwa_take_a_tour_helpcenter',
      })
    } else {
      trackEvent({
        eventCategory: 'cwa_onboarding_tour',
        eventAction: 'cwa_onboarding_tour_take_tour',
      })
    }
    this.$bvModal.show('tour-modal')
  }
}
