import { serializable, primitive, alias } from 'serializr'
import { ppCoreDate, deserializeOnly } from '@/models/transforms'

export default class OnboardInsuranceCheck {
  @serializable(primitive())
  complete!: boolean

  @serializable(alias('completed_by', primitive()))
  completedBy!: string

  @serializable(alias('completed_on', deserializeOnly(ppCoreDate())))
  completedOn!: Date
}
