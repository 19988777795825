







import { Component, Vue } from 'vue-property-decorator'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class LogoutView extends Vue {
  async created() {
    if (this.$pillpack.isLoggedIn) {
      await this.$store.dispatch('logOut')
    }
    this.$router.replace('/login')
  }
}
