






import { Component, Vue } from 'vue-property-decorator'
import openZendesk from '@/util/zendesk'

@Component
export default class ChatLink extends Vue {
  openZendesk = openZendesk
}
