import { serializable, primitive, identifier, alias } from 'serializr'
import { optional, deserializeOnly, phoneNumber } from '@/models/transforms'

export enum InsuranceStates {
  Init = 'init',
  Processing = 'processing',
  Verified = 'verified',
  Active = 'active',
  Inactive = 'inactive',
  SoftDeleted = 'soft_deleted',
  Deleted = 'deleted',
  ProcessingVerification = 'processing_verification',
  ProcessingMakeActive = 'processing_make_active',
  ProcessingMakeActivePrimary = 'processing_make_active_primary',
  ProcessingMakeActiveSecondary = 'processing_make_active_secondary',
  ProcessingMakeActiveTertiary = 'processing_make_active_tertiary',
  Terminated = 'terminated',
}

export enum InsuranceProfileTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export default class Insurance {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('number_last_four', optional(primitive())))
  numberLastFour!: string

  @serializable(optional(primitive()))
  description!: string

  @serializable(alias('number', primitive()))
  private _number: string = ''

  get number() {
    return this._number
  }

  set number(val) {
    this._number = val.toUpperCase()
  }

  @serializable(alias('rx_bin', primitive()))
  rxBin!: string

  @serializable(alias('group_number', primitive()))
  private _rxGroup: string = ''

  get rxGroup() {
    return this._rxGroup
  }

  set rxGroup(val) {
    this._rxGroup = val.toUpperCase()
  }

  @serializable(alias('rx_pcn', primitive()))
  private _rxPCN: string = ''

  get rxPCN() {
    return this._rxPCN
  }

  set rxPCN(val) {
    this._rxPCN = val.toUpperCase()
  }

  @serializable(alias('is_good_rx', primitive()))
  isGoodRx!: boolean

  @serializable(deserializeOnly(primitive()))
  state!: InsuranceStates

  @serializable(alias('insurance_profile_type', deserializeOnly(primitive())))
  insuranceProfileType!: InsuranceProfileTypes

  @serializable(alias('phone_number', phoneNumber()))
  phoneNumber!: string

  // This property is sholud be a string from PPCore in YY-MM-DD format.
  // Unlike other date properties we will keep it in that format
  // because the dates that go into and come out of date inputs always follow the `YYYY-MM-DD` format.
  @serializable(alias('effective_start_date', primitive()))
  effectiveStartDate?: string

  get isPrimary(): boolean {
    return this.insuranceProfileType === InsuranceProfileTypes.Primary
  }
}
