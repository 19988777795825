import { serializable, primitive, list, object } from 'serializr'

export enum DispenserType {
  Premium = 'premium',
  Disposable = 'disposable',
}

export enum DispenserColor {
  Blue = 'pdblue30---', // UPC
  Purple = 'pdpurple30-', // UPC
  Green = 'pdgreen30--', // UPC
  Gray = 'pdgray30---', // UPC
}

const hexMap: Record<DispenserColor, string> = {
  [DispenserColor.Blue]: '#45d0f5',
  [DispenserColor.Purple]: '#d3c1e9',
  [DispenserColor.Green]: '#73ce9b',
  [DispenserColor.Gray]: '#e6e7e2',
}

export default class Dispenser {
  @serializable(primitive())
  id!: DispenserColor

  @serializable(primitive())
  name!: string

  get hex() {
    return hexMap[this.id]
  }

  get type() {
    return DispenserType.Premium
  }
}

export class DispenserResponse {
  @serializable(primitive())
  sellingPremium?: boolean

  @serializable(list(object(Dispenser)))
  availableColors?: boolean
}
