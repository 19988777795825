import { render, staticRenderFns } from "./PremiumDispenserCard.vue?vue&type=template&id=ab3539d8&scoped=true&"
import script from "./PremiumDispenserCard.vue?vue&type=script&lang=ts&"
export * from "./PremiumDispenserCard.vue?vue&type=script&lang=ts&"
import style0 from "./PremiumDispenserCard.vue?vue&type=style&index=0&id=ab3539d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab3539d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BCardBody } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BImg } from 'bootstrap-vue'
installComponents(component, {BButton,BCardBody,BFormGroup,BImg})
