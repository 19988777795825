import { serializable, primitive, identifier, alias, list, object } from 'serializr'
import { compareAsc, compareDesc } from 'date-fns'
import Prescription from '@/models/Prescription'
import { deserializeOnly, optional, ppCoreDate } from '@/models/transforms'
import PrescriptionRequest from '@/models/PrescriptionRequest'
import Physician from '@/models/Physician'

export enum MedicationType {
  UserMedication = 'user_medication',
  NewMedication = 'new_medication',
  OtcMedicationRequest = 'otc_medication_request',
  SignupMedication = 'signup_medication',
  SupplementRequest = 'supplement_request',
}

export enum Group {
  Past = 'past',
  Pending = 'pending',
  Active = 'active',
}

export enum PrintCategory {
  Paused = 'Paused',
  Prescribed = 'Prescribed',
  SelfPrescribed = 'SelfPrescribed',
  AsNeeded = 'AsNeeded',
}

export enum AutoRefillActions {
  Start = 'start',
  Resume = 'resume',
  Pause = 'pause',
  None = 'none',
}

export type PastStatus = 'unknown' | 'expired' | 'discontinued'

export default class DashboardMedication {
  @serializable(identifier())
  id!: string

  @serializable(deserializeOnly(primitive()))
  type!: MedicationType

  @serializable(primitive())
  group!: Group

  @serializable(alias('med_description', primitive()))
  description!: string

  @serializable(alias('current_image_url', primitive()))
  currentImageUrl?: string

  @serializable(alias('in_packets', primitive()))
  inPackets?: boolean

  @serializable(alias('icon_name', primitive()))
  iconName?: string

  @serializable(primitive())
  scheduled?: boolean

  @serializable(alias('self_prescribed', primitive()))
  selfPrescribed?: boolean

  @serializable(optional(deserializeOnly(list(object(Prescription)))))
  prescriptions?: Prescription[]

  @serializable(alias('seo_friendly_names', optional(list(primitive()))))
  seoFriendlyNames?: string[]

  @serializable(optional(primitive()))
  ndc9?: string

  @serializable(alias('primary_prescription_id', primitive()))
  primaryPrescriptionId?: string

  @serializable(alias('prescription_request', object(PrescriptionRequest)))
  prescriptionRequest?: PrescriptionRequest

  @serializable(deserializeOnly(object(Physician)))
  physician?: Physician

  @serializable(alias('changes_deadline_date', deserializeOnly(ppCoreDate())))
  changesDeadlineDate?: Date

  @serializable(alias('next_arrival_date', deserializeOnly(ppCoreDate())))
  nextArrivalDate?: Date

  @serializable(primitive())
  discontinued?: boolean

  @serializable(primitive())
  expired?: boolean

  @serializable(alias('is_by_request', primitive()))
  isByRequest?: boolean

  @serializable(alias('remaining_refills', primitive()))
  remainingRefills?: Number

  @serializable(alias('refill_requested', deserializeOnly(primitive())))
  refillRequested!: boolean

  @serializable(alias('is_paused', primitive()))
  isPaused?: boolean

  @serializable(alias('is_ortf', primitive()))
  isOrtf?: boolean

  @serializable(alias('auto_refill', primitive()))
  autoRefill?: boolean

  @serializable(alias('rph_checked', primitive()))
  rphChecked?: boolean

  @serializable(alias('days_supply_preference', primitive()))
  rawDaysSupplyPreference?: number

  @serializable(alias('form_preference', primitive()))
  formPreference?: string

  @serializable(alias('can_pause', primitive()))
  canPause?: boolean

  @serializable(alias('can_resume', primitive()))
  canResume?: boolean

  @serializable(alias('can_delete', primitive()))
  canDelete?: boolean

  @serializable(alias('can_order_refill', primitive()))
  canOrderRefill?: boolean

  @serializable(alias('can_cancel_refill', primitive()))
  canCancelRefill?: boolean

  @serializable(alias('can_order_rush', primitive()))
  canOrderRush?: boolean

  @serializable(alias('can_cancel_rush', primitive()))
  canCancelRush?: boolean

  get daysSupplyPreference() {
    return Number(this.rawDaysSupplyPreference)
  }

  get imageUrl(): string | undefined {
    return (
      this.currentImageUrl &&
      this.currentImageUrl.replace(/(staging2|development|staging|test)/gi, 'production')
    )
  }

  get isPast(): boolean {
    return this.group === Group.Past
  }

  get isPending(): boolean {
    return this.group === Group.Pending
  }

  get medicationType(): string {
    if (this.inPackets) {
      return 'packet'
    } else if (this.iconName) {
      const icon = this.iconName.replace(/\.svg$/, '')
      return icon === 'other' ? 'box' : icon
    } else {
      return 'box'
    }
  }

  get ariaDescription() {
    // Since screen readers spell out words in all-caps letter-by-letter
    // we lowercase the description so it is pronounced as words
    return this.description && this.description.toLocaleLowerCase()
  }

  get primaryPrescription(): Prescription | undefined {
    if (!this.prescriptions?.length) return undefined

    return this.prescriptions.find(rx => rx.id === this.primaryPrescriptionId)
  }

  get autoRefillAction(): AutoRefillActions {
    if (this.group === Group.Pending || this.group === Group.Past) {
      return AutoRefillActions.None
    }

    if (this.isByRequest && this.canOrderRefill) {
      return AutoRefillActions.Start
    }

    if (!this.isByRequest && this.isPaused) {
      return AutoRefillActions.Resume
    }

    if (!this.isByRequest && !this.isPaused) {
      return AutoRefillActions.Pause
    }

    return AutoRefillActions.None
  }

  get mayChangeAutoRefill(): boolean {
    return this.autoRefillAction !== AutoRefillActions.None
  }

  get activePrescriptions(): Prescription[] {
    if (!this.prescriptions?.length) return []

    return this.prescriptions
      .filter(rx => rx.isDispensable)
      .sort((a, b) => {
        // Always put primary rx first
        if (a.id === this.primaryPrescriptionId) return -1
        if (b.id === this.primaryPrescriptionId) return 1
        // otherwise sort by start date
        return compareAsc(a.startDate!, b.startDate!)
      })
  }

  get pastPrescriptions(): Prescription[] {
    if (!this.prescriptions?.length) return []

    return this.prescriptions
      .filter(rx => rx.isPast)
      .sort((a, b) => compareDesc(a.startDate!, b.startDate!))
  }

  get newOrSignupMed(): boolean {
    return [MedicationType.SignupMedication, MedicationType.NewMedication].includes(this.type)
  }
}
