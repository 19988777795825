import { serializable } from 'serializr'

import UserEvent from '@/models/UserEvent'

export enum AlertType {
  AccountOverdue = 'Event::AccountOverdue',
  AccountHoldImminent = 'Event::AccountHoldImminent',
  Announcement = 'Event::AnnouncementAlert',
  AccountHold = 'Event::AccountHold',
  RenewalRequested = 'Event::RenewalRequested',
  DeletedSignupMed = 'Event::DeletedSignupMed',
  OrtfTransfer = 'Event::OrtfTransfer',
  Default = 'ServiceAlertEvent',
}

export enum MessageIds {
  PayerWelcomeModal = 'payer-welcome-modal',
}

export default class ServiceAlert extends UserEvent {
  class!: AlertType

  @serializable
  resolved?: boolean

  get messageId(): MessageIds {
    return this.locals?.['message_id']
  }
}
