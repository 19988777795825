import { render, staticRenderFns } from "./ServiceAlertListItem.vue?vue&type=template&id=4f9e4cb1&scoped=true&"
import script from "./ServiceAlertListItem.vue?vue&type=script&lang=ts&"
export * from "./ServiceAlertListItem.vue?vue&type=script&lang=ts&"
import style0 from "./ServiceAlertListItem.vue?vue&type=style&index=0&id=4f9e4cb1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9e4cb1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BImg } from 'bootstrap-vue'
import { BLink } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
installComponents(component, {BImg,BLink,BListGroupItem,BMedia})
