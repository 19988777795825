/* eslint-disable import/prefer-default-export */

import { $tc } from '@/i18n'
import Order from '@/models/Order'

export const displayCostWithPendingItems = (order: Order) => {
  if (!order.totalAmountDue) return ''

  const cost = [
    order.totalAmountDue,
    order.lineItemsPendingInsurance.length &&
      $tc('{count} pending billing', order.lineItemsPendingInsurance.length),
  ]
    .filter(Boolean)
    .join('+')

  return cost
}
