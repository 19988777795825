import { serializable, primitive, alias, list, object } from 'serializr'
import { stringifiedJsonList } from './transforms'

export class Condition {
  @serializable(primitive())
  id!: string

  @serializable(primitive())
  icd10?: string

  @serializable(alias('short_description', primitive()))
  shortDescription?: string

  @serializable(alias('long_description', primitive()))
  longDescription?: string

  @serializable(primitive())
  name!: string

  @serializable(primitive())
  friendly?: string

  @serializable(alias('display_on_signup', primitive()))
  displayOnSignup?: boolean

  get displayName(): string {
    return this.friendly ?? this.name ?? this.shortDescription ?? this.longDescription
  }
}

export class GetConditions {
  @serializable(list(object(Condition)))
  conditions!: Condition[]

  @serializable(alias('other_conditions', stringifiedJsonList()))
  otherConditions?: string[]
}

export class UpdateOtherConditions {
  @serializable(stringifiedJsonList())
  data?: string[]

  constructor(data: string[]) {
    this.data = data
  }
}
