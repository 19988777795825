import { serializable, alias, primitive, object } from 'serializr'
import PacketUserMedication from '@/models/Today/PacketUserMedication'
import { decimalNumber } from '@/models/transforms'

export default class PacketMedication {
  @serializable(alias('drug_id', primitive()))
  drugId!: string

  @serializable(alias('description', primitive()))
  description!: null | string

  @serializable(alias('drug_primary_name', primitive()))
  drugPrimaryName!: string

  @serializable(alias('short_description', primitive()))
  shortDescription!: string

  @serializable(alias('tab_count', decimalNumber()))
  tabCount!: number

  @serializable(alias('user_medication', object(PacketUserMedication)))
  userMedication!: PacketUserMedication

  get name(): string {
    return this.shortDescription || this.drugPrimaryName
  }
}
