import Vue from 'vue'
import { Module } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { RootState } from '@/store'
import { getLocalStorage, setLocalStorage } from '@/util/localStorage'
import { trackEvent } from '@/plugins/GoogleAnalyticsPlugin'

let bannerIdCounter = 1

export interface BannerAction {
  label: TranslateResult
  to?: string | object
  href?: string
  variant?: BootstrapVue.Variant
  handler?: (component: Vue & { dismiss: () => void }, event: Event) => void
}

export interface BannerInfo {
  message: TranslateResult
  id?: string
  title?: TranslateResult
  bgVariant?: BootstrapVue.Variant
  textVariant?: BootstrapVue.Variant
  dismissible?: boolean
  actions?: BannerAction[]
  durationMs?: number
  oneTime?: boolean
  onDismissed?: () => void
}

const DEFAULT_BANNER = {
  bgVariant: 'primary' as BootstrapVue.Variant,
  textVariant: 'white' as BootstrapVue.Variant,
  dismissible: true,
  durationMs: 0,
}

export const dismissedKey = (bannerId: string) => `banners::${bannerId}::dismissed`

export interface BannersState {
  banners: BannerInfo[]
}
const eventCategory = 'cwa_banner'
const eventActions = {
  show: 'banner_action',
  dismiss: 'banner_dismiss',
}

export const trackBannerEvent = (action: string | TranslateResult, banner?: { id?: string }) => {
  trackEvent({
    eventCategory,
    eventAction: action as string,
    eventLabel: banner?.id,
  })
}

const module: Module<BannersState, RootState> = {
  state: {
    banners: [],
  },
  mutations: {
    pushBanner({ banners }, banner: BannerInfo) {
      banners.push(banner)
    },
    updateBanner({ banners }, { id, banner }) {
      const index = banners.findIndex(b => b.id === id)
      if (index >= 0) {
        banners.splice(index, 1, banner)
      }
    },
    storeDismissedIfOneTime({ banners }, bannerId) {
      const banner = banners.find(b => b.id === bannerId)
      if (banner && banner.oneTime) {
        setLocalStorage(dismissedKey(bannerId), new Date())
      }
    },
    removeBanner({ banners }, bannerId) {
      const index = banners.findIndex(b => b.id === bannerId)
      if (index >= 0) {
        banners.splice(index, 1)
      }
    },
    removeAll(state) {
      state.banners = []
    },
  },
  actions: {
    showBanner({ commit, state }, banner: BannerInfo) {
      banner = { ...DEFAULT_BANNER, ...banner }
      if (!banner.id) {
        banner.id = `banner-${bannerIdCounter}`
        bannerIdCounter += 1
      }

      const exists = state.banners.some(b => b.id === banner.id)
      if (exists) {
        commit('updateBanner', { id: banner.id, banner })
        return
      }

      if (banner.oneTime && getLocalStorage(dismissedKey(banner.id), null)) return
      trackBannerEvent(eventActions.show, banner)
      commit('pushBanner', banner)
    },
    dismissBanner({ commit }, { id }: { id: string }) {
      trackBannerEvent(eventActions.dismiss, { id })
      commit('storeDismissedIfOneTime', id)
      commit('removeBanner', id)
    },
    dismissAllBanners({ commit }) {
      commit('removeAll')
    },
  },
  getters: {
    allBanners({ banners }) {
      return banners
    },
    temporaryBanners({ banners }) {
      return banners.filter(b => b.durationMs)
    },
    dismissibleBanners({ banners }) {
      return banners.filter(b => b.dismissible)
    },
    permanentBanners({ banners }) {
      return banners.filter(b => !b.dismissible && !b.durationMs)
    },
  },
}

export default module
