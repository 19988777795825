import { serializable, alias, list, object } from 'serializr'
import BulkMedication from '@/models/Today/BulkMedication'
import PacketSet from '@/models/Today/PacketSet'
import Packet from '@/models/Today/Packet'

export default class Today {
  @serializable(alias('packets', list(object(Packet))))
  packets!: Packet[]

  @serializable(alias('bulks', list(object(BulkMedication))))
  bulks!: BulkMedication[]

  @serializable(alias('packet_set', object(PacketSet)))
  packetSet!: PacketSet
}
