import sleep from './sleep'

declare global {
  interface Window {
    zESettings?: object
    zEACLoaded?: boolean
    zE?: {
      (action: 'webWidget', command: string): void
      (action: 'webWidget:on', event: string, callback: Function): void
    }
    zCsrfToken?: string
    zUserId?: string
  }
}

// memoize zendeskHasLoaded
let zendeskLoadFailed = false

export async function zendeskHasLoaded(count: number = 1): Promise<Boolean> {
  const maxAttempts = 50
  if (zendeskLoadFailed) {
    return false
  } else if (count > maxAttempts) {
    zendeskLoadFailed = true
    return false
  } else if (!window.zE || !window.zEACLoaded) {
    await sleep(100)
    return zendeskHasLoaded(count + 1)
  } else {
    return true
  }
}

export default async function openZendesk() {
  const loaded = await zendeskHasLoaded()
  if (loaded && window.zE) {
    window.zE('webWidget', 'open')
  } else {
    throw new Error('Could not open Zendesk Widget')
  }
}
