import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize } from '@/models/transforms'
import Order from '@/models/Order'
import GetPastOrdersResponse from '@/models/GetPastOrdersResponse'
import GetCurrentOrdersResponse from '@/models/GetCurrentOrdersResponse'
import OrderPreferences from '@/models/OrderPreferences'
import { PPArgs } from './PPClient'

export default class PPOrdersApi {
  static async current({
    session,
    baseUrl,
    userId,
    locale,
  }: PPArgs): Promise<PPResponse<GetCurrentOrdersResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/consumer_api/users/${userId}/orders/current`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserialize(GetCurrentOrdersResponse, response.data)
    return new PPSuccessResponse(results)
  }

  static async past(
    { baseUrl, session, userId, locale }: PPArgs,
    nextPageToken?: string,
  ): Promise<PPResponse<GetPastOrdersResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/consumer_api/users/${userId}/orders/past`,
      urlParams: {
        locale,
        next_page_token: nextPageToken,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserialize(GetPastOrdersResponse, response.data)
    return new PPSuccessResponse(results)
  }

  static async getOrder(
    { baseUrl, session, userId, locale }: PPArgs,
    id: string,
  ): Promise<PPResponse<Order>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/consumer_api/users/${userId}/orders/${id}`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const order = deserialize(Order, response.data.order)
    return new PPSuccessResponse(order)
  }

  static async preferences({
    baseUrl,
    session,
    userId,
    locale,
  }: PPArgs): Promise<PPResponse<OrderPreferences>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/consumer_api/users/${userId}/orders/preferences`,
      urlParams: {
        locale,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const preferences = deserialize(OrderPreferences, response.data)
    return new PPSuccessResponse(preferences)
  }
}
