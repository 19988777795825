import Vue from 'vue'
import { Module } from 'vuex'

import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import Pharmacy, { PharmacySearchResult } from '@/models/Pharmacy'

type State = {
  pharmacies: Pharmacy[]
  pharmacyResults: Pharmacy[]
}

const state: State = {
  pharmacies: [],
  pharmacyResults: [],
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,

  mutations: {
    setPharmacies(state: State, { pharmacies }: { pharmacies: Pharmacy[] }) {
      state.pharmacies = pharmacies
    },
    setPharmacyResults(state: State, { pharmacies }: { pharmacies: Pharmacy[] }) {
      state.pharmacyResults = pharmacies
    },
  },

  actions: {
    load: trackAsyncStatus('pharmacies/load', async function ({ commit }) {
      const pharmacies = await Vue.$pillpack.pharmacies.all()

      commit('setPharmacies', { pharmacies })
    }),

    search: trackAsyncStatus(
      'pharmacies/search',
      async function ({ commit }, { name, zipcode }: { name: string; zipcode: string }) {
        const pharmacies = await Vue.$pillpack.pharmacies.search({ name, zipcode })
        commit('setPharmacyResults', { pharmacies })
      },
    ),

    getDetails: trackAsyncStatus(
      'pharmacies/getDetails',
      async function (_, { pharmacy }: { pharmacy: PharmacySearchResult }) {
        return Vue.$pillpack.pharmacies.getDetails({ pharmacy })
      },
    ),

    add: trackAsyncStatus(
      'pharmacies/add',
      async function ({ dispatch, state }, { pharmacy }: { pharmacy: Pharmacy }) {
        const existing = state.pharmacies.find(
          pharm => pharm.formattedAddress === pharmacy.formattedAddress,
        )

        const savedPharmacy = existing || (await Vue.$pillpack.pharmacies.add({ pharmacy }))

        await dispatch('load')

        return savedPharmacy
      },
    ),
  },
}

export default module
