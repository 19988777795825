import { alias, serializable, primitive, identifier, list, object, map } from 'serializr'
import { deserializeOnly, ppCoreDate } from '@/models/transforms'
import getUserColor from '@/util/userColor'
import UserPreferences from '@/models/UserPreferences'
import SmsPhoneNumber from '@/models/SmsPhoneNumber'
import CareRelationship from '@/models/CareRelationship'
import HubCheckout from '@/models/HubCheckout'
import Account from '@/models/Account'
import Address from '@/models/Address'
import { Locale } from '@/i18n'
import { PILLPACK_PHONE, AEB_PHONE, CERPASS_PHONE, HORIZON_PHONE } from '@/util/phoneNumber'

const DEFAULT_COLOR = '#0074C0'

export enum UserStep {
  FIRST_SIGN_UP_STEP = 'address',
  FINISHED_SIGN_UP_STEP = 'welcome',
  ONBOARDING_CONFIRMED_STEP = 'confirmed',
  ONBOARDING_ON_HOLD_STEP = 'onhold',
  ACTIVE_USER_STEP = 'onboarded',
  CHURNED_USER_STEP = 'churned',
}

export default class User {
  @serializable(identifier())
  id!: string

  @serializable(alias('full_name', primitive()))
  fullName!: string

  @serializable(alias('first_name', primitive()))
  firstName?: string

  @serializable(alias('last_name', primitive()))
  lastName?: string

  @serializable(alias('preferred_name', primitive()))
  preferredName?: string

  @serializable(alias('raw_preferred_name', primitive()))
  rawPreferredName?: string

  @serializable(primitive())
  locale?: Locale

  @serializable(primitive())
  email?: string

  @serializable(alias('phone_number', primitive()))
  phoneNumber?: string

  @serializable(deserializeOnly(ppCoreDate()))
  birthdate!: Date

  @serializable(primitive())
  female!: boolean

  @serializable(alias('email_confirmed', primitive()))
  confirmed?: boolean

  @serializable(alias('signed_up', primitive()))
  signedUp?: boolean

  @serializable(alias('has_identification', primitive()))
  hasIdentification?: boolean

  @serializable(alias('require_identification', primitive()))
  requireIdentification?: boolean

  @serializable(primitive())
  shippable!: boolean

  @serializable(alias('shipping_address', deserializeOnly(object(Address))))
  shippingAddress?: Address

  @serializable(alias('sent_first_shipment', primitive()))
  sentFirstShipment!: boolean

  @serializable(primitive())
  onboarded!: boolean

  @serializable(primitive())
  onboarding!: boolean

  @serializable(alias('show_welcome_tour', primitive()))
  showWelcomeTour!: boolean

  @serializable(alias('hub_checkout', deserializeOnly(object(HubCheckout))))
  hubCheckout!: HubCheckout

  @serializable(alias('current_step', primitive()))
  currentStep!: UserStep

  @serializable(alias('in_amazon_pharmacy_cohort', primitive()))
  inAmazonPharmacy!: boolean

  @serializable(alias('on_shipping_hold', primitive()))
  onShippingHold!: boolean

  @serializable(alias('caregiver_relationships', deserializeOnly(list(object(CareRelationship)))))
  caregivers!: CareRelationship[]

  @serializable(
    alias('carereceiver_relationships', deserializeOnly(list(object(CareRelationship)))),
  )
  careReceivers!: CareRelationship[]

  @serializable(deserializeOnly(object(UserPreferences)))
  preferences?: UserPreferences

  @serializable(deserializeOnly(object(Account)))
  account?: Account

  @serializable(deserializeOnly(map(primitive())))
  cohorts?: { [name: string]: boolean }

  @serializable(alias('sms_phone_number', deserializeOnly(object(SmsPhoneNumber))))
  smsPhoneNumber?: SmsPhoneNumber

  @serializable(alias('is_on_orders_billing', primitive()))
  isOnOrdersBilling?: boolean

  @serializable(alias('uses_medication_fill_preferences', primitive()))
  usesMedicationFillPreferences?: boolean

  @serializable(primitive())
  ssn?: string

  // If this true, the users account is being assumed by their caregiver
  isAssumedUser?: boolean

  get isCareGiver(): boolean {
    return !!this.careReceivers && !!this.careReceivers.length
  }

  get color() {
    // The authenticated user is always the same color
    return this.isAssumedUser ? getUserColor(this.id) : DEFAULT_COLOR
  }

  get hasPremiumDispenser(): boolean {
    return this?.preferences?.dispenser === 'premium'
  }

  get isHubCheckout(): boolean {
    return !!this.hubCheckout?.isHubCheckout
  }

  get otcCatalogV2Enabled(): boolean {
    return !!this?.preferences?.otcCatalogV2Enabled
  }

  get rxCatalogV2Enabled(): boolean {
    return !!this?.preferences?.rxCatalogV2Enabled
  }

  get canPayOnlyOverdueBalance(): boolean {
    const overdueBalance = this.account?.currentOrdersOverdueBalance
    const owedBalance = this.account?.currentOwedBalance

    return !!(overdueBalance && Number(overdueBalance) > 0 && overdueBalance !== owedBalance)
  }

  get supportPhone() {
    if (this.cohorts?.aeb) return AEB_PHONE
    if (this.cohorts?.cerpass) return CERPASS_PHONE
    // eslint-disable-next-line camelcase
    if (this.cohorts?.horizon_bcbs) return HORIZON_PHONE
    return PILLPACK_PHONE
  }

  get isInPayerCohort() {
    return this.cohorts && (this.cohorts.aeb || this.cohorts.cerpass || this.cohorts.horizon_bcbs)
  }
}
