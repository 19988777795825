import { serializable, primitive, identifier, alias } from 'serializr'

export default class SupplementStrength {
  @serializable(alias('id', identifier()))
  id!: string

  @serializable(alias('name', primitive()))
  name!: string

  @serializable(alias('in_packets', primitive()))
  inPackets!: boolean

  @serializable(alias('container_type', primitive()))
  containerType!: string

  @serializable(alias('dose_type', primitive()))
  doseType!: string
}
