import PPSession from './PPSession'
import { PPHttp, PPHttpMethod, PPHttpContentType } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserializeArray } from '@/models/transforms'
import UserEvent from '@/models/UserEvent'

export default class PPEventsApi {
  static async get({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<UserEvent[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/events`,
      urlParams: { locale, unacknowledged: true },
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const userEvents: UserEvent[] = deserializeArray(UserEvent, response.data.events)

    return new PPSuccessResponse(userEvents)
  }

  static async acknowledge({
    baseUrl,
    session,
    userId,
    locale,
    userEventId,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    userEventId: string
  }): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/events/${userEventId}/acknowledge`,
      urlParams: { locale },
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return new PPSuccessResponse(undefined)
  }
}
