import PPSession from './PPSession'
import { PPError, PPLogInFailedError } from './PPError'
import { PPHttp, PPHttpMethod, PPHttpContentType, PPHttpError } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import eventBus from '@/util/EventBus'

export default class PPAuthApi {
  static async getSession({ baseUrl }: { baseUrl: string }): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      method: PPHttpMethod.GET,
      baseUrl,
      path: '/consumer_api/session',
    })

    if (response.failure) {
      if (response.error.status === 503) {
        document.body.classList.add('app-maintenance')
      }
      return new PPFailureResponse(response.error)
    }

    const session = PPSession.fromObject(response.data)
    if (!session) {
      throw new PPError('Failed to create session.')
    }
    window.zCsrfToken = session.csrfToken
    window.zUserId = session.userId
    return PPSuccessResponse.withSession(session)
  }

  static async login({
    baseUrl,
    session,
    email,
    captchaToken,
    password,
    cohort,
    cardholderId,
    firstName,
    lastName,
    birthdate,
  }: {
    baseUrl: string
    session: PPSession
    email: string
    password: string
    captchaToken: string
    cohort?: string
    cardholderId?: string
    firstName?: string
    lastName?: string
    birthdate?: string
  }): Promise<PPResponse<void>> {
    const content = [
      ['user[email]', email],
      ['user[password]', password],
      ['authenticity_token', session.csrfToken], // XXX login appears to require csrf token here?
    ]

    content.push(['captcha_token', captchaToken])

    if (cohort && cardholderId) {
      content.push(['cohort', cohort])
      content.push(['cardholder_id', cardholderId])

      if (firstName) content.push(['first_name', firstName])
      if (lastName) content.push(['last_name', lastName])
      if (birthdate) content.push(['birthdate', birthdate])
    }

    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: '/consumer_api/login',
      content,
      contentType: PPHttpContentType.FORM_URL_ENCODED,
    })

    if (response.failure) {
      const error = response.error

      if (PPHttpError.isUnauthorizedError(error)) {
        const loginFailedError = new PPLogInFailedError(<PPError>response.error)
        return new PPFailureResponse(loginFailedError)
      }

      return new PPFailureResponse(response.error)
    }

    const trustedDeviceHeader = response.headers['trusted-device']

    if (trustedDeviceHeader === 'false') {
      eventBus.dispatchEvent('PPAuth.UntrustedDevice')
    }

    const nextSession = PPSession.fromObject(response.data)

    if (!nextSession) {
      throw new PPError('Unexpected error logging in')
    }

    window.zCsrfToken = nextSession.csrfToken
    window.zUserId = nextSession.userId
    return PPSuccessResponse.withSession(nextSession)
  }

  static async logout({
    baseUrl,
    session,
  }: {
    baseUrl: string
    session: PPSession
  }): Promise<PPResponse<void>> {
    // XXX logout appears to not require csrf token?
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.DELETE,
      baseUrl,
      path: '/consumer_api/logout',
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const nextSession = PPSession.fromObject(response.data)
    if (!nextSession) {
      throw new PPError('Unexpected error logging out')
    }
    return PPSuccessResponse.withSession(nextSession)
  }

  static async resetPassword({
    baseUrl,
    session,
    email,
  }: {
    baseUrl: string
    session: PPSession
    email: string
  }): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: '/password',
      content: [['user[email]', email]],
      contentType: PPHttpContentType.MULTIPART_FORM_DATA,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    return PPSuccessResponse.voidResponse()
  }

  static async editPassword({
    baseUrl,
    session,
    token,
    newPassword,
  }: {
    baseUrl: string
    session: PPSession
    token: string
    newPassword: string
  }): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.PUT,
      baseUrl,
      path: '/password',
      content: [
        ['user[password]', newPassword],
        ['user[reset_password_token]', token],
      ],
      contentType: PPHttpContentType.MULTIPART_FORM_DATA,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    return PPSuccessResponse.voidResponse()
  }
}
