import type { PutMetricDataInput } from 'aws-sdk/clients/cloudwatch'
import PPClientModule from './PPClientModule'
import PPCloudWatchApi from './PPCloudWatchApi'
import TrackingHistory from '@/models/Tracking/TrackingHistory'
import TrackingEvent from '@/models/Tracking/TrackingEvent'
import { PPSuccessResponse } from './PPResponse'
import platform from '@/util/platform'
import Shipment from '@/models/Shipment'

// used to prevent us from spamming the same tracking event translations over and over
const trackedHistories: Set<string> = new Set()
const trackedEventDescriptions: Set<string> = new Set()

export default class PPCloudWatchClient extends PPClientModule {
  static Namespace = 'ConsumerTech/CWA'
  static DefaultValue = 1
  static DefaultUnit = 'Count'

  noteEventDescriptions(history: TrackingHistory) {
    return this.performRequest(args => {
      if (
        trackedHistories.has(history.trackingNumber) ||
        trackedHistories.has(history.containerId)
      ) {
        return Promise.resolve(new PPSuccessResponse({}))
      }

      const event: PutMetricDataInput = {
        Namespace: PPCloudWatchClient.Namespace,
        MetricData: history.trackingEvents
          .filter(evt => !evt.isSynthetic)
          .map(evt => {
            return {
              MetricName: 'TrackingEvent',
              Unit: PPCloudWatchClient.DefaultUnit,
              Value: PPCloudWatchClient.DefaultValue,
              Dimensions: evt.cloudWatchDimensions,
            }
          }),
      }

      trackedHistories.add(history.containerId)
      trackedHistories.add(history.trackingNumber)

      return PPCloudWatchApi.putMetricData(args, event)
    })
  }

  noteMissingEventDescription(evt: TrackingEvent) {
    return this.performRequest(args => {
      if (trackedEventDescriptions.has(evt.eventDescriptionTranslatorKey)) {
        return Promise.resolve(new PPSuccessResponse({}))
      }

      const event: PutMetricDataInput = {
        Namespace: PPCloudWatchClient.Namespace,
        MetricData: [
          {
            MetricName: 'MissingEventDescription',
            Unit: PPCloudWatchClient.DefaultUnit,
            Value: PPCloudWatchClient.DefaultValue,
            Dimensions: evt.cloudWatchDimensions,
          },
        ],
      }

      trackedEventDescriptions.add(evt.eventDescriptionTranslatorKey)

      return PPCloudWatchApi.putMetricData(args, event)
    })
  }

  noteShipmentProperties(shipments: Shipment[]) {
    return this.performRequest(args => {
      const event: PutMetricDataInput = {
        Namespace: PPCloudWatchClient.Namespace,
        MetricData: [
          ...shipments.map(shipment => ({
            MetricName: 'ShipmentProperties',
            Unit: PPCloudWatchClient.DefaultUnit,
            Value: PPCloudWatchClient.DefaultValue,
            Dimensions: [
              { Name: 'is_amazon_carrier', Value: this.getBooleanString(shipment.amazonCarrier) },
              { Name: 'has_container_id', Value: this.getBooleanString(shipment.containerId) },
              { Name: 'has_tracking_id', Value: this.getBooleanString(shipment.trackingId) },
              { Name: 'delivery_problem', Value: this.getBooleanString(shipment.deliveryProblem) },
              { Name: 'is_projected', Value: this.getBooleanString(shipment.isProjected) },
            ],
          })),
        ],
      }

      return PPCloudWatchApi.putMetricData(args, event)
    })
  }

  async noteDeviceProperties() {
    // No PHI just device properties
    const canUseWebAuth = await platform.canUseWebAuth()
    const isPWA = platform.isPWA

    return this.performRequest(args => {
      const event: PutMetricDataInput = {
        Namespace: PPCloudWatchClient.Namespace,
        MetricData: [
          {
            MetricName: 'UserDeviceProperties',
            Unit: PPCloudWatchClient.DefaultUnit,
            Value: PPCloudWatchClient.DefaultValue,
            Dimensions: [
              { Name: 'is_web_auth_available', Value: this.getBooleanString(canUseWebAuth) },
              { Name: 'is_pwa_installed', Value: this.getBooleanString(isPWA) },
            ],
          },
        ],
      }

      return PPCloudWatchApi.putMetricData(args, event)
    })
  }

  async noteLatency(method: string, time: number) {
    return this.performRequest(args => {
      const event: PutMetricDataInput = {
        Namespace: PPCloudWatchClient.Namespace,
        MetricData: [
          {
            MetricName: `ApiLatency_${method}`,
            Unit: PPCloudWatchClient.DefaultUnit,
            Value: time,
          },
        ],
      }

      return PPCloudWatchApi.putMetricData(args, event)
    })
  }

  private getBooleanString(value: any) {
    return value ? 'true' : 'false'
  }
}
