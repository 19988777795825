import PPClient from '@/ppapi/PPClient'
import { PILLPACK_PHONE, PILLPACK_FAX } from '@/util/phoneNumber'
import getApiHost from '@/util/apiHost'

const client = new PPClient(getApiHost())

export default {
  install(Vue: any) {
    Vue.prototype.$pillpackPhone = PILLPACK_PHONE
    Vue.prototype.$pillpackFax = PILLPACK_FAX
    Vue.prototype.$pillpack = client
    Vue.$pillpack = client
  },
}
