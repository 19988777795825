import { serializable, primitive, identifier, alias, list, object } from 'serializr'
import { isBefore } from 'date-fns'
import { deserializeOnly, ppCoreDate } from '@/models/transforms'
import OrderLineItem from '@/models/OrderLineItem'
import OrderPaymentItem from '@/models/OrderPaymentItem'
import Address from '@/models/Address'
import Shipment from '@/models/Shipment'
import ShipmentInfo from '@/models/ProjectedShipment/ShipmentInfo'

export default class Order {
  @serializable(alias('order_id', identifier()))
  id!: string

  @serializable(alias('status', primitive()))
  status!: string

  @serializable(alias('total_amount_due_string', primitive()))
  totalAmountDue?: string

  @serializable(alias('delivered_at', deserializeOnly(ppCoreDate())))
  deliveredAt?: Date

  @serializable(alias('delivery_problem', deserializeOnly(primitive())))
  deliveryProblem?: boolean

  @serializable(object(ShipmentInfo))
  shipmentInfo?: ShipmentInfo

  @serializable(list(object(Shipment)))
  shipments!: Shipment[]

  @serializable(alias('changes_deadline_date', deserializeOnly(ppCoreDate())))
  changesDeadlineDate?: Date

  @serializable(alias('line_items', deserializeOnly(list(object(OrderLineItem)))))
  lineItems?: OrderLineItem[]

  @serializable(alias('payment_items', deserializeOnly(list(object(OrderPaymentItem)))))
  paymentItems?: OrderPaymentItem[]

  @serializable(alias('print_invoice_url', deserializeOnly(primitive())))
  printInvoiceUrl?: string

  @serializable(alias('shipping_address', deserializeOnly(object(Address))))
  shippingAddress?: Address

  @serializable(alias('tracking_url', primitive()))
  trackingUrl?: string

  @serializable(alias('has_credit_applied', primitive()))
  hasCreditApplied?: boolean

  @serializable(alias('total_credit_applied_string', primitive()))
  totalCreditAppliedString?: string

  @serializable(alias('has_credit_earned', primitive()))
  hasCreditEarned?: number

  @serializable(alias('total_credit_earned_string', primitive()))
  totalCreditEarnedString?: string

  @serializable(alias('has_contribution_from_checks', primitive()))
  hasContributionFromChecks?: number

  @serializable(alias('total_contribution_from_checks_string', primitive()))
  totalContributionFromChecksString?: string

  get canMakeChanges(): boolean {
    if (!this.changesDeadlineDate) return false

    const today = new Date()

    return (
      (this.status === 'upcoming' || this.status === 'preparing') &&
      isBefore(today, this.changesDeadlineDate)
    )
  }

  get hasMultipleShipments() {
    return this.shipments.length > 1
  }

  get lineItemsCount() {
    if (!this.lineItems) return 0

    return this.lineItems.length
  }

  get lineItemsPendingInsurance() {
    if (!this.lineItems) return []

    return this.lineItems.filter(item => item.costIsPending)
  }

  get lineItemsWithCost() {
    if (!this.lineItems) return []

    return this.lineItems.filter(item => !item.costIsPending)
  }

  get lineItemsInShipment() {
    if (!this.lineItems) return []

    return this.lineItems.filter(item => item.inShipment)
  }

  get hasPackets(): boolean {
    if (!this.lineItems) return false

    return !!this.lineItems.find(
      item => item.dashboardMedication && item.dashboardMedication.inPackets,
    )
  }

  showFallbackTracking: boolean = false
}
