import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { setHours } from 'date-fns'
import { $t, $te, trackingEventDateString } from '@/i18n'
import TrackingEvent, { TrackingEventState } from '@/models/Tracking/TrackingEvent'
import TrackingHistory from '@/models/Tracking/TrackingHistory'
import TrackingAddress from '@/models/Tracking/TrackingAddress'
import { capitalizeEachWord } from '@/util/strings'
import { clone } from '@/models/transforms'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import Shipment from '@/models/Shipment'

export enum TerminalStatuses {
  UNDELIVERABLE,
  PARTIAL_DELIVERED,
  DELIVERED,
  AVAILABLE_FOR_PICKUP,
  RETURNING,
  RETURNED,
  CUSTOMER_ACTION,
}

export enum RepeatableStatuses {
  DELAYED,
  RETURNING,
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
}

interface EventParams {
  eventDate?: Date
  shortStatus: TrackingEventState
  subText: TranslateResult
  translation: TranslateResult
}

const upperCase = (str?: string) => str?.toUpperCase()

function getAddress(address?: TrackingAddress) {
  if (!address) return ''
  const { city, state, country } = address
  if ([city, state].map(upperCase).includes('NA')) return ''
  return [capitalizeEachWord(city), state || country].filter(Boolean).join(', ')
}

function generateEvent({
  eventDate,
  shortStatus,
  subText,
  translation,
}: EventParams): TrackingEvent {
  const event = new TrackingEvent()
  event.eventDate = eventDate
  event.shortStatus = shortStatus
  event.translation = translation
  event.subText = subText
  return event
}

function generateOrderEvent(shipment: Shipment) {
  // set time to 10am to avoid off-hours date logic
  const eventDate = shipment?.createdAt ? setHours(shipment.createdAt, 10) : undefined
  const shortStatus = RepeatableStatuses[RepeatableStatuses.IN_TRANSIT] as TrackingEventState
  const translation = eventDate
    ? $t('_shipTracking.orderedOn', { date: trackingEventDateString(eventDate).short })
    : $t('_shipTracking.ordered')
  const subText = ''
  return generateEvent({
    eventDate,
    shortStatus,
    translation,
    subText,
  })
}

function generateArrivalEvent(trackingHistory: TrackingHistory) {
  const eventEstimate = { dateTime: trackingHistory.bestArrivalEstimateDate } ||
    trackingHistory.bestArrivalEstimateTrackingDate || {
      dateTime: trackingHistory.promisedArrivalDate,
    }
  if (!eventEstimate) return null
  const translation = $t('_shipTracking.arriving', {
    date: trackingHistory.dateTimeMessage,
  })
  const subText = getAddress(trackingHistory.shippingAddress)
  const shortStatus = RepeatableStatuses[RepeatableStatuses.IN_TRANSIT] as TrackingEventState
  // save arrival messaging to trackingHistory
  trackingHistory.arrivalMessage = translation

  return generateEvent({
    eventDate: eventEstimate.dateTime,
    shortStatus,
    translation,
    subText,
  })
}

function translationFallback(event: TrackingEvent): TranslateResult | undefined {
  let str: TranslateResult | undefined

  switch (event.shortStatus) {
    case 'DELIVERY_ATTEMPTED':
      str = $t('_shipTracking.deliveryAttempted')
      break
    case 'OUT_FOR_DELIVERY':
      str = $t('_shipTracking.outForDelivery')
      break
    case 'DELIVERED':
      str = $t('_shipTracking.delivered')
      break
    case 'IN_TRANSIT':
      str = $t('_shipTracking.inTransit')
      break
    case 'DELAYED':
      str = $t('_shipTracking.delayed')
      break
    case 'CUSTOMER_ACTION':
      str = $t('_shipTracking.actionRequired')
      break
    case 'UNDELIVERABLE':
      str = $t('_shipTracking.undeliverable')
      break
    case 'PARTIAL_DELIVERED':
      str = $t('_shipTracking.partiallyDelivered')
      break
    case 'AVAILABLE_FOR_PICKUP':
      str = $t('_shipTracking.availableForPickup')
      break
    case 'RETURNING':
      str = $t('_shipTracking.returning')
      break
    case 'RETURNED':
      str = $t('_shipTracking.returned')
      break
    case 'PICKUP_ATTEMPTED':
      str = $t('_shipTracking.pickupAttempted')
      break
    case 'PICKUP_CANCELLED':
      str = $t('_shipTracking.pickupCancelled')
      break
    case 'PICKUP_SCHEDULED':
      str = $t('_shipTracking.pickupScheduled')
      break
    case 'PICKUP_SUCCESSFUL':
      str = $t('_shipTracking.pickupSuccessful')
      break
    case 'RETURN_REQUEST_ACCEPTED':
      str = $t('_shipTracking.returnRequestAccepted')
      break
    case 'RETURN_RECEIVED_IN_FC':
      str = $t('_shipTracking.returnReceived')
      break
    case 'REFUND_ISSUED':
      str = $t('_shipTracking.refundIssued')
      break
    case 'UNDISPLAYED':
      break
    case '':
      break
    case undefined:
      break
    default:
      throw new UnreachableCaseError(event.shortStatus)
  }

  return str
}

function noteMissingEventDescription(event: TrackingEvent) {
  Vue.$pillpack.cloudWatch.noteMissingEventDescription(event)

  const error = new Error(`missing translation for: ${event.eventDescriptionTranslatorKey}`)
  console.error(error) // eslint-disable-line no-console
  Vue.$newrelic?.noticeError(error)
}

/*
  We don't wan't to show the customer ALL of the shipping events (by default), because they just
  include too much detail. We also want to know ahead of time if we are missing an English
  translation for an eventDescriptionTranslatorKey, so we can hide that step and page our team.
*/
export default function processTrackingHistory(
  trackingHistory: TrackingHistory,
  shipment: Shipment,
): TrackingHistory {
  const history = clone(TrackingHistory, trackingHistory)
  const { trackingEvents } = history
  let previousEvent: TrackingEvent
  let isTerminalStatus = false

  const processedEvents = trackingEvents.map(
    (trackingEvent: TrackingEvent, index): TrackingEvent => {
      const { eventDescriptionTranslatorKey: key } = trackingEvent
      const isFirstEvent = index === trackingEvents.length - 1 // they are in reverse order

      if (
        isFirstEvent &&
        trackingEvent.shortStatus === RepeatableStatuses[RepeatableStatuses.IN_TRANSIT]
      ) {
        trackingEvent.translation = $t('_shipTracking.shipped')
      } else if ($te(key)) {
        trackingEvent.translation = $t(key)
      } else {
        // we're missing the translation, let's log it, then set a fallback translation
        try {
          noteMissingEventDescription(trackingEvent)
        } catch (e) {
          console.error(e) // eslint-disable-line
        }

        // get the fallback
        trackingEvent.translation = translationFallback(trackingEvent)

        // if we don't have a fallback translation, hide the trackingEvent
        trackingEvent.hide = !trackingEvent.translation || trackingEvent.shouldHide
      }
      // checking for duplicate trackingEvents
      if (previousEvent) {
        const { shortStatus } = trackingEvent
        if (
          !isFirstEvent &&
          shortStatus in RepeatableStatuses &&
          previousEvent.shortStatus === shortStatus
        ) {
          // hide adjacent repeatable trackingEvents except the first one
          trackingEvent.hide = true
          const previousKey = previousEvent.eventDescriptionTranslatorKey
          if (previousKey === key) {
            // mark adjacent repeatable events with exact same message as duplicate
            trackingEvent.duplicate = true
          }
        }
      }
      trackingEvent.subText = [
        trackingEvent.eventDate && trackingEventDateString(trackingEvent.eventDate).long,
        getAddress(trackingEvent.eventAddress),
      ]
        .filter(Boolean)
        .join(', ')
      previousEvent = trackingEvent

      if (trackingEvent.shortStatus in TerminalStatuses) {
        isTerminalStatus = true
      }

      return trackingEvent
    },
  )

  // decide what date/datetimes to show for arrival
  const { currentStatusEvent } = history
  let arrivalDateTimeMessages
  if (currentStatusEvent.shortStatus === TerminalStatuses[TerminalStatuses.DELIVERED]) {
    const deliveryDate = currentStatusEvent.eventDate
    if (deliveryDate) {
      arrivalDateTimeMessages = trackingEventDateString(deliveryDate)
      history.arrivalMessage = $t('_shipTracking.deliveredOn', {
        date: arrivalDateTimeMessages.short,
      })
      history.dateMessage = arrivalDateTimeMessages.short
      history.dateTimeMessage = arrivalDateTimeMessages.long
    }
  } else {
    const arrivalDate =
      history.bestArrivalEstimateDate ||
      history.bestArrivalEstimateTrackingDate?.dateTime ||
      history.promisedArrivalDate
    if (arrivalDate) {
      arrivalDateTimeMessages = trackingEventDateString(arrivalDate)
    }
    history.dateMessage = arrivalDateTimeMessages?.short
    // if we are falling back to the promisedArrivalDate, just show date, not time
    history.dateTimeMessage = history.bestArrivalEstimateTrackingDate
      ? arrivalDateTimeMessages?.long
      : arrivalDateTimeMessages?.short

    if (!isTerminalStatus) {
      // if shipment is still en-route, add an arrival event
      const arrivalEvent = generateArrivalEvent(history)
      if (arrivalEvent) {
        processedEvents.unshift(arrivalEvent)
      }
    } else {
      // terminal status, but not delivered (sad path)
      history.arrivalMessage = $t('_shipTracking.expected', {
        date: arrivalDateTimeMessages?.short,
      })
    }
  }

  // add an order event
  const orderEvent = generateOrderEvent(shipment)
  processedEvents.push(orderEvent)

  history.trackingEvents = processedEvents.reverse()

  return history
}
