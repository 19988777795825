







































































































import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { mapState } from 'vuex'
import PageHeader from '@/components/PageHeader.vue'
import ChatLink from '@/components/ChatLink.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import Icon from '@/components/Icon.vue'
import HelpSearchCard from '@/components/HelpSearchCard.vue'
import Card from '@/components/Card.vue'
import OnboardingTour from '@/components/dashboard/OnboardingTour.vue'
import User from '@/models/User'
import { zendeskHasLoaded } from '@/util/zendesk'

interface HelpLink {
  href: string
  label: TranslateResult
  iconName?: string
  gaEvent: string
}

@Component({
  computed: {
    ...mapState('user', ['me']),
  },
  components: {
    Card,
    PageHeader,
    LinkArrowIcon,
    Icon,
    HelpSearchCard,
    ChatLink,
    OnboardingTour,
  },
})
export default class HelpView extends Vue {
  me!: User
  showChatButton: Boolean = false

  async mounted(): Promise<void> {
    this.showChatButton = await zendeskHasLoaded()
  }

  get links(): HelpLink[] {
    return [
      {
        label: this.$t('Getting started'),
        href: 'https://help.pillpack.com/hc/en-us/categories/360000155188-Getting-Started',
        iconName: 'get-started',
        gaEvent: 'cwa_help_getting_started',
      },
      {
        label: this.$t('Medication & Refills'),
        href: 'https://help.pillpack.com/hc/en-us/categories/360000154547-Medication-Refills',
        iconName: 'calendar',
        gaEvent: 'cwa_help_medications_and_refills',
      },
      {
        label: this.$t('Payment & Insurance'),
        href: 'https://help.pillpack.com/hc/en-us/categories/360000150068-Payment-Insurance',
        iconName: 'payments',
        gaEvent: 'cwa_help_payments_and_insurance',
      },
      {
        label: this.$t('Shipping & Packaging'),
        href: 'https://help.pillpack.com/hc/en-us/categories/360000156168-Shipping-Packaging',
        iconName: 'shipping',
        gaEvent: 'cwa_help_shipping_packaging',
      },
      {
        label: this.$t('Safety & Privacy'),
        href: 'https://help.pillpack.com/hc/en-us/categories/360000156208-Safety-Privacy',
        iconName: 'insurance',
        gaEvent: 'cwa_help_safety_privacy',
      },
      {
        label: this.$t('Account Settings'),
        href: 'https://help.pillpack.com/hc/en-us/categories/360000156188-Your-Account-Preferences',
        iconName: 'gear',
        gaEvent: 'cwa_help_account_settings',
      },
      {
        label: this.$t('Go to help center'),
        href: 'https://help.pillpack.com',
        iconName: 'blank',
        gaEvent: 'cwa_help_go_to_help_center',
      },
    ]
  }
}
