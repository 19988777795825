





























































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import PageTitle from '@/components/PageTitle.vue'
import UserInitials from '@/components/UserInitials.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import CareUserElement from '@/components/CareUserElement.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'
import CareRelationship from '@/models/CareRelationship'
import User from '@/models/User'
import Card from '@/components/Card.vue'

@Component({
  components: {
    Card,
    PageTitle,
    UserInitials,
    LinkArrowIcon,
    CareUserElement,
    SkeletonListGroup,
  },
  computed: {
    ...mapGetters('user', ['authenticatedUser', 'verifiedReceivers']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class CaregiverView extends Vue {
  verifiedReceivers!: CareRelationship[]
  authenticatedUser!: User
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => Error

  caregiverLink(userId: string) {
    if (userId === this.authenticatedUser.id) {
      return '/dashboard'
    }
    return `/?caringFor=${userId}`
  }

  get loading() {
    return this.isInProgress('user/loadCaregiver') || this.isInProgress('user/loadMe')
  }

  get hasError() {
    return !!(this.getError('user/loadCaregiver') || this.getError('user/loadMe'))
  }

  get isSignedUp(): boolean {
    return !!this.authenticatedUser.signedUp
  }

  get apiHost() {
    return process.env.API_HOST
  }
}
