import PPSession from './PPSession'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { serialize, deserialize, deserializeArray } from '@/models/transforms'
import Supplement from '@/models/Supplement'
import SupplementRequest, { SupplementRequestRequest } from '@/models/SupplementRequest'

export default class PPSupplementsApi {
  static async getAll({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<Supplement[]>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: '/api/supplements',
      urlParams: {
        locale,
        user_id: userId,
        with_medication_costs: true,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserializeArray(Supplement, response.data)

    return new PPSuccessResponse(results)
  }

  static async getCost({
    baseUrl,
    session,
    userId,
    locale,
    supplementStrengthId,
    quantity,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    supplementStrengthId: string
    quantity: number
  }): Promise<PPResponse<number>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/medications/otc_cost`,
      urlParams: {
        locale,
        supplement_strength_id: supplementStrengthId,
        quantity,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const cost = Number(response.data.otc_cost)

    return new PPSuccessResponse(cost)
  }

  static async request({
    baseUrl,
    session,
    userId,
    locale,
    payload,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    payload: SupplementRequest
  }): Promise<PPResponse<SupplementRequest>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/supplement_requests`,
      urlParams: {
        locale,
      },
      content: serialize(SupplementRequestRequest, { supplementRequest: payload }),
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserialize(SupplementRequest, response.data)

    return new PPSuccessResponse(results)
  }
}
