import Vue from 'vue'
import { Module } from 'vuex'
import { compareAsc } from 'date-fns'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import StripeCard from '@/models/StripeCard'
import StripeBankAccount from '@/models/StripeBankAccount'
import GetPaymentMethodsResponse from '@/models/GetPaymentMethodsResponse'

interface State {
  stripeCards: StripeCard[]
  stripeBankAccounts: StripeBankAccount[]
  bankAccountIdBeingVerified: string | null
}

const state: State = {
  stripeCards: [],
  stripeBankAccounts: [],
  bankAccountIdBeingVerified: null,
}

const module: Module<State, RootState> = {
  namespaced: true,
  state,

  mutations: {
    handleLoadResponse(state, response: GetPaymentMethodsResponse) {
      state.stripeCards = response.stripeCards
      state.stripeBankAccounts = response.stripeBankAccounts
    },

    handleMakeDefaultResponse(state, response: StripeCard | StripeBankAccount) {
      const isFsaHsa = response instanceof StripeCard && response.isFsaHsa
      const newStripeCards = state.stripeCards.map(card => {
        if (response instanceof StripeCard && response.id === card.id) {
          return response
        } else {
          if (isFsaHsa) {
            card.defaultFsaHsaPaymentMethod = false
          } else {
            card.defaultPaymentMethod = false
          }
          return card
        }
      })

      const newBankAccounts = state.stripeBankAccounts.map(card => {
        if (response instanceof StripeBankAccount && response.id === card.id) {
          return response
        } else {
          if (!isFsaHsa) {
            card.defaultPaymentMethod = false
          }
          return card
        }
      })

      state.stripeCards = newStripeCards
      state.stripeBankAccounts = newBankAccounts
    },

    handleDeleteResponse(state, paymentMethod: StripeCard | StripeBankAccount) {
      if (paymentMethod instanceof StripeCard) {
        state.stripeCards = state.stripeCards.filter(card => card.id !== paymentMethod.id)
      } else {
        state.stripeBankAccounts = state.stripeBankAccounts.filter(
          account => account.id !== paymentMethod.id,
        )
      }
    },

    handleVerifyResponse(state, id: string) {
      state.stripeBankAccounts = state.stripeBankAccounts.map(account => {
        if (account.id === id) {
          account.verified = true
        }
        return account
      })
    },

    startVerifyingBankAccount(state, id: string) {
      state.bankAccountIdBeingVerified = id
    },

    stopVerifyingBankAccount(state) {
      state.bankAccountIdBeingVerified = null
    },
  },

  actions: {
    load: trackAsyncStatus('paymentMethods/load', async ({ commit }) => {
      const response = await Vue.$pillpack.paymentMethods.get()
      commit('handleLoadResponse', response)
    }),

    makeDefault: trackAsyncStatus(
      'paymentMethods/makeDefault',
      async ({ state, commit }, { id }) => {
        const paymentMethod = [...state.stripeCards, ...state.stripeBankAccounts].find(
          p => p.id === id,
        )
        if (!paymentMethod) return

        const updatedPaymentMethod = await Vue.$pillpack.paymentMethods.makeDefault(paymentMethod)
        commit('handleMakeDefaultResponse', updatedPaymentMethod)
      },
    ),

    delete: trackAsyncStatus('paymentMethods/delete', async ({ commit, state }, { id }) => {
      const paymentMethod = [...state.stripeCards, ...state.stripeBankAccounts].find(
        p => p.id === id,
      )
      if (!paymentMethod) return

      await Vue.$pillpack.paymentMethods.delete(paymentMethod)
      commit('handleDeleteResponse', paymentMethod)
    }),

    addCard: trackAsyncStatus(
      'paymentMethods/addCard',
      async ({ dispatch }, { token, options }) => {
        await Vue.$pillpack.paymentMethods.createCard(token, options)
        await dispatch('load')
      },
    ),

    addBankAccount: trackAsyncStatus(
      'paymentMethods/addBankAccount',
      async ({ dispatch }, { token, options }) => {
        await Vue.$pillpack.paymentMethods.createBankAccount(token, options)
        await dispatch('load')
      },
    ),

    verify: trackAsyncStatus(
      'paymentMethods/verify',
      async ({ commit }, { id, deposit1, deposit2 }) => {
        await Vue.$pillpack.paymentMethods.verify({ id, deposit1, deposit2 })
        commit('handleVerifyResponse', id)
      },
    ),

    makePayment: trackAsyncStatus('paymentMethods/makePayment', async ({ commit }, payment) => {
      const account = await Vue.$pillpack.paymentMethods.makePayment(payment)

      commit('user/setAccount', { account }, { root: true })
    }),
  },

  getters: {
    allVerifiedMethods(state) {
      return [
        ...state.stripeCards,
        ...state.stripeBankAccounts.filter((paymentMethod: StripeBankAccount) => {
          return paymentMethod.verified
        }),
      ]
    },

    nonFsaHsa(state) {
      return [...state.stripeCards, ...state.stripeBankAccounts]
        .filter(
          paymentMethod => paymentMethod instanceof StripeBankAccount || !paymentMethod.isFsaHsa,
        )
        .sort((p1, p2) => compareAsc(p1.createdAt, p2.createdAt))
    },

    fsaHsa(state) {
      return state.stripeCards
        .filter(card => card.isFsaHsa)
        .sort((p1, p2) => compareAsc(p1.createdAt, p2.createdAt))
    },

    hasCreditCard(state) {
      return !!state.stripeCards.find(card => card.funding === 'credit')
    },

    hasMultipleDefaults(state, getters) {
      const defaults = getters.allVerifiedMethods.filter(
        (paymentMethod: StripeBankAccount | StripeCard) => {
          return paymentMethod.isDefaultMethod
        },
      )
      return defaults.length > 1
    },
  },
}

export default module
