import PPSession from './PPSession'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'

export default class PPTrustedDevicesApi {
  static async setTrustedDevice({
    baseUrl,
    session,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/consumer_api/set_trusted_device`,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    return PPSuccessResponse.voidResponse()
  }
}
