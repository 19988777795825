import PPClientModule from './PPClientModule'
import PPMedicationsApi, { MedicationResponse } from './PPMedicationsApi'

export default class PPMedicationsClient extends PPClientModule {
  getAll(): Promise<MedicationResponse> {
    return this.performRequest(args => {
      return PPMedicationsApi.get(args, false)
    })
  }

  getPending(): Promise<MedicationResponse> {
    return this.performRequest(args => {
      return PPMedicationsApi.get(args, true)
    })
  }

  getToday = this.wrapApi(PPMedicationsApi.getToday)

  getOne = this.wrapApi(PPMedicationsApi.getMed)

  pauseAutoRefill = this.wrapApi(PPMedicationsApi.pauseAutoRefill)

  resumeAutoRefill = this.wrapApi(PPMedicationsApi.resumeAutoRefill)

  literature = this.wrapAnonApi(PPMedicationsApi.literature)

  delete = this.wrapApi(PPMedicationsApi.delete)

  requestRefill = this.wrapApi(PPMedicationsApi.requestRefill)

  cancelRefillRequest = this.wrapApi(PPMedicationsApi.cancelRefillRequest)

  medPreferenceChangeRequest = this.wrapApi(PPMedicationsApi.medPreferenceChangeRequest)

  physicianUpdateRequest = this.wrapApi(PPMedicationsApi.physicianUpdateRequest)

  orderRushShipment = this.wrapApi(PPMedicationsApi.orderRushShipment)

  cancelRushShipment = this.wrapApi(PPMedicationsApi.cancelRushShipment)

  getRushShipmentOptions = this.wrapApi(PPMedicationsApi.getRushShipmentOptions)

  getRxCatalog = this.wrapAnonApi(PPMedicationsApi.getRxCatalog)

  getRxConcepts = this.wrapAnonApi(PPMedicationsApi.getRxConcepts)

  search = this.wrapAnonApi(PPMedicationsApi.search)

  strengthInfo = this.wrapAnonApi(PPMedicationsApi.strengthInfo)

  addNewRXRequest = this.wrapApi(PPMedicationsApi.addNewRXRequest)

  transferInRequest = this.wrapApi(PPMedicationsApi.transferInRequest)
}
