import PrescriptionStrength from '@/models/PrescriptionStrength'
import SignupPhysician from '@/models/SignupPhysician'
import Pharmacy from '@/models/Pharmacy'
import { TransferInRequestDetails, PrescriptionRequestDetails } from '@/models/NewMedRequest'
import businessDayUtil from '@/util/businessDay'

export enum DeliveryOption {
  NEXT = 'NEXT_SHIPMENT',
  ASAP = 'AS_SOON_AS_POSSIBLE',
}

export const DAYS_TO_CHASE = 5

export function asapDeliveryDate() {
  const today = new Date()
  return businessDayUtil.daysFromDate(today, DAYS_TO_CHASE + 1)
}

export default function getNewMedDetails({
  medStrength,
  prescriber,
  pharmacy,
  delivery,
  nextShipmentDate,
}: {
  medStrength: PrescriptionStrength
  prescriber: SignupPhysician
  pharmacy?: Pharmacy
  delivery: DeliveryOption
  nextShipmentDate: Date | undefined
}): TransferInRequestDetails | PrescriptionRequestDetails {
  const { name, displayString } = medStrength
  const description = [name, displayString].filter(Boolean).join(' ')

  return {
    description,
    medDetails: medStrength,
    physicianId: prescriber.id,
    otherPhysicianInfo: `clinic: ${prescriber.clinicName || 'n/a'}`,
    addToNextPillpack: delivery === DeliveryOption.NEXT,
    expectedArrivalDate: delivery === DeliveryOption.NEXT ? nextShipmentDate : asapDeliveryDate(),
    otherShipDateSelected: true,
    otherShipmentNotes:
      delivery === DeliveryOption.NEXT ? 'Add to next shipment' : 'As soon as possible',
    ...(pharmacy && {
      isTransferIn: true,
      pharmacyId: pharmacy.id,
    }),
  }
}
