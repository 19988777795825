import PPSession from './PPSession'
import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize } from '@/models/transforms'
import ServiceAlertsResponse from '@/models/ServiceAlertsResponse'

export default class PPServiceAlertsApi {
  static async get({
    baseUrl,
    session,
    userId,
    locale,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
  }): Promise<PPResponse<ServiceAlertsResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/consumer_api/users/${userId}/alerts`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const alerts = deserialize(ServiceAlertsResponse, response.data.service_alerts)
    return new PPSuccessResponse(alerts)
  }

  static async acknowledge({
    baseUrl,
    session,
    userId,
    locale,
    alertId,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    alertId: string
  }): Promise<PPResponse<ServiceAlertsResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/consumer_api/users/${userId}/alerts/acknowledge/${alertId}`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const alerts = deserialize(ServiceAlertsResponse, response.data.service_alerts)
    return new PPSuccessResponse(alerts)
  }

  static async resolve({
    baseUrl,
    session,
    userId,
    locale,
    alertId,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    alertId: string
  }): Promise<PPResponse<ServiceAlertsResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/consumer_api/users/${userId}/alerts/resolve/${alertId}`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const alerts = deserialize(ServiceAlertsResponse, response.data.service_alerts)
    return new PPSuccessResponse(alerts)
  }

  static async viewMany({
    baseUrl,
    session,
    userId,
    locale,
    alertIds,
  }: {
    baseUrl: string
    session: PPSession
    userId: string
    locale: string
    alertIds: string[]
  }): Promise<PPResponse<ServiceAlertsResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/consumer_api/users/${userId}/alerts/view_many`,
      urlParams: { locale },
      content: { alert_ids: alertIds },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const alerts = deserialize(ServiceAlertsResponse, response.data.service_alerts)
    return new PPSuccessResponse(alerts)
  }
}
