import { render, staticRenderFns } from "./SkipToMain.vue?vue&type=template&id=7faa59a4&scoped=true&"
import script from "./SkipToMain.vue?vue&type=script&lang=ts&"
export * from "./SkipToMain.vue?vue&type=script&lang=ts&"
import style0 from "./SkipToMain.vue?vue&type=style&index=0&id=7faa59a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7faa59a4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BLink } from 'bootstrap-vue'
installComponents(component, {BLink})
