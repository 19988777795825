





















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SiteLogo extends Vue {
  @Prop(Boolean)
  horizontal!: boolean

  get height() {
    return this.horizontal ? '30px' : '84px'
  }

  get transition() {
    return this.horizontal ? 'height 0.25s' : 'none'
  }
}
