







































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import User from '@/models/User'
import CareRelationship from '@/models/CareRelationship'
import UserInitials from '@/components/UserInitials.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import CareUserElement from '@/components/CareUserElement.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import { PPClientState } from '@/ppapi/PPClient'
import getApiHost from '@/util/apiHost'

interface SwitchableUserItem {
  id: string
  fullName: string
  color: string
}

@Component({
  components: {
    LoadingSpinner,
    CareUserElement,
    UserInitials,
    LinkArrowIcon,
  },
  computed: {
    ...mapState(['clientState']),
    ...mapState('user', ['me']),
    ...mapGetters('user', ['authenticatedUser', 'verifiedReceivers']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
})
export default class UserDropdown extends Vue {
  isInProgress!: (key: string) => boolean
  clientState!: PPClientState
  me!: User
  authenticatedUser!: User
  verifiedReceivers!: CareRelationship[]

  signingOut: boolean = false

  async created() {
    await this.$store.dispatch('user/ensureMe')
    if (this.clientState.isAssumedUser) {
      this.$store.dispatch('user/loadCaregiver')
    }
  }

  caregiverLink(userId: string) {
    if (userId === this.authenticatedUser.id) {
      return '/dashboard'
    }
    return `/?caringFor=${userId}`
  }

  get isLoading() {
    if (this.me.id && this.authenticatedUser.id) return false
    return this.isInProgress('user/loadMe') || this.isInProgress('user/loadCaregiver')
  }

  get relationships(): CareRelationship[] {
    const activeUserId = this.me.id
    if (!activeUserId || !this.verifiedReceivers.length) return []
    return this.verifiedReceivers
  }

  get canSwitchAccounts(): boolean {
    return this.relationships.length > 0
  }

  get isSignedUp(): boolean {
    return !!this.authenticatedUser.signedUp
  }

  get apiHost() {
    return getApiHost()
  }
}
