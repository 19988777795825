









import { Component, Vue, Prop } from 'vue-property-decorator'
import Icon from '@/components/Icon.vue'

@Component({
  components: {
    Icon,
  },
})
export default class AlertIcon extends Vue {
  @Prop({ type: String, default: 'danger' })
  variant!: string

  @Prop({ type: String, default: 'exclamation' })
  iconName?: string

  get textVariant() {
    return `text-${this.variant}`
  }
}
