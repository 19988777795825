import PPClientModule from './PPClientModule'
import PPEventsApi from './PPEventsApi'

export default class PPEventsClient extends PPClientModule {
  get() {
    return this.performRequest(args => {
      return PPEventsApi.get(args)
    })
  }

  acknowledge(userEventId: string) {
    return this.performRequest(args => {
      return PPEventsApi.acknowledge({
        ...args,
        userEventId,
      })
    })
  }
}
