








import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class TourProgressBar extends Vue {
  @Prop()
  state!: string
}
