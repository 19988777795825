import { render, staticRenderFns } from "./TodaysMedListItem.vue?vue&type=template&id=7de8b656&scoped=true&"
import script from "./TodaysMedListItem.vue?vue&type=script&lang=ts&"
export * from "./TodaysMedListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7de8b656",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
installComponents(component, {BListGroupItem,BMedia})
