import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import ServiceAlert from '@/models/ServiceAlert'
import ServiceAlertsResponse from '@/models/ServiceAlertsResponse'

export interface UserState {
  resolved: ServiceAlert[]
  unresolved: ServiceAlert[]
  messages: ServiceAlert[]
}

const state: UserState = {
  resolved: [],
  unresolved: [],
  messages: [],
}

const module: Module<UserState, RootState> = {
  namespaced: true,
  state,

  mutations: {
    setResolved(state, alerts: ServiceAlert[]): void {
      state.resolved = alerts
    },

    setUnresolved(state, alerts: ServiceAlert[]): void {
      state.unresolved = alerts
    },

    setMessages(state, alerts: ServiceAlert[]): void {
      state.messages = alerts
    },
  },

  actions: {
    ensureLoaded: async ({ rootGetters, dispatch }) => {
      if (
        !rootGetters['asyncStatus/hasSucceeded']('alerts/load') &&
        !rootGetters['asyncStatus/isInProgress']('alerts/load')
      ) {
        await dispatch('load')
      }
    },
    load: trackAsyncStatus('alerts/load', async ({ dispatch }) => {
      const alerts = await Vue.$pillpack.serviceAlerts.get()
      dispatch('handleAlerts', alerts)
    }),
    acknowledge: trackAsyncStatus(
      'alerts/acknowledge',
      async ({ dispatch }, alert: ServiceAlert) => {
        const alerts = await Vue.$pillpack.serviceAlerts.acknowledge(alert.id)
        dispatch('handleAlerts', alerts)
      },
    ),
    resolve: trackAsyncStatus('alerts/resolve', async ({ dispatch }, alert: ServiceAlert) => {
      const alerts = await Vue.$pillpack.serviceAlerts.resolve(alert.id)
      dispatch('handleAlerts', alerts)
    }),
    viewMany: trackAsyncStatus('alerts/viewMany', async ({ dispatch }, alertIds: string[]) => {
      if (!alertIds.length) return

      const alerts = await Vue.$pillpack.serviceAlerts.viewMany(alertIds)
      dispatch('handleAlerts', alerts)
    }),

    handleAlerts({ commit }, alerts: ServiceAlertsResponse) {
      commit('setResolved', alerts.resolved ?? [])
      commit('setUnresolved', alerts.unresolved ?? [])
      commit('setMessages', alerts.messages ?? [])
    },
  },

  getters: {
    hasMessages(state) {
      return state.messages.length > 0
    },
    allMessages(state) {
      return state.messages
    },
  },
}

export default module
