import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=d7c8816e&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=ts&"
export * from "./Navbar.vue?vue&type=script&lang=ts&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=d7c8816e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7c8816e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BNavItem } from 'bootstrap-vue'
import { BNavbar } from 'bootstrap-vue'
import { BNavbarBrand } from 'bootstrap-vue'
import { BNavbarNav } from 'bootstrap-vue'
import { BPopover } from 'bootstrap-vue'
installComponents(component, {BButton,BNavItem,BNavbar,BNavbarBrand,BNavbarNav,BPopover})
