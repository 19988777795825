
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class LoadingSpinner extends Vue {
  @Prop({ type: [Number, String], default: 36 })
  size!: number | string

  @Prop({ type: [Number, String], default: 6.66 })
  width!: number | string

  @Prop({ type: String, default: 'primary' })
  variant!: string

  @Prop({ type: Boolean, default: false })
  button!: boolean

  get classes(): object {
    return {
      button: this.button,
      [`text-${this.variant}`]: this.variant,
    }
  }

  get styles(): object {
    return {
      height: `${this.size}px`,
      width: `${this.size}px`,
    }
  }
}
