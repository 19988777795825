






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SkipToMain extends Vue {
  @Prop({ type: String, default: '#content' })
  mainId!: string
}
