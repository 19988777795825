import { serializable, primitive, alias } from 'serializr'

export class PharmacySearchResult {
  @serializable(alias('place_id', primitive()))
  googlePlaceId!: string

  @serializable(primitive())
  name?: string

  @serializable(alias('formatted_address', primitive()))
  address?: string
}

const selectAddressComponent = (details: google.maps.places.PlaceResult, componentName: string) => {
  // eslint-disable-next-line camelcase
  const component = details?.address_components?.find(addressComponent =>
    addressComponent.types.includes(componentName),
  )
  return (component && component.short_name) || ''
}

export default class Pharmacy {
  id?: string
  name: string
  phoneNumber?: string
  street?: string
  city?: string
  state?: string
  zipcode?: string

  constructor({
    id,
    name,
    phoneNumber,
    street,
    city,
    state,
    zipcode,
  }: {
    id?: string
    name: string
    phoneNumber: string
    street?: string
    city?: string
    state?: string
    zipcode?: string
  }) {
    this.id = id
    this.name = name
    this.phoneNumber = phoneNumber
    this.street = street
    this.city = city
    this.state = state
    this.zipcode = zipcode
  }

  get formattedAddress(): string {
    const { street, city, state, zipcode } = this

    const streetCityState = [street, city, state].filter(Boolean).join(', ')

    const address = [streetCityState, zipcode?.substring(0, 5)].filter(Boolean).join(' ')

    return address
  }

  toPillPackCoreRequest() {
    return {
      id: this.id,
      name: this.name,
      phone_number: this.phoneNumber,
      street: this.street,
      city: this.city,
      state: this.state,
      zipcode: this.zipcode,
    }
  }

  static fromPillPackCoreResponse(pharmacy: any) {
    return new Pharmacy({
      id: pharmacy.id,
      name: pharmacy.name,
      phoneNumber: pharmacy.phone_number,
      street: pharmacy.street,
      city: pharmacy.city,
      state: pharmacy.state,
      zipcode: pharmacy.zipcode,
    })
  }

  static fromGooglePlaceResult(result: google.maps.places.PlaceResult) {
    return new Pharmacy({
      name: result.name,
      phoneNumber: result.formatted_phone_number || '',
      city: selectAddressComponent(result, 'locality'),
      state: selectAddressComponent(result, 'administrative_area_level_1'),
      zipcode: selectAddressComponent(result, 'postal_code'),
      street: [
        selectAddressComponent(result, 'street_number'),
        selectAddressComponent(result, 'route'),
      ]
        .join(' ')
        .trim(),
    })
  }
}
