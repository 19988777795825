import PPSession from './PPSession'
import { PPError } from './PPError'

export class PPBaseResponse {
  readonly updatedSession?: PPSession

  constructor(updatedSession?: PPSession) {
    this.updatedSession = updatedSession
  }
}

export class PPSuccessResponse<T> extends PPBaseResponse {
  readonly success = true
  readonly failure = false

  readonly data: T

  constructor(data: T, updatedSession?: PPSession) {
    super(updatedSession)
    this.data = data
  }

  static withSession(updatedSession: PPSession): PPSuccessResponse<void> {
    return new PPSuccessResponse<void>(undefined, updatedSession)
  }

  static voidResponse(): PPSuccessResponse<void> {
    return new PPSuccessResponse<void>(undefined)
  }
}

export class PPFailureResponse extends PPBaseResponse {
  readonly success = false
  readonly failure = true
  readonly error: PPError

  constructor(error: PPError, updatedSession?: PPSession) {
    super(updatedSession)
    this.error = error
  }
}

export type PPResponse<T> = PPSuccessResponse<T> | PPFailureResponse
