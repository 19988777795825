import PPClientModule from './PPClientModule'
import PPPaymentMethodsApi from './PPPaymentMethodsApi'
import StripeCard from '@/models/StripeCard'
import StripeBankAccount from '@/models/StripeBankAccount'

export default class PPPaymentMethodsClient extends PPClientModule {
  get() {
    return this.performRequest(args => {
      return PPPaymentMethodsApi.get(args)
    })
  }

  createCard(token: stripe.Token, options: { isFsaHsa?: boolean; makeDefault?: boolean } = {}) {
    return this.performRequest(args => {
      return PPPaymentMethodsApi.createCard({
        ...args,
        token,
        isFsaHsa: options.isFsaHsa || false,
        makeDefault: options.makeDefault || false,
      })
    })
  }

  createBankAccount(token: stripe.Token, options: { makeDefault?: boolean } = {}) {
    return this.performRequest(args => {
      return PPPaymentMethodsApi.createBankAccount({
        ...args,
        token,
        makeDefault: options.makeDefault || false,
      })
    })
  }

  makeDefault(paymentMethod: StripeCard | StripeBankAccount) {
    return this.performRequest(args => {
      return PPPaymentMethodsApi.makeDefault({
        ...args,
        paymentMethod,
      })
    })
  }

  delete(paymentMethod: StripeCard | StripeBankAccount) {
    return this.performRequest(args => {
      return PPPaymentMethodsApi.delete({
        ...args,
        paymentMethod,
      })
    })
  }

  verify({ id, deposit1, deposit2 }: { id: string; deposit1: string; deposit2: string }) {
    return this.performRequest(args => {
      return PPPaymentMethodsApi.verify({
        ...args,
        id,
        deposit1,
        deposit2,
      })
    })
  }

  makePayment = this.wrapApi(PPPaymentMethodsApi.makePayment)
}
