











import { Component, Vue, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import DashboardMedication from '@/models/DashboardMedication'
import { formatDate } from '@/i18n'
import Prescription from '@/models/Prescription'
import { medStatusFinder, StatusClass, chaseClass, MedicationStatus } from '@/util/medStatusHelpers'
import UnreachableCaseError from '@/util/UnreachableCaseError'

@Component
export default class MedStatusLine extends Vue {
  @Prop({ type: DashboardMedication, required: true })
  med!: DashboardMedication

  @Prop({ type: Boolean, default: false })
  past!: boolean

  get statusLineClass(): StatusClass {
    const chase = this.med.prescriptionRequest

    if (chase) {
      const statusClass = chaseClass(chase)
      if (statusClass) return statusClass
    }

    return 'med-status--default'
  }

  get medStatusCopy(): TranslateResult | undefined {
    let copy: TranslateResult | undefined
    const status: MedicationStatus = medStatusFinder(this.med)
    const chase = this.med.prescriptionRequest
    let deadline: string | undefined

    switch (status) {
      case 'discontinued':
      case 'expired':
        if (this.med.selfPrescribed) copy = this.$t('medStatus.discontinued.otc.header')
        else copy = this.$t('medStatus.discontinued.header')
        break
      case 'pending_otc':
        copy = this.$t('chase.status.processing.header')
        break
      case 'pending':
        copy = this.$t('chase.status.preChase.header')
        break
      case 'new_chase':
        copy = this.$t('chase.status.newChase.header')
        break
      case 'in_progress':
        deadline = chase && formatDate(chase.dueDate, 'MMMM D')
        copy = this.$t('chase.status.inProgress.header', {
          deadline,
        })
        break
      case 'has_error':
        copy = this.$t('chase.status.error.header')
        break
      case 'unable_to_acquire':
        copy = this.$t('chase.status.unable.header')
        break
      case 'denied':
        copy = this.$t('chase.status.denied.header')
        break
      case 'received_signup_med':
        copy = this.$t('chase.status.renewed.firstShipment.header')
        break
      case 'pre_rph_check':
        copy = this.$t('medStatus.preRphCheck.header')
        break
      case 'ortf':
        copy = this.$t('medStatus.ortf.header')
        break
      case 'active':
      case 'unknown':
      case 'received':
      case undefined:
        break
      default:
        throw new UnreachableCaseError(status)
    }

    return copy
  }

  pastStatus(rx: Prescription): TranslateResult {
    const expiry = rx.endDate as Date
    return this.$t('Ended on {date}', {
      date: formatDate(expiry, 'MMM D, YYYY'),
    }) as string
  }
}
