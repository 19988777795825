






import { Component, Vue, Prop } from 'vue-property-decorator'
import SkeletonListGroupItem from '@/components/SkeletonListGroupItem.vue'

@Component({
  components: {
    SkeletonListGroupItem,
  },
})
export default class SkeletonListGroup extends Vue {
  /**
   * The number of skeleton list group items to display
   */
  @Prop(Number)
  length!: number
}
