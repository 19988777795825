import { render, staticRenderFns } from "./Banners.vue?vue&type=template&id=55063014&scoped=true&"
import script from "./Banners.vue?vue&type=script&lang=ts&"
export * from "./Banners.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55063014",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCollapse } from 'bootstrap-vue'
installComponents(component, {BCollapse})
