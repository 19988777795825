import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import Insurance from '@/models/Insurance'

interface InsuranceState {
  insurances: Insurance[]
}

const state: InsuranceState = {
  insurances: [],
}

const module: Module<InsuranceState, RootState> = {
  namespaced: true,
  state,

  mutations: {
    updateInsurances(state, insurances: Insurance[]) {
      state.insurances = insurances
    },
  },

  actions: {
    load: trackAsyncStatus('insurances/load', async ({ commit }) => {
      const insurances = await Vue.$pillpack.insurances.all()
      commit('updateInsurances', insurances)
    }),

    create: trackAsyncStatus('insurances/create', async ({ commit }, insurance) => {
      const insurances = await Vue.$pillpack.insurances.create(insurance)
      commit('updateInsurances', insurances)
    }),

    ensureLoaded: async ({ state, dispatch }) => {
      if (state.insurances.length > 0) {
        return
      }
      await dispatch('load')
    },
  },

  getters: {
    primaryInsurance: state => state.insurances.find(i => i.isPrimary),
  },
}

export default module
