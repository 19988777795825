




// Google Recaptcha v2. Docs: https://developers.google.com/recaptcha/docs/display
import { Component, Vue } from 'vue-property-decorator'
import once from 'lodash/once'

const ensureCaptchaReady = once(() => {
  return new Promise((resolve, reject) => {
    if (!document.getElementById('recaptchaScript')) {
      const recaptcha = document.createElement('script')
      recaptcha.setAttribute('id', 'recaptchaScript')
      recaptcha.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      recaptcha.setAttribute('async', 'true')
      recaptcha.setAttribute('defer', 'true')
      recaptcha.onload = () => window.grecaptcha.ready(() => resolve(true))
      recaptcha.onerror = e => reject(e)
      document.head.appendChild(recaptcha)
    }
  })
})

@Component
export default class Recaptcha extends Vue {
  sitekey?: string = process.env.CAPTCHA_KEY
  id: number = 0
  $refs!: {
    recaptcha: HTMLElement
  }

  async mounted() {
    await ensureCaptchaReady()
    this.renderCaptcha()
  }

  renderCaptcha() {
    this.id = window.grecaptcha.render(this.$refs.recaptcha, {
      sitekey: this.sitekey,
      size: 'invisible',
      callback: this.onValidate,
      badge: 'bottomleft',
    })
  }

  async execute() {
    await ensureCaptchaReady()
    window.grecaptcha.execute(this.id)
  }

  async reset() {
    await ensureCaptchaReady()
    window.grecaptcha.reset(this.id)
  }

  onValidate(response: string) {
    this.$emit('validate', response)
  }
}
