import { PPHttp, PPHttpMethod, PPHttpContentType } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize } from '@/models/transforms'
import User from '@/models/User'
import Dispenser, { DispenserResponse } from '@/models/Dispenser'
import { PPArgs } from './PPClient'

export default class PPDispenserApi {
  static async getPremiumDispensers({
    baseUrl,
    session,
    userId,
    locale,
  }: PPArgs): Promise<PPResponse<DispenserResponse>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/dispensers`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const res = deserialize(DispenserResponse, response.data)
    return new PPSuccessResponse(res)
  }

  static async updatePremiumDispenser(
    { baseUrl, session, userId, locale }: PPArgs,
    dispenser: Dispenser,
  ): Promise<PPResponse<User>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.PUT,
      baseUrl,
      path: `/consumer_api/users/${userId}/update_premium_dispenser`,
      urlParams: { locale },
      content: {
        type: dispenser.type,
        color: dispenser.id,
      },
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const updatedUser = deserialize(User, response.data)
    return new PPSuccessResponse(updatedUser)
  }
}
