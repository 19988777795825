import PPClientModule from './PPClientModule'
import Supplement from '@/models/Supplement'
import SupplementRequest from '@/models/SupplementRequest'
import PPSupplementsApi from '@/ppapi/PPSupplementsApi'

export default class PPSupplementsClient extends PPClientModule {
  getAll(): Promise<Supplement[]> {
    return this.performRequest(PPSupplementsApi.getAll)
  }

  getCost({
    supplementStrengthId,
    quantity,
  }: {
    supplementStrengthId: string
    quantity: number
  }): Promise<number> {
    return this.performRequest(args => {
      return PPSupplementsApi.getCost({
        ...args,
        supplementStrengthId,
        quantity,
      })
    })
  }

  request(supplementRequest: SupplementRequest): Promise<SupplementRequest> {
    return this.performRequest(args => {
      return PPSupplementsApi.request({
        ...args,
        payload: supplementRequest,
      })
    })
  }
}
