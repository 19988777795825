







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PageTitle extends Vue {
  @Prop(String)
  pretitle?: string
}
