import { serializable, primitive } from 'serializr'
import { parse } from 'date-fns'

export default class TrackingDate {
  @serializable(primitive())
  day?: string

  @serializable(primitive())
  endTime?: string

  @serializable(primitive())
  timeZone?: string

  @serializable(primitive())
  timeFormat?: string

  @serializable(primitive())
  dayFormat?: string

  get hasTime(): boolean {
    return !!this.endTime
  }

  get dateTime(): Date {
    const { day, endTime } = this
    const dateTimeString = [day, endTime].filter(Boolean).join(' ')
    return parse(dateTimeString)
  }
}
