













import { Component, Vue, Prop } from 'vue-property-decorator'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'

@Component({
  components: {
    LinkArrowIcon,
  },
})
export default class CardToggleHeader extends Vue {
  @Prop(String)
  toggle!: string
}
