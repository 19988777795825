import { serializable, primitive, identifier, alias } from 'serializr'
import { clone } from '@/models/transforms'

export default class Address {
  clone(): Address {
    return clone(Address, this)
  }

  @serializable(identifier())
  id?: string

  @serializable(alias('is_current_shipping', primitive()))
  isCurrentShipping?: boolean

  @serializable(primitive())
  name?: string

  @serializable(primitive())
  street?: string

  @serializable(primitive())
  street2?: string

  @serializable(primitive())
  city?: string

  @serializable(primitive())
  state?: string

  @serializable(primitive())
  zipcode?: string

  @serializable(alias('require_identification', primitive()))
  requireIdentification?: string

  get fiveDigitZipcode(): string {
    const zip = this.zipcode || ''
    return zip.slice(0, 5)
  }
}
