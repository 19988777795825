import { serializable, primitive, alias } from 'serializr'

export default class ManualPayment {
  @serializable(alias('payment_method_id', primitive()))
  paymentMethodId!: string

  @serializable(alias('payment_method_class', primitive()))
  paymentMethodClass!: string

  @serializable(alias('amount', primitive()))
  amount!: number
}
