import { render, staticRenderFns } from "./OrderRefillModal.vue?vue&type=template&id=b9becebe&scoped=true&"
import script from "./OrderRefillModal.vue?vue&type=script&lang=ts&"
export * from "./OrderRefillModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9becebe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'
import { BSpinner } from 'bootstrap-vue'
installComponents(component, {BButton,BFormGroup,BFormSelect,BSpinner})
