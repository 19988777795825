import Vue from 'vue'
import { Module } from 'vuex'

import { RootState } from '@/store'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import getNewMedDetails, { DeliveryOption } from '@/util/addRx'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import Physician from '@/models/Physician'
import SignupPhysician from '@/models/SignupPhysician'
import Pharmacy from '@/models/Pharmacy'
import {
  PrescriptionRequestDetails,
  TransferInRequestDetails,
  NewMedRequest,
  TransferInRequest,
} from '@/models/NewMedRequest'

export type MedOption =
  | { type: 'NAME_ONLY'; name: string }
  | { type: 'NAME_AND_FORM'; name: string; form: string }
  | { type: 'NAME_AND_UNKNOWN_STRENGTH'; med: PrescriptionStrength }
  | { type: 'NAME_AND_STRENGTH'; med: PrescriptionStrength }
  | null

type PharmacyOption =
  | { type: 'HAS_PHARMACY'; pharmacyId: string | null }
  | { type: 'NO_PHARMACY' }
  | null

type State = {
  med: MedOption
  medStrengthOptions: PrescriptionStrength[]
  prescriber: Physician | null
  prescriberDetails: SignupPhysician | null
  pharmacy: PharmacyOption
  delivery: DeliveryOption | null
}

const defaultState = (): State => ({
  med: null,
  medStrengthOptions: [],
  prescriber: null,
  prescriberDetails: null,
  pharmacy: null,
  delivery: null,
})

const module: Module<State, RootState> = {
  namespaced: true,
  state: defaultState(),

  mutations: {
    reset(state: State) {
      Object.assign(state, defaultState())
    },

    selectMedName(state: State, { name }: { name: string }) {
      state.med = { type: 'NAME_ONLY', name }
    },

    selectMedStrength(state: State, { strength }: { strength: PrescriptionStrength }) {
      state.med = { type: 'NAME_AND_STRENGTH', med: strength }
    },

    selectMedForm(state: State, { name, form }: { name: string; form: string }) {
      state.med = { type: 'NAME_AND_FORM', name, form }
    },

    selectUnknownMedStrength(state: State, { name }: { name: string }) {
      state.med = { type: 'NAME_AND_UNKNOWN_STRENGTH', med: { name } as PrescriptionStrength }
    },

    selectPrescriber(state: State, { prescriber }: { prescriber: Physician }) {
      state.prescriber = prescriber
    },

    selectPharmacy(state: State, { pharmacyId }: { pharmacyId: string }) {
      if (state.pharmacy?.type !== 'HAS_PHARMACY') return

      state.pharmacy.pharmacyId = pharmacyId
    },

    selectHasPharmacy(state: State) {
      state.pharmacy = { type: 'HAS_PHARMACY', pharmacyId: null }
    },

    selectNoPharmacy(state: State) {
      state.pharmacy = { type: 'NO_PHARMACY' }
    },

    deliverInNextShipment(state: State) {
      state.delivery = DeliveryOption.NEXT
    },

    deliverASAP(state: State) {
      state.delivery = DeliveryOption.ASAP
    },

    setMedStrengths(state, { medStrengths }) {
      state.medStrengthOptions = medStrengths
    },

    setPrescriberDetails(state, { prescriber }: { prescriber: SignupPhysician }) {
      state.prescriberDetails = prescriber
    },
  },

  actions: {
    reset({ commit }) {
      commit('reset')
      commit('medications/resetMedicationResults', null, { root: true })
      commit('physicians/resetPhysicianSearchResults', null, { root: true })
      commit('asyncStatus/reset', 'pharmacies/search', { root: true })
    },
    loadMedStrengths: trackAsyncStatus(
      'addRx/loadMedStrengths',
      async function ({ state, commit }) {
        if (state.med?.type !== 'NAME_ONLY') return

        const getRxConceptsAsMedStrengths = async ({ name }: { name: string }) => {
          const concepts = await Vue.$pillpack.medications.getRxConcepts({ name })
          return concepts.map(PrescriptionStrength.fromRxConcept)
        }

        const { name } = state.med
        const medStrengths = await getRxConceptsAsMedStrengths({ name })
        commit('setMedStrengths', { medStrengths })
      },
    ),

    loadPrescriberDetails: trackAsyncStatus(
      'addRx/loadPrescriberDetails',
      async function ({ state, commit }) {
        if (!state.prescriber) return
        const { spi } = state.prescriber
        if (spi) {
          const prescriber = await Vue.$pillpack.physicians.getPhysician({
            prescriberId: spi as string,
          })
          commit('setPrescriberDetails', { prescriber })
        } else {
          commit('setPrescriberDetails', { prescriber: state.prescriber })
        }
      },
    ),

    selectPrescriber({ dispatch, commit }, { prescriber }: { prescriber: Physician }) {
      commit('selectPrescriber', { prescriber })
      dispatch('loadPrescriberDetails')
    },

    createMedRequest: trackAsyncStatus(
      'addRx/createMedRequest',
      async function (
        { dispatch, rootGetters },
        {
          medStrength,
          prescriber,
          pharmacy,
          delivery,
        }: {
          medStrength: PrescriptionStrength
          prescriber: SignupPhysician
          pharmacy?: Pharmacy
          delivery: DeliveryOption
        },
      ) {
        const payload = getNewMedDetails({
          prescriber,
          pharmacy,
          medStrength,
          delivery,
          nextShipmentDate: rootGetters['orders/nextShipmentDate'],
        })
        if (pharmacy) {
          await dispatch('transferInRequest', {
            prescription: payload as TransferInRequestDetails,
          })
        } else {
          await dispatch('addNewRXRequest', {
            prescription: payload as PrescriptionRequestDetails,
          })
        }
      },
    ),

    async transferInRequest(
      _,
      {
        prescription,
      }: {
        prescription: TransferInRequestDetails
      },
    ) {
      await Vue.$pillpack.medications.transferInRequest(
        new TransferInRequest({
          prescription,
        }),
      )
    },

    async addNewRXRequest(
      _,
      {
        prescription,
      }: {
        userId: string
        prescription: PrescriptionRequestDetails
      },
    ) {
      await Vue.$pillpack.medications.addNewRXRequest(
        new NewMedRequest({
          prescription,
        }),
      )
    },
  },

  getters: {
    medName(state: State) {
      if (state.med?.type === 'NAME_AND_STRENGTH') {
        return state.med.med.name
      } else if (state.med?.type === 'NAME_AND_UNKNOWN_STRENGTH') {
        return state.med.med.name
      } else if (state.med?.type === 'NAME_ONLY' || state.med?.type === 'NAME_AND_FORM') {
        return state.med.name
      }
      return null
    },
    pharmacy(state: State, _getters, rootState: RootState) {
      if (state.pharmacy?.type !== 'HAS_PHARMACY') return null

      const pharmaciesModule = (rootState as any).pharmacies
      const { pharmacyId } = state.pharmacy
      return pharmaciesModule.pharmacies.find((pharmacy: Pharmacy) => pharmacy.id === pharmacyId)
    },
    medStrength(state: State) {
      if (state.med?.type === 'NAME_AND_STRENGTH') {
        return state.med.med
      } else if (state.med?.type === 'NAME_AND_UNKNOWN_STRENGTH') {
        return state.med.med
      }
      return null
    },
    availableStrengths(state: State) {
      let selectedForm: any
      if (state.med?.type === 'NAME_AND_FORM') {
        selectedForm = state.med?.form
      } else if (state.med?.type === 'NAME_AND_STRENGTH') {
        selectedForm = state?.med.med.form
      }
      return state.medStrengthOptions.filter(s => s.form === selectedForm)
    },
  },
}

export default module
