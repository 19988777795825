





























import { Component, Vue, Prop } from 'vue-property-decorator'
import DashboardMedication from '@/models/DashboardMedication'

import Icon from '@/components/Icon.vue'

@Component({
  components: { Icon },
})
export default class MedImage extends Vue {
  @Prop({ type: Object, required: true })
  med!: DashboardMedication

  @Prop({ type: Number, default: 40 })
  size!: number

  @Prop(String)
  fallbackIcon?: string

  imgError: boolean = false
  get src() {
    return this.med.imageUrl
  }

  get icon() {
    return this.fallbackIcon ? this.fallbackIcon : this.med.medicationType
  }
}
