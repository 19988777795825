






































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import MobileNavLink from '@/components/navbar/MobileNavLink.vue'

interface Link {
  to: string
  label: string | object
  exact?: boolean
}

@Component({
  computed: {
    ...mapState('welcome', ['onboardingPopover']),
  },
  components: {
    MobileNavLink,
  },
})
export default class NavMenu extends Vue {
  onboardingPopover!: boolean

  @Prop(Boolean) mobile!: boolean

  get links() {
    return [
      { to: '/dashboard', label: this.$t('Home'), gaEvent: 'cwa_home_page' },
      { to: '/medications', label: this.$t('Medications'), gaEvent: 'cwa_medications_page' },
      { to: '/orders', label: this.$t('Orders & Billing'), gaEvent: 'cwa_orders_page' },
      { to: '/help', label: this.$t('Help'), gaEvent: 'cwa_help', class: 'd-none d-md-block' },
    ]
  }

  hidePopover() {
    this.$store.commit('welcome/setOnboardingPopover', false)
  }
}
