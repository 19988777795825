/* eslint-disable no-console */
import Vue, { PluginObject } from 'vue'

// always boot the plugins for metrics, but we only "configure" or "enable" them in certain environments.
// if loading these scripts fails the entire app crashes. lets fail gracefully, and report any errors.
function safelyLoadPlugins(plugins: PluginObject<any>[]) {
  const errs: Error[] = []

  plugins.forEach(plug => {
    try {
      Vue.use(plug)
    } catch (err) {
      errs.push(err)
    }
  })

  errs.forEach(err => {
    console.error(err)

    // report the failure to whatever library is available
    if (Vue.$honeybadger) {
      Vue.$honeybadger.notify(err)
    }
    if (Vue.$newrelic) {
      Vue.$newrelic.noticeError(err)
    }
  })
}

export default safelyLoadPlugins
