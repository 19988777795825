









import { Component, Vue, Prop } from 'vue-property-decorator'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

@Component({
  components: {
    LoadingSpinner,
  },
})
export default class LoadingButton extends Vue {
  @Prop({ type: String })
  variant?: string

  @Prop({ type: String, default: 'button' })
  type?: string

  @Prop({ type: Boolean, default: false })
  loading!: boolean

  get props() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loading, ...rest } = this.$props
    return rest
  }

  get labelClassName(): false | string {
    return this.loading && 'invisible'
  }
}
