




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class StepProgressBarEdge extends Vue {
  @Prop()
  active!: boolean
}
