import PPClientModule from './PPClientModule'
import PPPhysiciansApi from './PPPhysiciansApi'

export default class PPPhysiciansClient extends PPClientModule {
  searchPhysicians = this.wrapAnonApi(PPPhysiciansApi.searchPhysicians)

  searchPhysiciansByLocation = this.wrapAnonApi(PPPhysiciansApi.searchPhysiciansByLocation)

  allPhysicians = this.wrapApi(PPPhysiciansApi.allPhysicians)

  getPhysician = this.wrapApi(PPPhysiciansApi.getPhysician)

  addPhysician = this.wrapApi(PPPhysiciansApi.addPhysician)
}
