import { render, staticRenderFns } from "./MedicationListItem.vue?vue&type=template&id=dc173c62&scoped=true&"
import script from "./MedicationListItem.vue?vue&type=script&lang=ts&"
export * from "./MedicationListItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc173c62",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
installComponents(component, {BButton,BListGroupItem,BMedia})
