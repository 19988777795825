
















import { Component, Vue, Prop } from 'vue-property-decorator'
import Dispenser from '@/models/Dispenser'

@Component({
  model: {
    prop: 'checked',
    event: 'input',
  },
})
export default class DispenserSwatch extends Vue {
  @Prop(Object)
  checked?: Dispenser

  @Prop({ type: Object, required: true })
  value!: Dispenser

  get id() {
    return `${this.value.name}-dispenser-option`
  }

  get isChecked() {
    return this.value.id === this.checked?.id
  }

  get swatchStyle() {
    return {
      backgroundColor: this.value.hex,
    }
  }
}
