












import { Vue, Component, Prop } from 'vue-property-decorator'
import SkipToMain from '@/components/SkipToMain.vue'
import Banners from '@/components/Banners.vue'
import Navbar from '@/components/navbar/Navbar.vue'
import AppFooter from '@/components/AppFooter.vue'

@Component({
  components: {
    SkipToMain,
    Banners,
    Navbar,
    AppFooter,
  },
})
export default class DefaultLayout extends Vue {
  @Prop({ type: String, default: '0' })
  menuHeight!: String
}
