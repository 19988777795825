import PPClientModule from './PPClientModule'
import PPAddressesApi from './PPAddressesApi'

export default class PPAddressesClient extends PPClientModule {
  get = this.wrapApi(PPAddressesApi.get)

  addOrUpdate = this.wrapApi(PPAddressesApi.addOrUpdate)

  delete = this.wrapApi(PPAddressesApi.delete)

  setCurrentShippingAddress = this.wrapApi(PPAddressesApi.setCurrentShippingAddress)

  zipcodeLookup = this.wrapAnonApi(PPAddressesApi.zipcodeLookup)
}
