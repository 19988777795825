import { serializable, alias, list, object } from 'serializr'
import { deserializeOnly, ppCoreDate } from '@/models/transforms'
import Order from '@/models/Order'

export default class GetCurrentOrdersResponse {
  @serializable(alias('orders', deserializeOnly(list(object(Order)))))
  orders?: Order[]

  @serializable(alias('projected_next_arrival_date', ppCoreDate()))
  projectedNextArrivalDate?: Date
}
