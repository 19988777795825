















import { Component, Vue, Prop } from 'vue-property-decorator'

import DashboardMedication from '@/models/DashboardMedication'
import OrderRefillModal from '@/components/medications/OrderRefillModal.vue'

@Component({
  components: {
    OrderRefillModal,
  },
})
export default class OrderRefillButton extends Vue {
  @Prop({ type: DashboardMedication, required: true })
  medication!: DashboardMedication

  @Prop(String)
  size?: string

  openModal() {
    this.$bvModal.show(this.modalId)
  }

  get modalId() {
    return `order-med-refill-modal-${this.medication.id}`
  }

  get mayOrderRefill() {
    return this.medication.canOrderRefill || this.medication.canOrderRush
  }
}
