











































































































import { Component, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { mapGetters, mapState } from 'vuex'

import Card from '@/components/Card.vue'
import PageTitle from '@/components/PageTitle.vue'
import Loading from '@/components/Loading.vue'
import StepProgressBar from '@/components/step-progress/StepProgressBar.vue'
import MedicationListItem from '@/components/medications/MedicationListItem.vue'
import InsuranceItem from '@/components/settings/InsuranceItem.vue'

import DashboardMedication from '@/models/DashboardMedication'
import OnboardState from '@/models/Onboard/OnboardState'
import Order from '@/models/Order'
import Physician from '@/models/Physician'
import User, { UserStep } from '@/models/User'

import DeliveryInfoModal from '@/components/DeliveryInfoModal.vue'
import { trackEvent } from '@/plugins/GoogleAnalyticsPlugin'

import { formatDate, formatDateMedium } from '@/i18n'
import Insurance from '@/models/Insurance'
import { CategorizedMeds } from '@/util/categorizeMeds'
import FeatureFlags, { FeatureFlag } from '@/util/featureFlags'

interface OnboardingCopy {
  header?: TranslateResult
  estimate?: TranslateResult
  alert?: TranslateResult
  bullets?: TranslateResult[]
}

enum OnboardingStep {
  CHECK_INSURANCE = 0,
  GATHER_RXS = 1,
  PREPARING_ORDER = 2,

  ON_HOLD = -1,
}

@Component({
  components: {
    Card,
    PageTitle,
    Loading,
    StepProgressBar,
    InsuranceItem,
    MedicationListItem,
    DeliveryInfoModal,
  },
  computed: {
    ...mapState('user', ['me', 'onboardState']),
    ...mapState('insurances', ['insurances']),
    ...mapGetters('medications', ['actionableMedsByPrescriber', 'pendingMedications']),
    ...mapGetters('orders', ['currentOrders']),
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
})
export default class OnboardingCard extends Vue {
  loaded: boolean = false
  me!: User
  onboardState!: OnboardState
  insurances!: Insurance[]
  currentOrders!: Order[]
  pendingMedications!: DashboardMedication[]
  actionableMedsByPrescriber!: CategorizedMeds<Physician>[]
  covidFlag!: FeatureFlag | undefined
  isInProgress!: (key: string, id?: string) => boolean
  hasSucceeded!: (key: string, id?: string) => boolean
  getError!: (key: string, id?: string) => Error

  get isOnboardingLoading() {
    const allLoaded =
      this.me.id && this.onboardState && this.insurances.length && this.pendingMedications.length
    const anyLoading =
      this.isInProgress('user/loadMe') ||
      this.isInProgress('user/loadOnboardState') ||
      this.isInProgress('insurances/load') ||
      this.isInProgress('medications/loadPending')
    return !allLoaded && anyLoading
  }

  get hasError() {
    return !!(
      this.getError('user/loadMe') ||
      this.getError('user/loadOnboardState') ||
      this.getError('insurances/load') ||
      this.getError('medications/loadPending')
    )
  }

  get steps() {
    return [
      { label: this.$t('Verifying insurance') },
      { label: this.$t('Gathering prescriptions') },
      { label: this.$t('Preparing first order') },
    ]
  }

  get userOnboardingStep(): OnboardingStep {
    if (this.me) {
      if (this.me.onboarding) {
        if (this.me.currentStep === UserStep.ONBOARDING_ON_HOLD_STEP) {
          return OnboardingStep.ON_HOLD
        } else if (this.onboardState && this.onboardState.isVerifying) {
          return OnboardingStep.CHECK_INSURANCE
        } else {
          return OnboardingStep.GATHER_RXS
        }
      } else {
        return OnboardingStep.PREPARING_ORDER
      }
    }
    return -1
  }

  get copy(): OnboardingCopy {
    switch (this.userOnboardingStep) {
      case OnboardingStep.CHECK_INSURANCE:
        return this.checkInsuranceStepCopy()

      case OnboardingStep.GATHER_RXS:
        return this.gatherRxsStepCopy

      case OnboardingStep.PREPARING_ORDER:
        return this.preparingBoxStepCopy

      case OnboardingStep.ON_HOLD:
        return this.onHoldStepCopy()

      default:
        return {}
    }
  }

  onHoldStepCopy() {
    return {
      header: this.$t('onboardingSteps.checkInsurance.onHold.header'),
      bullets: [
        this.$t('onboardingSteps.checkInsurance.onHold.bullet1'),
        this.$t('onboardingSteps.checkInsurance.onHold.bullet2'),
      ],
    }
  }

  checkInsuranceStepCopy() {
    return {
      header: this.$t('onboardingSteps.checkInsurance.inProgress.header'),
      estimate: this.$t('onboardingSteps.checkInsurance.inProgress.estimate'),
      alert: this.$t('onboardingSteps.checkInsurance.inProgress.bullet2'),
      bullets: [this.$t('onboardingSteps.checkInsurance.inProgress.bullet1')],
    }
  }

  get gatherRxsStepCopy() {
    if (this.hasDeniedRxs) {
      return {
        header: this.$tc('onboardingSteps.gatherRxs.denied.header', this.denyingDocsCount),
      }
    }

    const chaseCount = this.pendingMedications.reduce(
      (count, med) => (med.selfPrescribed ? count : count + 1),
      0,
    )

    // weeks gathering rx - covid affected
    const weeksGatheringPrescriptions = this.covidFlag?.enabled
      ? this.covidFlag?.options?.weeksGatheringPrescriptions
      : 1
    const estimate = this.$tc(
      'onboardingSteps.gatherRxs.inProgress.estimate',
      weeksGatheringPrescriptions,
    )

    // minimum user supply threshold - covid affected
    const supplyThreshold = this.covidFlag?.enabled
      ? this.covidFlag?.options?.weeksSupplyThreshold
      : 3
    const alert = this.$t('onboardingSteps.gatherRxs.inProgress.bullet3', {
      count: supplyThreshold,
    })

    if (chaseCount === 0 && this.onboardState?.isInFinalCheck) {
      return {
        header: this.$t('onboardingSteps.gatherRxs.finalCheck.header'),
        estimate,
        alert,
        bullets: [this.$t('onboardingSteps.gatherRxs.finalCheck.bullet1')],
      }
    }

    return {
      header: this.$tc('onboardingSteps.gatherRxs.inProgress.header', chaseCount),
      estimate,
      alert,
      bullets: [
        this.$t('onboardingSteps.gatherRxs.inProgress.bullet1'),
        this.$t('onboardingSteps.gatherRxs.inProgress.bullet2'),
      ],
    }
  }

  get preparingBoxStepCopy() {
    const dateFormat = 'MM/DD'
    const firstOrder = this.firstOrder
    const bullet1 =
      firstOrder && firstOrder.shipmentInfo
        ? this.$t('onboardingSteps.preparingBox.bullet1', {
            startDate: formatDate(firstOrder.shipmentInfo.startDate, dateFormat),
            shipDate: formatDate(firstOrder.shipmentInfo.shipDate, dateFormat),
          })
        : this.$t('onboardingSteps.preparingBox.bullet1Alt')

    // preparing first order - covid affected
    let estimate: TranslateResult | string
    if (firstOrder && firstOrder.deliveredAt) {
      estimate = this.$t('onboardingSteps.preparingBox.estimate', {
        arrivalDate: formatDateMedium(firstOrder.deliveredAt),
      })
    } else {
      const count = this.covidFlag?.enabled ? this.covidFlag?.options?.weeksPreparingOrder : 1
      estimate = this.$tc('onboardingSteps.preparingBox.estimateDuration', count)
    }
    return {
      header: this.$t('onboardingSteps.preparingBox.header'),
      estimate,
      bullets: [bullet1, this.$t('onboardingSteps.preparingBox.bullet2')],
    }
  }

  get accountOnHold(): boolean {
    return this.me.currentStep === UserStep.ONBOARDING_ON_HOLD_STEP
  }

  get firstOrder(): Order | undefined {
    return this.currentOrders[0]
  }

  get hasDeniedRxs(): boolean {
    return this.denyingDocsCount > 0
  }

  get denyingDocsCount(): number {
    return this.actionableMedsByPrescriber.length
  }

  get showContactUs(): boolean {
    return this.accountOnHold
  }

  get showRefillWarning(): boolean {
    return this.userOnboardingStep === OnboardingStep.GATHER_RXS
  }

  showDeliveryInfoModal() {
    trackEvent({
      eventCategory: 'cwa_onboarding_tour',
      eventAction: 'cwa_onboarding_why_3_weeks',
    })
    this.$bvModal.show('delivery-info-modal')
  }

  async created() {
    const covidFlag = await FeatureFlags.get('covid-onboarding')
    if (covidFlag?.enabled) {
      this.covidFlag = covidFlag
    }
    this.loaded = true
  }
}
