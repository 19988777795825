























import { Component, Vue, Prop } from 'vue-property-decorator'
import Insurance from '@/models/Insurance'

@Component
export default class InsuranceItem extends Vue {
  @Prop({ type: Insurance, required: true })
  insurance!: Insurance
}
