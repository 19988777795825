import { Module } from 'vuex'
import Vue from 'vue'
import throttle from 'lodash/throttle'
import { RootState } from '@/store'
import { Condition, GetConditions } from '@/models/Conditions'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import { BannerInfo } from './banners'
import { $t } from '@/i18n'

export interface ConditionsState {
  conditions: Condition[]
  searchResults: Condition[] | Error
  commonConditions: Condition[]
  otherConditions: string[]
  currentQuery: string
}

const module: Module<ConditionsState, RootState> = {
  namespaced: true,
  state: {
    conditions: [],
    commonConditions: [],
    otherConditions: [],
    searchResults: [],
    currentQuery: '',
  },
  mutations: {
    addCondition(state, condition: Condition) {
      state.conditions.push(condition)
    },
    updateCurrentQuery(state, query: string) {
      state.currentQuery = query
    },
    updateSearchResults(state, conditions: Condition[]) {
      state.searchResults = conditions
    },
    resetSearch(state) {
      state.searchResults = []
      state.currentQuery = ''
    },
    updateConditions(state, conditions: Condition[]) {
      state.conditions = conditions
    },
    updateCommonConditions(state, conditions: Condition[]) {
      state.commonConditions = conditions
    },
    updateOtherConditions(state, list: string[]) {
      state.otherConditions = list
    },
  },
  actions: {
    load: trackAsyncStatus('conditions/load', async function ({ dispatch }) {
      const conditions = await Vue.$pillpack.allergiesConditions.conditions()
      dispatch('handleDataFromServer', conditions)
    }),

    search: throttle(
      async ({ commit, state, dispatch }, query: string) => {
        if (!query || query.length < 3) {
          commit('resetSearch')
          return
        }
        commit('updateCurrentQuery', query)

        const conditions = await dispatch('_fetchSearchResults', query)
        if (query !== state.currentQuery) return
        commit('updateSearchResults', conditions || [])
        commit('updateCurrentQuery', '')
      },
      500,
      { trailing: true },
    ),

    // Use a second action for search to track async requests separately
    _fetchSearchResults: trackAsyncStatus(
      'conditions/search',
      async function (_, query) {
        const conditions = await Vue.$pillpack.allergiesConditions.searchConditions(query)
        return conditions
      },
      { mergeRequests: false },
    ),

    loadCommon: trackAsyncStatus('conditions/loadCommon', async function ({ commit }) {
      const conditions = await Vue.$pillpack.allergiesConditions.commonConditions()
      commit('updateCommonConditions', conditions)
    }),

    addCondition: trackAsyncStatus(
      'conditions/addCondition',
      async function ({ state, commit, dispatch }, condition: Condition | string) {
        if (typeof condition === 'string') {
          const newList = [...state.otherConditions, condition]
          commit('updateOtherConditions', newList)
          const conditions = await Vue.$pillpack.allergiesConditions.updateOtherConditions(newList)
          dispatch('handleDataFromServer', conditions)
        } else {
          commit('addCondition', condition)
          const conditions = await Vue.$pillpack.allergiesConditions.addCondition(condition.id)
          dispatch('handleDataFromServer', conditions)
        }

        const banner: BannerInfo = {
          id: 'conditions-save-complete',
          bgVariant: 'success',
          title: $t('Updated!'),
          message: $t('Conditions have been added to your health profile'),
          durationMs: 3000,
        }

        dispatch('showBanner', banner, { root: true })
      },
    ),

    addConditions: trackAsyncStatus(
      'conditions/addCondition',
      async function (
        { dispatch },
        { conditionIds }: { conditionIds: string[]; otherConditions: string[] },
      ) {
        if (conditionIds.length) {
          const conditions = await Vue.$pillpack.allergiesConditions.addConditions(conditionIds)
          dispatch('handleDataFromServer', conditions)
        }
      },
    ),

    handleDataFromServer({ commit }, conditions: GetConditions) {
      commit('updateConditions', conditions.conditions)
      if (conditions.otherConditions) {
        commit('updateOtherConditions', conditions.otherConditions)
      }
    },
  },
  getters: {
    allFormatted(state) {
      return state.conditions
        .map(a => a.displayName)
        .concat(state.otherConditions)
        .join(', ')
    },
  },
}

export default module
