import { serializable, primitive, identifier, alias, object } from 'serializr'

export class SignupPhysicianAddress {
  @serializable(alias('street', primitive()))
  street!: string

  @serializable(alias('street2', primitive()))
  street2!: string

  @serializable(alias('city', primitive()))
  city!: string

  @serializable(alias('state', primitive()))
  state!: string

  @serializable(alias('zip', primitive()))
  zipcode!: string
}

export default class SignupPhysician {
  @serializable(identifier())
  id!: string

  @serializable(alias('first_name', primitive()))
  firstName!: string

  @serializable(alias('last_name', primitive()))
  lastName!: string

  @serializable(alias('phone_number', primitive()))
  phoneNumber!: string

  @serializable(alias('fax_number', primitive()))
  faxNumber!: string

  @serializable(alias('npi', primitive()))
  npi!: string

  @serializable(alias('spi', primitive()))
  spi!: string

  @serializable(alias('prescriber_id', primitive()))
  prescriberId!: string

  @serializable(alias('surescripts_prescriber_id', primitive()))
  surescriptsPrescriberId!: string

  @serializable(alias('clinic_name', primitive()))
  clinicName!: string

  @serializable(alias('address', object(SignupPhysicianAddress)))
  address?: SignupPhysicianAddress

  get fullName() {
    return [this.firstName, this.lastName].filter(name => !!name).join(' ')
  }

  get clinic() {
    return this.clinicName || this.formattedAddress
  }

  get formattedAddress(): string {
    if (!this.address) return ''

    const { street, city, state, zipcode } = this.address
    return `${street}, ${city}, ${state} ${zipcode?.substring(0, 5)}`
  }

  get categoryId(): string {
    return this.spi || this.surescriptsPrescriberId || this.id
  }
}
