






















































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import platform from '@/util/platform'

import Modal from '@/components/Modal.vue'
@Component({
  components: {
    Modal,
  },
  computed: {
    ...mapState(['showTrustedDeviceModal']),
  },
})
export default class TrustDeviceModal extends Vue {
  showTrustedDeviceModal!: boolean

  onHide() {
    this.$store.commit('hideTrustedDeviceModal')
  }

  showModal() {
    return true
  }

  isMobile() {
    if (platform.platform === null) return false
    return true
  }

  async setTrustedDevice() {
    this.$store.dispatch('setTrustedDevice')
    this.$bvModal.hide('trusted-device-modal')
  }
}
