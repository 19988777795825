import { PluginObject } from 'vue'
import errorInfoFromVm, { VMErrorInfo } from '@/util/errorInfoFromVm'

declare global {
  interface Window {
    newrelic: NewRelic
  }
}

export class NewRelic {
  enabled: boolean = !!process.env.ENABLE_NR

  constructor() {
    if (this.enabled) {
      const release = process.env.GIT_COMMIT as string
      this.addRelease(release, release)
    }
  }

  addRelease(releaseName: string, releaseId: string): void {
    if (window.newrelic) {
      window.newrelic.addRelease(releaseName, releaseId)
    }
  }

  noticeError(
    error: Error,
    customAttributes?: VMErrorInfo | { [key: string]: string | number | boolean | undefined },
  ): void {
    if (this.enabled && window.newrelic) {
      window.newrelic.noticeError(error, customAttributes)
    }
  }

  addPageAction(
    eventType: string,
    attributes?: { [keys: string]: boolean | number | string },
  ): void {
    if (this.enabled && window.newrelic) {
      window.newrelic.addPageAction(eventType, attributes)
    }
  }
}

const plugin: PluginObject<void> = {
  install(Vue: any) {
    const ppnr = new NewRelic()
    Vue.prototype.$newrelic = ppnr
    Vue.$newrelic = ppnr

    // hook into Vue's error handling
    const chainedErrorHandler = Vue.config.errorHandler

    Vue.config.errorHandler = (error: Error, vm: any, info: any) => {
      ppnr.noticeError(error, errorInfoFromVm(vm))

      if (typeof chainedErrorHandler === 'function') {
        chainedErrorHandler.call(Vue, error, vm, info)
      } else {
        throw error
      }
    }
  },
}

export default plugin
