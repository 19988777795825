import { serializable, primitive, identifier, alias, list } from 'serializr'

export default class HelpCenterArticle {
  @serializable(identifier())
  id!: number

  @serializable(primitive())
  url!: string

  @serializable(alias('html_url', primitive()))
  htmlUrl!: string

  @serializable(alias('created_at', primitive()))
  createdAt!: string

  @serializable(alias('updated_at', primitive()))
  updatedAt!: string

  @serializable(primitive())
  name!: string

  @serializable(primitive())
  title!: string

  @serializable(primitive())
  locale!: string

  @serializable(alias('label_names', list(primitive())))
  labelNames!: string[]

  @serializable(primitive())
  body!: string

  @serializable(primitive())
  snippet!: string

  @serializable(alias('result_type', primitive()))
  resultType!: string
}
