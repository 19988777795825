import { serializable, primitive, alias, list, object } from 'serializr'
import DashboardMedication from '@/models/DashboardMedication'

export default class OrderLineItem {
  @serializable(alias('dispense_group_id', primitive()))
  id?: string

  @serializable(alias('quantity_string', primitive()))
  quantity?: string

  @serializable(alias('cost_string', primitive()))
  cost?: string

  @serializable(alias('payment_type', primitive()))
  paymentType?: 'insurance' | 'cash'

  @serializable(alias('cost_is_pending', primitive()))
  costIsPending?: boolean

  @serializable(alias('fee_waived', primitive()))
  feeWaived?: boolean

  @serializable(alias('is_returned', primitive()))
  isReturned?: boolean

  @serializable(alias('is_overdue', primitive()))
  isOverdue?: boolean

  @serializable(alias('med_description', primitive()))
  medDescription?: string

  @serializable(alias('med_image_url', primitive()))
  medImageUrl?: string

  @serializable(alias('prescription_id', primitive()))
  prescriptionId?: string

  @serializable(alias('self_prescribed', primitive()))
  selfPrescribed?: boolean

  @serializable(alias('include_in_shipment', primitive()))
  inShipment?: boolean

  @serializable(alias('is_premium_dispenser', primitive()))
  isPremiumDispenser?: boolean

  @serializable(alias('sorted_doses_in_12_hour_format', list(list(primitive()))))
  sortedDosesIn12HourFormat?: [string, number][]

  @serializable(alias('dashboard_medication', object(DashboardMedication)))
  dashboardMedication?: DashboardMedication
}
