const hasLocalStorage = () => !!window.localStorage

export function setLocalStorage(key: string, value: any) {
  return hasLocalStorage() ? localStorage.setItem(key, value) : null
}

export function getLocalStorage(key: string, fallback?: any) {
  if (!hasLocalStorage()) return null
  const stored = localStorage.getItem(key)
  if (stored === null && fallback !== undefined) return fallback
  return stored
}

export function clearLocalStorage(key: string) {
  return hasLocalStorage() ? localStorage.removeItem(key) : null
}
