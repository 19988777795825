import { render, staticRenderFns } from "./MobileNavLink.vue?vue&type=template&id=5e3eb871&scoped=true&"
import script from "./MobileNavLink.vue?vue&type=script&lang=ts&"
export * from "./MobileNavLink.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e3eb871",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BLink } from 'bootstrap-vue'
installComponents(component, {BLink})
