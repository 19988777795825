








import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Banner from '@/components/Banner.vue'
import { BannerInfo } from '@/vuex/banners'

const EMPTY_BANNER: BannerInfo = {
  id: 'empty',
  message: '\u00A0', // Non-breaking space
  bgVariant: 'light',
}

@Component({
  components: {
    Banner,
  },
  computed: {
    ...mapGetters(['allBanners', 'temporaryBanners', 'dismissibleBanners', 'permanentBanners']),
  },
})
export default class Banners extends Vue {
  allBanners!: BannerInfo[]
  temporaryBanners!: BannerInfo[]
  dismissibleBanners!: BannerInfo[]
  permanentBanners!: BannerInfo[]

  get topBanner(): BannerInfo {
    // Show any temporary banners before dismissible banners before permanent banners
    // Empty banner needed for better transitions
    return (
      this.temporaryBanners[0] ||
      this.dismissibleBanners[0] ||
      this.permanentBanners[0] ||
      EMPTY_BANNER
    )
  }

  get hasBanner(): boolean {
    return !!this.allBanners.length
  }
}
