import type { Dimension } from 'aws-sdk/clients/cloudwatch'
import { TranslateResult } from 'vue-i18n'
import { serializable, primitive, object } from 'serializr'
import { trackingEventDate } from '@/models/transforms'
import TrackingAddress from './TrackingAddress'

export type TrackingEventState =
  | 'UNDISPLAYED'
  | 'UNDELIVERABLE'
  | 'PARTIAL_DELIVERED'
  | 'IN_TRANSIT'
  | 'DELIVERED'
  | 'OUT_FOR_DELIVERY'
  | 'DELIVERY_ATTEMPTED'
  | 'AVAILABLE_FOR_PICKUP'
  | 'DELAYED'
  | 'RETURNING'
  | 'RETURNED'
  | 'CUSTOMER_ACTION'
  | 'PICKUP_ATTEMPTED'
  | 'PICKUP_CANCELLED'
  | 'PICKUP_SUCCESSFUL'
  | 'RETURN_REQUEST_ACCEPTED'
  | 'PICKUP_SCHEDULED'
  | 'RETURN_RECEIVED_IN_FC'
  | 'REFUND_ISSUED'
  | ''

export default class TrackingEvent {
  static readonly HiddenStates: TrackingEventState[] = ['UNDISPLAYED', '']

  /**
   * Prefer shortStatus
   */
  @serializable(primitive())
  depictedState!: TrackingEventState

  /**
   * Preferred over depictedState
   */
  @serializable(primitive())
  shortStatus!: TrackingEventState

  @serializable(primitive())
  eventCode!: string

  @serializable(primitive())
  eventDescriptionTranslatorKey!: string

  @serializable(trackingEventDate())
  eventDate?: Date

  @serializable(primitive())
  source!: string

  @serializable(object(TrackingAddress))
  eventAddress!: TrackingAddress

  translation?: TranslateResult
  subText?: TranslateResult
  hide? = false
  duplicate? = false

  get isSynthetic() {
    return !this.eventDescriptionTranslatorKey
  }

  get shouldHide() {
    return !this.shortStatus || TrackingEvent.HiddenStates.includes(this.shortStatus)
  }

  get cloudWatchDimensions(): Dimension[] {
    const dimensions: Array<keyof TrackingEvent> = [
      'source',
      'depictedState',
      'shortStatus',
      'eventCode',
      'eventDescriptionTranslatorKey',
      'translation',
    ]

    return dimensions
      .filter(d => this[d])
      .map(
        (d): Dimension => {
          return {
            Name: d,
            Value: this[d]?.toString() as string,
          }
        },
      ) as Dimension[]
  }
}
