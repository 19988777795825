import { PPHttp, PPHttpMethod } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { serialize, deserialize, deserializeArray } from '@/models/transforms'
import SignupPhysician from '@/models/SignupPhysician'
import Physician from '@/models/Physician'
import { PPArgs, PPBaseArgs } from './PPClient'
import { PrescriberSearchQuery } from '@/vuex/physicians'

export default class PPPhysiciansApi {
  static async searchPhysicians(
    args: PPBaseArgs,
    { lastName, phoneNumber }: { lastName: string; phoneNumber: string },
  ): Promise<PPResponse<SignupPhysician[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: '/api/v2/signup/search_prescribers',
      urlParams: {
        name: lastName,
        phone_number: phoneNumber,
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserializeArray(SignupPhysician, response.data)

    return new PPSuccessResponse(results)
  }

  static async searchPhysiciansByLocation(
    args: PPBaseArgs,
    { lastName, location, distance, firstName, phone }: PrescriberSearchQuery,
  ): Promise<PPResponse<SignupPhysician[]>> {
    const urlParams = {
      last_name: lastName,
      origin: location,
      distance,
      first_name: firstName || undefined,
      phone: phone || undefined,
    }

    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: '/api/v2/signup/search_prescribers_by_location_bounding_box',
      urlParams,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserializeArray(SignupPhysician, response.data.prescribers)

    return new PPSuccessResponse(results)
  }

  static async allPhysicians(args: PPArgs): Promise<PPResponse<Physician[]>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/physicians`,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const results = deserializeArray(Physician, response.data.physicians)

    return new PPSuccessResponse(results)
  }

  static async getPhysician(
    args: PPArgs,
    { prescriberId }: { prescriberId: string },
  ): Promise<PPResponse<SignupPhysician>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl: args.baseUrl,
      path: `/consumer_api/physicians/${prescriberId}`,
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const physician = deserialize(SignupPhysician, response.data.physician)

    return new PPSuccessResponse(physician)
  }

  static async addPhysician(
    args: PPArgs,
    { physician }: { physician: Physician },
  ): Promise<PPResponse<Physician>> {
    const response = await PPHttp.request({
      csrfToken: args.session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl: args.baseUrl,
      path: `/consumer_api/users/${args.userId}/physicians`,
      content: {
        physician: serialize(Physician, physician),
      },
    })

    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    const savedPhysician = deserialize(Physician, response.data.physician)

    return new PPSuccessResponse(savedPhysician)
  }
}
