/* eslint-disable import/no-mutable-exports */
import { textSearch as t, geocode as g, details as d } from './googlePlaces'

const textSearch = async ({ name, location }: { name: string; location: google.maps.LatLng }) => {
  return window.googlePlacesUtil?.textSearch
    ? window.googlePlacesUtil.textSearch({ name, location })
    : t({ name, location })
}

const geocode = async (zipcode: string): Promise<google.maps.GeocoderResult[]> => {
  return window.googlePlacesUtil?.geocode
    ? window.googlePlacesUtil.geocode({ zipcode })
    : g(zipcode)
}

const details = async (placeId: string): Promise<google.maps.places.PlaceResult> => {
  return window.googlePlacesUtil?.details ? window.googlePlacesUtil.details(placeId) : d(placeId)
}

export { textSearch, geocode, details }
