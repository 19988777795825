import { serializable, alias, primitive, object, list } from 'serializr'
import PacketUserMedication from '@/models/Today/PacketUserMedication'
import Dose from '@/models/PrescriptionDose'

export default class BulkMedication {
  @serializable(alias('id', primitive()))
  id?: string

  @serializable(alias('class', primitive()))
  class?: string

  @serializable(alias('icon', primitive()))
  icon?: string

  @serializable(alias('desc', primitive()))
  name?: string

  @serializable(alias('doses', list(object(Dose))))
  doses?: Dose[]

  @serializable(alias('directions', primitive()))
  directions?: string

  @serializable(alias('user_medication', object(PacketUserMedication)))
  userMedication!: PacketUserMedication

  @serializable(alias('created_at', primitive()))
  createdAt?: string

  @serializable(alias('updated_at', primitive()))
  updatedAt?: string

  // Normalized shape with PacketMedication
  get tabCount() {
    return undefined
  }
}
