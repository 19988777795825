import { Module } from 'vuex'
import { RootState } from '@/store'
import platform, { PlatformType } from '@/util/platform'

export interface PWAState {
  loadPWAPrompts: boolean
  showPWAModal: boolean
  platform: PlatformType | null
}

const state: PWAState = {
  loadPWAPrompts: platform.shouldLoadInstallPrompts(),
  showPWAModal: false,
  platform: platform.platform,
}

const module: Module<PWAState, RootState> = {
  namespaced: true,
  state,
  actions: {
    afterPrompt: () => platform.afterPrompt(),
  },
  mutations: {
    toggleModal(state, value) {
      state.showPWAModal = value
    },
  },
}

export default module
