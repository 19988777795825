

























import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import ServiceAlert from '@/models/ServiceAlert'

@Component({
  computed: {
    ...mapState('alerts', { serviceAlerts: 'unresolved' }),
  },
})
export default class ServiceAlertNavBell extends Vue {
  serviceAlerts!: ServiceAlert[]
  created() {
    this.$store.dispatch('alerts/ensureLoaded')
  }
}
