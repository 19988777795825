import { Module } from 'vuex'
import { RootState } from '@/store'
import HelpCenterArticle from '@/models/HelpCenterArticle'
import trackAsyncStatus from '@/util/trackAsyncStatus'
import searchHelpCenter, { SearchResponse, cancelRequest } from '@/util/helpCenterApi'

export interface HelpCenterState {
  query: string
  searchResults: HelpCenterArticle[]
  resultsCount: number
  resultsQuery: string
}

const module: Module<HelpCenterState, RootState> = {
  namespaced: true,
  state: {
    query: '',
    resultsQuery: '',
    resultsCount: 0,
    searchResults: [],
  },
  mutations: {
    setQuery: (state, query: string) => {
      state.query = query
    },
    setSearchResults: (state, search: SearchResponse) => {
      state.resultsQuery = search.query
      state.resultsCount = search.count
      state.searchResults = search.results
    },
  },
  actions: {
    runSearch: trackAsyncStatus(
      'helpCenter/search',
      async ({ commit, state }) => {
        const query = state.query
        if (!query) return
        const response = await searchHelpCenter(query)
        commit('setSearchResults', response)
      },
      // It's important not to merge requests because we are only
      // changing the url query and want each query to have its own request
      { mergeRequests: false },
    ),

    clear: ({ commit }) => {
      cancelRequest()
      commit('setSearchResults', { query: '', count: 0, results: [] })
    },
  },
}

export default module
