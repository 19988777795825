import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '@/store'
import AsyncStatus from '@/util/AsyncStatus'

type asyncStatusLookup = {
  [id: string]: AsyncStatus
}

interface State {
  startSession: AsyncStatus
  resetPassword: AsyncStatus
  editPassword: AsyncStatus
  setTrustedDevice: AsyncStatus
  'addresses/get': AsyncStatus
  'addresses/addOrUpdate': AsyncStatus
  'addresses/delete': AsyncStatus
  'addresses/setCurrentShippingAddress': AsyncStatus
  'addresses/zipcodeLookup': AsyncStatus
  'alerts/load': AsyncStatus
  'alerts/acknowledge': asyncStatusLookup
  'alerts/resolve': asyncStatusLookup
  'alerts/viewMany': AsyncStatus
  'allergies/load': AsyncStatus
  'allergies/loadCommon': AsyncStatus
  'allergies/addAllergy': AsyncStatus
  'allergies/search': AsyncStatus
  'conditions/load': AsyncStatus
  'conditions/search': AsyncStatus
  'conditions/loadCommon': AsyncStatus
  'conditions/addCondition': AsyncStatus
  'dispensers/load': AsyncStatus
  'dispensers/updatePremiumDispenser': AsyncStatus
  'insurances/load': AsyncStatus
  'insurances/create': AsyncStatus
  'helpCenter/search': AsyncStatus
  'paymentMethods/load': AsyncStatus
  'paymentMethods/makeDefault': AsyncStatus
  'paymentMethods/delete': asyncStatusLookup
  'paymentMethods/addCard': AsyncStatus
  'paymentMethods/addBankAccount': AsyncStatus
  'paymentMethods/verify': asyncStatusLookup
  'paymentMethods/makePayment': AsyncStatus
  'medications/load': AsyncStatus
  'medications/loadPending': AsyncStatus
  'medications/loadOne': asyncStatusLookup
  'medications/loadLiterature': asyncStatusLookup
  'medications/loadToday': AsyncStatus
  'medications/pauseAutoRefill': asyncStatusLookup
  'medications/resumeAutoRefill': asyncStatusLookup
  'medications/delete': asyncStatusLookup
  'medications/requestRefill': asyncStatusLookup
  'medications/cancelRefillRequest': asyncStatusLookup
  'medications/orderRushShipment': asyncStatusLookup
  'medications/cancelRushShipment': asyncStatusLookup
  'medications/medPreferenceChange': AsyncStatus
  'medications/search': AsyncStatus
  'medications/getRxCatalog': AsyncStatus
  'medications/getRxConcepts': AsyncStatus
  'medications/requestOtc': AsyncStatus
  'medications/requestOtcMedication': AsyncStatus
  'user/loadMe': AsyncStatus
  'user/loadCaregiver': AsyncStatus
  'user/loadOnboardState': AsyncStatus
  'user/loadAlerts': AsyncStatus
  'user/addCaregiver': AsyncStatus
  'user/removeCaregiver': AsyncStatus
  'user/resendEmailConfirmation': AsyncStatus
  'user/switchAccount': AsyncStatus
  'user/updatePassword': AsyncStatus
  'user/updateName': AsyncStatus
  'user/updateEmail': AsyncStatus
  'user/updatePhoneNumber': AsyncStatus
  'user/updateSms': AsyncStatus
  'user/updatePhiInCommunications': AsyncStatus
  'user/updateUserIdentification': AsyncStatus
  'user/updateChildSafePackaging': AsyncStatus
  'user/updateLastFour': AsyncStatus
  'user/turnOnAutopay': AsyncStatus
  'user/makeManualOrderPayment': AsyncStatus
  'user/makeManualPartialPayment': AsyncStatus
  'orders/loadPast.initial': AsyncStatus
  'orders/loadPast.more': AsyncStatus
  'orders/loadCurrent': AsyncStatus
  'orders/loadOne': asyncStatusLookup
  'orders/loadPreferences': AsyncStatus
  'orders/loadBillPayments': AsyncStatus
  'orders/loadShipmentLiterature': asyncStatusLookup
  'otcMedications/load': AsyncStatus
  'projectedShipments/loadNext': AsyncStatus
  'userEvents/load': AsyncStatus
  'userEvents/acknowledge': asyncStatusLookup
  'supplements/load': AsyncStatus
  'addRx/loadMedStrengths': AsyncStatus
  'addRx/loadPrescriberDetails': AsyncStatus
  'addRx/loadRxConcepts': AsyncStatus
  'addRx/createMedRequest': AsyncStatus
  'physicians/load': AsyncStatus
  'physicians/add': AsyncStatus
  'physicians/searchByPhone': AsyncStatus
  'physicians/searchByLocation': AsyncStatus
  'physicianUpdate/sendRequest': AsyncStatus
  'physicianUpdate/selectPhysician': AsyncStatus
  'pharmacies/load': AsyncStatus
  'pharmacies/search': AsyncStatus
  'pharmacies/add': AsyncStatus
  'pharmacies/getDetails': AsyncStatus
  'tracking/load': AsyncStatus
}

const notStarted = () => new AsyncStatus()

const defaultState: State = {
  startSession: notStarted(),
  resetPassword: notStarted(),
  editPassword: notStarted(),
  setTrustedDevice: notStarted(),
  'addresses/get': notStarted(),
  'addresses/addOrUpdate': notStarted(),
  'addresses/delete': notStarted(),
  'addresses/setCurrentShippingAddress': notStarted(),
  'addresses/zipcodeLookup': notStarted(),
  'alerts/load': notStarted(),
  'alerts/acknowledge': {},
  'alerts/resolve': {},
  'alerts/viewMany': notStarted(),
  'allergies/load': notStarted(),
  'allergies/loadCommon': notStarted(),
  'allergies/addAllergy': notStarted(),
  'allergies/search': notStarted(),
  'conditions/load': notStarted(),
  'conditions/search': notStarted(),
  'conditions/loadCommon': notStarted(),
  'conditions/addCondition': notStarted(),
  'dispensers/load': notStarted(),
  'dispensers/updatePremiumDispenser': notStarted(),
  'insurances/load': notStarted(),
  'insurances/create': notStarted(),
  'helpCenter/search': notStarted(),
  'paymentMethods/load': notStarted(),
  'paymentMethods/makeDefault': notStarted(),
  'paymentMethods/delete': {},
  'paymentMethods/addCard': notStarted(),
  'paymentMethods/addBankAccount': notStarted(),
  'paymentMethods/verify': {},
  'paymentMethods/makePayment': notStarted(),
  'medications/load': notStarted(),
  'medications/loadPending': notStarted(),
  'medications/loadOne': {},
  'medications/loadLiterature': {},
  'medications/loadToday': notStarted(),
  'medications/pauseAutoRefill': {},
  'medications/resumeAutoRefill': {},
  'medications/delete': {},
  'medications/requestRefill': {},
  'medications/cancelRefillRequest': {},
  'medications/orderRushShipment': {},
  'medications/cancelRushShipment': {},
  'medications/medPreferenceChange': notStarted(),
  'medications/search': notStarted(),
  'medications/getRxCatalog': notStarted(),
  'medications/getRxConcepts': notStarted(),
  'medications/requestOtc': notStarted(),
  'medications/requestOtcMedication': notStarted(),
  'user/loadMe': notStarted(),
  'user/loadCaregiver': notStarted(),
  'user/loadOnboardState': notStarted(),
  'user/loadAlerts': notStarted(),
  'user/addCaregiver': notStarted(),
  'user/removeCaregiver': notStarted(),
  'user/resendEmailConfirmation': notStarted(),
  'user/switchAccount': notStarted(),
  'user/updatePassword': notStarted(),
  'user/updateEmail': notStarted(),
  'user/updatePhoneNumber': notStarted(),
  'user/updateSms': notStarted(),
  'user/updatePhiInCommunications': notStarted(),
  'user/updateUserIdentification': notStarted(),
  'user/updateChildSafePackaging': notStarted(),
  'user/updateLastFour': notStarted(),
  'user/updateName': notStarted(),
  'user/turnOnAutopay': notStarted(),
  'user/makeManualOrderPayment': notStarted(),
  'user/makeManualPartialPayment': notStarted(),
  'orders/loadPast.initial': notStarted(),
  'orders/loadPast.more': notStarted(),
  'orders/loadCurrent': notStarted(),
  'orders/loadOne': {},
  'orders/loadPreferences': notStarted(),
  'orders/loadBillPayments': notStarted(),
  'orders/loadShipmentLiterature': {},
  'otcMedications/load': notStarted(),
  'projectedShipments/loadNext': notStarted(),
  'userEvents/load': notStarted(),
  'userEvents/acknowledge': {},
  'supplements/load': notStarted(),
  'addRx/loadMedStrengths': notStarted(),
  'addRx/loadPrescriberDetails': notStarted(),
  'addRx/loadRxConcepts': notStarted(),
  'addRx/createMedRequest': notStarted(),
  'physicians/load': notStarted(),
  'physicians/add': notStarted(),
  'physicians/searchByPhone': notStarted(),
  'physicians/searchByLocation': notStarted(),
  'physicianUpdate/sendRequest': notStarted(),
  'physicianUpdate/selectPhysician': notStarted(),
  'pharmacies/load': notStarted(),
  'pharmacies/search': notStarted(),
  'pharmacies/add': notStarted(),
  'pharmacies/getDetails': notStarted(),
  'tracking/load': notStarted(),
}

function isLookup(val: AsyncStatus | asyncStatusLookup): val is asyncStatusLookup {
  return !val?.status
}

export type AsyncStatusKey = keyof State

interface BasePayload {
  key: AsyncStatusKey
  id?: string
}

function getOrCreateStatus(state: State, { key, id }: BasePayload) {
  const val = state[key]
  if (isLookup(val)) {
    if (id) {
      if (!val[id]) Vue.set(state[key], id, notStarted())
      return val[id]
    }
  } else {
    return val
  }
  return null
}

/**
 * Finds the appropriate AsyncStatus instance whether it is in a lookup or not
 * Runs the callback with the status and payload
 * @param mutationCb perform a function on an AsyncStatus
 */
function statusMutation<P extends BasePayload>(
  mutationCb: (status: AsyncStatus, payload: P) => void,
) {
  return (state: State, payload: P) => {
    const val = getOrCreateStatus(state, payload)
    if (val) mutationCb(val, payload)
  }
}

const defaultStatus = (status?: AsyncStatus): AsyncStatus => status || notStarted()

const module: Module<State, RootState> = {
  namespaced: true,
  state: defaultState,

  mutations: {
    start: statusMutation((status, { request }) => status.start(request)),
    succeed: statusMutation(status => status.succeed()),
    fail: statusMutation((status, { err }) => status.fail(err)),
    reset: statusMutation(status => status.reset()),
  },

  actions: {},

  getters: {
    isLoading: (state: State) => (key: AsyncStatusKey, id: string): boolean => {
      // This getter is true only when loading resources for the first time or after a failure
      const substate = state[key]
      const status = isLookup(substate) ? defaultStatus(substate[id]) : substate
      return status.loading || status.retrying
    },
    isInProgress: (state: State) => (key: AsyncStatusKey, id: string): boolean => {
      const substate = state[key]
      const status = isLookup(substate) ? defaultStatus(substate[id]) : substate
      return status.inProgress
    },
    hasSucceeded: (state: State) => (key: AsyncStatusKey, id: string): boolean => {
      const substate = state[key]
      const status = isLookup(substate) ? defaultStatus(substate[id]) : substate
      return status.succeeded
    },
    hasSucceededBefore: (state: State) => (key: AsyncStatusKey, id: string): boolean => {
      const substate = state[key]
      const status = isLookup(substate) ? defaultStatus(substate[id]) : substate
      return status.succeeded || status.reloading
    },
    getInFlightRequest: (state: State) => (key: AsyncStatusKey, id: string): Promise<any> => {
      const substate = state[key]
      const status = isLookup(substate) ? defaultStatus(substate[id]) : substate
      return status.request || Promise.resolve(null)
    },
    getError: (state: State) => (key: AsyncStatusKey, id: string): Error | null => {
      const substate = state[key]
      const status = isLookup(substate) ? defaultStatus(substate[id]) : substate
      return status.error
    },
    getPasswordStrengthMsg: (state: State) => (key: AsyncStatusKey, id: string): Error | null => {
      const substate = state[key]
      const error: any = isLookup(substate) ? defaultStatus(substate[id]).error : substate.error
      return error?.data?.errors?.password?.[0]?.message
    },
  },
}

export default module
