import UnreachableCaseError from '@/util/UnreachableCaseError'

export enum ASYNC_STATE {
  NOT_STARTED = 'notStarted',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  RELOADING = 'reloading',
  FAILED = 'failed',
  RETRYING = 'retrying',
}

export default class AsyncStatus {
  private _state: ASYNC_STATE = ASYNC_STATE.NOT_STARTED
  private _request: Promise<any> | null = null
  private _error: Error | null = null

  get status() {
    return this._state
  }

  get notStarted() {
    return this._state === ASYNC_STATE.NOT_STARTED
  }

  get loading() {
    return this._state === ASYNC_STATE.LOADING
  }

  get succeeded() {
    return this._state === ASYNC_STATE.SUCCEEDED
  }

  get reloading() {
    return this._state === ASYNC_STATE.RELOADING
  }

  get failed() {
    return this._state === ASYNC_STATE.FAILED
  }

  get retrying() {
    return this._state === ASYNC_STATE.RETRYING
  }

  get request() {
    return this._request
  }

  get error() {
    return this._error
  }

  get inProgress() {
    return this.loading || this.reloading || this.retrying
  }

  start(request: Promise<any>) {
    let state: ASYNC_STATE

    switch (this._state) {
      case ASYNC_STATE.NOT_STARTED:
        state = ASYNC_STATE.LOADING
        break
      case ASYNC_STATE.SUCCEEDED:
        state = ASYNC_STATE.RELOADING
        break
      case ASYNC_STATE.FAILED:
        state = ASYNC_STATE.RETRYING
        break
      case ASYNC_STATE.LOADING:
      case ASYNC_STATE.RELOADING:
      case ASYNC_STATE.RETRYING:
        state = this._state
        break
      default:
        throw new UnreachableCaseError(this._state)
    }

    this._state = state
    this._error = null
    this._request = request
    return this
  }

  succeed() {
    this._state = ASYNC_STATE.SUCCEEDED
    this._request = null
    this._error = null
    return this
  }

  fail(error: Error) {
    this._state = ASYNC_STATE.FAILED
    this._error = error
    return this
  }

  reset() {
    this._state = ASYNC_STATE.NOT_STARTED
    this._error = null
    this._request = null
    return this
  }
}
