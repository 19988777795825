// import polyfills, they will be filtered based on browserslist
// and used in legacy builds
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// we have to register custom hooks (ex. vue-router) BEFORE we register any components
// https://github.com/vuejs/vue-class-component#adding-custom-hooks
import '@/util/registerHooks'
import Vue from 'vue'
import { ModalPlugin, ToastPlugin, VBToggle, VBTooltip } from 'bootstrap-vue'
import Announcer from 'vue-announcer'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n, { formatDateFilter } from '@/i18n'
import LoadingDirective from '@/directives/loading'
import '@/util/customEventPolyfill'
import '@/registerServiceWorker'
import PPClientPlugin from '@/plugins/PPClientPlugin'
import HoneybadgerPlugin from '@/plugins/HoneybadgerPlugin'
import NewRelicPlugin from '@/plugins/NewRelicPlugin'
import GoogleAnalyticsPlugin from '@/plugins/GoogleAnalyticsPlugin'
import locationUtil, { LocationUtil } from '@/util/location'
import tel from '@/util/phoneNumber'
import platform from '@/util/platform'
import sessionMonitor from '@/util/sessionMonitor'
import safelyLoadPlugins from '@/util/safelyLoadPlugins'
import { lowercase, currency } from '@/util/strings'

import '@/styles/index.scss'

declare global {
  interface Window {
    Cypress: boolean
    $ppSessionMonitor: typeof sessionMonitor
    $location: LocationUtil
    google: typeof google
    googlePlacesUtil?: {
      textSearch: Function
      geocode: Function
      details: Function
    }
  }
}

try {
  window.$location = locationUtil
  window.$ppSessionMonitor = sessionMonitor

  Vue.config.productionTip = false

  // Plugins
  Vue.use(PPClientPlugin) // adds the pillpack api client to component as vm.$pillpack
  Vue.use(ModalPlugin) // adds the $bvModal controls
  Vue.use(ToastPlugin) // adds the $bvToast controls
  Vue.use(Announcer) // adds a11y announcer

  safelyLoadPlugins([NewRelicPlugin, HoneybadgerPlugin, GoogleAnalyticsPlugin])

  // Directives
  Vue.directive('b-toggle', VBToggle) // adds the v-b-toggle directive globally
  Vue.directive('b-tooltip', VBTooltip) // adds the v-b-tooltip directive globally
  Vue.directive('loading', LoadingDirective)
  Vue.directive('autofocus', {
    inserted: el => el.focus(),
  })

  // Filters
  Vue.filter('tel', tel)
  Vue.filter('tel.link', tel.link)
  Vue.filter('tel.nowrap', tel.nowrap)
  Vue.filter('date', formatDateFilter)
  Vue.filter('lowercase', lowercase)
  Vue.filter('currency', currency)

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
} catch (e) {
  document.body.classList.add('app-failure')
}

// touch effect forces any interactive elements to re-render and show correct pseudo class states.
window.addEventListener('touchstart', event => {
  const TOUCH_CLASSNAME = 'pillpack--touch-active'
  let target: HTMLElement | null = event.target as HTMLElement
  if (!target) return

  target = target.closest('button') || target.closest('a')
  if (!target) return

  target.classList.add(TOUCH_CLASSNAME)
  const undo = () => {
    if (!target) return
    target.classList.remove(TOUCH_CLASSNAME)
    window.removeEventListener('touchend', undo)
  }

  window.addEventListener('touchend', undo)
})

// tabfocus hack
const TABFOCUS_CLASSNAME = 'pillpack--tabfocus'

window.addEventListener('keydown', event => {
  if (event.key === 'Tab') {
    document.body.classList.add(TABFOCUS_CLASSNAME)
  }
})

window.addEventListener('mousedown', () => {
  document.body.classList.remove(TABFOCUS_CLASSNAME)
})

// pwa install prompt
platform.incrementPageViews()
platform.maybePrompt()
