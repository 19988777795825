




























import { Component, Vue, Prop } from 'vue-property-decorator'
import PageTitle from '@/components/PageTitle.vue'
import PageSubtitle from '@/components/PageSubtitle.vue'

@Component({
  components: {
    PageTitle,
    PageSubtitle,
  },
})
export default class PageHeader extends Vue {
  @Prop(String)
  title?: string

  @Prop(String)
  pretitle?: string

  @Prop(String)
  subtitle?: string

  @Prop(String)
  ariaLabel?: string

  @Prop(Array)
  breadcrumbs?: object[]

  @Prop({ type: Boolean, default: false })
  leftAlignIcon?: boolean

  @Prop({ type: String, default: 'display-3' })
  titleClass?: string

  get hasSubtitle(): boolean {
    return !!(this.subtitle || this.$scopedSlots.subtitle)
  }
}
