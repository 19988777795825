import PPSession from './PPSession'
import { PPError } from './PPError'
import { PPHttp, PPHttpMethod, PPHttpContentType } from './PPHttp'
import { PPResponse, PPFailureResponse, PPSuccessResponse } from './PPResponse'
import { deserialize, serialize, serializeInstance } from '@/models/transforms'
import User from '@/models/User'
import PasswordUpdate from '@/models/PasswordUpdate'
import CareRelationship from '@/models/CareRelationship'
import UserIdentification from '@/models/UserIdentification'
import OnboardState from '@/models/Onboard/OnboardState'
import { PPArgs } from './PPClient'

export default class PPUsersApi {
  static async me({ baseUrl, session, userId, locale }: PPArgs): Promise<PPResponse<User>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const user = deserialize(User, response.data)
    return new PPSuccessResponse(user)
  }

  static async getUser(
    { baseUrl, session, locale }: PPArgs,
    userId: string,
  ): Promise<PPResponse<User>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const user = deserialize(User, response.data)
    return new PPSuccessResponse(user)
  }

  static async getOnboardState({
    baseUrl,
    session,
    userId,
    locale,
  }: PPArgs): Promise<PPResponse<OnboardState>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.GET,
      baseUrl,
      path: `/api/users/${userId}/onboard_state_object`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const state = deserialize(OnboardState, response.data)
    return new PPSuccessResponse(state)
  }

  static async resendEmailConfirmation({
    baseUrl,
    session,
    userId,
    locale,
  }: PPArgs): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/resend_email_confirmation`,
      urlParams: { locale },
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    return PPSuccessResponse.voidResponse()
  }

  static async update(
    { baseUrl, session, userId, locale }: PPArgs,
    user: any,
  ): Promise<PPResponse<User>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.PUT,
      baseUrl,
      path: `/consumer_api/users/${userId}`,
      urlParams: { locale },
      content: JSON.stringify(serialize(User, user)),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const updatedUser = deserialize(User, response.data)
    return new PPSuccessResponse(updatedUser)
  }

  static async updatePassword(
    { baseUrl, session, userId, locale }: PPArgs,
    payload: PasswordUpdate,
  ): Promise<PPResponse<void>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.PUT,
      baseUrl,
      path: `/consumer_api/users/${userId}/update_password`,
      urlParams: { locale },
      content: JSON.stringify({ password: serializeInstance(payload) }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }

    // resetting the password resets the session so we need to update the CSRF token and such
    const newSession = PPSession.fromObject(response.data)
    if (!newSession) {
      throw new PPError('Failed to create new session after updating password.')
    }

    return PPSuccessResponse.withSession(newSession)
  }

  static async addCaregiver(
    { baseUrl, session, userId, locale }: PPArgs,
    email: string,
  ): Promise<PPResponse<CareRelationship>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/care_relationships`,
      urlParams: { locale },
      content: JSON.stringify({ caregiver_email: email }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const newRelationship = deserialize(CareRelationship, response.data)
    return new PPSuccessResponse(newRelationship)
  }

  static async removeCaregiver(
    { baseUrl, session, userId, locale }: PPArgs,
    id: string,
  ): Promise<PPResponse<CareRelationship>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.DELETE,
      baseUrl,
      path: `/api/users/${userId}/care_relationships/${id}`,
      urlParams: { locale },
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const newRelationship = deserialize(CareRelationship, response.data)
    return new PPSuccessResponse(newRelationship)
  }

  static async updateSmsPreference(
    { baseUrl, session, userId, locale }: PPArgs,
    { enable, phoneNumber }: { enable: boolean; phoneNumber?: string },
  ): Promise<PPResponse<User>> {
    const requestObj: any = { return_user: true }
    let path = `/api/users/${userId}/`
    if (enable) {
      path += 'enable_sms'
      requestObj.send_start_message = true
      if (phoneNumber) {
        requestObj.phone_number = phoneNumber
      }
    } else {
      path += 'disable_sms'
      requestObj.send_stop_message = true
    }

    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path,
      urlParams: { locale },
      content: JSON.stringify(requestObj),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const updatedUser = deserialize(User, response.data)
    return new PPSuccessResponse(updatedUser)
  }

  static async updatePhiInCommunicationsPreference(
    { baseUrl, session, userId, locale }: PPArgs,
    enable: boolean,
  ): Promise<PPResponse<User>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.PUT,
      baseUrl,
      path: `/api/users/${userId}/user_preference`,
      urlParams: { locale },
      content: JSON.stringify({ return_user: true, phi_in_communications_enabled: enable }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const updatedUser = deserialize(User, response.data)
    return new PPSuccessResponse(updatedUser)
  }

  static async updateChildSafePackaging(
    { baseUrl, session, userId, locale }: PPArgs,
    enable: boolean,
  ): Promise<PPResponse<User>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.PUT,
      baseUrl,
      path: `/api/users/${userId}/user_preference`,
      urlParams: { locale },
      content: JSON.stringify({ return_user: true, use_child_safe_packaging: enable }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const updatedUser = deserialize(User, response.data)
    return new PPSuccessResponse(updatedUser)
  }

  static async updateUserIdentification(
    { baseUrl, session, userId, locale }: PPArgs,
    identification: UserIdentification,
  ): Promise<PPResponse<User>> {
    const response = await PPHttp.request({
      csrfToken: session.csrfToken,
      method: PPHttpMethod.POST,
      baseUrl,
      path: `/api/users/${userId}/update_identification`,
      urlParams: { locale },
      content: JSON.stringify({
        return_user: true,
        user_identification: serialize(UserIdentification, identification),
      }),
      contentType: PPHttpContentType.JSON,
    })
    if (response.failure) {
      return new PPFailureResponse(response.error)
    }
    const updatedUser = deserialize(User, response.data)
    return new PPSuccessResponse(updatedUser)
  }
}
