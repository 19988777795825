
































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { TranslateResult } from 'vue-i18n'

import { validationStateMixin } from '@/util/validationState'
import PageTitle from '@/components/PageTitle.vue'
import LoadingButton from '@/components/LoadingButton.vue'

@Component({
  components: { PageTitle, LoadingButton },
  mixins: [validationMixin, validationStateMixin],
  validations: {
    email: { required, email },
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError', 'hasSucceeded']),
  },
})
export default class PasswordResetView extends Vue {
  email = ''
  error: TranslateResult | null = null

  resetPassword() {
    this.error = null
    this.$v.$touch()
    if (this.$v.$invalid) {
      this.error = this.$t('_validations.email')
      return
    }
    this.$store.dispatch('resetPassword', { email: this.email })
  }
}
