










import { Vue, Component, Prop } from 'vue-property-decorator'

import Banners from '@/components/Banners.vue'
import AppFooter from '@/components/AppFooter.vue'

@Component({
  components: {
    Banners,
    AppFooter,
  },
})
export default class NoNavbarLayout extends Vue {
  @Prop({ type: String, default: '0' })
  menuHeight!: String
}
