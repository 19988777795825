import { render, staticRenderFns } from "./SearchInput.vue?vue&type=template&id=b0d35670&scoped=true&"
import script from "./SearchInput.vue?vue&type=script&lang=ts&"
export * from "./SearchInput.vue?vue&type=script&lang=ts&"
import style0 from "./SearchInput.vue?vue&type=style&index=0&id=b0d35670&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0d35670",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButtonClose } from 'bootstrap-vue'
import { BFormInput } from 'bootstrap-vue'
import IconClearField from '@/assets/icons/icon-clear-field.svg'
installComponents(component, {BButtonClose,BFormInput,IconClearField})
