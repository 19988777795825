




import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SkeletonLine extends Vue {
  @Prop()
  height!: number | string

  @Prop()
  width!: number | string

  get style() {
    const height = typeof this.height === 'number' ? `${this.height}px` : this.height
    const width = typeof this.width === 'number' ? `${this.width}px` : this.width

    return { width, height }
  }
}
