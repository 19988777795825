import { serializable, primitive } from 'serializr'

export default class TrackingAddress {
  @serializable(primitive())
  city?: string

  @serializable(primitive())
  country?: string

  @serializable(primitive())
  state?: string
}
