import { serializable, primitive, alias, object } from 'serializr'
import SmsPhoneNumber from './SmsPhoneNumber'
import getUserColor from '@/util/userColor'

export default class CareUser {
  @serializable(primitive())
  id!: string

  @serializable(alias('first_name', primitive()))
  firstName!: string

  @serializable(alias('last_name', primitive()))
  lastName!: string

  @serializable(primitive())
  name!: string

  @serializable(primitive())
  female!: boolean

  @serializable(alias('preferred_phone_number', object(SmsPhoneNumber)))
  preferredPhoneNumber?: SmsPhoneNumber

  @serializable(primitive())
  email!: string

  @serializable(alias('image_url', primitive()))
  imageUrl!: string

  @serializable(alias('signed_up', primitive()))
  signedUp!: boolean

  @serializable(alias('batch_code', primitive()))
  batchCode!: string

  get color() {
    return getUserColor(this.id)
  }

  get fullName() {
    return this.name
  }
}
