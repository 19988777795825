export const PHONE_NUMBER_REGEX = /^(1|)?(\d{3})(\d{3})(\d{4})$/
export const DEFAULT_DASH = '-'

// PillPack numbers
export const PILLPACK_PHONE = '855-745-5725'
export const PILLPACK_FAX = '603-935-9108'

// Cohort specific numbers
export const AEB_PHONE = '855-406-4088'
export const CERPASS_PHONE = '855-966-0966'
export const HORIZON_PHONE = '855-494-4897'

/**
 * 'link' option creates a string that is compatible with href attribute on <a>links</a>
 * 'nowrap' option substitutes the normal dash for a non-breaking HTML dash which prevents text from wrapping
 */
export interface FormatOptions {
  dash?: string | false
  nowrap?: boolean
  link?: boolean
  countryCode?: boolean
}

const defaultOptions: FormatOptions = {
  nowrap: false,
  link: false,
  countryCode: true,
}

function matchPhoneNumber(value: string) {
  const cleaned = `${value}`.replace(/\D/g, '')
  return cleaned.match(PHONE_NUMBER_REGEX)
}

export function stripNonPhoneDigits(str: string): string {
  let phoneNumber = str.replace(/\D/g, '') // strip non-digits such as +
  phoneNumber = phoneNumber.replace(/^1/g, '') // strip leading 1 (country code)
  return phoneNumber
}

export function isPhoneNumber(value: string): boolean {
  return !!matchPhoneNumber(value)
}

export function tel(value: string, formatOptions?: FormatOptions): string {
  const options = { ...defaultOptions, ...formatOptions }
  const match = matchPhoneNumber(value)
  if (match) {
    let dash = DEFAULT_DASH

    // dash is optional but it should be possible to not use a dash at all
    if (options.dash !== undefined) dash = options.dash || ''

    // use non-breaking HTML dashes unless its a link, then use the default/provided dash
    if (options.nowrap && !options.link) dash = '\u2011'

    const countryCode = options.countryCode && match[1] ? `1${dash}` : ''
    const prefix = options.link ? 'tel:' : ''
    return [prefix, countryCode, match[2], dash, match[3], dash, match[4]].join('')
  }
  return ''
}

tel.link = (value: string, options?: FormatOptions): string => {
  return tel(value, { ...options, link: true })
}

tel.nowrap = (value: string, options?: FormatOptions): string => {
  return tel(value, { ...options, nowrap: true })
}

export default tel
