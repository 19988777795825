





























import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

import { ErrorModalState } from '@/store'
import Modal from '@/components/Modal.vue'
import GenericError from '@/components/GenericError.vue'

@Component({
  components: {
    Modal,
    GenericError,
  },
  computed: {
    ...mapState(['errorModal']),
  },
})
export default class ErrorModal extends Vue {
  errorModal!: ErrorModalState

  reload() {
    window.location.reload()
  }

  onHide() {
    this.$store.commit('hideErrorModal')
  }
}
