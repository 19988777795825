
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { BvModalEvent } from 'bootstrap-vue'
import Loading from '@/components/Loading.vue'

@Component({
  components: {
    Loading,
  },
})
export default class Modal extends Vue {
  @Prop({ type: Boolean, default: false })
  confirmBeforeClose!: boolean

  @Prop({ type: Boolean, default: false })
  loading?: boolean

  isConfirmingClose: boolean = false

  cancelClose() {
    this.isConfirmingClose = false
  }

  onHide(e: BvModalEvent) {
    if (this.loading) {
      e.preventDefault()
      return
    }
    if (this.shouldConfirmClose(e)) {
      e.preventDefault()

      this.isConfirmingClose = true
      this.scrollToTop()
    } else {
      // Re-emit the event so that the parent component knows that
      // the modal is actually closing
      this.$emit('hide', e)
    }
  }

  onHidden() {
    this.isConfirmingClose = false
  }

  scrollToTop() {
    if (!document) return
    if (!this.$attrs.id) return

    const modalElement = document.getElementById(this.$attrs.id)
    if (!modalElement) return
    if (typeof modalElement.scrollTo !== 'function') return

    modalElement.scrollTo(0, 0)
  }

  shouldConfirmClose(e: BvModalEvent) {
    return !(
      !this.confirmBeforeClose ||
      e.trigger === 'ok' ||
      (this.isConfirmingClose && e.trigger === 'confirm')
    )
  }

  get listeners() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { hide, ...rest } = this.$listeners

    return rest
  }
}
